import { getLineChart } from 'api/chart';
import { useQuery } from 'react-query';

export function useLineChart(base: string, quote: string, day: string) {
  return useQuery({
    queryKey: ['coinPair', base, quote, day],
    queryFn: () => {
      return getLineChart(base, quote, day);
    },
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: true,
  });
}
