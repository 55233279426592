import React from 'react';

export default function Harmony() {
  return (
    <svg width='30' height='30' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient
          y2='0.69155'
          x2='-0.18946'
          y1='0'
          x1='0.5'
          id='paint0_linear'
        >
          <stop stopColor='#00E8A2' />
          <stop stopColor='#00ADE8' offset='1' />
        </linearGradient>
      </defs>
      <g>
        <title>Layer 1</title>
        <g id='页面-1' fill='none' fillRule='evenodd'>
          <g id='2' fill='#00ADE8'>
            <circle
              id='椭圆形备份-3'
              fillOpacity='0.1'
              cx='14.83193'
              cy='15'
              r='15'
            />
            <g stroke='null' id='编组-19' fillRule='nonzero'>
              <path
                stroke='null'
                id='svg_1'
                fill='url(#paint0_linear)'
                d='m19.16574,7.73206c-1.72062,0 -3.12427,1.40368 -3.16959,3.1243l0,3.48655c-0.31698,0 -0.63388,0.04528 -0.99618,0.04528c-0.36222,0 -0.67918,0 -0.99614,0.04528l0,-3.57711c0,-1.72063 -1.44895,-3.1243 -3.21487,-3.07902c-1.67536,0 -3.03376,1.40368 -3.07903,3.07902l0,8.33149c0.04528,1.72062 1.44896,3.12434 3.21487,3.07902c1.72064,-0.04525 3.07902,-1.40365 3.07902,-3.07902l0,-3.48657c0.31696,0 0.63392,-0.04525 0.99614,-0.04525c0.36229,0 0.6792,0 0.99618,-0.04525l0,3.53182c0.04532,1.72062 1.44897,3.12427 3.21484,3.07902c1.72069,-0.04525 3.07909,-1.40365 3.07909,-3.07902l0,-8.28624c0,-1.72063 -1.40372,-3.1243 -3.12434,-3.1243l0.00001,0zm-8.28621,1.26784c1.04144,0 1.85647,0.81503 1.85647,1.85647l0,3.66768c-0.9056,0.13583 -1.81119,0.40754 -2.67152,0.76977c-0.36224,0.18108 -0.72448,0.36222 -1.04144,0.63388l0,-5.07132c0,-0.99615 0.81503,-1.85647 1.85647,-1.85647l0.00001,0zm1.85647,10.18796c0,1.04142 -0.81503,1.85652 -1.85647,1.85652c-1.04144,0 -1.85647,-0.8151 -1.85647,-1.85652l0,-0.81503c0,-0.72445 0.58864,-1.44897 1.5848,-1.85645c0.6792,-0.31698 1.40368,-0.49812 2.17343,-0.63395l-0.04528,3.30542l-0.00001,0zm6.42973,1.85652c-1.04142,0 -1.85645,-0.8151 -1.85645,-1.85652l0,-3.66765c0.9056,-0.13583 1.81119,-0.40754 2.67147,-0.76977c0.36222,-0.18115 0.72452,-0.36224 1.04142,-0.63392l0,5.07133c0,1.04142 -0.86028,1.85652 -1.85645,1.85652l0,0.00001zm0.27165,-7.47121c-0.6792,0.31696 -1.40365,0.49807 -2.17342,0.63392l0,-3.35072c0,-1.04143 0.8151,-1.85647 1.85652,-1.85647c1.04142,0 1.85645,0.81503 1.85645,1.85647l0,0.81503c0.04525,0.76976 -0.54337,1.44896 -1.53955,1.90176l0.00001,0z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
