import { ArrowRightIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import CardTrader from 'components/common/CardTrader';
import TemplateText from 'components/common/Text/TemplateText';
import { TOP_TRADERS } from 'data/copytrading';
import React from 'react';

export default function TopTradersTab() {
  return (
    <>
      {TOP_TRADERS.map((item) => (
        <Box mt='20px' key={item.key}>
          <Flex alignItems='center' justifyContent='space-between'>
            <Box>
              <TemplateText txt={item.showColumnDesc} fontSize='32px' />
              <TemplateText txt={item.showColumnIllustrate} />
            </Box>
            <Flex cursor='pointer' alignItems='center' gap='10px'>
              <TemplateText txt='See more' />
              <ArrowRightIcon h='14px' w='14px' color='text.500' />
            </Flex>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap='16px' mt='20px'>
            <CardTrader traders={item.ShowColumnValue} />
          </SimpleGrid>
        </Box>
      ))}
    </>
  );
}
