import React from 'react';

export default function SelectTrader() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_i_29_198)'>
        <rect
          x='1.82762'
          y='2.81228'
          width='72'
          height='52'
          rx='4'
          transform='matrix(0.913812 0.406138 0 1 31.1813 12.2577)'
          fill='#eff3fb'
          stroke='#162239'
          strokeWidth='4'
        />
      </g>
      <rect
        width='76'
        height='56'
        rx='6'
        transform='matrix(0.913812 0.406138 0 1 19.0238 22)'
        fill='#162239'
      />
      <rect
        width='24'
        height='10'
        rx='4'
        transform='matrix(0.913812 0.406138 0 1 42.7518 44.5234)'
        fill='#eff3fb'
      />
      <rect
        width='40'
        height='5'
        rx='2'
        transform='matrix(0.913812 0.406138 0 1 42.7518 61.3235)'
        fill='#eff3fb'
      />
      <rect
        width='40'
        height='5'
        rx='2'
        transform='matrix(0.913812 0.406138 0 1 42.7518 72.5234)'
        fill='#eff3fb'
      />
      <mask id='path-6-inside-1_29_198' fill='#eff3fb'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.3272 75.1057C31.4158 78.0823 36.6303 74.2226 36.9742 66.4848C37.3181 58.747 32.6611 50.0612 26.5725 47.0845C20.484 44.1079 15.2694 47.9676 14.9255 55.7054C14.5816 63.4432 19.2386 72.129 25.3272 75.1057ZM15.7999 72.2372C11.8858 70.3237 8.55594 72.3008 8.3625 76.6534C8.16905 81.0059 11.1852 86.0856 15.0993 87.9991L33.9983 97.2386C37.9124 99.1521 41.2422 97.175 41.4356 92.8225C41.6291 88.4699 38.6129 83.3903 34.6988 81.4767L15.7999 72.2372Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.3272 75.1057C31.4158 78.0823 36.6303 74.2226 36.9742 66.4848C37.3181 58.747 32.6611 50.0612 26.5725 47.0845C20.484 44.1079 15.2694 47.9676 14.9255 55.7054C14.5816 63.4432 19.2386 72.129 25.3272 75.1057ZM15.7999 72.2372C11.8858 70.3237 8.55594 72.3008 8.3625 76.6534C8.16905 81.0059 11.1852 86.0856 15.0993 87.9991L33.9983 97.2386C37.9124 99.1521 41.2422 97.175 41.4356 92.8225C41.6291 88.4699 38.6129 83.3903 34.6988 81.4767L15.7999 72.2372Z'
        fill='#162239'
      />
      <path
        d='M15.0993 87.9991L15.1881 86.0011L15.0993 87.9991ZM33.9983 97.2386L33.9095 99.2366L33.9983 97.2386ZM35.1775 65.6064C34.8711 72.4997 30.2944 75.4926 25.416 73.1076L25.2384 77.1037C32.5372 80.672 38.3896 75.9456 38.771 67.3632L35.1775 65.6064ZM26.4837 49.0826C31.3622 51.4676 35.4838 58.7131 35.1775 65.6064L38.771 67.3632C39.1524 58.7809 33.9601 48.6548 26.6613 45.0865L26.4837 49.0826ZM16.7222 56.5838C17.0286 49.6905 21.6053 46.6976 26.4837 49.0826L26.6613 45.0865C19.3626 41.5182 13.5101 46.2446 13.1287 54.827L16.7222 56.5838ZM25.416 73.1076C20.5376 70.7226 16.4159 63.4771 16.7222 56.5838L13.1287 54.827C12.7473 63.4093 17.9396 73.5354 25.2384 77.1037L25.416 73.1076ZM10.1593 77.5318C10.3037 74.2827 12.7893 72.8068 15.7111 74.2353L15.8887 70.2392C10.9822 67.8405 6.80822 70.3189 6.56573 75.7749L10.1593 77.5318ZM15.1881 86.0011C12.2664 84.5727 10.0149 80.7808 10.1593 77.5318L6.56573 75.7749C6.32324 81.231 10.1041 87.5984 15.0105 89.9971L15.1881 86.0011ZM34.0871 95.2406L15.1881 86.0011L15.0105 89.9971L33.9095 99.2366L34.0871 95.2406ZM39.6389 91.944C39.4945 95.1931 37.0088 96.669 34.0871 95.2406L33.9095 99.2366C38.8159 101.635 42.9899 99.1569 43.2324 93.7009L39.6389 91.944ZM34.61 83.4747C37.5318 84.9032 39.7833 88.695 39.6389 91.944L43.2324 93.7009C43.4749 88.2449 39.694 81.8774 34.7876 79.4787L34.61 83.4747ZM15.7111 74.2353L34.61 83.4747L34.7876 79.4787L15.8887 70.2392L15.7111 74.2353Z'
        fill='#eff3fb'
        mask='url(#path-6-inside-1_29_198)'
      />
      <mask id='path-8-inside-2_29_198' fill='#eff3fb'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.3149 76.1057C25.4035 79.0823 30.6181 75.2226 30.962 67.4848C31.3059 59.747 26.6489 51.0612 20.5603 48.0845C14.4717 45.1079 9.25715 48.9676 8.91325 56.7054C8.56934 64.4432 13.2263 73.129 19.3149 76.1057ZM9.78763 73.2372C5.87353 71.3237 2.54371 73.3008 2.35026 77.6534C2.15682 82.0059 5.17301 87.0856 9.08711 88.9991L27.986 98.2386C31.9001 100.152 35.23 98.175 35.4234 93.8225C35.6168 89.4699 32.6007 84.3903 28.6866 82.4767L9.78763 73.2372Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.3149 76.1057C25.4035 79.0823 30.6181 75.2226 30.962 67.4848C31.3059 59.747 26.6489 51.0612 20.5603 48.0845C14.4717 45.1079 9.25715 48.9676 8.91325 56.7054C8.56934 64.4432 13.2263 73.129 19.3149 76.1057ZM9.78763 73.2372C5.87353 71.3237 2.54371 73.3008 2.35026 77.6534C2.15682 82.0059 5.17301 87.0856 9.08711 88.9991L27.986 98.2386C31.9001 100.152 35.23 98.175 35.4234 93.8225C35.6168 89.4699 32.6007 84.3903 28.6866 82.4767L9.78763 73.2372Z'
        fill='#273550'
      />
      <path
        d='M9.78763 73.2372L9.87643 71.2392L9.78763 73.2372ZM28.6866 82.4767L28.5978 84.4747L28.6866 82.4767ZM29.1652 66.6064C28.8589 73.4997 24.2821 76.4926 19.4037 74.1076L19.2261 78.1037C26.5249 81.672 32.3773 76.9456 32.7588 68.3632L29.1652 66.6064ZM20.4715 50.0826C25.3499 52.4676 29.4716 59.7131 29.1652 66.6064L32.7588 68.3632C33.1402 59.7809 27.9479 49.6548 20.6491 46.0865L20.4715 50.0826ZM10.71 57.5838C11.0164 50.6905 15.5931 47.6976 20.4715 50.0826L20.6491 46.0865C13.3503 42.5182 7.49791 47.2446 7.11648 55.827L10.71 57.5838ZM19.4037 74.1076C14.5253 71.7226 10.4036 64.4771 10.71 57.5838L7.11648 55.827C6.73504 64.4093 11.9273 74.5354 19.2261 78.1037L19.4037 74.1076ZM4.14703 78.5318C4.29143 75.2827 6.77706 73.8068 9.69883 75.2353L9.87643 71.2392C4.97001 68.8405 0.795985 71.3189 0.553496 76.7749L4.14703 78.5318ZM9.17591 87.0011C6.25414 85.5727 4.00263 81.7808 4.14703 78.5318L0.553496 76.7749C0.311006 82.231 4.09188 88.5984 8.9983 90.9971L9.17591 87.0011ZM28.0748 96.2406L9.17591 87.0011L8.9983 90.9971L27.8972 100.237L28.0748 96.2406ZM33.6266 92.944C33.4822 96.1931 30.9966 97.669 28.0748 96.2406L27.8972 100.237C32.8037 102.635 36.9777 100.157 37.2202 94.7009L33.6266 92.944ZM28.5978 84.4747C31.5195 85.9032 33.771 89.695 33.6266 92.944L37.2202 94.7009C37.4627 89.2449 33.6818 82.8774 28.7754 80.4787L28.5978 84.4747ZM9.69883 75.2353L28.5978 84.4747L28.7754 80.4787L9.87643 71.2392L9.69883 75.2353Z'
        fill='#eff3fb'
        mask='url(#path-8-inside-2_29_198)'
      />
      <defs>
        <filter
          id='filter0_i_29_198'
          x='31.0238'
          y='14.9592'
          width='69.4497'
          height='82.9482'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-6' dy='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_29_198'
          />
        </filter>
      </defs>
    </svg>
  );
}
