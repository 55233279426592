export const FAKE_DATA = [
  {
    id: 34917798,
    coin_id: 1,
    price: 30086.50594597629,
    volume: 10229545967,
    datetime: '2023-04-17T09:07:00.000Z',
  },
  {
    id: 34919023,
    coin_id: 1,
    price: 30086.907522898604,
    volume: 10226019575,
    datetime: '2023-04-17T09:08:00.000Z',
  },
  {
    id: 34921683,
    coin_id: 1,
    price: 30086.236006132258,
    volume: 10227433986,
    datetime: '2023-04-17T09:09:00.000Z',
  },
  {
    id: 34923464,
    coin_id: 1,
    price: 30082.269245960535,
    volume: 10226051474,
    datetime: '2023-04-17T09:10:00.000Z',
  },
  {
    id: 34925244,
    coin_id: 1,
    price: 30084.091074044696,
    volume: 10225645994,
    datetime: '2023-04-17T09:11:00.000Z',
  },
  {
    id: 34927024,
    coin_id: 1,
    price: 30084.74277658264,
    volume: 10226608433,
    datetime: '2023-04-17T09:12:00.000Z',
  },
  {
    id: 34928805,
    coin_id: 1,
    price: 30087.234805108146,
    volume: 10225762410,
    datetime: '2023-04-17T09:13:00.000Z',
  },
  {
    id: 34930586,
    coin_id: 1,
    price: 30090.460705245507,
    volume: 10228790590,
    datetime: '2023-04-17T09:14:00.000Z',
  },
  {
    id: 34932367,
    coin_id: 1,
    price: 30091.20706937319,
    volume: 10230392001,
    datetime: '2023-04-17T09:15:00.000Z',
  },
  {
    id: 34934148,
    coin_id: 1,
    price: 30094.04673770394,
    volume: 10242131877,
    datetime: '2023-04-17T09:16:00.000Z',
  },
  {
    id: 34935929,
    coin_id: 1,
    price: 30091.034328161135,
    volume: 10232271589,
    datetime: '2023-04-17T09:17:00.000Z',
  },
  {
    id: 34937710,
    coin_id: 1,
    price: 30092.192451225605,
    volume: 10242184511,
    datetime: '2023-04-17T09:18:00.000Z',
  },
  {
    id: 34939491,
    coin_id: 1,
    price: 30091.641210934165,
    volume: 10245542900,
    datetime: '2023-04-17T09:19:00.000Z',
  },
  {
    id: 34941272,
    coin_id: 1,
    price: 30091.59783985379,
    volume: 10247196874,
    datetime: '2023-04-17T09:20:00.000Z',
  },
];
