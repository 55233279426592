import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import Search from 'components/svg/Search';
import { ChakraType } from 'constants/types';

interface SearchInputProps {
  h?: ChakraType;
  w?: ChakraType;
  value?: string;
  border?: ChakraType;
  borderColor?: string;
  className?: string;
  placeholder?: string;
  paddingRight?: string;
  backgroundColor?: string;
  onChange: (value: string) => void;
  isFocus?: boolean;
  borderRadius?: string;
}

export default function SearchInput({
  h,
  w,
  value,
  className,
  placeholder,
  onChange,
  borderColor,
  backgroundColor,
  border,
  isFocus = false,
  borderRadius,
  ...rest
}: SearchInputProps) {
  const focusUsernameInputField = (input: HTMLInputElement | null) => {
    if (input && isFocus) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  return (
    <InputGroup w={w} h={h}>
      <Input
        border={border}
        borderColor={borderColor}
        type='text'
        fontSize='14px'
        value={value}
        color='text.500'
        className={className}
        placeholder={placeholder}
        bg={backgroundColor}
        onChange={(e) => onChange(e.target.value)}
        ref={focusUsernameInputField}
        borderRadius={borderRadius ? borderRadius : '8px'}
        {...rest}
      />

      <InputRightElement h={h} cursor='pointer'>
        <Search />
      </InputRightElement>
    </InputGroup>
  );
}
