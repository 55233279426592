import { SimpleGrid } from '@chakra-ui/react';
import CardTrader from 'components/common/CardTrader';
import { ALL_TRADERS } from 'data/copytrading';
import React, { useState } from 'react';

interface props {
  valueSearchTrader: string;
  isHideFull: boolean;
  optionFilter: string;
}

export default function AllTraderTab({
  valueSearchTrader,
  isHideFull,
  optionFilter,
}: props) {
  const [notFoundTrader, setNotFoundTrader] = useState(false);
  return (
    <>
      <SimpleGrid
        columns={notFoundTrader ? 1 : { base: 1, md: 2, lg: 3 }}
        gap='16px'
        mt='20px'
      >
        <CardTrader
          setNotFoundTrader={setNotFoundTrader}
          searchTrader={valueSearchTrader}
          isHideFull={isHideFull}
          optionFilter={optionFilter}
          traders={ALL_TRADERS}
          notFoundTrader={notFoundTrader}
        />
      </SimpleGrid>
    </>
  );
}
