import React from 'react';

export default function StartIcon() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.5134 18L103.513 50.0019V95.0463L100.239 97L46.5134 63.2895V18Z'
        fill='#162239'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.3561 52.2533V98.2192L84.791 105.28L84.791 58.4082L97.3561 52.2533Z'
        fill='#eff3fb'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.5134 56.9924V103.197C86.5134 103.982 85.6501 104.461 84.984 104.046L31.5134 70.6791V25'
        fill='#162239'
      />
      <path
        d='M86.5134 56.9924V103.197C86.5134 103.982 85.6501 104.461 84.984 104.046L31.5134 70.6791V25'
        stroke='#162239'
        strokeWidth='4'
      />
      <path
        d='M33.7079 25.1763L45.7971 19.2687L98.2929 50.1367L85.7531 56.2722L33.7079 25.1763Z'
        fill='#eff3fb'
        stroke='#162239'
        strokeWidth='4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.7577 21.4675V30.0123L37.7996 25.2392L45.7577 21.4675Z'
        fill='#A4A9AE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.3562 52.8215L100.239 51.426V97L97.3562 95.2325V52.8215Z'
        fill='#A4A9AE'
      />
      <path
        d='M39.5134 67.8519L50.6594 60.4213C51.1274 60.1093 51.7606 60.2445 52.0603 60.7205L59.5091 72.5509C59.792 73.0002 60.3768 73.1501 60.8409 72.8922L78.0134 63.3519'
        stroke='#eff3fb'
        strokeWidth='5'
        strokeLinecap='round'
      />
      <circle
        r='20'
        transform='matrix(0.898384 0.43921 -0.0444006 0.999014 27.3773 66.0551)'
        fill='#162239'
        stroke='#eff3fb'
        strokeWidth='2'
      />
      <circle
        r='20'
        transform='matrix(0.898384 0.43921 -0.0444006 0.999014 21.7985 70.0546)'
        fill='#273550'
        stroke='#eff3fb'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5129 61.9264C20.0167 61.6838 19.5946 61.9345 19.5701 62.4862L19.3481 67.4813L14.8562 65.2852C14.36 65.0427 13.9379 65.2933 13.9134 65.845L13.8246 67.8431C13.8001 68.3948 14.1824 69.0387 14.6786 69.2813L19.1705 71.4773L18.9485 76.4724C18.924 77.0241 19.3063 77.6681 19.8025 77.9106L21.5992 78.789C22.0954 79.0316 22.5175 78.781 22.542 78.2292L22.764 73.2342L27.2559 75.4302C27.7521 75.6728 28.1742 75.4222 28.1987 74.8704L28.2875 72.8724C28.312 72.3206 27.9297 71.6767 27.4335 71.4342L22.9416 69.2381L23.1636 64.243C23.1881 63.6913 22.8058 63.0474 22.3096 62.8048L20.5129 61.9264Z'
        fill='#eff3fb'
      />
      <rect
        x='84.5908'
        y='102.479'
        width='19.3898'
        height='4'
        transform='rotate(-33 84.5908 102.479)'
        fill='#162239'
      />
    </svg>
  );
}
