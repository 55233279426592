import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { LIST_TAB_SWAP } from 'data/Swap';

export default function Swap() {
  return (
    <Tabs py='30px' variant='enclosed'>
      <TabList w='fit-content' borderColor='bg.100'>
        {LIST_TAB_SWAP.map((item) => (
          <Tab
            p={{ base: '5px', md: '16px' }}
            key={item.name}
            _selected={{
              color: 'text.500',
              borderBottom: '5px solid transparent',
              borderImageSlice: 1,
              borderImageSource: 'linear-gradient(190deg,#1d6dff,#132038)',
              borderTop: '0px solid transparent',
              borderRight: '0px solid transparent',
              borderLeft: '0px solid transparent',
              marginTop: '5px',
            }}
            color='text.400'
            fontSize='18px'
            fontWeight={600}
            w={LIST_TAB_SWAP.length > 1 ? '50%' : '100%'}
            gap='10px'
          >
            {item.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {LIST_TAB_SWAP.map((item) => (
          <TabPanel p='0px' key={item.name}>
            {item.element}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
