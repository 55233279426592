import CopyTrading from 'components/svg/CopyTrading';
import FutureIcon from 'components/svg/FurureIcon';
import LimitOrder from 'components/svg/LimitOrder';
import Pool from 'components/svg/Pool';
import Referral from 'components/svg/Referral';
import SpotCopyTrade from 'components/svg/SpotCopyTrade';
import SwapIcon from 'components/svg/SwapIcon';
import { NavItem } from 'constants/interface';

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Spot Trading',

    children: [
      {
        label: 'Swap',
        icon: <SwapIcon />,
        description: 'Swap at the best rates',
        href: '/trade?feature=swap',
      },
      {
        label: 'Limit Order',
        icon: <LimitOrder />,
        description: 'Gasless limit orders',
        href: '/trade?feature=limitOrder',
      },
    ],
    href: '#!',
  },
  {
    label: 'Perpetual',
    children: [],
    href: '/future',
  },
  // {
  //   label: 'Pool',

  //   children: [
  //     // {
  //     //   label: 'Pool',
  //     //   description: 'Stake tokens and earn',
  //     //   icon: <Pool />,
  //     //   href: '/liquidity',
  //     // },
  //     // {
  //     //   label: 'Referral',
  //     //   icon: <Referral />,
  //     //   description: 'Invite friends and earn rewards',
  //     //   href: '/referral',
  //     // },
  //   ],
  //   href: '/liquidity',
  // },
  // {
  //   label: 'Copy Trading',

  //   children: [
  //     {
  //       label: 'Future Copy Trading',
  //       icon: <CopyTrading />,
  //       description: 'Copy trades from top traders',
  //       href: '/copytrading/futures',
  //     },
  //     {
  //       label: 'Spot Copy Trading',
  //       icon: <SpotCopyTrade />,
  //       description: 'Follow spot trading experts',
  //       href: '/copytrading/spot',
  //     },
  //   ],
  //   href: '#!',
  // },
  // {
  //   label: 'Launchpad',
  //   href: '!#',
  //   disable: true,
  // },
];
export default NAV_ITEMS;
