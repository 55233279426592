import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Input,
  SlideFade,
  useDisclosure,
} from '@chakra-ui/react';
import ArrowBottomMenu from 'components/svg/ArrowBottomMenu';
import { CURRENCY_TYPE } from 'constants/enum';
import { ChildrenPoolType, PoolType } from 'constants/interface';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import TemplateText from '../Text/TemplateText';

interface Props {
  item: PoolType;
  index: number;
  setConvertCoin: (value: ChildrenPoolType) => void;
  setConvertMoney: (value: ChildrenPoolType) => void;

  name: string;
  isOpen?: boolean;
  onClose?: () => void;
  newArrayPool?: PoolType[];
}

export default function WrapSelectCoin({
  item,
  index,
  name,
  setConvertCoin = () => ({}),
  setConvertMoney = () => ({}),
  newArrayPool,
}: Props) {
  const [coin, setCoin] = useState(item.children[0]);

  useEffect(() => {
    if (name === CURRENCY_TYPE.COIN) {
      return setConvertCoin(coin);
    }
    return setConvertMoney(coin);
  }, [coin, name, setConvertCoin, setConvertMoney]);

  const [arrayCoins, setArrayCoin] = useState(newArrayPool);
  const [valueSearch, setValueSearch] = useState('');

  useEffect(() => {
    const newArrSearch: PoolType[] = [];

    newArrayPool?.forEach((item) => {
      if (
        item.children.filter((it) =>
          it.name.toLowerCase().includes(valueSearch.toLowerCase())
        ).length > 0
      ) {
        return newArrSearch.push({
          name: item.name,
          children: item.children.filter((it) =>
            it.name.toLowerCase().includes(valueSearch.toLowerCase())
          ),
        });
      }
    });

    setArrayCoin(newArrSearch);
  }, [newArrayPool, valueSearch]);

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Flex
        w='100%'
        onClick={onToggle}
        key={item.name}
        p='16px 20px 16px 16px'
        alignItems='center'
        borderRadius={10}
        bg='bg.300'
      >
        <Flex
          w='100%'
          cursor='pointer'
          alignItems='center'
          justifyContent='center'
          gap='10px'
        >
          <Flex alignItems='center' gap='10px'>
            <Box>{coin.icon}</Box>
            <TemplateText fontWeight={400} txt={coin.name} />
          </Flex>

          <ArrowBottomMenu w='15px' h='15px' />
        </Flex>
        {index == 1 && (
          <Icon
            as={AddIcon}
            position='absolute'
            top='52%'
            left='49%'
            color='text.400'
            ml='2px'
            h='20px'
            w='20px'
            m='20px 0 '
          />
        )}
      </Flex>
      {isOpen && (
        <Box
          position={isOpen ? 'absolute' : 'unset'}
          top='0'
          left='0px'
          bg='bg.200'
          borderRadius='30px'
          zIndex={999}
          w={{ base: '100%', lg: '460px' }}
          h='420px'
        >
          <SlideFade in={isOpen} offsetX='100px'>
            <Box w='100%'>
              <Flex
                justifyContent='center'
                position='relative'
                alignItems='center'
                h='81px'
                bg='bg.300'
                borderRadius='30px 30px 0 0'
                p='30px 30px 30px'
              >
                <TemplateText fontSize='18px' txt='Select a Token' />

                <CloseButton
                  position='absolute'
                  right='30px'
                  bg='bg.200'
                  color='text.500'
                  borderRadius='100px'
                  onClick={onClose}
                  _hover={{ color: 'text.400' }}
                />
              </Flex>
              <Box h='389px' p='10px 30px 30px'>
                <Input
                  border='1px solid #28344b'
                  bg='bg.300'
                  p='8px 16px'
                  placeholder='Search name or paste address'
                  borderRadius='8px'
                  fontSize='16px'
                  color='text.500'
                  onChange={(e) => setValueSearch(e.target.value)}
                  mt='10px'
                  _hover={{ boxShadow: 'none' }}
                />
                {arrayCoins &&
                  arrayCoins[0]?.children.map((child) => (
                    <React.Fragment key={uuidv4()}>
                      <Flex
                        alignItems='center'
                        gap='10px'
                        h='56px'
                        mt='20px'
                        p='0px 10px'
                        borderRadius='10px'
                        _hover={{ background: '#ffffff08' }}
                        onClick={() => {
                          onClose();
                          setCoin(child);
                          setValueSearch('');
                        }}
                      >
                        <Box>{child.icon}</Box>
                        <Box>
                          <TemplateText
                            textAlign='start'
                            mb='5px'
                            txt={child.name}
                          />
                          <TemplateText
                            fontWeight={400}
                            color='text.400'
                            txt={child.label}
                          />
                        </Box>
                      </Flex>
                    </React.Fragment>
                  ))}
              </Box>
            </Box>
          </SlideFade>
        </Box>
      )}
    </>
  );
}
