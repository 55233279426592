import NoData from 'components/common/NoData';
import React from 'react';

export default function HistoryTab() {
  return (
    <NoData
      title='No record.'
      content={`Let's start trading now.`}
      isHistoryTab
    />
  );
}
