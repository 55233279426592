interface Props {
  w?: string;
  h?: string;
  color?: string;
}

export default function ArrowBottomMenu({ w, h, color }: Props) {
  return (
    <svg
      width={w}
      height={h}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.99999 3.78132L8.29999 0.481323L9.24266 1.42399L4.99999 5.66666L0.757324 1.42399L1.69999 0.481323L4.99999 3.78132Z'
        fill={color || '#eff3fb'}
      />
    </svg>
  );
}
