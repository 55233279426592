import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ERC20_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import { ConnectKitButton } from 'connectkit';
import useVisible from 'hook/useVisible';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DAI_ADDRESS,ROUTE_ADDRESS, USDT_ADDRESS } from 'utils/ERC20';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

import ModalFutureLong from './ModalFutureLong';
import ModalFutureShort from './ModalFutureShort';
import ModalSuccess from './ModalSuccess';

interface props {
  isLongTab: boolean;
  isApprove?: boolean;
  symbolCoin?: string;
  valueInputPay: number;
  leverage: number;
  isInfinity: boolean;
  settingSlippage: number;
  timeInterval: boolean;
}

export default function ButtonAction({
  isLongTab,
  symbolCoin,
  valueInputPay,
  leverage,
  isInfinity,
  settingSlippage,
  timeInterval,
}: props) {
  const futureLongModal = useVisible();
  const futureShortModal = useVisible();
  const successModal = useVisible();
  const { isConnected, address } = useAccount();
  const processingModal = useVisible();
  const [message, setMessage] = useState('');

  const { data: allowance }: any = useContractRead({
    address: isLongTab ? DAI_ADDRESS : USDT_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'allowance',
    enabled: isConnected && timeInterval,
    args: [address, ROUTE_ADDRESS],
  });

  const LinkPrice = BigNumber(10).pow(18).toNumber();

  const allowanceNumber: BigNumber = useMemo(() => {
    if (allowance) {
      return BigNumber(allowance.toString() / LinkPrice);
    }
    return BigNumber(0);
  }, [LinkPrice, allowance]);

  const { config: configApprove } = usePrepareContractWrite({
    address: isLongTab ? DAI_ADDRESS : USDT_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'approve',
    enabled: isConnected,
    args: [ROUTE_ADDRESS, BigNumber(1000 * 18)],
  });

  const { write: writeApprove, isLoading: isLoadingApprove } =
    useContractWrite(configApprove);

  const isApprove = useMemo(() => {
    return valueInputPay <= allowanceNumber.toNumber();
  }, [valueInputPay, allowanceNumber]);

  useEffect(() => {
    processingModal.visible &&
      !isLoadingApprove &&
      new Promise(() => {
        setTimeout(processingModal.hide, 5000);
      });
    successModal.visible &&
      new Promise(() => {
        setTimeout(successModal.hide, 3000);
      });
  }, [
    processingModal.visible,
    processingModal.hide,
    isLoadingApprove,
    successModal,
  ]);

  const handleApprove = useCallback(() => {
    setMessage('Approving');
    processingModal.show();
    writeApprove?.();
  }, [writeApprove, processingModal]);

  const handleProcessing = () => {
    setMessage('Processing');
    processingModal.show();
  };

  const isEnable = useMemo(
    () => isConnected && isApprove && isInfinity && timeInterval,
    [isConnected, isApprove, isInfinity, timeInterval]
  );

  return (
    <>
      {!isConnected ? (
        <ConnectKitButton.Custom>
          {({ show, isConnecting }) => {
            return (
              <ButtonBase
                onClick={show}
                w='100%'
                h='44px'
                bg='#2669f5'
                isLoading={isConnecting}
                _loading={{
                  color: 'text.500',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                content='Connect Wallet'
                colorText='text.500'
                fsText={18}
                fwText={400}
                mt='20px'
                borderRadius='10px'
              />
            );
          }}
        </ConnectKitButton.Custom>
      ) : (
        <>
          {!valueInputPay ? (
            <ButtonBase
              w='100%'
              h='44px'
              bg={isLongTab ? '#0ECB81' : '#E43E53'}
              content='ENTER AN AMOUNT'
              colorText='text.500'
              isDisabled={!isApprove}
              fsText={18}
              fwText={400}
              mt='20px'
              borderRadius='10px'
            />
          ) : (
            <>
              {isApprove ? (
                <>
                  {isInfinity ? (
                    <ButtonBase
                      w='100%'
                      h='44px'
                      bg={isLongTab ? '#0ECB81' : '#E43E53'}
                      content={isLongTab ? 'Long' : 'Short'}
                      onClick={
                        isLongTab ? futureLongModal.show : futureShortModal.show
                      }
                      colorText='text.500'
                      fsText={18}
                      fwText={400}
                      mt='20px'
                      borderRadius='10px'
                    />
                  ) : (
                    <ButtonBase
                      w='100%'
                      h='44px'
                      bg={isLongTab ? '#0ECB81' : '#E43E53'}
                      content='ENTER AN AMOUNT'
                      colorText='text.500'
                      fsText={18}
                      fwText={400}
                      mt='20px'
                      borderRadius='10px'
                    />
                  )}
                </>
              ) : (
                <ButtonBase
                  w='100%'
                  onClick={handleApprove}
                  h='44px'
                  bg='bg.300'
                  content={`APPROVE ${symbolCoin}`}
                  colorText='text.400'
                  fsText={18}
                  fwText={400}
                  mt='20px'
                  borderRadius='10px'
                />
              )}
            </>
          )}
        </>
      )}
      <ModalFutureLong
        processing={handleProcessing}
        isEnable={isEnable && isLongTab && timeInterval}
        settingSlippage={settingSlippage}
        valueInputPay={valueInputPay}
        leverage={leverage}
        isOpen={futureLongModal.visible}
        onClose={futureLongModal.hide}
        onOpen={futureLongModal.show}
        setModalSuccess={successModal.show}
      />
      <ModalFutureShort
        processing={handleProcessing}
        isEnable={isEnable && !isLongTab && timeInterval}
        settingSlippage={settingSlippage}
        valueInputPay={valueInputPay}
        leverage={leverage}
        isOpen={futureShortModal.visible}
        onClose={futureShortModal.hide}
        onOpen={futureShortModal.show}
        setModalSuccess={successModal.show}
      />
      <Modal
        isCentered
        isOpen={processingModal.visible}
        onClose={processingModal.hide}
        size='sm'
      >
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius={12}>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px'
          >
            <Button
              isLoading
              border='none'
              color='white'
              variant='outline'
            ></Button>
            <TemplateText fontSize='18px' txt={message} my='auto' />
          </ModalHeader>
        </ModalContent>
      </Modal>
      <ModalSuccess
        isOpen={successModal.visible}
        onClose={successModal.hide}
        onOpen={successModal.show}
      />
    </>
  );
}
