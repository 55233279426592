import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  Icon,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import FlexBox from 'components/common/FlexBox';
import TemplateText from 'components/common/Text/TemplateText';
import { NavItem } from 'constants/interface';
import useModeTheme from 'hook/colorDarkMode';
import { Link } from 'react-router-dom';

interface props {
  navItem: NavItem;
  onCloseMenu: () => void;
}

const MobileMenu = ({ navItem, onCloseMenu }: props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { darkModeColors } = useModeTheme();

  return (
    <Stack
      h='100%'
      mt='unset'
      onClick={navItem.children ? onToggle : onCloseMenu}
    >
      <Flex
        h='51px'
        cursor='pointer'
        mt='-8px'
        align='center'
        justify='space-between'
        alignItems='center'
        _hover={{
          textDecoration: 'none',
        }}
        borderBottom={darkModeColors.border400}
      >
        {navItem.disable ? (
          <Flex position='relative'>
            <Box
              bg='bg.300'
              color='text.500'
              p='0 5px'
              fontSize='14px'
              borderRadius='10px'
              position='absolute'
              top='-10px'
              right='-45px'
            >
              soon
            </Box>
            <TemplateText
              color='gray'
              txt={navItem.label}
              fontWeight={400}
              fontSize={16}
            />
          </Flex>
        ) : (
          <TemplateText
            fontWeight={400}
            color={darkModeColors.text555}
            fontSize={16}
            txt={navItem.label}
          />
        )}

        {navItem.children && (
          <Icon
            h={6}
            w={6}
            as={ChevronDownIcon}
            transition='all .25s ease-in-out'
            transform={isOpen ? 'rotate(180deg)' : ''}
            color={darkModeColors.text600}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          align='start'
          borderLeft={1}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
        >
          {navItem.children &&
            navItem.children.map((child) => (
              <Link key={child.label} to={child.href || '#'}>
                <Box w='700px' onClick={onCloseMenu}>
                  <FlexBox
                    h='52px'
                    cursor='pointer'
                    ml='4px'
                    justifyContent='flex-start'
                  >
                    <Box mr='8px'>{child.icon}</Box>
                    <Box>
                      <TemplateText
                        mr='10px'
                        txt={child.label || ''}
                        fontSize={16}
                        fontWeight={400}
                        color={darkModeColors.text555}
                      />
                      <TemplateText
                        txt={child.description || ''}
                        fontSize={10}
                        color={darkModeColors.text550}
                      />
                    </Box>
                  </FlexBox>
                </Box>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileMenu;
