import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';

interface props {
  glp: number;
  glp_percent: number;
}

export default function CalculationAddLiquidity({ glp, glp_percent }: props) {
  return (
    <Box
      w={{ base: '100%', lg: '460px' }}
      p='36px 30px 30px'
      borderRadius='30px'
      bg='bg.200'
      mb='50px'
    >
      <TemplateText mb='18px' txt='PLP TOKENS IN YOUR WALLET' />
      <Box>
        <Flex alignItems='center' justifyContent='space-between' mb='10px'>
          <Flex alignItems='center'>
            <TemplateText fontWeight={400} color='text.400' txt={`PLP`} />
          </Flex>
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={`${Number(glp).toFixed(5)}`}
          />
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={`${Number(glp_percent || 0).toFixed(5)} %`}
          />
        </Flex>
      </Box>
    </Box>
  );
}
