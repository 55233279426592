function AllSymbol() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{
        position: 'absolute',
        visibility: 'hidden',
        height: 0,
        width: 0,
      }}
    >
      <symbol viewBox='0 0 25 24' id='binance-symbol'>
        <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M7.83901 10.085L12.5 5.424L17.1632 10.087L19.8752 7.37501L12.5 0L5.1272 7.3728L7.8391 10.0848L7.83901 10.085ZM0.5 12L3.2121 9.28752L5.924 11.9994L3.2119 14.7115L0.5 12ZM7.83901 13.9153L12.5 18.576L17.1631 13.9131L19.8765 16.6236L19.8752 16.6251L12.5 24L5.1272 16.6272L5.12336 16.6234L7.8393 13.915L7.83901 13.9153ZM19.076 12.0012L21.7881 9.28906L24.5 12.001L21.788 14.7131L19.076 12.0012Z'
            fill='#F3BA2F'
          />
          <path
            d='M15.2502 11.9976H15.2513L12.4995 9.24573L10.4655 11.2791L10.2319 11.5128L9.74993 11.9948L9.74609 11.9986L9.74993 12.0025L12.4995 14.7524L15.2515 12.0005L15.2528 11.999L15.2503 11.9976'
            fill='#F3BA2F'
          />
        </svg>
      </symbol>
    </svg>
  );
}

export default AllSymbol;
