import { Box, Flex, Icon } from '@chakra-ui/react';
import { ERC20_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipItem from 'components/common/TooltipItem';
import { useCallback, useEffect, useState } from 'react';
import { BsQuestion } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { GLP_ADDRESS } from 'utils/ERC20';
import { useAccount, useBalance, useContractRead } from 'wagmi';

export default function Pool() {
  const [balanceGLP, setBalanceGLP] = useState(0);
  const [timeInterval, setTimeInterval] = useState(true);

  const { isConnected, address } = useAccount();

  const { data: balanceGLP_Token } = useBalance({
    address: address,
    token: GLP_ADDRESS,
    enabled: isConnected && timeInterval,
  });

  const { data: glpSupply }: any = useContractRead({
    address: GLP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'totalSupply',
    enabled: isConnected && timeInterval,
  });

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    setBalanceGLP(Number(balanceGLP_Token?.formatted) || 0);
    setInterval(() => {
      toggle();
    }, 15000);
  }, [balanceGLP_Token, toggle, timeInterval]);

  useEffect(() => {
    document.title = 'MicoDex | The Super DEX on Mantle';
  }, []);
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      minH={{ base: '93.8vh', xl: '91.3Vh' }}
    >
      <Box
        w='460px'
        p='24px 18px 18px'
        borderRadius='30px'
        bg='bg.200'
        position='relative'
      >
        <Flex mb='30px' justifyContent='space-between' alignItems='center'>
          <Box>
            <TemplateText fontSize={18} mb='18px' txt='Liquidity' />
            <TemplateText txt='Add liquidity to receive LP tokens' />
          </Box>
        </Flex>
        <Link to='/liquidity/add-liquidity'>
          <ButtonBase
            w={{ base: '70%', md: '172px' }}
            h='45px'
            _hover={{ opacity: '.65' }}
            bg='#2669f5'
            content='Add liquidity'
            fsText={18}
            fwText={600}
            colorText='text.500'
            borderRadius='12px'
          />
        </Link>
        <Flex
          m='20px 0 15px'
          justifyContent='space-between'
          alignItems='center'
        >
          <TemplateText txt='Your Liquidity' />
          <TooltipItem
            children={
              <Box
                bg='bg.300'
                h='30px'
                w='30px'
                textAlign='center'
                borderRadius='100px'
              >
                <Icon
                  as={BsQuestion}
                  color='text.500'
                  h='20px'
                  w='20px'
                  mt='5px'
                />
              </Box>
            }
            label='When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
          />
        </Flex>
        <Flex
          border='1px solid #28344b'
          h='114px'
          p='15px 20px'
          borderRadius='10px'
          bg='bg.300'
          alignItems='center'
          justifyContent='center'
        >
          {balanceGLP ? (
            <Flex
              alignItems='center'
              justifyContent='space-between'
              mb='10px'
              w='full'
            >
              <Flex alignItems='center'>
                <TemplateText fontWeight={400} color='text.400' txt={`PLP`} />
              </Flex>
              <TemplateText
                color='text.400'
                fontWeight={400}
                txt={`${Number(balanceGLP).toFixed(5)}`}
              />
              <TemplateText
                color='text.400'
                fontWeight={400}
                txt={`${Number(
                  BigNumber(balanceGLP)
                    .multipliedBy(10 ** 18)
                    .multipliedBy(100)
                    .dividedBy(glpSupply)
                    .toNumber()
                ).toFixed(5)} %`}
              />
            </Flex>
          ) : (
            <TemplateText txt='No Liquidity found.' color='text.400' />
          )}
        </Flex>
        <Link to='/liquidity/remove-liquidity'>
          <ButtonBase
            w={{ base: '70%', md: '172px' }}
            h='45px'
            _hover={{ opacity: '.65' }}
            bg='#2669f5'
            content='Remove liquidity'
            fsText={18}
            fwText={600}
            colorText='text.500'
            borderRadius='12px'
            mt='20px'
            isDisabled={!balanceGLP}
          />
        </Link>
        <Box mt='20px'>
          <Flex gap='5px' mb='10px'>
            <TemplateText
              fontWeight={400}
              color='text.400'
              txt={`Don't see a pool you joined?`}
            />
            <Link to='/liquidity/import'>
              <TemplateText
                fontWeight={400}
                cursor='pointer'
                txt={`Import it`}
              />
            </Link>
          </Flex>
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt='Or, if you staked your LP tokens in a farm, unstake them to see them here'
          />
        </Box>
      </Box>
    </Flex>
  );
}
