import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipExplain from 'components/common/TooltipExplain';
import useModeTheme from 'hook/colorDarkMode';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { getDateNow } from 'utils/date';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenCurrentModal: () => void;
}

export default function GasRebateHistoryModal({
  isOpen = true,
  onClose = () => ({}),
  onOpenCurrentModal = () => ({}),
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
        <ModalHeader
          display='flex'
          justifyContent='center'
          bg='bg.100'
          borderRadius='10px 10px 0 0 '
          alignItems='center'
          position='relative'
        >
          <Icon
            as={BiChevronLeft}
            position='absolute'
            top='15px'
            h='30px'
            w='30px'
            left='15px'
            cursor='pointer'
            color='text.400'
            bg='bg.200'
            borderRadius='100px'
            onClick={() => {
              onOpenCurrentModal();
              onClose();
            }}
          />
          <TemplateText
            fontSize='18px'
            color='text.500'
            txt='Gas Rebate History'
          />
        </ModalHeader>
        <ModalCloseButton
          top='15px'
          _hover={{
            color: darkModeColors.text555,
          }}
          bg='bg.200'
          borderRadius='50%'
          color={darkModeColors.text550}
        />

        <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='20px'>
          <Box border='1px solid #28344b' borderRadius='10px' h='400px'>
            <TableContainer>
              <Table variant=''>
                <Thead>
                  <Tr>
                    <Th color='text.400'>To Hash</Th>
                    <Th color='text.400'>Gas Fee</Th>
                    <Th color='text.400'>PTD Rebate</Th>
                  </Tr>
                </Thead>
              </Table>
            </TableContainer>
          </Box>
          <Flex justifyContent='space-between' mt='10px'>
            <Flex alignItems='center'>
              <TemplateText
                color='text.400'
                fontSize={12}
                txt='Last Updated On'
              />
              <TooltipExplain label='the timestamp of last update.' />
            </Flex>

            <TemplateText color='text.400' fontSize={12} txt={getDateNow()} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
