import { Box, Flex, Image } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { INFO_FOOTER, TEXT_FOOTER } from 'data/footer';
import useModeTheme from 'hook/colorDarkMode';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { darkModeColors } = useModeTheme();
  return (
    <Box
      bg='bg.100'
      mt={{ base: '0px', md: '60px' }}
      p={{ base: '0 16px', xl: 'unset' }}
    >
      <Box className='maxW' p={{ base: '32px 0px 16px', xl: '60px 0px 42px' }}>
        <Flex
          flexWrap='wrap'
          justifyContent={{ base: 'space-between', xl: 'unset' }}
          w='100%'
        >
          <Box
            display={{ base: 'none', xl: 'block' }}
            w={{ base: '100%', xl: '30%' }}
          >
            <Image w='220px' src='./logo-micodex.png' />
            {/* <TemplateText
              mt='12px'
              fontSize={14}
              fontWeight={400}
              color={darkModeColors.text550}
              textAlign='start'
              txt='MicoDex is the first cryptocurrency data platform that powers the people. With Web3, we empower more than 300 million users in the cryptocurrency space with transparency, decentralization, and democratization.'
            /> */}
          </Box>
          <Flex
            w={{ base: '100%', xl: '60%' }}
            flexWrap={{ base: 'wrap', xl: 'unset' }}
            justifyContent='space-between'
            m={{ base: '32px 24px 0 0px', xl: '0 0 0 100px' }}
          >
            {INFO_FOOTER.map((infoItem) => (
              <Box key={infoItem.name} w={{ base: '33%', md: 'auto' }}>
                <TemplateText
                  txt={infoItem.name}
                  as='h3'
                  fontSize={18}
                  fontWeight={400}
                  mb='24px'
                  color={darkModeColors.text555}
                />
                {infoItem.children.map((item) => (
                  <Flex key={item.name} gap='8px' cursor='pointer'>
                    {item.icon && <Box mt='2px'>{item.icon}</Box>}
                    <Link to={item.href}>
                      <TemplateText
                        fontSize={16}
                        fontWeight={400}
                        mb='16px'
                        color={darkModeColors.text550}
                        txt={item.name}
                      />
                    </Link>
                  </Flex>
                ))}
              </Box>
            ))}
          </Flex>
        </Flex>
        <Flex
          m={{ base: '20px 0 0 24px', xl: '20px 0 0 0' }}
          justifyContent={{ base: 'start', xl: 'center' }}
          flexWrap='wrap'
          gap='5px'
        >
          {TEXT_FOOTER.map((item) => (
            <TemplateText
              key={item.name}
              fontSize={14}
              fontWeight={400}
              color={darkModeColors.text550}
              txt={item.value}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
