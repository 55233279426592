import CheckStatus from 'components/svg/CheckStatus';
import InviteFriend from 'components/svg/InviteFriend';
import ReferralLink from 'components/svg/ReferralLink';
import Way01 from 'components/svg/Way01';
import Way02 from 'components/svg/Way02';
import Way03 from 'components/svg/Way03';

export const REFER_OTHERS = [
  {
    way: '01',

    label: 'Get your referral link',
    description: 'Connect your wallet to get your personal referral link.',
    icon: <ReferralLink />,
    img: <Way01 />,
  },
  {
    way: '02',

    label: 'Invite Friends',
    description: 'Share your love to your friends.',
    icon: <InviteFriend />,
    img: <Way02 />,
  },
  {
    way: '03',
    label: 'Check referral status',
    description: 'Check if your referral links have been activated',
    icon: <CheckStatus />,
    img: <Way03 />,
  },
];
