import { Box, Checkbox, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipExplain from 'components/common/TooltipExplain';
import { LEVERAGE_CUSTOM } from 'data/future';
import React, { useState } from 'react';

interface props {
  setLeverage: (value: number) => void;
  leverage: number;
}

export default function Leverage({ leverage, setLeverage }: props) {
  const [isCustomize, setIsCustomize] = useState(false);
  return (
    <Box mt='10px' w='100%'>
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex alignItems='center'>
          <TemplateText txt='Leverage' />
          <TooltipExplain label='Size equals leverage times your amount of collateral' />
          <TemplateText ml='5px' txt={`${leverage}x`} />
        </Flex>
        <Checkbox
          isChecked={isCustomize}
          color='text.500'
          onChange={(e) => setIsCustomize(e.target.checked)}
        >
          Customize
        </Checkbox>
      </Flex>
      {!isCustomize ? (
        <Flex
          w='100%'
          alignItems='center'
          justifyContent='space-between'
          gap='10px'
          mt='10px'
        >
          {LEVERAGE_CUSTOM.map((item) => (
            <Box
              w='100%'
              _hover={{ background: 'bg.300' }}
              border='1px solid #28344b'
              borderRadius='5px'
              bg={item === leverage ? 'bg.300' : 'bg.200'}
              cursor='pointer'
              key={item}
              onClick={() => setLeverage(item)}
              p='2px 0'
            >
              <TemplateText
                fontSize={13}
                fontWeight={400}
                textAlign='center'
                txt={`${item}x`}
              />
            </Box>
          ))}
        </Flex>
      ) : (
        <input
          style={{
            width: '100%',
            accentColor: 'orangered',
            backgroundColor: 'red',
          }}
          type='range'
          min='0'
          max='30'
          step='1'
          value={leverage}
          onChange={(e) => setLeverage(Number(e.target.value))}
        />
      )}
    </Box>
  );
}
