import ApprovalRevoke from 'components/svg/ApprovalRevoke';
import DiscordIcon from 'components/svg/DiscordIcon';
import Email from 'components/svg/Email';
import InfoHubIcon from 'components/svg/InfoHubIcon';
import MediumIcon from 'components/svg/MediumIcon';
import TelegramIcon from 'components/svg/TelegramIcon';
import TwitterIcon from 'components/svg/TwitterIcon';

export const INFO_FOOTER = [
  {
    name: 'Services',
    children: [
      { name: 'Swap', href: '/trade?feature=swap' },
      { name: 'Perpetual', href: '/trade' },
    ],
  },
  {
    name: 'Developer',
    children: [{ name: 'SDK' }, { name: 'API' }, { name: 'Github' }],
  },
  {
    name: 'Info',
    children: [
      { name: 'Docs' },
      { name: 'Media kit' },
      { name: 'tutorial' },
      { name: 'Security & Audits' },
    ],
  },
  {
    name: 'Community',
    children: [
      { name: 'Twitter', icon: <TwitterIcon /> },
      { name: 'Medium', icon: <MediumIcon /> },
      { name: 'Telegram', icon: <TelegramIcon /> },
      { name: 'Discord', icon: <DiscordIcon /> },
    ],
  },
];
export const TEXT_FOOTER = [
  {
    name: 'year',
    value: '© 2024 MicoDex',
    base: 'unset',
    md: '100%',
    xl: 'unset',
  },
  {
    name: 'address',
    value: 'All rights reserved.',
    base: 'unset',
    md: 'unset',
    xl: 'unset',
  },
  {
    name: 'email',
    value: ' Email us: support@micodex.com',
    base: '100%',
    md: 'unset',
    xl: 'unset',
  },
];

export const FIX_FOOTER = [
  { name: 'Approval', icon: <ApprovalRevoke />, border: false },
  { name: 'Infohub', icon: <InfoHubIcon />, border: true },
  { name: 'Email', icon: <Email />, border: false },
  { name: 'Twitter', icon: <TwitterIcon />, border: false },
  { name: 'Discord', icon: <DiscordIcon />, border: false },
  { name: 'Telegram', icon: <TelegramIcon />, border: true },
];
