import React from 'react';

export default function NoPositions() {
  return (
    <svg
      width='60'
      height='61'
      viewBox='0 0 60 61'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='5hqd4majia'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='tc27tw7bpb'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='xbdystzwkc'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='1d94fqjtwd'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='so2duih1le'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='url(#5hqd4majia)'
          d='M42.222 60h8.89V31.111h-8.89z'
          transform='translate(0 1)'
        />
        <path
          fill='url(#tc27tw7bpb)'
          d='M27.778 60h8.889V37.778h-8.89z'
          transform='translate(0 1)'
        />
        <path
          fill='url(#xbdystzwkc)'
          d='M14.444 60h8.89V45.556h-8.89z'
          transform='translate(0 1)'
        />
        <path
          fill='url(#1d94fqjtwd)'
          d='M0 60h8.889v-7.778H0z'
          transform='translate(0 1)'
        />
        <path
          d='M4.444 42.111V18.464c0-2.863 2.352-5.185 5.253-5.185h18.735M58.889 13.28c0 6.782-5.57 12.28-12.44 12.28-6.87 0-12.44-5.498-12.44-12.28 0-6.782 5.57-12.28 12.44-12.28 6.87 0 12.44 5.498 12.44 12.28z'
          stroke='#b7becb'
        />
        <path
          d='M51.442 12.28c0 5.145-4.225 9.316-9.438 9.316-5.212 0-9.438-4.171-9.438-9.316 0-5.146 4.226-9.317 9.438-9.317 5.213 0 9.438 4.171 9.438 9.317'
          fill='url(#so2duih1le)'
          transform='translate(4.444 1)'
        />
        <path
          d='M46.38 15.345a.95.95 0 0 1-.955-.943v-4.305a.95.95 0 0 1 .955-.943.95.95 0 0 1 .956.943v4.305a.95.95 0 0 1-.956.943m1.092 2.515c0 .596-.489 1.078-1.091 1.078a1.085 1.085 0 0 1-1.093-1.078c0-.595.49-1.077 1.093-1.077.602 0 1.091.482 1.091 1.077'
          fill='#28344b'
        />
      </g>
    </svg>
  );
}
