import React from 'react';

interface Props {
  color?: string;
}

export default function ShortIcon({ color }: Props) {
  return (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_404_101918'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='12'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 9.354L15.803 11.857L17.1576 3.30468L1.35455 0.801731L0 9.354Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_404_101918)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.206 11.7624L10.9102 11.082C10.5805 11.0298 10.3554 10.7201 10.4077 10.3904C10.4599 10.0606 10.7696 9.83563 11.0993 9.88785L13.8591 10.3249L9.72826 4.65874L5.9615 6.81633C5.70204 6.9649 5.37303 6.90159 5.18727 6.66731L1.38333 1.86893C1.17592 1.60728 1.2199 1.22706 1.48152 1.01964C1.61953 0.910269 1.79053 0.870784 1.95178 0.896322C2.0962 0.919197 2.23276 0.994218 2.33077 1.11782L5.81174 5.50877L9.59726 3.34043C9.86466 3.18725 10.2046 3.25982 10.3862 3.50888L14.9168 9.72359L15.3266 7.13649C15.3788 6.80676 15.6885 6.58175 16.0182 6.63398C16.348 6.68621 16.5729 6.9959 16.5207 7.32562L15.8976 11.2599C15.8454 11.5896 15.5357 11.8146 15.206 11.7624Z'
          fill={color}
        />
      </g>
    </svg>
  );
}
