import CopyTrading from 'pages/CopyTrading';
import FuturesPage from 'pages/Futures';
import Home from 'pages/Home';
import Pool from 'pages/Pool';
import AddLiquidity from 'pages/Pool/AddLiquidity';
import ImportPool from 'pages/Pool/ImportPool';
import RemoveLiquidity from 'pages/Pool/RemoveLiquidity';
import ReferralPage from 'pages/Referral';
import SWapPage from 'pages/Swap';

const routes = [
  {
    path: '/',
    component: Home,
    title: 'MicoDex',
  },

  {
    path: '/trade',
    component: SWapPage,
    title: '',
  },

  {
    path: '/future',
    component: FuturesPage,
    title: 'MicoDex | Trade',
  },
  {
    path: '/liquidity',
    component: Pool,
    title: 'MicoDex',
  },

  {
    path: '/liquidity/import',
    component: ImportPool,
    title: 'MicoDEx | Best swap returns for decentralized crypto trading',
  },
  {
    path: '/liquidity/add-liquidity',
    component: AddLiquidity,
    title: 'MicoDEx | Best swap returns for decentralized crypto trading',
  },
  {
    path: '/liquidity/remove-liquidity',
    component: RemoveLiquidity,
    title: 'MicoDEx | Best swap returns for decentralized crypto trading',
  },
  {
    path: '/referral',
    component: ReferralPage,
    title: 'MicoDex',
  },
  {
    path: '/copytrading/futures',
    component: CopyTrading,
    title: '',
  },
  {
    path: '/copytrading/spot',
    component: CopyTrading,
    title: '',
  },
];

export default routes;
