import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Icon,
  Link,
  Stack,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { ERC20_ABI } from 'abis/ERC20';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import DrawerItem from 'components/common/DrawerItem';
import PopoverItem from 'components/common/PopoverItem';
import TagBox from 'components/common/TagBox';
import TemplateText from 'components/common/Text/TemplateText';
import TextCoin from 'components/common/TextCoin';
import Bell from 'components/svg/Bell';
import HamburgerIcon from 'components/svg/HamburgerIcon';
import NAV_ITEMS from 'config/header';
import { ConnectKitButton } from 'connectkit';
import { ChildrenConvertCoin } from 'constants/types';
import { LIST_CHAIN } from 'data/ecosystem';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { FaBoxOpen } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { USDT_ADDRESS } from 'utils/ERC20';
import { useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi';

import Menu from './Menu';
import MobileMenu from './MobileMenu';

interface props {
  onCloseMenu: () => void;
}

interface header {
  setLocation: (value: string) => void;
}

const Header = ({ setLocation }: header) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  // const detailModal = useVisible();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const initRef = useRef(null);
  useOutsideClick({
    ref: initRef,
    handler: () => setOpen(false),
  });

  const { isConnected, address } = useAccount();

  const notificationList = []; //fake data

  const { darkModeColors } = useModeTheme();

  useEffect(() => {
    setLocation(location.pathname);
  }, [location.pathname, setLocation]);

  const [coin] = useState<ChildrenConvertCoin>(LIST_CHAIN[0]);

  const DesktopNav = () => {
    return (
      <Stack direction='row' spacing={4} className='stack-header'>
        {NAV_ITEMS.map((navItem, k) => {
          return (
            <Box key={Number(k)}>
              <Menu navItem={navItem} />
            </Box>
          );
        })}
      </Stack>
    );
  };
  const MobileNav = ({ onCloseMenu }: props) => {
    return (
      <Stack p='16px 24px 0 24px' display={{ xl: 'none' }} bg='bg.100'>
        {NAV_ITEMS.map((navItem, k) => (
          <MobileMenu onCloseMenu={onCloseMenu} key={k} navItem={navItem} />
        ))}
      </Stack>
    );
  };

  const { config: configFaucet } = usePrepareContractWrite({
    address: USDT_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'mint',
    enabled: isConnected,
    args: [address, 1000000 * 10 ** 18],
  });

  const { write: writeFaucet } = useContractWrite(configFaucet);

  const handleFaucet = useCallback(() => {
    writeFaucet?.();
  }, [writeFaucet]);

  return (
    <Box className={location.pathname == '/' ? 'maxW' : ''}>
      <Flex
        p={{
          base: '0 16px',
          xl: location.pathname == '/' ? 'unset' : '0 16px',
        }}
        borderStyle='solid'
        justifyContent='space-between'
        alignItems='center'
      >
        <Flex
          m={{ base: '15px 0px 20px 0px', xl: '30px 0px' }}
          w='100%'
          alignItems='center'
          justify='start'
        >
          <Link zIndex={999} href='/'>
            <TextCoin />
          </Link>
          <Flex
            display={{ base: 'none', xl: 'flex' }}
            ml='30px'
            alignItems='center'
          >
            <DesktopNav />
          </Flex>
        </Flex>
        {location.pathname == '/' ? (
          <Link href='/trade?feature=swap'>
            <ButtonBase
              w='155px'
              h='50px'
              bg='#2669f5'
              content='Launch App'
              colorText='#eff3fb'
              fsText={16}
              fwText={600}
              borderRadius='8px'
              display={{ base: 'none', xl: 'block' }}
            />
          </Link>
        ) : (
          <Flex
            gap='20px'
            mr={{ base: '10px', xl: 'unset' }}
            alignItems='center'
          >
            {/* <Button
              display={{ base: 'none', md: 'flex' }}
              onClick={handleFaucet}
              borderRadius={10}
              bg='blue.300'
              _hover={{
                background: 'bg.300',
              }}
            >
              <TemplateText txt='Faucet USDT' fontSize={14} />
            </Button> */}
            {/* {isConnected && (
              <Flex
                display={{ base: 'none', lg: 'flex' }}
                alignItems='center'
                p='0 10px'
                borderRadius={10}
                cursor='pointer'
                h='40px'
                onClick={detailModal.show}
                transition='all .3s ease'
                gap='5px'
                bg='#162239'
              >
                <MantleDex />
                <TemplateText
                  fontSize={15}
                  txt={
                    convertBigNumber(Number(data?.formatted)).toString() || ''
                  }
                />
              </Flex>
            )} */}

            <PopoverItem
              isSelect
              m='5px 5px 0px 0px'
              buttonItem={
                <Box>
                  <TagBox
                    w={{ base: '80px', xl: '160px' }}
                    gap='10px'
                    content={coin.name}
                    iconLeft={coin.icon}
                    h='44px'
                    // iconRight={<ArrowBottom />}
                    fsText='14px'
                    fwText={400}
                    p='8px'
                    justifyContent='space-around'
                    colorText={darkModeColors.text555}
                    borderRadius={10}
                    className='border-box'
                    bg='#162239'
                    bd='none'
                  />
                </Box>
              }
              boxShadow='0px 2px 14px rgba(0, 0, 0, 0.16), 0px 16px 40px rgba(0, 0, 0, 0.16)'
              w='170px'
              border='none'
            >
              {/* <SelectCoinOption
                name={coin.name}
                setCoin={setCoin}
                coins={LIST_CHAIN}
              /> */}

              {/* // TODO: temporarily hide */}
            </PopoverItem>

            <ConnectKitButton.Custom>
              {({
                isConnected,
                isConnecting,
                show,
                truncatedAddress,
                ensName,
              }) => {
                return (
                  <ButtonBase
                    zIndex={999}
                    onClick={show}
                    w={isConnected ? '168px' : '150px'}
                    h='44px'
                    p={isConnected ? '0 10px' : '0 28px'}
                    iconLeft={
                      isConnected ? (
                        <Icon
                          h='20px'
                          w='20px'
                          color='text.500'
                          as={BiUserCircle}
                        />
                      ) : null
                    }
                    bg={isConnected ? 'bg.200' : '#2669f5'}
                    content={
                      isConnected
                        ? ensName ?? truncatedAddress
                        : 'Connect Wallet'
                    }
                    colorText={darkModeColors.text555}
                    fsText={14}
                    isLoading={isConnecting}
                    _loading={{
                      color: 'text.500',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    fwText={400}
                    borderRadius='10px'
                    display={{ base: 'none', xl: 'block' }}
                  />
                );
              }}
            </ConnectKitButton.Custom>

            {isConnected && (
              <PopoverItem
                display={{ base: 'none', lg: 'block' }}
                callback={setOpen}
                m='5px 5px 0px 0px'
                buttonItem={
                  <Flex
                    bg='bg.200'
                    p='10px'
                    h='44px'
                    alignItems='center'
                    borderRadius='10px'
                    display={{ base: 'none', lg: 'block' }}
                  >
                    <Bell />
                  </Flex>
                }
                boxShadow='0px 2px 14px rgba(0, 0, 0, 0.16), 0px 16px 40px rgba(0, 0, 0, 0.16)'
                w='400px'
                border='none'
              >
                {open && (
                  <Box
                    p='16px'
                    bg='bg.200'
                    borderRadius={10}
                    mr='10px'
                    ref={initRef}
                  >
                    <Flex alignItems='center' justifyContent='space-between'>
                      <TemplateText txt='Notifications' fontSize='16px' />
                      <Box
                        bg='bg.300'
                        borderRadius={100}
                        onClick={() => setOpen(!open)}
                      >
                        <CloseButton color={darkModeColors.text550} />
                      </Box>
                    </Flex>
                    <Flex direction='column' alignItems='center'>
                      {notificationList.length === 0 && (
                        <Flex
                          h='300px'
                          direction='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Icon
                            textAlign='center'
                            as={FaBoxOpen}
                            color={darkModeColors.text550}
                            ml='2px'
                            h='40px'
                            w='40px'
                            mt='7px'
                          />

                          <TemplateText
                            color={darkModeColors.text550}
                            txt={`Can't find any notifications`}
                          />
                        </Flex>
                      )}

                      <ButtonBase
                        borderRadius='10px'
                        content='Subcribe via email'
                        w='100%'
                        bg='bg.300'
                      />
                    </Flex>
                  </Box>
                )}
              </PopoverItem>
            )}
          </Flex>
        )}
        <Flex>
          <Flex alignItems='center' justifyContent='center'>
            <Box
              w='40px'
              h='46px'
              bg='bg.100'
              cursor='pointer'
              borderRadius='4px'
              alignItems='center'
              justifyContent='center'
              display={{ base: 'flex', xl: 'none' }}
              onClick={() => {
                onToggle();
              }}
            >
              {isOpen ? (
                <CloseIcon color='text.500' w={3} h={3} />
              ) : (
                <HamburgerIcon />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <DrawerItem
        pbody='0px'
        onClose={onClose}
        isOpen={isOpen}
        icon={<TextCoin />}
      >
        <MobileNav onCloseMenu={onClose} />
      </DrawerItem>

      {/* <ModalYourBalance
        isOpen={detailModal.visible}
        onClose={detailModal.hide}
        balance={data?.formatted || ''}
      /> */}
    </Box>
  );
};

export default Header;
