import { InfoOutlineIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Input } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import ArrowRightLeft from 'components/svg/ArrowRightLeft';
import ResetDollar from 'components/svg/ResetDollar';
import { CoinPair } from 'constants/interface';
import { useCallback, useMemo, useState } from 'react';
import { convertBigNumber } from 'utils/number';

import TemplateText from '../Text/TemplateText';
import TooltipItem from '../TooltipItem';

interface Props {
  limitPrice: number;
  setLimitPrice: (value: number) => void;
  coinPair: CoinPair;
}

export default function LimitPrice({
  limitPrice,
  setLimitPrice,
  coinPair,
}: Props) {
  const [isConvert, setIsConvert] = useState(true);

  const limitPriceConvert = useMemo(() => {
    const price =
      coinPair.coinTarget.price != 0
        ? coinPair.coinBase.price / coinPair.coinTarget.price
        : Infinity;
    return new BigNumber(price).precision(6).toNumber();
  }, [coinPair]);

  const toolTip = useMemo(() => {
    return {
      question:
        'The estimated execution price considers the gas cost to execute your order to ensure your desired price gets fulfilled. According to the current gas price, the order should get executed when 1 SIS = 1.457507 BUSD',
      info: 'The gap between order price and execution price is too high due to the network fee, you can try to higher the amount.',
      reset: 'Reset limit order to current market price',
    };
  }, []);

  const valueInput = useMemo(() => {
    return isConvert
      ? limitPrice
      : Number(new BigNumber(1 / limitPrice).precision(6));
  }, [isConvert, limitPrice]);

  const [valuelimitprice, setValueLimitPrice] = useState(String(valueInput));

  const handleChangeLimitPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValueLimitPrice(e.target.value);
      const limitPrice = isConvert
        ? convertBigNumber(Number(valuelimitprice))
        : convertBigNumber(1 / Number(valuelimitprice));
      setLimitPrice(limitPrice);
    },
    [isConvert, setLimitPrice, valuelimitprice]
  );

  const ExecutionPrice = useCallback(() => {
    const value =
      coinPair.coinTarget.price != 0
        ? coinPair.coinBase.price / coinPair.coinTarget.price
        : Infinity;

    const limitValueConvert = BigNumber(value).precision(6).toNumber();

    return (
      <Flex alignItems='center'>
        <TemplateText
          fontSize={{ base: '12px', md: '14px', lg: '12px', xl: '14px' }}
          color='text.400'
          w='max-content'
          txt={`~ 1 ${coinPair.coinBase.symbol} = ${limitValueConvert} ${coinPair.coinTarget.symbol}`}
        />

        <TooltipItem
          children={<InfoOutlineIcon mb='3px' bg='bg.200' color='#ff9b79' />}
          label={toolTip.info}
        />
      </Flex>
    );
  }, [
    coinPair.coinBase.price,
    coinPair.coinBase.symbol,
    coinPair.coinTarget.price,
    coinPair.coinTarget.symbol,
    toolTip.info,
  ]);

  return (
    <Box
      my='10px'
      w='full'
      p='12px 12px 10px'
      border='1px solid #28344b'
      bg='bg.100'
      borderRadius='12px'
      _focusWithin={{ border: '1px solid #2669f5' }}
    >
      <Flex justifyContent='space-between' alignItems='center'>
        <TemplateText txt='Limit Price' color='text.400' fontSize='14px' />
        <Flex>
          <TooltipItem
            children={
              <Box
                as='button'
                px='8px'
                color='text.400'
                w='32px'
                h='25px'
                cursor='pointer'
                onClick={() => setLimitPrice(limitPriceConvert)}
              >
                <ResetDollar />
              </Box>
            }
            label={toolTip.reset}
          />

          <Box
            as='button'
            color='text.400'
            w='22px'
            h='20px'
            onClick={() => setIsConvert(!isConvert)}
          >
            <Flex>
              <ArrowRightLeft />
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' alignItems='center' py='7px'>
        <Flex>
          <TemplateText fontSize='20px' txt='1' pr='5px' />
          <TemplateText
            mt='7px'
            w='max-content'
            fontSize='14px'
            txt={`${
              isConvert ? coinPair.coinBase.symbol : coinPair.coinTarget.symbol
            } =`}
          />
        </Flex>
        <Flex>
          <Input
            mr='5px'
            value={valuelimitprice}
            type='number'
            p='0'
            focusBorderColor='bg.100'
            border='none'
            box-sizing='border-box'
            fontSize='20px'
            color='text.500'
            textAlign='right'
            onChange={handleChangeLimitPrice}
          />
          <TemplateText
            mt='12px'
            fontSize='14px'
            color='text.400'
            txt={
              isConvert ? coinPair.coinTarget.symbol : coinPair.coinBase.symbol
            }
          />
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' gap='10px' alignItems='center'>
        <Flex alignItems='center'>
          <TemplateText
            w='max-content'
            fontSize={{ base: '12px', md: '14px', lg: '12px', xl: '14px' }}
            color='text.400'
            txt='Est. execution price'
          />

          <TooltipItem
            children={
              <QuestionOutlineIcon mb='3px' bg='bg.100' color='text.400' />
            }
            label={toolTip.question}
          />
        </Flex>

        <ExecutionPrice />
      </Flex>
    </Box>
  );
}
