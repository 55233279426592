import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export default function ModalSuccess({
  isOpen = true,
  onClose,
}: PopoverItemProps) {
  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
          <ModalBody maxH='70vh' zIndex={1} pb='20px' mt={2}>
            <Box>
              {/* <Image
                src='../completed.png'
                w='50%'
                m='auto'
                borderRadius='100%'
              /> */}
              <TemplateText
                fontSize={18}
                m='auto'
                w='fit-content'
                txt='TRANSACTION COMPLETED'
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
