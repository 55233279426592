import React from 'react';

export default function NoHistory() {
  return (
    <svg
      width='48'
      height='50'
      viewBox='0 0 48 50'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path id='mv6q9vh5ga' d='M0 0h43.023v48.31H0z' />
        <path id='v4nh28a6wd' d='M0 0h22.735v22.754H0z' />
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='hdp40efisb'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g>
          <mask id='he8kim3cuc' fill='#eff3fb'>
            <use xlinkHref='#mv6q9vh5ga' />
          </mask>
          <path
            d='M17.107 22.432c-1.954 0-3.908-.01-5.862.003-1.213.008-2.13.79-2.303 1.925-.17 1.119.454 2.142 1.524 2.494.352.116.716.125 1.082.125 3.686 0 7.372.001 11.058-.002.253 0 .509-.01.76-.045.901-.128 1.509-.645 1.81-1.488.53-1.49-.567-2.99-2.207-3.007-1.954-.02-3.908-.005-5.862-.005m4.452-5.174c3.32 0 6.641.001 9.962-.002.269 0 .54-.016.806-.057.895-.141 1.622-.819 1.815-1.673.211-.93-.142-1.86-.9-2.394-.491-.346-1.046-.426-1.628-.426-6.721.002-13.442.001-20.163.002-.128 0-.255-.004-.382.006-1.574.117-2.573 1.55-2.09 2.996.327.976 1.19 1.545 2.379 1.547 3.4.003 6.8 0 10.2.001M0 26.434v-4.579c.024-.282.067-.565.07-.848.038-3.465.22-6.921.59-10.368.523-4.887 4.276-9.002 9.102-9.852C12.612.285 15.497.07 18.39.03c4.655-.062 9.308-.087 13.938.554 3.257.452 5.937 1.883 7.92 4.52a10.949 10.949 0 0 1 2.228 6.016c.13 2.012.332 4.02.426 6.035.151 3.24.12 6.484.107 9.726-.001.13.062.282-.067.395-6.844-4.15-13.96-1.402-17.268 2.439-1.938 2.25-3.107 4.832-3.334 7.785-.316 4.133 1.034 7.7 3.966 10.733-.164.016-.237.029-.31.03-5.068.066-10.131.166-15.177-.532-3.323-.46-5.998-1.94-8.022-4.606C1.22 41.046.671 38.634.482 36.09.258 33.06.088 30.032.064 26.996c-.002-.187-.042-.374-.064-.562'
            fill='url(#hdp40efisb)'
            mask='url(#he8kim3cuc)'
          />
        </g>
        <g transform='translate(24.357 27.246)'>
          <mask id='qmfksftdze' fill='#eff3fb'>
            <use xlinkHref='#v4nh28a6wd' />
          </mask>
          <path
            d='M22.64 9.941c.388 3-.417 5.972-2.267 8.365a11.426 11.426 0 0 1-7.395 4.326 1.73 1.73 0 0 1-.05.023l-.223.099H10.13l-.135-.034c-.235-.057-.47-.109-.721-.163-.716-.156-1.457-.317-2.2-.642-2.03-.886-3.654-2.114-4.824-3.651-1.196-1.57-1.928-3.47-2.175-5.65a11.243 11.243 0 0 1 2.408-8.36 11.325 11.325 0 0 1 7.61-4.182c6.128-.695 11.755 3.732 12.548 9.87zm-12.29-7.59A9.045 9.045 0 0 0 4.271 5.69a8.963 8.963 0 0 0-1.917 6.667c.4 3.538 2.244 5.977 5.636 7.457.536.234 1.135.364 1.77.502.21.046.427.093.645.145h1.802c.086-.033.19-.063.308-.08a9.151 9.151 0 0 0 6.043-3.477 8.956 8.956 0 0 0 1.807-6.668c-.633-4.902-5.126-8.438-10.015-7.884zm-.013 2.93c.722.004 1.202.533 1.206 1.36.007 1.51.02 3.02-.009 4.53-.01.475.084.663.607.631.807-.05 1.62-.029 2.43-.005.621.018 1.097.439 1.182.98.092.593-.174 1.096-.692 1.33-.197.089-.402.092-.607.092-1.335.004-2.67.01-4.005 0-.872-.007-1.308-.448-1.316-1.33-.01-1.048-.002-2.097-.002-3.146 0-1.033-.007-2.066.001-3.1.007-.85.46-1.346 1.205-1.342z'
            fill='#b7becb'
            mask='url(#qmfksftdze)'
          />
        </g>
      </g>
    </svg>
  );
}
