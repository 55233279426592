import React from 'react';

export default function Ont() {
  return (
    <svg
      width='30px'
      height='30px'
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>3</title>
      <g
        id='页面-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='画板'
          transform='translate(-33.000000, -140.000000)'
          fill='#48A3FF'
        >
          <g id='3' transform='translate(33.000000, 140.000000)'>
            <circle
              id='椭圆形备份-5'
              fillOpacity='0.2'
              cx='15'
              cy='15'
              r='15'
            ></circle>
            <path
              d='M7,7.49684291 L9.2704339,9.76719638 L20.2328438,20.7296063 C17.2056523,23.7567979 12.2976255,23.7567979 9.2704339,20.7296063 C7.75683811,19.2160105 7,17.232226 7,15.2483611 L7,15.2483611 L7,7.49684291 Z M10.11724,9.27039369 C13.1444316,6.2432021 18.0524584,6.2432021 21.07965,9.27039369 C22.5931653,10.7839895 23.3500839,12.7678544 23.3500839,14.7516389 L23.3500839,14.7516389 L23.3500839,22.5032375 Z'
              id='形状结合'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
