import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import LongIcon from 'components/svg/LongIcon';
import ShortIcon from 'components/svg/ShortIcon';
import { TAB_NAME } from 'constants/enum';
import { CoinPairTrade } from 'constants/interface';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import LongTab from './LongTab';
import ShortTab from './ShortTab';

interface Props {
  coinPair: CoinPairTrade;
  setCoinPair: (value: CoinPairTrade) => void;
}

export default function LeftWrap({ coinPair, setCoinPair }: Props) {
  const LIST_TAB = useMemo(
    () => [
      {
        name: 'LONG',
        icon: <LongIcon color='#0ECB81' />,
        color: '#0ECB81',
      },
      {
        name: 'SHORT',
        icon: <ShortIcon color='#E43E53' />,
        color: '#E43E53',
      },
    ],
    []
  );

  return (
    <Box w={{ base: '100%', lg: '40%' }} maxW={{ base: 'unset', lg: '475px' }}>
      <Box
        bg='bg.200'
        p={{ base: '24px', lg: '16px', xl: '24px' }}
        borderRadius='20px'
      >
        <Tabs variant='enclosed'>
          <TabList w='100%' borderColor='bg.200'>
            {LIST_TAB.map((item) => (
              <Tab
                p='16px'
                key={uuidv4()}
                _selected={{
                  color: item.color,
                  borderBottom: '5px solid transparent',
                  borderImageSlice: 1,
                  borderImageSource: 'linear-gradient(190deg,#1d6dff,#132038)',
                  borderTop: '0px solid transparent',
                  borderRight: '0px solid transparent',
                  borderLeft: '0px solid transparent',
                }}
                color='text.400'
                fontSize={{ base: '14px', md: '16px' }}
                fontWeight={600}
                w='50%'
                gap='10px'
              >
                {item.icon}
                {item.name}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel p='16px 0'>
              <LongTab coinPair={coinPair} setCoinPair={setCoinPair} />
            </TabPanel>
            <TabPanel p='16px 0'>
              <ShortTab coinPair={coinPair} setCoinPair={setCoinPair} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
