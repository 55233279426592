import React from 'react';

export default function InfoHubIcon() {
  return (
    <svg
      data-v-e55b351e=''
      width='20px'
      height='20px'
      viewBox='0 0 1024 1024'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M865.92 934.208H166.208c-1.152-0.512-2.304-1.344-3.584-1.6-36.736-6.08-63.68-25.792-80-59.2-4.864-9.984-7.232-21.248-10.816-32V180.864c0.64-1.152 1.472-2.24 1.664-3.456 10.56-52.8 52.864-87.552 106.688-87.552h505.152c62.208 0 108.672 46.592 108.672 109.056V577.92h158.208v276.608c-0.896 2.176-1.92 4.352-2.688 6.592a101.248 101.248 0 0 1-65.152 67.84c-6.016 2.176-12.288 3.584-18.432 5.312zM422.208 555.072H219.136a55.168 55.168 0 0 0-12.544 1.088c-20.864 4.992-33.6 22.976-30.784 43.008 2.56 18.816 20.224 32.64 42.496 32.64h408.32c24.96 0 42.688-16.64 42.496-39.04-0.192-21.76-18.432-37.76-43.136-37.76H422.208z m-57.152-206.72l-141.824 0.064c-3.712 0-7.488 0-11.072 0.704-22.4 4.288-37.824 21.952-35.456 40.512 2.688 21.056 20.864 35.52 45.12 35.52h285.824c26.432 0 46.464-16.64 46.464-38.4 0-21.824-20.032-38.4-46.528-38.4H365.056z m438.272 283.52c-0.256 2.432-0.576 4.48-0.576 6.592 0 62.656-0.064 125.248 0.064 187.904 0 6.144 0.64 12.544 2.176 18.496a48.448 48.448 0 0 0 95.168-12.032c0.192-65.664 0-131.2 0-196.864 0-1.28-0.192-2.56-0.384-4.096h-96.448z'
        fill='#b7becb'
      ></path>
    </svg>
  );
}
