import axios from 'axios';

export const getPrice = async (symbol: string) => {
  const res = await axios.get(
    `https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${symbol}&tsyms=USD`
  );
  return res?.data;
};

export const researchToken = async (symbol: string) => {
  try {
    const response = await axios.post('https://interface.gateway.uniswap.org/v1/graphql', 
      {
        query: `
        query SearchTokensWeb($searchQuery: String!, $chains: [Chain!]) {
          searchTokens(searchQuery: $searchQuery, chains: $chains) {
            ...SimpleTokenDetails
            id
            decimals
            name
            chain
            standard
            address
            symbol
            market(currency: USD) {
              id
              price {
                id
                value
                currency
                __typename
              }
              pricePercentChange(duration: DAY) {
                id
                value
                __typename
              }
              volume24H: volume(duration: DAY) {
                id
                value
                currency
                __typename
              }
              __typename
            }
            project {
              id
              name
              logo {
                id
                url
                __typename
              }
              safetyLevel
              logoUrl
              isSpam
              __typename
            }
            __typename
          }
        }
        fragment SimpleTokenDetails on Token {
          id
          address
          chain
          symbol
          name
          decimals
          standard
          project {
            id
            name
            logo {
              id
              url
              __typename
            }
            safetyLevel
            logoUrl
            isSpam
            __typename
          }
          __typename
        }`,
        variables: {
          searchQuery: "arb",
          chains: ["ARBITRUM"]
        }
      },
      {
        headers: {
          'accept': '*/*',
          'accept-language': 'en-US,en;q=0.9',
          'content-type': 'application/json',
          'origin': 'https://app.uniswap.org',
          'priority': 'u=1, i',
          'referer': 'https://app.uniswap.org/',
          'sec-ch-ua': '"Google Chrome";v="125", "Chromium";v="125", "Not.A/Brand";v="24"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'same-site',
          'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36'
        }
      });
    return response.data
  } catch (err) {
    console.log("error:", err)
  }
};