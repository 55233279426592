import { Flex, Image } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';

interface props {
  name: string;
  img: string;
  val: number;
}

export default function CoinItem({ name, img, val }: props) {
  return (
    <Flex w='full' bg='bg.100' px={3} py={4} borderRadius={8} mb='5px'>
      <Flex alignItems='center' gap='7px'>
        <Image w='25px' h='25px' src={img} borderRadius='50%' />
        <TemplateText fontSize='14px' color='text.400' txt={name} />
      </Flex>
      <TemplateText
        ml='auto'
        w='fit-content'
        color='text.400'
        txt={val.toString()}
      />
    </Flex>
  );
}
