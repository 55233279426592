import Ethereum from 'components/svg/Ethereum';
import MantleDex from 'components/svg/MantleDex';
import USDT from 'components/svg/USDT';

export const TOLERANCE = [
  { value: 0.5, label: '0.5%' },
  { value: 1, label: '1%' },
  { value: 3, label: '3%' },
];

export const POOL_IMPORT = [
  {
    name: 'Coin',
    children: [
      {
        name: 'WETH',
        label: 'Ethereum',
        value: 1,
        icon: <Ethereum />,
      },
      {
        name: 'ETH',
        label: 'Ethereum',
        value: 1,
        icon: <Ethereum />,
      },
      {
        name: 'USDT',
        label: 'USD Coin',
        value: 1,
        icon: <USDT />,
      },
    ],
  },
  {
    name: 'Money',
    children: [
      {
        name: 'PTD',
        label: 'MicoDex',
        value: 19137.45,
        icon: <MantleDex />,
      },
    ],
  },
];

export const COIN_OWNER = [
  {
    name: 'PTD',
    label: 'MicoDex',
    value: 19137.45,
    icon: <MantleDex />,
  },
];

export const COIN_MARKET = [
  {
    name: 'WETH',
    label: 'Ethereum',
    value: 1,
    icon: <Ethereum />,
  },
  {
    name: 'ETH',
    label: 'Ethereum',
    value: 1,
    icon: <Ethereum />,
  },
  {
    name: 'USDT',
    label: 'USD Coin',
    value: 1,
    icon: <USDT />,
  },
];
