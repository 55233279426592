import React from 'react';

export default function InviteFriend() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='50px'
      height='50px'
      viewBox='0 0 110 104'
      enableBackground='new 0 0 110 104'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='110'
        height='104'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAABoCAYAAAAQAsXmAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAk
nElEQVR42u19eZgdVbXvb+2qOkPPnXQ6naSTTkJQCALJhTAFAgFBQBy4CF68XBUUwaciet/7HLhq
X3nqp/c6izwQwfcAkVHBCA5IAkSFkIkhIZCZdIZOd/r0cPqMVbXeH7X3rl2nh3R3uvvE73P1V1/V
qb2ruvb+1Vp7TXsX8A/6uyQq9wMMR8xMWHenjakxC0nLQn/CRgwWyCbUOITOPgsiLoLKHqMizmCP
kQaQqPFQ7POQrCiiz3OxIe3SVVd55W7TeNFRBRwzEzY/7ACIwSs6SDo2kAaqqgCyg2fN5EgfAwBZ
wbGQ50iUlGWBnEUgi+DCQ2Uyh5iVxybkaPlyt9xtHiuVHThubRX4SEsMfmUchayNagDCIQB4+fUD
8V/8ctPcXftT83vT7jG5XHFBwXVb2Ec1M1X7zNW+71cTkQUSaQvosyzRRxalHdval0w62+qrK3fO
ba7c+a6zTnjrgvOPTwf/VRDyBYIdK8LPZjCnKU10arHcfTEaKgtwmrOseByVeQfpLKG6Gq9tOWR/
5/Y/LWo72L+0L104J1/wzwQ4PqKGkNkUGnCeCByP2a/WVMeeb5k57YWPXr5ow9KlJ/QHhQWCbRdA
lX2YcnqaiPxy9MtoaFKBY2bCtqdiEOkE7KJAOksHDmWsT31z1dl7Dqavyubdy8CoGlNDqPS3OkGR
3+q0EMKrSMRWtzTXP/rZj5/9zJLj52dBRIjZPtxiL2Zd0E1ER+2YOCnAhYDtScCuEgDw2VtXzl/7
6t5/60sXr/J9b8YRN6QEOfWzlBNNANWxZYlMdVVixeLjWh757q1XrQdyQXXf6UWTlyI6+sbCCQeO
+Q4HO1CB/gCw67751PGvvtH+uWzO+2eARViPx96IAaAdDsSBAKotEXdeOumE5tt++q2r/gIIAnxG
Pt6F5jNTRDT2hxxnmjDgmFlg88MVKPTbsGL0ka+tOGHTjo4v5AreZbJ84hpFNMRx6TkFWHhebbG4
/dqi42f/8Kff/sizICJ4uSKQO0hN7+qfsAcfTRvH+4ZaLKbbk6gDfvTglpr7H1z/pf6c9wlmXwxx
zZE3hOiw50vHueB3OAaWch8IqKpIrLr6PWf+7xuuW9oGAMiiDy/say+3TTiuwDEzof3eCmQ9B36B
3/nhJz7Qnsp8w/d5ekm9iW3UICAOBeBg3FcKHkEUZs6o/+kPvn75PfNa5hbg5YtonLmXaF5uQhsy
XBvH60a8cqWN5lcrIOJ0291v1t21YuNthbz3bl0+wWAN2cAhxr+huM8UnUIIAzxCLOZs/eB7z7j5
po8u3wnEgArnANWdlCpLu8bjJrzpoRgSqSRSwBVf+8OSN3em7vZ9ng2UD7ABDR0BgMOKTkEgEISg
/MK3zfraPT+68QkQEVj0YOrJ+yZbcTli4Hjrk3GgKw7RT2d/6NH/0d1b+Doz7KMFsAENHlRkjlB0
SvCICPW1Vb/+4X99+BvHzW3OQfhp1C3eM5mG+5iBY2bCrlVxuK/GutOeuOjap77TnytcL8sm6/nH
3vAhABxKdBIRhBBabBIRKioTa7/9las+c/opx6RRpCw29+2eLP/nmIBjZkLbwwnkCs6ql7bF/v3b
z9+Zy3uX/z0AFmn8KMErHfOICPFYbOtN17/7xivff9pBeLkCGjI7JsNgHxtwex5KInfAufuxtqof
3rv2l67rn/v3BprugHEALxaz91179fJPfOyaC96C6+UwbdH2iRabYrQX8NYn48gdcFY90xX70b3r
7vt7Bg0IxLp6fnOvmhSc45IyBhhgeb5Y9Gb+4oFn7nr8ty9Mh20n0fPqXGaeUK/UqIDjTQ/FgG3x
7rQnPnfb87cXiu7yv2fQIm0bBrywTgicAk/VLRT9pu/d+eTtf1m/pRYsqtG9Yc5EPu+IgWN+yEIi
lQSm4MLrnvxWPu9+YDI7djJoKPBMrjPBA0KuY2bk8sUFX/36Az/cuv1gAk5lHR/Y1jhRzzoi4JiZ
8MbOCvh5Puea+6/vzxRuLHcnTxSNGjxDZAJAfza/+KYv/Z+vougS4vlZfODlyol4zpFx3P4VSTh1
4sqb/3pyqjf3zXJ37kRTKXjB8fDjXfgbSHX3X/qJm39yOQCg0p7LK1fa4/2MhwWOt/4ojv4e+8f3
vlS9ZefBX/i+Hyt3x04GDQBnQPngIlPRq6+3fen2u3+/AB7FsXjWuI93wwLH3CoAxAHgnsd2/sDz
vPll7Muy0lAiU5WVch2z7/zqsdXfeWPngSQcquPUzrrxfJ7hOW7djAQwBRd/7MGLc3n3inJ33mTT
4USmKtOboagAQKFYnHvLf953LYpEcPzZ/NBD1ng925DAMa+0UQ/ngd9tTrZ19n+n3J04WXRsE+P+
T3l4odXF96/xUJMcWmRGuRCa64Ky4PzefR3X3nX/0y1wEccFi484RUPRUIFNQsfmBFLAj/7fS5/3
Pa+l3B06WfT9f/VwYrOPygTwznf4+Pwl0XjpYCIzPM8DxjqAxYOP/PlLRRQBi6Yz70yMx3MOznGb
H3bQZ1uf+N6z83ozhc+VuzMni6ZWAS0NZsYY4ZR5/qAi06TBuM4EON2fPfXfv3T3BSASyIuZ4/Gs
A4BjZsK0jhhSwLpNHZ9nhlPuDp0sciwG+wCYNBBxZ+C7Xcp15nkAg3Ad8Mpr2z6ZLXoCeXcq88hy
RYejQTjuTht9tvXZ29fMzuWLV5e7MyebiAlgAmnwGGAG+35kb27s+2CfB5Sbx/l8oeWr/3n3MtgO
0LP7iLluIHCb6+NIAX9dt+szzDzuhuPRQk01wL8sAe64hnHZSUYBA5D9TSyAIXzFAxQTRMUla61T
mQuMlza+eX3R9QQc0cC86Yjs4QhwvHKljUTK+srP1jdmcv5Hyt25403HTSfceDbw0MeAP90E3HIx
cNZ8giNMDpIc51MAgArOkATD2IcbD1pWWi9fKBx76zfuPh2uJ5CtPSI/ZpSjFnQ46IzRynU7rmT2
j1gOl5tsCzh1FmH5scDytxFm1MoCgYCzmKOvLkOPb0SQIjMatDQT281NXR9cxzJWJ4+JdJ21G7Ze
DmANfDQAaBtz2/T/ZCZ03e+gppJ7s96Hyt3pY6XKGHDOXML5CwjnzCdUJwgse58kQ8GXaAiSYMle
BwG+EVyVispQFIhGAsD6GmYekJjE8v5EhO7e/mV/W7Ol9sylJ4K5o5poWt9Y2mlw3J02emPiQ//z
d+9wXe+EcgMwGmqqApbPF1g+X2BJM8GxpZjSnAVAyI6WgBEQKBQ60i25xUd4ndqTyVUSGIOTgo0l
x4VlgOQ8VSf4/+LeXz117plLT3wCPf40AEcI3CvJGHwP23d1XFVuIEZCx00VOH+ewHnzBBY2Bj3L
pEScBEMpBhSIPK1G+Bwwkqoc0dwp/M2KW0ZO8t9pzhuMA7ft2P8+AL9FhT+VmXeOJbXPDv4ZE1IP
2+jpRybvv7e8kAzxoAJYMsPCeXMEzp9noakq6GPd96qj1KBEJDkq5C7VkYGIDOuRiZwPfR4+g3ig
6q3q6/GNQ6O9RGeRz+dHxrlsJrvw93/667SLL1zaCaejEkB6TMABDwv09NNNtz7f4vv+UePeqnQI
y5otLJ9jYdlsgaqYlFkCYcezKfIkSGCQqiJUJQVqyH1SqilpKEEwWFYqJyCOggszoWig6NTHBops
gAsAf/rD2hMvvnDpSmRF7diB29XvAMCmrfvPKncOSVMFYXmzjfNn21gyw4YtOBCBxnijgCJfgiRU
D7EGi5lBQoR1SY048nqYEtIAy0fIkXKW1VA0UAyqVwCDntNSAYxde9tPAeyVsKgWwN6xAZeptFFV
pO5ed1k5wDquzsL5s2wsn+3g+HohZVNozJLU9pgYZGp9CJWBACgyroHWEHXXM4NFcD+WWiYIEByK
PMVlrEBkQJjcZigz6reSrBEONAAzy0nK1e5DPUsB/Dc8t5aZxWjT+QLgZsBCH1AoeOdMFDg3HV+B
D86LoTZGIAF8ZX0/HttVQFOS8MiFVSFY0nMRURyE6iPFCYYSIJkt4C4/OKcBU9eTBpb9cIxjLR8B
IiXyKDoequ4MJXOoRZqghLfSxwpwYh+CgkYoweG6xcbf/ekvTe++cGk70FGBUYpLwdwq0FcUP394
fY3P/qyJAO3SWXHccGwStbaQHWOWyt73SXstgt+QqAh9TjmA4Ud9iqTq+4afUdYhkN6rc8Fe6LqR
4YvDOgpZ0wgfSbJkNCcz0tIIsK9s2NwcuPCTydH2qQDOFQDwx+f2TFhawqn1ju4I1ZGRzvLDDgsA
kM1Tx34ULLDhjlJ73xCpfngvVcYlYOvzHHAPsRSdvgGasgFJcSTDgtow5CYACOZBt+D/MIgZ+/d1
NMMlQs6tGG2f2tiWtZBwKNWbOWaigLNJdhYY7EFrgIoCbghEl1YsWAkj1rWU6FT1Iqq4EmtavVfj
XfS+Sk8PfkuVnY2xiEMlJnipGIIGcg7JgXAok0DoySLynBrjiMFSs+3qSAUavLBGzXE2KvdYSFno
7y8umCjg1JsbdKkaS4xiQ4lQtpfWBmECGHojApDYsLuC0UOPZRyc0v+Lo/+X1XW+GjalCSE9JyzH
RoKI2HHEamyj0I6Eof5HQAoVEkFhe9S4mE5n5gMguPkxcJxdL1AD5Ive7InCjTjsKFKgRIAlAxZo
GwxkKBB6M1T3ASMO6WICyxcC0oQwjiWo0LzJIXdorZJDEWsaDoZHmYggKLAaiaA5MwRM7tm080Kl
ppAvNAM24PCoQzw2uvoIbJOPsS0MMxIKMBBgyR5a9dbABmJMeT20pggh41ocHEuNkkBg9qP2lhKJ
Chx5PUquV1zIvrINOeLdN7Va7V803hvTUiNp0AsJvCkrTW1TXUzKBFHk+TWAC3j2qOOeNqw4IVkB
9lE9UcApZUR3fgmjsO4oCr0eCltlUAPaDcVKNEqxCsO1pf+nujcHpoLikEDVlyLUD70dgoJNRwNU
yMcHLOUD1e0JQVCnA4UkrCOCBw9tRZByMmvzxme3wgXIhieYmUbjsxRIFwXQB/g8YcCxoflp7VF3
UHjOVNfhk66nr/UNRCNb1ITgAaYBGxpqeB2b10u1MmIKKC008hIaolW+S5ZQ7i3W7jE1thGx5Ejj
vNQuAWB/W3sCts0IFNIRk8CUGKHfIZ95wkQlGWCRoc5rkiq4srVKbTEMuDYEaTATggaro0EIXwbz
BaKSl0cfB+wCgQAAIblPIFQ8iGUZB1wnEN3M4VmBp+6X6uyOy+JRATcpOSXtOT98w0kpBgYpr4RS
YACds6HKtaYnVXvlswy9H4Zf0Rz7TO+LaUoQtNjUIEgt0/S0gBmOYUgraRa6sQzFxNAqhaFVqusi
epX54rqjR0LAr2AImwTRqD3UI6VH92fRU+CQ80q8ciYX6gQdnyJiSxvt8lyUG4VhcoioGC0VewaH
mlIgMAe45HrDy6PFoOQkKQL1dYrbGLDkecvgLFVPkNxEAOS06Q15+R6MaqUiG7VFxiFAiLFFYkdC
+/Me3rvmEC6cFke9E3DL673B/Pa0y1jZXsAZUx0kndBIDhWJ4JU1/YckAFZhGmGaD2SYD6HSAkCn
K4TRA6UVSjtLAhI4s5XyEigVgkLNM1T5gzPK/lNcpspIAsUgWBSaFIYOCyLGtBlNBcltowQuBQBp
CEukR3ntqKiz4OGBvZkB59Mu49MbexAXwFkNMZzfGMe5jTFMjZdm8ZhBUNkFJLmPQoBMaRT+VjIy
NMJJx3pYA2Kpeuq/ymBdMLZFb6rADtY/MZUVChQSubRGwKVhbM40QSzL6rcBwIVPzuii4Db8AoPq
SAjRp96WcsTk8j6w8mABKw8WQABOrndwfmMM5zfGMLdaaJMgTEcwvB9GAFXZemZUIJIYBGiTIojn
Re37wL4zownRCLjQQIeBVMEUgqY4UEcGQqNbEMHTTwtYJPoCbrNGvUKDjdqYj14g7lhtEzbIjYDm
VtpICuD1PhfMjI1dBWzsKuB7W4CWSgsXNMWxvCmGRVNsCIp4IyVg0LLK9HyEotK4QHed9H4AECI0
B8IYYPA7IiotaftJYIUEL1jS2w9XINIpFFH3l2Bfu8sqKmNtcEGwrPzogdsGD41p1FTGth/qzoz2
+nEjwYyHlzSgPe/jmc4cnunI4aVUAa4P7O73cM+ODO7ZkUF9jLC8KY7zpsdw5jQbCTvoERJRP2dp
PC4Mn5s5J8qVYUQH2BgbWUUPjLENBtfJV0AYbmnNvUoDZoYwAqtEag+0TBE5HHizFjMXHhw9cLXw
IGyaM71mx659PWVbzmlHxsOejIc5FRY+1FyBq5uTyDDjuY48nunIY3VnAX2uj+4C4zdv5fCbt3JI
2IwzG+I4b4aDc6fHUS8nMJExHkYSuRRYZqBWxtsE1BgX3EElxWrlxHBXhdzDoVIiWVJxpxAks85E
OL4xw7dCD8rcumQGG1acg788YHNr6+vU2jpikWlj4ZUudj6Ai849dudzG/bIByvPOPdsZwH/NrtC
jy+VBFw8PYFLmhIoMmN9TwHPHMzjzwfyOJj3UfCAZ9vzeLY9D0IaJ9U7WD7DwXkzYmiptnS6g2kB
h8qLad+RtrvgG0oMELi8pCICAVhmKEeOaUL/VtqlGVoKDHSlg2rOBtDckEwBAN56bS4OvX4Jf2bB
0/TjbSMSmzrX4f0Xvb3fssSok1bGk1Z15gd6SKS4cohwWn0cX3x7NW49scQ7JzF4JVXEjzf34wPP
pPCBp7vxk01pvNJZjNh8oest/B86p4Sj54P6gRgmyECoCM0I5d/US0QRjDgch5sl6yjgJQfPb6zu
AQSQSeXh+7PgZy7nm+fWjQg4AIDFBaASyYS9unQpwMmktT15pIt+ibtKDEhTWFKXQJWtOtrUCll3
4FuZIu7bmsUNz/fg3U8ewjfX92H1vgLyLqKpET5pF5SlFBpdrnzCbIAUdLqFwPksiGERwwbDVsdy
LwBYBFgc1iMKzlXGRO+8pto0mBk9BwryzamBV3w/f3FO/ciAizcUgH5Mq6taPeloGeQysLqrEDqB
DcewySkWAUsbonNSlBouP7SjlQMQ0O36eGp3Fl9+sRvveaoD//FCN57clUNPTkax/RCUUq8J+YZL
TAT1LAq0UGGAZFEgUi0KvSq2BNSKpDwEZQumVW4HiNB3KAvfCFD6fgwZ913cOnfYKcdBO6dPLYAs
OuP0OS+UEzgAeLYzL3NMQodwONEQOpKwbGpcAobIPgpmCKjaCh7jrwfy+O6GXlz51EHc/GwX3kgV
tfM3TFwKxauQnn2VS6JekEgMDlE7TmjHpRKlMt9GelSOb67fA4DRs2/gquoe1yBVfCe3tg65uIIs
OMUFe3zL9WfvdSxrd1nEpZyf9mxKvoDKz2gk7phJPuc0JCLpeSF4soPlm6/EoB6DAMkhwZj1RlcB
e/qKUuwhApjiPkGAIznKBgIuAsMhhk0+bCuYhmyL4NgSDEswHMFwyA/qEMMiH7YIOHHR/Ib9AAid
bYPbYK4/E6k7zxoWOCJi2DUFAKiqrlgxeWgNpJ6ij409RSNdD4PE4wh1loVFtbGINgcDREVahZeg
RqqYxrE6b8TvyFBelKIhBEMIw/sPklzIMlgacqPQyUimi4wxtdbZP2t6TT/8ooeutqFXUnd5IX96
9qDTjg1W7M1C2HTqyY2P6aXbUR4lZVVXDiq2puysUGQKCSYb4jJ0M2mPX6kMJZYZy6E2o5UNWa03
78P32NAogVTWC6PkyshWyUVkKC6CNLBKeSFLaqEm0Az807zpLwNE6Np3eMc+uWcMtvZlCFzzlTkA
+P4t73vDcaxNJmiTDd6qVDai9WnuM0QnQWDZ1KQGDcRRZuNAm7NYKgQslQYlVpkNDTEQm77n47c7
0vp/sU9YsS0dKBkkxZ4ALIshBMFiqaiwkWfJoSiNHgd7W7B/3uKWnQCAts09h+0MHw24ac6AnFcN
HBExPD8DAI1Tqh4qB6cp2p510ZZ1Q/dTqX0lATwm6WBWIhqBDG3tiIyMiNAgYg0dtDVF5n1v9OBb
azpw3+YefHn1Aaxtz0QiAepW6iUQBAgr4DYywt2K06BXTg+4fXZD1daGuoocipkiDr2VHVGHkLek
VFGJai1uPAsq0L+899QnAMoDZeI6AlZ25SR3CQNAOf4Ydta5U+MDrtXRZmPsU8oHaZss5AIz/c5i
xisdWTy5qxdtvUVpg5E2B2yo+8h7SLGoQLQMI1xnMKsAKhhnLZz1KnwAHTsPz22KPK5B112RhOUI
cHTspXkU7OJHr1zcWV9Xce/AdfknHjzFMc/25KSGhwinaRNB7pdNSWqnrjKWzd+lCsnA4zAQqsc7
CbACW0BGAsAQljK+ASG9KEJAR7stgrbtwrEteJHqq+PtZy2aF0zY37mxe1Qdw/68IYEDACSCuNyV
l57yMyHgHu4TXxNFa/tyaC94JS4qaO5T50+sCu25oZ7MDFFqsKjkXMkYGSokJXE2I7ynVkE/XN+o
F+HcRS1/FUSMzl3dyPWNdon7Zm49T48LA4GbcVkWrvA+c+2Z+2urKh7UjZjkMc9l4O79fREbzswh
Uap6kgQcMfDZdH4HwpBMCJIh6hC19wKXVaCwhOJWikQ1psngdnhOiV1EJoTYCB3UtcnYoWX/NH83
AGD7mkOj7hCfbXS8oWdTDQCOiBgJ6kWuQO+59PjbiahglEX2E00Pd6TxYm8udA6zGKBhbkkX4Pph
NIQQejeiaBm+TKW3DOBEhN4NCqPaagaO4jBlAihghcxj0VldZhBVitNli+asFmQxDu3uQbprbB/S
te2WIYEDAEy/KAMn7v6vj1/c1tRQ/ROd+TvJ4BWZ8entHXiuOxdRUJR95/qEO/YMYgoZg9iQ4rNk
PoAax0yWVHMDtLtK1SPSMTgV8RYIRXBglIcB1aa6il3nnvb2wATY8eLouU2R6zcNC1xgGjhdAPBf
X7n8Dsexdg8F3kQDmPMZn95+ENe/2Y5nUlnsybvo9xhruvP41JYDWK2i9obnZLDxTnDouTDtOXWp
0jgtw5GsxJzynAhCmK4gSNtnQg6GQoQhHShns2D/ivNOWimYGHu3HEJ3Z+GwjR6S/BplFgyZhknz
lud4/5OZk09opEULW7669pWd/9fMtjKDrZMReF2TzmJNOhsuQQFjHBojKeYS+nfgrNS+Y4b+Z+F8
DjI4mfR4GXAXa/mpZnSdNH/6C3NnT+tGIevizec7j7AbBDIPVALoG35N5i3JFHLAz797xXPVVRWP
mV92ChpzeI1qvGg4rTEsZ3N4CfP8jRuY9wnUedUj4eQPEiqMo76nI7nHIm0eCKjyEDw9w0dGvauS
8c73LT9lPQBg+0vtcItH/nZ7ffHgeYfrjOXLXbjchbygb37xslYnZu0Y9HOUBnjl9LgM2Q5EwZR9
HHnu8PnDa4K9yolUUxLDdobzB6I3DF4A8q6+4MTfVSTtInr2p9H22vgkHHuOA4zguwN07KW9SFqZ
ZYuP6b/k7Hd8BhCFob7kW9oRRyNF57FJMHQb1Hy3AGahRaH5vVRozkIJaCY3L1s894/HzJvZhULW
xcu/3z/e7RjZlz4aL+iAk3BvveXyLW+b39gKDPwMs7lXx0cTgObzAohwjzwB5WxkCoEyNUx5gZFj
EuUyddAys/61i5Yt2gIAeO3Pe5HPjl+KuFUsAiMEjoh8zOhtBwr08M+ue2haQ81dJnilovNoAlBF
n0ufJ9zC51flWtUXQvodoyIyaiOGfEYAGmoSez582ekrAQC71x8csSN5pFSoKACj+JoV0aV52JUd
IEG/+/mN36mvrvh1CN7IARwriHqG6gjBohKOUF7iyIoKpWOXOiKSKXYwuI409+nZsfomwe+aqsTB
j//zst8mkwkPB3f14c0XusYVNAHG1GvSowIOAGjqGb3I2ofisTh+ddt1/1FZmVhFUryYAJYCNRyI
48WNGig5x4BNURgRhyEAEd4xtEOh1mOhEE5SAd3IPUNTIRm3e667/Ixf19RUFJA62I+Xn9w3rqAB
AFOvSpod9RcbqeWcFOzq7qbmpuJt37rm5sqK2HPhZ5ij3GeCVApYtNPpMBswlHijUgBK/8+g1ylu
lOXmLERtC0jQTOemVmbUWxDsEgmn+9rLTnuksb42g/6uLDb8Zk8kc2vciDrU0aiBAwA0Le4ERN/i
E+dlH73vc5+sq0s+IQTpzgjSrgd21FAgHinXkdHhevpUKafJ5gZzClSUM3QoCqJgPTAi+MIMBgl5
i+AmXDKm1VbE2z95xVkPzm5u7EW+r4C1T+wZF3ttMHKsXUcEHBExpi1ph+d2N9UlvD8++LkvzGis
vccEKArg4Fw4HJAj3dS85IH3M4xoM/xiPIt2UUXQlUvSmJxHxqoPROBgLQ401Fbu/tQHz3mkcYrk
tBcf2Y18/0RNMvRx3LQ9GoMjvRv3vjgVBW8aCsBVn7rj6u0793/Z99keuBBZ+BKWfh3KPCiRQnI/
SPxMpxME97ZF6CURYFjSl0gUGN2q3Bahz9ExE1il68uSQVECYJEPQRR4TtS9ZdmxM2s3fviy055P
JuIuug/2Y8PjbRPGaQBA1h76SdtT6ufYRKV5v5rTD8FKHgCIHrrtxl9dfslpH4zF7D1Cu4sUJ4Si
yeQOIUpF6vCbiq9JPUSfI/NPhDO6oUUnAt8VArEoSAVw1KugY+dqwg5AAr4g+EzwWcBnAoQoXnTq
3BU3fGDpymTCcdGxuxfrHptY0ADA9nZF+n287sv71lbAc2fBhrP6lbbqr33j/q9192YuiX6u2ahf
6pSWs+QPy3FqWpvJcQw9z1qnGFCQH6LWobTllF5b+R1VEFVGBCwEnhPNkQgSW/X8NwLqKmLtH734
xBXzZ03rBQDsXncA217qnlDAAICsDBoW/IpaV+mo+bhaxsxsIbVuFlhUAYJu+MLPL9i4cfuXXdeb
PtRH9Mwfukw7fg3gzHZIkCztagonVCgl0BIybU6mJzsCQXaXQBQwxcUSbEuEa27Z8n6OgHv6cY1/
ueK8hS/HnZiHYqaIV59uQ2rfqGeSjokcPE8/2P96BMvx/h/MTOhbNxVF0QjyxCubdlfe8o1HP76/
vfs6Zl8Y9aJADvionrGKgTE+CoEBq/uAIDs5nGsdpiUg4CTt0YdM7iFY8IPxSwIajIUqrBOMkXMb
km/+6zvf8eyc6bV9AAiH2nqx+ekDKORHPW97TGSJbky5/pHSSY8T5oti3hRDN80AZ2qAGH7ysxXz
fvPU+hu7e9KXlIKnkCoVn4MBp5QNvUKPlJUqH0SLUsFhap1UXgQDlqU4VI6VQk6DkvdWL0BjTWzf
JafMWb3s5JY2EIBcbxFbXzqA9m2TO9/aif+RfrBrV+npCXciMm+twaHsTAiKAcAd9/2+5dHH11zb
lUq/D+BwnepBvgoVcXggPKdcU6XpdCWOETg6TTwcA1XaHBHBhh+MiTLRh4jRXJfYfekps/52zkmz
9ulV2HZv6sSOF7smxqgehgTtoB/ve3qwoknx/jKzwIENU1EVnwaXHYDogd/8renRXz//3n3tXe8v
Ft0ZzAMlz1DA6fR/CiZaBAk7HCqOEjhb1SHoxFclRkEEW6r7MUGFd8yoeG35STM2nXbc9A710Gjf
3Y3tL3aNIZXuyImsQ2hY8LipkESKJ/NZgoW7P1gPshrhFhNwHM709ln//ePHT1qzbuulh7r6LvZ8
r6qU48wHVWDpjC1ZqFYwK81WJpZjGpsaJ0OQ8Orrqv52ycKaPRcvamqvjDvB7A7P9XFgWzd2buia
QGN6eLJEFm7tr+mnm4dcwaQs8RZmJnRvrAVZU8DVNSBXAEBvtt+++96nj9mwbuvife2Hzujvz57m
y89SmsCZHKfCaMoBrKbwEkFzlrTQuKoi+VrTzIYXjl/49pff/6H3bJ0xozmP9m1VWPP4OejtdNG5
vRd7X++dNMVjUETIgxVfQT/c2T5stbI9oCTmlTbSzfVgawp8v0rzmQN0dfQ7f3h+zfQtr++dtXfP
/jmp7v6WTCYz23X9as/1qhl+Fft+FcC2ZVl9Fll9jkNpx7LTFYlY+5SGmp0zpze2LTh+7v4zzlzS
Pmd2c16mR0nHo8jC4w788vN5vPz7ZfC4pqydYYksKPbHw4EWNuAoIWa20L2rGuzXAFwDSwSLTDv6
+7oUfAzdSKeKCFQQYJuBuHBv2wz2C2D0IJZIAZXdRKTtMG6dm0Cq+E64/rh8NXjURNYh+DV/GE48
RqqX5SFHSMxsob09gQo3ARtJCE6g6McRExZcYQOeBRJ2AB77YMuDY3uA74HtIrJuDjZlUGlngf1Z
ohOGzWnk1laB1J1nweWFk9pQQTsw9W2rhlJEBqOjGrhyEX969kyQe4b8HObEkSW6IZw1g9lph6N/
ADcEMTPhpjnzQd6ScR/7yMrA9tah7oY3RrMMVOQW5e6go524tVWg665j5Py0Zvhj/sS2D7L2wvZ2
oe7tW0cjFgejfwA3CuLW84KpTrbdEkzA8GswVGhMgMHUC1AHHGsXjpu2h25YN7ZZOoPQP4A7AuLW
1iCX3+uLqwxjWMUiChUFTL0mPVYxOBL6/+6eqqaBL4Q6AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIz
LTAzLTA0VDAyOjUzOjQwKzAwOjAwPPWtbAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMy0wNFQw
Mjo1Mzo0MCswMDowME2oFdAAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDMtMDRUMDI6NTM6
NDArMDA6MDAavTQPAAAAAElFTkSuQmCC'
      />
    </svg>
  );
}
