import { Box, CloseButton, Flex, SlideFade } from '@chakra-ui/react';
import ListButton from 'components/common/ListButton';
import TemplateText from 'components/common/Text/TemplateText';
import { TOLERANCE } from 'data/pool';

interface props {
  isOpen?: boolean;
  onClose?: () => void;
  valueTolerance: number;
  setValueTolerance: (val: number) => void;
}

export default function Setting({
  isOpen,
  onClose,
  valueTolerance,
  setValueTolerance,
}: props) {
  return (
    <Box
      position={isOpen ? 'absolute' : 'unset'}
      top='0'
      left='0'
      bg='bg.200'
      h='full'
      zIndex={100}
      w={{ base: '100%', lg: '460px' }}
      borderRadius='30px'
    >
      <SlideFade in={isOpen} offsetX='100px'>
        <Box w='100%'>
          <Flex
            justifyContent='space-between'
            alignItems='center'
            h='66px'
            bg='bg.300'
            borderRadius='30px 30px 0 0'
            p='24px 18px 18px'
          >
            <Flex
              bg='bg.200'
              borderRadius='5px'
              h='30px'
              w='70px'
              alignItems='center'
            >
              <TemplateText
                cursor='pointer'
                onClick={() => setValueTolerance(3)}
                w='100%'
                textAlign='center'
                _hover={{ color: 'text.400' }}
                color='text.500'
                txt='Reset'
              />
            </Flex>
            <TemplateText fontSize='18px' txt='Advanced Settings' />

            <CloseButton
              bg='bg.200'
              color='text.500'
              borderRadius='100px'
              onClick={onClose}
              _hover={{ color: 'text400' }}
            />
          </Flex>
          <Box h='389px' p='10px 18px 18px'>
            <Flex justifyContent='space-between' mb='20px'>
              <TemplateText txt='Slippage Tolerance' />
              <TemplateText txt={`${valueTolerance}%`} />
            </Flex>
            <ListButton
              selected={valueTolerance}
              setSelected={setValueTolerance}
              data={TOLERANCE}
              p={{ base: '10px 7px', md: '10px 16px' }}
              w='100%'
              mb='8px'
            />
          </Box>
        </Box>
      </SlideFade>
    </Box>
  );
}
