import { Box, useOutsideClick } from '@chakra-ui/react';
import ArrowBottom from 'components/svg/ArrowBottom';
import useVisible from 'hook/useVisible';
import React, { ReactNode, useRef } from 'react';

import PopoverItem from '../PopoverItem';
import TagBox from '../TagBox';

interface props {
  content?: string;
  img?: string;
  children?: ReactNode;
  isOrderType?: boolean;
  isSlippage?: boolean;
}

export default function DropDownItem({
  content,
  img,
  children,
  isOrderType,
  isSlippage,
}: props) {
  const focusElement = useVisible();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  useOutsideClick({
    ref: ref,
    handler: focusElement.hide,
  });

  return (
    <PopoverItem
      isSelect
      unHover
      m='5px 5px 0px 0px'
      buttonItem={
        <Box ref={ref}>
          <TagBox
            showText
            w={{
              base: isOrderType ? '100%' : '100px',
              md: isOrderType ? '180px' : '120px',
              lg: isOrderType ? '140px' : '120px',
            }}
            content={content}
            img={img}
            iconRight={<ArrowBottom />}
            fsText={{ base: '13px', md: '16px' }}
            fwText={400}
            isOrderType={isOrderType}
            h='40px'
            gap={{ base: '5px', md: '8px' }}
            colorText='text.500'
            borderRadius={10}
            className='border-box'
            bg='bg.100'
            bd={
              focusElement.visible ? '1px solid #2669f5' : '1px solid #28344b'
            }
            onClick={focusElement.show}
          />
        </Box>
      }
      boxShadow='0px 2px 14px rgba(0, 0, 0, 0.16), 0px 16px 40px rgba(0, 0, 0, 0.16)'
      w={isOrderType ? '160px' : isSlippage ? '300px' : '250px'}
      border='none'
      bg='bg.100'
      borderRadius='20px'
    >
      {children}
    </PopoverItem>
  );
}
