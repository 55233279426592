import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import MyTrade from 'components/svg/MyTrade';
import Tutorials from 'components/svg/Tutorials';
import { LIST_TABS } from 'data/copytrading';
import { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface props {
  setTabSelected: (tab: string) => void;
}

export default function ListTabs({ setTabSelected }: props) {
  const router = useLocation();

  const tabSelected = useMemo(
    () => router.pathname.split('/')[2],
    [router.pathname.split('/')[2]]
  );

  useEffect(() => {
    setTabSelected(tabSelected);
  }, [tabSelected]);

  return (
    <>
      <Flex justifyContent='space-between' w='100%'>
        <Flex w='100%' gap='6px'>
          {LIST_TABS.map((item) => (
            <Box
              textAlign='center'
              key={item.label}
              w={{ base: '30%', lg: '20%' }}
            >
              <Link to={item.url}>
                <Box p='12px' cursor='pointer' key={item.value}>
                  <TemplateText
                    fontSize={{ base: 14, md: 16 }}
                    fontWeight={700}
                    txt={item.label}
                    color={item.value === tabSelected ? 'text.500' : 'text.400'}
                  />
                </Box>

                <Box
                  w='100%'
                  h='4px'
                  bg={
                    item.value === tabSelected
                      ? 'linear-gradient(190deg,#1d6dff,#132038)'
                      : 'unset'
                  }
                />
              </Link>
            </Box>
          ))}
        </Flex>
        <Flex alignItems='center' gap='10px'>
          <Flex alignItems='center' gap='5px'>
            <MyTrade />
            <TemplateText
              display={{ base: 'none', md: 'block' }}
              w='max-content'
              txt='My trades'
            />
          </Flex>
          <Flex alignItems='center' gap='5px'>
            <Tutorials />
            <TemplateText
              display={{ base: 'none', md: 'block' }}
              w='max-content'
              txt='Tutorials'
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
