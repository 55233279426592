import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import BannerSpot from 'components/svg/BannerSpot';
import { TYPE_TAB_SECLECTED_COPY_TRADING } from 'constants/enum';
import { FAQContent, GAINS_UP } from 'data/copytrading';
import React from 'react';

import FAQ from '../FAQ';
import ContentFAQ from '../FAQ/ContentFAQ';
import TabTrader from '../TabTrader';

interface props {
  tabSelected: string;
}

export default function BannerCopytrading({ tabSelected }: props) {
  return (
    <>
      {tabSelected === TYPE_TAB_SECLECTED_COPY_TRADING.FUTURES && (
        <Box pb='50px'>
          <Flex mt='30px' alignItems='center' justifyContent='space-between'>
            <Box w={{ base: '100%', lg: '50%' }}>
              <TemplateText
                txt='Top Traders'
                fontSize={{ base: '32px', md: '48px' }}
              />
              <TemplateText
                txt='Meet some of our most popular traders'
                fontSize='20px'
                color='text.400'
              />
              <Flex alignItems='center' mt='20px' gap='20px'>
                <TemplateText txt='Apply to become a trader' color='#2669f5' />
                <TemplateText txt='Start Trader Program' color='#2669f5' />
              </Flex>
            </Box>
            <Image
              display={{ base: 'none', lg: 'block' }}
              w='50%'
              src='/trader-banner-avatars.svg'
            />
          </Flex>
          <TabTrader />
          <Box mt='30px'>
            <Flex alignItems='center' justifyContent='space-between'>
              <TemplateText
                txt='Hand off, gains up'
                fontSize={{ base: '20px', md: '32px' }}
              />
              <TemplateText txt='Learn more' color='#2669f5' />
            </Flex>
            <SimpleGrid columns={{ base: 1, xl: 3 }} gap='30px' mt='30px'>
              {GAINS_UP.map((item) => (
                <Flex
                  key={item.title}
                  border={{ base: '1px solid #28344b', xl: 'unset' }}
                  direction={{ base: 'row', xl: 'column' }}
                  p='10px'
                  gap={{ base: '20px', xl: 'unset' }}
                  alignItems={{ base: 'center', xl: 'unset' }}
                  borderRadius={{ base: '10px', xl: 'unset' }}
                >
                  <Box w='120px'>{item.icon}</Box>
                  <Box>
                    <TemplateText
                      fontSize={{ base: '16px', md: '20px' }}
                      txt={item.title}
                    />
                    <TemplateText
                      w={{ base: '100%', xl: '310px' }}
                      fontSize={{ base: '12px', md: '14px', xl: '16px' }}
                      txt={item.description}
                      color='text.400'
                      mt='10px'
                    />
                  </Box>
                </Flex>
              ))}
            </SimpleGrid>
          </Box>
          <Box mt='30px'>
            <Flex alignItems='center' justifyContent='space-between' mb='30px'>
              <TemplateText
                txt='Frequently Asked Questions'
                fontSize={{ base: '18px', md: '32px' }}
              />
              <TemplateText txt='Learn more' color='#2669f5' />
            </Flex>

            <Box>
              {FAQContent.map((item) => {
                return (
                  <FAQ
                    key={item.question}
                    title={item.question}
                    children={<ContentFAQ item={item} />}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      )}

      {tabSelected === TYPE_TAB_SECLECTED_COPY_TRADING.SPOT && (
        <Box pb='50px'>
          <Flex mt='30px' alignItems='center' justifyContent='space-between'>
            <Box w='50%'>
              <TemplateText txt='Top Spot Trader' fontSize='48px' />
              <TemplateText
                txt='Meet some of our most popular traders'
                fontSize='20px'
                color='text.400'
              />

              <TemplateText
                mt='20px'
                txt='Apply to become a trader'
                color='#2669f5'
              />
            </Box>
            <Box mr='70px' display={{ base: 'none', lg: 'block' }}>
              <BannerSpot />
            </Box>
          </Flex>
          <TabTrader />
          <Box mt='30px'>
            <Flex alignItems='center' justifyContent='space-between'>
              <TemplateText
                txt='Hand off, gains up'
                fontSize={{ base: '20px', md: '32px' }}
              />
              <TemplateText txt='Learn more' color='#2669f5' />
            </Flex>
            <SimpleGrid columns={{ base: 1, xl: 3 }} gap='30px' mt='30px'>
              {GAINS_UP.map((item) => (
                <Flex
                  key={item.title}
                  border={{ base: '1px solid #28344b', xl: 'unset' }}
                  direction={{ base: 'row', xl: 'column' }}
                  p='10px'
                  gap={{ base: '20px', xl: 'unset' }}
                  alignItems={{ base: 'center', xl: 'unset' }}
                  borderRadius={{ base: '10px', xl: 'unset' }}
                >
                  <Box w='120px'>{item.icon}</Box>
                  <Box>
                    <TemplateText
                      fontSize={{ base: '16px', md: '20px' }}
                      txt={item.title}
                    />
                    <TemplateText
                      w={{ base: '100%', xl: '310px' }}
                      fontSize={{ base: '12px', md: '14px', xl: '16px' }}
                      txt={item.description}
                      color='text.400'
                      mt='10px'
                    />
                  </Box>
                </Flex>
              ))}
            </SimpleGrid>
          </Box>
          <Box mt='30px'>
            <Flex alignItems='center' justifyContent='space-between' mb='30px'>
              <TemplateText
                txt='Frequently Asked Questions'
                fontSize={{ base: '18px', md: '32px' }}
              />
              <TemplateText txt='Learn more' color='#2669f5' />
            </Flex>

            <Box>
              {FAQContent.map((item) => {
                return (
                  <FAQ
                    key={item.question}
                    title={item.question}
                    children={<ContentFAQ item={item} />}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
