import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import React from 'react';

export default function MarketInfo() {
  return (
    <Box mt='30px'>
      <TemplateText fontSize={16} txt='Market Info' />
      <Flex mt='10px' alignItems='center' justifyContent='space-between'>
        <TemplateText txt='Borrow Fee' color='text.400' fontWeight={400} />
        <TemplateText txt='0.000938% per hour' fontWeight={400} />
      </Flex>
      <Flex mt='10px' justifyContent='space-between'>
        <TemplateText
          txt='Available Liquidity'
          color='text.400'
          fontWeight={400}
        />
        <Box>
          <TemplateText txt='2,249.4412 ETH' fontWeight={400} />
          <TemplateText
            fontSize={12}
            txt='$4,075,830.02'
            color='text.400'
            textAlign='end'
            fontWeight={400}
          />
        </Box>
      </Flex>
    </Box>
  );
}
