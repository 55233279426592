import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Banner from './Banner';
import Introduction from './Introduction';

const Home = () => {
  useEffect(() => {
    document.title = 'MicoDex | The Super DEX on Mantle';
  }, []);

  return (
    <Box position='relative'>
      <Box className='maxW' bg='bg.100' pb='60px'>
        <Banner />
      </Box>
      <Box p={{ base: '80px 0 60px 0', md: '112px 0 60px 0' }} bg='bg.200'>
        <Introduction />
      </Box>
    </Box>
  );
};

export default Home;
