import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Skeleton, useDisclosure } from '@chakra-ui/react';
import { ERC20_ABI, GLP_MANAGER_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import WrapRemoveLiquidity from 'components/common/WrapRemoveLiquidity';
import { ConnectKitButton } from 'connectkit';
import { CoinType } from 'constants/interface';
import { USDT } from 'data/cross';
import { useCoins } from 'hook/coin';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GLP_ADDRESS, GLP_MANAGER_ADDRESS } from 'utils/ERC20';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

import Setting from '../Setting';

export default function RemoveLiquidity() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [convertCoin, setConvertCoin] = useState<CoinType>(USDT);
  const [valueMoney, setValueMoney] = useState(1);
  const [balanceGLP, setBalanceGLP] = useState(0);
  const [timeInterval, setTimeInterval] = useState(true);
  const [valueTolerance, setValueTolerance] = useState(3);

  const { isConnected, address } = useAccount();

  const { data: balanceGLP_Token } = useBalance({
    address: address,
    token: GLP_ADDRESS,
    enabled: isConnected && timeInterval,
  });

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    setInterval(() => {
      toggle();
    }, 10000);
    setBalanceGLP(Number(balanceGLP_Token?.formatted) || 0);
  }, [balanceGLP_Token, toggle, timeInterval]);

  const { data: dataTo, isLoading: isLoadingTo } = useCoins(
    convertCoin.symbol,
    isConnected
  );

  const coinPrice = useMemo(
    () =>
      !isLoadingTo && isConnected
        ? dataTo?.RAW[convertCoin.symbol]?.USD.PRICE
        : 0,
    [isLoadingTo, dataTo?.RAW, convertCoin.symbol, isConnected]
  );

  const LinkPrice = BigNumber(10).pow(18).toNumber();

  const { data: aumInUSDG }: any = useContractRead({
    address: GLP_MANAGER_ADDRESS,
    abi: GLP_MANAGER_ABI,
    functionName: 'getAumInUsdg',
    enabled: isConnected && timeInterval,
    args: [false],
  });

  const { data: glpSupply }: any = useContractRead({
    address: GLP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'totalSupply',
    enabled: isConnected && timeInterval,
  });

  const isInfinity = useMemo(
    () => valueMoney <= balanceGLP,
    [valueMoney, balanceGLP]
  );

  const USDG_Amount = useMemo(
    () =>
      BigNumber(aumInUSDG)
        .multipliedBy(valueMoney)
        .multipliedBy(LinkPrice)
        .dividedToIntegerBy(glpSupply),
    [valueMoney, aumInUSDG, glpSupply, LinkPrice]
  );

  const minOut = useMemo(
    () =>
      USDG_Amount.multipliedBy(1 - valueTolerance / 100).dividedToIntegerBy(
        coinPrice
      ) || 0,
    [USDG_Amount, coinPrice, valueTolerance]
  );

  const { config: configAddLq, isSuccess } = usePrepareContractWrite({
    address: GLP_MANAGER_ADDRESS,
    abi: GLP_MANAGER_ABI,
    functionName: 'removeLiquidity',
    enabled: isConnected && timeInterval && isInfinity && valueMoney > 0,
    args: [`0x${convertCoin.address}`, valueMoney * 10 ** 18, minOut, address],
  });

  const { write: writeAddLq } = useContractWrite(configAddLq);

  const handleRemoveLq = useCallback(() => {
    writeAddLq?.();
    isSuccess && setValueMoney(1);
  }, [writeAddLq, isSuccess]);

  const percentOut = useMemo(
    () =>
      BigNumber(valueMoney)
        .multipliedBy(LinkPrice)
        .dividedBy(glpSupply)
        .multipliedBy(100)
        .decimalPlaces(9)
        .toString(),
    [valueMoney, LinkPrice, glpSupply]
  );

  const isNaN = useMemo(() => minOut.toString() === 'NaN', [minOut]);

  return (
    <Flex
      alignItems='center'
      direction='column'
      justifyContent='center'
      minH={{ base: '93.8vh', xl: '91.3Vh' }}
      gap='20px'
      p={{ base: '0 16px', lg: 'unset' }}
    >
      <Box
        w={{ base: '100%', lg: '460px' }}
        p='36px 30px 30px'
        borderRadius='30px'
        bg='bg.200'
        position='relative'
        mt={{ base: '50px', lg: 'unset' }}
      >
        <Flex mb='30px' alignItems='center' gap='10px'>
          <Link to='/liquidity'>
            <Box
              bg='bg.300'
              h='40px'
              w='40px'
              textAlign='center'
              borderRadius='100px'
              cursor='pointer'
            >
              <Icon
                as={ChevronLeftIcon}
                _hover={{ color: 'text.400' }}
                color='text.500'
                ml='-2px'
                h='30px'
                w='30px'
                mt='5px'
              />
            </Box>
          </Link>

          <Flex w='full' justifyContent='space-between' alignItems='center'>
            <TemplateText fontSize={18} txt='Remove Liquidity' />
            <Box
              bg='bg.300'
              h='40px'
              w='40px'
              textAlign='center'
              borderRadius='100px'
              onClick={onToggle}
              cursor='pointer'
            >
              <Icon
                as={MdSettings}
                _hover={{ color: 'text.400' }}
                color='text.500'
                h='30px'
                w='30px'
                mt='5px'
              />
            </Box>
          </Flex>
        </Flex>
        <Box pb='20px' textAlign='center'>
          <Flex
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            w='100%'
          >
            <WrapRemoveLiquidity
              balance={balanceGLP}
              convertCoin={convertCoin}
              setConvertCoin={setConvertCoin}
              value={valueMoney}
              setValue={setValueMoney}
            />
          </Flex>
        </Box>

        {!isSuccess && valueMoney > 0 && (
          <TemplateText
            mb='20px'
            color='red'
            txt='Unable to fetch. Try adjusting your slippage.'
          />
        )}

        {isConnected ? (
          <Box>
            <TemplateText txt='PRICE AND POOL SHARE' mb='20px' />
            <Flex
              border='1px solid #28344b'
              p='24px'
              justifyContent='space-between'
              borderRadius='10px'
            >
              <Box textAlign='center'>
                <TemplateText txt={coinPrice} />
                <TemplateText color='text.400' txt='Token Price' />
              </Box>
              <Box textAlign='center'>
                {isNaN ? (
                  <Skeleton height='22px' borderRadius={5} />
                ) : (
                  <TemplateText
                    txt={BigNumber(minOut)
                      .dividedBy(LinkPrice)
                      .decimalPlaces(5)
                      .toString()}
                  />
                )}
                <TemplateText
                  color='text.400'
                  txt={`est. ${convertCoin.symbol}`}
                />
              </Box>
              <Box textAlign='center'>
                {isNaN ? (
                  <Skeleton height='22px' borderRadius={5} />
                ) : (
                  <TemplateText txt={`${percentOut} %`} />
                )}
                <TemplateText color='text.400' txt='Share of pool' />
              </Box>
            </Flex>
            <ButtonBase
              content='Withdraw'
              w='100%'
              bg='#2669f5'
              onClick={handleRemoveLq}
              mt='20px'
              isDisabled={!isInfinity || !isConnected || !isSuccess}
            />
          </Box>
        ) : (
          <ConnectKitButton.Custom>
            {({ isConnecting, show }) => {
              return (
                <ButtonBase
                  onClick={show}
                  w='100%'
                  h='44px'
                  bg='#2669f5'
                  content='Connect Wallet'
                  fsText={14}
                  isLoading={isConnecting}
                  _loading={{
                    color: 'text.500',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  fwText={400}
                  borderRadius='10px'
                />
              );
            }}
          </ConnectKitButton.Custom>
        )}
        {isOpen && (
          <Setting
            isOpen={isOpen}
            onClose={onClose}
            valueTolerance={valueTolerance}
            setValueTolerance={setValueTolerance}
          />
        )}
      </Box>
    </Flex>
  );
}
