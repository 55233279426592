import React from 'react';

export default function BarchartIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='15'
      height='15'
      x='0'
      y='0'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M21.792 6.401a.751.751 0 0 1-1.5-.034l.041-1.806-1.805-.041a.751.751 0 0 1 .034-1.5l2.556.059a.748.748 0 0 1 .732.766zM5.827 9.346v12.141c0 .698-.565 1.263-1.263 1.263H2.513a1.262 1.262 0 0 1-1.263-1.263V9.346c0-.697.565-1.263 1.263-1.263h2.051c.698 0 1.263.566 1.263 1.263zM17.109 15.5v5.987a1.262 1.262 0 0 1-1.263 1.263h-2.051a1.262 1.262 0 0 1-1.263-1.263V15.5a1.262 1.262 0 0 1 1.263-1.263h2.051a1.262 1.262 0 0 1 1.263 1.263zM11.468 12.423v9.064c0 .698-.565 1.263-1.263 1.263H8.154a1.262 1.262 0 0 1-1.263-1.263v-9.064a1.262 1.262 0 0 1 1.263-1.263h2.051c.698 0 1.263.566 1.263 1.263zM22.75 12.423v9.064c0 .698-.565 1.263-1.263 1.263h-2.051a1.262 1.262 0 0 1-1.263-1.263v-9.064a1.262 1.262 0 0 1 1.263-1.263h2.051c.698 0 1.263.566 1.263 1.263z'
          fill='#eff3fb'
        ></path>
        <path
          d='M2.45 2.6a.749.749 0 1 1 .9-1.2l5.953 4.465h2.827a.75.75 0 0 1 .59.287l2.287 2.911 5.3-5.531A.75.75 0 1 1 21.39 4.57l-5.898 6.154a.747.747 0 0 1-1.131-.056l-2.595-3.303H9.053a.747.747 0 0 1-.45-.15z'
          fill='#eff3fb'
        ></path>
      </g>
    </svg>
  );
}
