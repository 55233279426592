import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const date = new Date();
export const renderHour = (datetime: string | undefined) => {
  const timestamp = dayjs.utc(datetime, 'YYYY-MM-DD HH:mm:ss');
  const current = timestamp.local().hour();
  const next = timestamp.local().add(1, 'h').hour();
  return `${current}:00-${next}:00`;
};

export const formatDate = (
  date: number | string | Date,
  stringFormat: string
) => {
  return dayjs.utc(date).format(stringFormat);
};

export const formatDateCurrent = (stringFormat: string) => {
  return dayjs.utc(date).format(stringFormat);
};

export const formatDateChart = (date: number | string) => {
  return formatDate(date, 'DD/MM/YYYY');
};

export const formatDateChartVer2 = (date: number | string) => {
  return formatDate(date, 'MMMM, D, YYYY hh:mm');
};
export const getDateNow = () => {
  return dayjs().format('DD-MM-YYYY HH:mm:ss');
};

export const formatDateUTC = (
  date: string,
) => {
  const UTC = new Date();
  const offset = UTC.getTimezoneOffset()
  const local = new Date(date);
  const dateUTC = new Date(local.getTime() - offset * 60000);
  return dayjs.utc(dateUTC).format('MMMM, D, YYYY hh:mm');
};