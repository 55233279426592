import { getPrice, researchToken } from 'api/coins';
import { useQuery } from 'react-query';

export function useCoins(symbol: string, enabled: boolean) {
  return useQuery({
    queryKey: ['coinPrice', symbol],
    queryFn: () => {
      return getPrice(symbol);
    },
    refetchInterval: 3000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: enabled,
  });
}

export function useResearchToken(symbol: string, enabled: boolean) {
  return useQuery({
    queryKey: ['tokenString', symbol],
    queryFn: () => {
      return researchToken(symbol);
    },
    refetchInterval: 3000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: enabled,
  });
}
