import React from 'react';

export default function Moonriver() {
  return (
    <svg
      width='30px'
      height='30px'
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>moonriver</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='moonriver' fillRule='nonzero'>
          <circle
            id='椭圆形备份-3'
            fillOpacity='0.1'
            fill='#53CBC8'
            cx='15'
            cy='15'
            r='15'
          ></circle>
          <g id='symbol' transform='translate(7.000000, 6.003271)'>
            <path
              d='M2.47722147,2.2247438 C1.05068934,3.65131562 0.249356288,5.58615451 0.249356288,7.60360366 C0.249535188,8.08418296 0.294679568,8.56369141 0.384199177,9.03585953 L1.36683762,8.36280935 C3.09439306,7.17801241 5.89688129,7.17801241 7.62401337,8.36280935 L8.65512751,9.06898812 L9.21397402,9.45308925 C10.7711236,10.519978 13.2936594,10.519978 14.8493272,9.45308925 L15.3078353,9.13916146 C15.7664845,6.90039574 15.1949713,4.57402193 13.7511584,2.80262831 C12.3073455,1.0312347 10.144034,0.00229069582 7.85877124,0 C5.83857019,-0.0032710721 3.9037536,0.798171971 2.47722147,2.2247438 Z'
              id='Path_207'
              fill='#F2B705'
            ></path>
            <path
              d='M12.1306659,12.1728089 C10.9846072,12.1728089 9.88871767,11.8618447 9.06357234,11.2963302 L7.47488179,10.207532 C6.71430016,9.68467186 5.69292349,9.39678123 4.60158516,9.39678123 C3.51024684,9.39678123 2.48855264,9.68255502 1.72797101,10.207532 L0.700773023,10.9108531 C0.569255993,11.0021901 0.398882998,11.0160592 0.254324993,10.9471958 C0.109766987,10.8783323 0.0131873606,10.7372945 0.00124624492,10.5776179 C-0.0106948708,10.4179414 0.0638333162,10.2641075 0.196541059,10.1745093 L1.22320984,9.47118826 C2.13144125,8.84809557 3.33042104,8.5055904 4.60116179,8.5055904 C5.87190255,8.5055904 7.07067065,8.84809557 7.97890207,9.47118826 L9.56759262,10.5599864 C11.1404721,11.5225866 13.1201189,11.5225866 14.6929983,10.5599864 L15.2962985,10.147202 C15.4277471,10.0542521 15.5990789,10.0393342 15.7446141,10.1081669 C15.8901492,10.1769996 15.9873096,10.3189044 15.998849,10.4794823 C16.0103884,10.6400602 15.9345165,10.7943994 15.8003188,10.8833341 L15.1970186,11.2961185 C14.3681688,11.8635382 13.2768305,12.1728089 12.1306659,12.1728089 Z'
              id='Path_208'
              fill='#53CBC8'
            ></path>
            <path
              d='M4.56835073,15.3673365 L4.56835073,11.0694046 C4.5649951,10.9789962 4.56881741,10.8884642 4.57978168,10.7986604 C4.60742916,10.5538135 4.81464311,10.3688385 5.0610459,10.369047 C5.29982485,10.3755176 5.49549558,10.5606091 5.51521453,10.7986604 C5.53345702,10.9478665 5.53869828,11.0983758 5.53087917,11.2484895 L5.53087917,19.8175752 C5.50515953,20.1351016 5.3061763,20.3447749 5.038184,20.3390594 C4.77019169,20.3333439 4.57671226,20.1156266 4.57554799,19.7933373 L4.57554799,16.9371871 L4.56835073,15.3673365 Z'
              id='Path_209'
              fill='#53CBC8'
            ></path>
            <path
              d='M3.66456468,13.2042406 L3.66456468,15.3400292 C3.66456468,15.7055021 3.46960345,15.9474572 3.19335546,15.9387782 C2.91710747,15.9300991 2.70775172,15.6839103 2.70648161,15.335478 L2.70648161,11.0657001 C2.70648161,10.691654 2.92430474,10.4393263 3.20785584,10.4538267 C3.49140694,10.4683271 3.66064852,10.6945117 3.66202447,11.0682403 C3.66594063,11.7803463 3.66456468,12.4922405 3.66456468,13.2042406 Z'
              id='Path_210'
              fill='#53CBC8'
            ></path>
            <path
              d='M6.43942811,13.2013829 L6.43942811,11.7001179 C6.43942811,11.3520031 6.62412265,11.1271944 6.89920638,11.1271944 C7.1742901,11.1271944 7.38608023,11.3592004 7.38925549,11.6870993 C7.39962802,12.7090405 7.39962802,13.7304877 7.38925549,14.751441 C7.38925549,15.09945 7.18423927,15.3084882 6.90238164,15.305867 C6.62052402,15.3031961 6.43995732,15.0891833 6.43667622,14.7328128 L6.43667622,13.1999011 L6.43942811,13.2013829 Z'
              id='Path_211'
              fill='#53CBC8'
            ></path>
            <path
              d='M0.843977441,13.5136172 L0.843977441,12.0120347 C0.843977441,11.6641316 1.02877782,11.439217 1.30375571,11.439217 C1.57873359,11.439217 1.79062956,11.671223 1.79391066,11.999122 C1.80329533,13.0210631 1.80329533,14.0425104 1.79391066,15.0634637 C1.79391066,15.4114726 1.58741265,15.6205109 1.30703681,15.6177858 C1.02666098,15.6150071 0.84440081,15.4011001 0.841331388,15.0447296 L0.841331388,13.5122412 L0.843977441,13.5136172 Z'
              id='Path_212'
              fill='#53CBC8'
            ></path>
            <path
              d='M8.77144803,12.235785 L8.77282397,12.235785 C9.03315833,12.2359019 9.24413903,12.4469774 9.24413903,12.7073117 L9.24413903,13.523778 C9.2440806,13.7840539 9.03309989,13.9950347 8.77282397,13.9950931 L8.77144803,13.9950931 C8.51111364,13.9950931 8.30003816,13.7841124 8.29992128,13.523778 L8.29992128,12.7073117 C8.29997971,12.4469189 8.5110552,12.2358434 8.77144803,12.235785 L8.77144803,12.235785 Z'
              id='Path_213'
              fill='#53CBC8'
            ></path>
            <path
              d='M10.6395618,12.9507487 L10.6409378,12.9507487 C10.9012721,12.9508655 11.1122528,13.161941 11.1122528,13.4222754 L11.1122528,18.0017474 C11.1122528,18.1267382 11.0625932,18.2466081 10.9742014,18.33498 C10.8858097,18.4233519 10.7659286,18.4729566 10.6409378,18.4729566 L10.6395618,18.4729566 C10.5145808,18.4729566 10.3947105,18.4233486 10.3063356,18.3349736 C10.2179606,18.2465987 10.1683245,18.1267284 10.1683526,18.0017474 L10.1683526,13.4222754 C10.1682941,13.1619582 10.3792446,12.9508656 10.6395618,12.9507487 Z'
              id='Path_214'
              fill='#53CBC8'
            ></path>
            <path
              d='M12.4945512,13.1282459 L12.4959272,13.1282459 C12.7562615,13.1282459 12.967337,13.3392266 12.9674539,13.599561 L12.9674539,17.4916941 C12.9673978,17.6166946 12.9176877,17.7365531 12.8292592,17.8249022 C12.7408308,17.9132512 12.6209277,17.9628536 12.4959272,17.9627975 L12.4945512,17.9627975 C12.3695799,17.9628536 12.2497094,17.9132411 12.1613314,17.8248829 C12.0729534,17.7365248 12.0233139,17.6166654 12.0233419,17.4916941 L12.0233419,13.5996668 C12.0232858,13.4746577 12.0729062,13.3547494 12.1612811,13.2663348 C12.2496561,13.1779201 12.3695421,13.1282459 12.4945512,13.1282459 L12.4945512,13.1282459 Z'
              id='Path_215'
              fill='#53CBC8'
            ></path>
            <path
              d='M14.3754719,12.6842382 L14.3768479,12.6842382 C14.637148,12.6842382 14.8481629,12.8952531 14.8481629,13.1555532 L14.8481629,14.8343154 C14.8481045,15.0945913 14.6371238,15.3055721 14.3768479,15.3056305 L14.3754719,15.3056305 C14.2504811,15.3056305 14.1306112,15.2559708 14.0422393,15.1675791 C13.9538673,15.0791873 13.9042346,14.9593062 13.9042626,14.8343154 L13.9042626,13.1556591 C13.9042065,13.0306499 13.9538269,12.9107416 14.0422018,12.822327 C14.1305768,12.7339123 14.2504628,12.6842382 14.3754719,12.6842382 Z'
              id='Path_216'
              fill='#53CBC8'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
