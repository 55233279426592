import React from 'react';

export default function SwapIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='20'
      height='20'
      x='0'
      y='0'
      viewBox='0 0 479.71 479.71'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M469.936 328.847a32.003 32.003 0 0 0-3.045-2.592c-13.381-8.971-31.276-7.013-42.4 4.64l-10.08 10.08a48 48 0 0 0-8.64 11.84l-6.24 12V66.575c.634-16.247-10.62-30.552-26.56-33.76-17.486-2.564-33.74 9.534-36.303 27.02a32.044 32.044 0 0 0-.337 4.98v299.52l-4.32-9.44a47.98 47.98 0 0 0-9.44-13.6l-10.56-10.56c-11.124-11.653-29.019-13.611-42.4-4.64-14.259 10.441-17.355 30.464-6.914 44.724a32.018 32.018 0 0 0 2.754 3.276l80 80c12.49 12.504 32.751 12.515 45.255.025l.025-.025 80-80c12.275-12.715 11.919-32.973-.795-45.248zM214.411 104.655l-78.88-79.04c-12.49-12.504-32.751-12.515-45.255-.025l-.025.025-80 80c-11.653 11.124-13.611 29.019-4.64 42.4 10.441 14.259 30.464 17.355 44.724 6.914a32.018 32.018 0 0 0 3.276-2.754l10.88-10.88a47.998 47.998 0 0 0 11.52-18.56l4.32-9.92v302.24c-.607 16.347 10.812 30.689 26.88 33.76 17.445 2.829 33.881-9.019 36.71-26.465.297-1.83.434-3.682.41-5.535v-304l3.2 9.12a48.004 48.004 0 0 0 11.36 17.92l11.52 11.52c12.492 12.501 32.753 12.509 45.255.017a32.055 32.055 0 0 0 1.785-1.937c10.732-13.471 9.413-32.903-3.04-44.8z'
          fill='#2669f5'
        ></path>
      </g>
    </svg>
  );
}
