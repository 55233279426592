import React from 'react';

export default function ApprovalRevoke() {
  return (
    <svg
      data-v-e55b351e=''
      viewBox='0 0 1024 1024'
      width='20px'
      height='20px'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M512 512h298.666667c-22.613333 175.36-139.946667 331.946667-298.666667 380.586667V512H213.333333V268.8l298.666667-132.693333M512 42.666667L128 213.333333v256c0 236.8 163.84 457.813333 384 512 220.16-54.186667 384-275.2 384-512V213.333333l-384-170.666666z'
        fill='#b7becb'
      ></path>
    </svg>
  );
}
