import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';

import TemplateText from '../Text/TemplateText';

interface Props {
  isShow: boolean;
  PCT: number;
  coinSymbol: string;
}

export default function Warning({ isShow, PCT, coinSymbol }: Props) {
  return (
    <Box>
      {isShow && (
        <Flex
          w='full'
          p='10px'
          border='1px solid #28344b'
          bg='bg.300'
          borderRadius='12px'
          mt='10px'
        >
          <WarningTwoIcon color='#f75029' boxSize={4} mr='10px' />
          <TemplateText
            txt={` Please be aware that the price entered is ${
              PCT == Infinity ? PCT : `-${PCT}%`
            } below the market, meaning your ${coinSymbol} will be sold at a lower market price. `}
            color='#f75029'
            fontSize='14px'
          />
        </Flex>
      )}
    </Box>
  );
}
