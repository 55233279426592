import { Collapse, Flex, Stack } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import TriangleDownIconRotate from 'components/common/TriangleDownIconRotate';
import useVisible from 'hook/useVisible';
import React, { ReactNode } from 'react';

interface props {
  title: string;
  children: ReactNode;
}

export default function FAQ({ title, children }: props) {
  const FAQ = useVisible();
  return (
    <Stack h='auto' mt='unset' borderBottom='1px solid #28344b' p='16px 0'>
      <Flex
        cursor='pointer'
        alignItems='center'
        justifyContent='space-between'
        _hover={{ textDecoration: 'none' }}
        onClick={FAQ.toggle}
      >
        <TemplateText fontSize={{ base: '16px', md: '20px' }} txt={title} />
        <TriangleDownIconRotate isRotate={FAQ.visible} />
      </Flex>
      <Collapse
        in={FAQ.visible}
        animateOpacity
        style={{ marginTop: '0 !important' }}
      >
        <Stack>{children}</Stack>
      </Collapse>
    </Stack>
  );
}
