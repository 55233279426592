export const FONT_FAMILY = `Inter`;
export const Slippage = ['#FA3961'];
export const yourGasRefund = [
  'black',
  'linear-gradient(315deg,#6d665f,#c9c2b3)',
];
export const defaultValue = ['text.500', 'unset', ''];

export const NUMBER_DEFAULT = 120500;

export const HEIGHT_CHART = 48;
export const WIDTH_CHART = 164;

export const ONE_DAY = {
  name: '1D',
  value: '1',
};

export const MINUTES_30 = {
  name: '30M',
  value: '30',
}
