import { Box, Flex, Icon, Skeleton } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import SwapCoinBox from 'components/common/SwapCoinBox';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipItem from 'components/common/TooltipItem';
import ModalSelectToken from 'components/ModalSelectToken';
import ArrowChange from 'components/svg/ArrowChange';
import IconSwap from 'components/svg/IconSwap';
import { defaultValue, Slippage, yourGasRefund } from 'constants/app';
import { TYPE_GET_COLOR } from 'constants/enum';
import { CoinPair } from 'constants/interface';
import { useCoins } from 'hook/coin';
import useVisible from 'hook/useVisible';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsQuestion } from 'react-icons/bs';
import { convert_USD, convertBigNumber } from 'utils/number';
import { useFeeData } from 'wagmi';

import ModalGasRefund from './GasRefundModal';
import ButtonAction from '../ButtonAction';

interface Props {
  coinPair: CoinPair;
  setCoinPair: (value: CoinPair) => void;
  slipPage: number;
  isLoading: boolean;
}

export default function MoneyConvertBox({
  coinPair,
  setCoinPair,
  slipPage,
  isLoading,
}: Props) {
  const [valueInput, setValueInput] = useState(1);
  const [isEnabled, setIsEnabled] = useState(true);

  const [tokenFrom, setTokenFrom] = useState(coinPair.coinBase);
  const [tokenTo, setTokenTo] = useState(coinPair.coinTarget);
  const detailTagModalFrom = useVisible();
  const detailTagModalTo = useVisible();
  const [balance, setBalance] = useState(0);

  const onSwap = useCallback(() => {
    setIsEnabled(false);
    setCoinPair({
      coinBase: coinPair.coinTarget,
      coinTarget: coinPair.coinBase,
    });
    new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    setIsEnabled(true);
  }, [coinPair.coinBase, coinPair.coinTarget, setCoinPair]);

  useEffect(() => {
    setTokenFrom(coinPair.coinBase);
    setTokenTo(coinPair.coinTarget);
  }, [coinPair]);
  const { data: dataETH, isLoading: isLoadingETH } = useCoins(
    'WETH',
    isEnabled
  );

  const coinPriceETH = useMemo(
    () => (!isLoadingETH && isEnabled ? dataETH?.RAW.WETH.USD.PRICE : 0),
    [dataETH, isLoadingETH, isEnabled]
  );

  const { data: dataFrom, isLoading: isLoadingFrom } = useCoins(
    tokenFrom.symbol,
    isEnabled
  );

  const coinPriceFrom = useMemo(
    () =>
      !isLoading && !isLoadingFrom && isEnabled
        ? dataFrom?.RAW[tokenFrom.symbol]?.USD.PRICE
        : 0,
    [isLoading, isLoadingFrom, isEnabled, dataFrom?.RAW, tokenFrom]
  );

  const { data: dataTo, isLoading: isLoadingTo } = useCoins(
    tokenTo.symbol,
    isEnabled
  );

  const coinPriceTo = useMemo(
    () =>
      !isLoading && !isLoadingTo && isEnabled
        ? dataTo?.RAW[tokenTo.symbol]?.USD.PRICE || 0
        : 0,
    [isLoading, isLoadingTo, isEnabled, dataTo?.RAW, tokenTo]
  );

  useEffect(() => {
    setCoinPair({
      coinBase: tokenFrom,
      coinTarget: tokenTo,
    });
  }, [setCoinPair, tokenFrom, tokenTo]);

  const outPut = useMemo(() => {
    return new BigNumber(
      coinPriceTo != 0 ? (valueInput * coinPriceFrom) / coinPriceTo : Infinity
    ).precision(6);
  }, [valueInput, coinPriceFrom, coinPriceTo]);

  const minOut = useMemo(() => {
    if (outPut.toString() === 'NaN' || isLoading) {
      return 0;
    }
    return BigNumber(outPut.toNumber() - (slipPage * outPut.toNumber()) / 100)
      .dp(6, BigNumber.ROUND_DOWN)
      .toNumber();
  }, [outPut, slipPage, isLoading]);

  const gasRefundModal = useVisible();

  const getColor = (name: string) => {
    if (name === TYPE_GET_COLOR.SLIPPAGE) {
      return Slippage;
    }
    if (name === TYPE_GET_COLOR.YOUR_GAS) {
      return yourGasRefund;
    }
    return defaultValue;
  };

  const { data: gasPrice } = useFeeData({ formatUnits: 'ether' });

  const INFO_GROUP = useMemo(
    () => [
      {
        name: 'Minimum Receive',
        value: `${
          !isLoading
            ? `$${convert_USD(minOut, coinPriceTo)} ~ ${minOut} ${
                coinPair.coinTarget.symbol
              }`
            : '--'
        }`,
      },
      {
        name: 'Est. Gas Fee',
        value: `$${convert_USD(
          Number(gasPrice?.formatted?.maxFeePerGas) || 0,
          coinPriceETH
        )} ~ ${convertBigNumber(
          Number(gasPrice?.formatted?.maxFeePerGas) || 0,
          10
        )} ETH`,
      },
      {
        name: 'Slippage',
        value: `${slipPage}%`,
      },
      {
        name: 'Your Gas Refund',
        value: '0% GAS REFUND',
      },
    ],
    [
      isLoading,
      minOut,
      coinPriceTo,
      coinPair.coinTarget.symbol,
      gasPrice?.formatted?.maxFeePerGas,
      coinPriceETH,
      slipPage,
    ]
  );

  const isInfinity = useMemo(() => valueInput < balance, [valueInput, balance]);

  return (
    <Box position='relative' mt={{ base: '24px', md: '48px', lg: '24px' }}>
      <SwapCoinBox
        isFrom={true}
        coin={tokenFrom}
        setToken={setTokenFrom}
        setValueInput={setValueInput}
        value={outPut.toNumber()}
        onOpen={detailTagModalFrom.show}
        isLoading={isLoading}
        valueInput={valueInput}
        setBalance={setBalance}
        coinPrice={coinPriceFrom}
      />
      <SwapCoinBox
        isFrom={false}
        coin={tokenTo}
        setToken={setTokenTo}
        setValueInput={setValueInput}
        value={outPut.toNumber()}
        onOpen={detailTagModalTo.show}
        isLoading={isLoading}
        setBalance={setBalance}
        coinPrice={coinPriceTo}
      />
      <Box
        position='absolute'
        left='calc(50% - 16px)'
        top='125px'
        onClick={onSwap}
        cursor='pointer'
        bg='bg.200'
        w='35px'
        h='35px'
        borderRadius='50%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        border='1px solid #28344b'
        box-shadow='0 2px 6px 0 rgba(21,20,44,0.38)'
      >
        <ArrowChange color='#b7becb' />
      </Box>
      <Flex alignItems='center' mt='10px' gap='10px'>
        {isLoading ? (
          <Skeleton w='200px' h='20px' borderRadius='5px' />
        ) : (
          <TemplateText
            color='text.400'
            txt={`1 ${coinPair.coinBase.symbol} = ${BigNumber(
              coinPriceFrom / coinPriceTo
            )
              .dp(6, BigNumber.ROUND_DOWN)
              .toNumber()} ${coinPair.coinTarget.symbol}`}
          />
        )}
        <Box cursor='pointer' onClick={onSwap}>
          <IconSwap />
        </Box>
      </Flex>
      <ButtonAction
        isEnterAmount={valueInput > 0}
        valueInput={valueInput}
        coinPair={coinPair}
        isEnabled={isEnabled}
        minOut={minOut}
        setValueInput={setValueInput}
        isInfinity={isInfinity}
        isLoading={isLoading}
      />
      <Flex
        direction='column'
        gap='10px'
        mt='30px'
        p='10px'
        borderRadius='12px'
        border='1px solid #28344b'
      >
        {INFO_GROUP.map((item) => (
          <Flex
            key={item.name}
            justifyContent='space-between'
            alignItems='center'
          >
            <Flex alignItems='center'>
              <TemplateText txt={item.name} color='text.400' fontSize={13} />
              {item.name === 'Price Impact' && (
                <TooltipItem
                  children={
                    <Flex
                      bg='bg.100'
                      h='15px'
                      w='15px'
                      justifyContent='center'
                      alignItems='center'
                      borderRadius='100px'
                    >
                      <Icon
                        as={BsQuestion}
                        color='text.400'
                        h='13px'
                        w='13px'
                      />
                    </Flex>
                  }
                  label='The difference between market price and est. price due to trade size'
                />
              )}
            </Flex>
            <TemplateText
              color={`${getColor(item.name)[0]}`}
              bg={`${getColor(item.name)[1]}`}
              borderRadius='10px'
              p='0 5px'
              cursor={item.name === 'Your Gas Refund' ? 'pointer' : 'default'}
              txt={item.value.toString()}
              fontSize={13}
              onClick={() => {
                if (item.name === 'Your Gas Refund') {
                  gasRefundModal.show();
                }
              }}
            />
          </Flex>
        ))}
      </Flex>

      <ModalSelectToken
        setCrossToken={setTokenFrom}
        isOpen={detailTagModalFrom.visible}
        onClose={detailTagModalFrom.hide}
      />
      <ModalSelectToken
        setCrossToken={setTokenTo}
        isOpen={detailTagModalTo.visible}
        onClose={detailTagModalTo.hide}
      />
      <ModalGasRefund
        isOpen={gasRefundModal.visible}
        onClose={gasRefundModal.hide}
        onOpen={gasRefundModal.show}
      />
    </Box>
  );
}
