import {
  Box,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ROUTER_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import {
  DAI_ADDRESS,
  ROUTE_ADDRESS,
  USDT_ADDRESS,
  VAULT_ADDRESS,
} from 'utils/ERC20';
import { convertPrice } from 'utils/number';
import { VAULT_ABI } from 'utils/Vault';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  collateral: string;
  size: string;
  isLong: boolean;
  timeInterval: boolean;
  delta: number;
}

export default function ModalClose({
  isOpen,
  onClose,
  onOpen,
  collateral,
  size,
  isLong,
  timeInterval,
  delta,
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();
  const { address } = useAccount();

  const { data: btc_price_short }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'getMaxPrice',
    enabled: timeInterval && !isLong && isOpen,
    args: [DAI_ADDRESS],
  });

  const { data: btc_price_long }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'getMinPrice',
    enabled: timeInterval && isLong && isOpen,
    args: [DAI_ADDRESS],
  });

  const _price = useMemo(
    () =>
      BigNumber(isLong ? btc_price_long : btc_price_short || 0)
        .dividedToIntegerBy(10 ** 30)
        .toNumber(),
    [btc_price_long, btc_price_short]
  );

  const maxValue = useMemo(
    () => Number(convertPrice(collateral).toNumber() / _price).toFixed(5),
    [collateral]
  );

  const { config: configWithdraw, isSuccess } = usePrepareContractWrite({
    address: ROUTE_ADDRESS,
    abi: ROUTER_ABI,
    enabled: !!collateral && !!size && isOpen,
    functionName: 'decreasePosition',
    onError(error) {
      console.log('Error Close', error);
    },
    args: [
      isLong ? DAI_ADDRESS : USDT_ADDRESS,
      DAI_ADDRESS,
      Number(collateral) - Number(delta),
      size,
      isLong,
      address,
      isLong ? btc_price_long : btc_price_short,
    ],
  });

  const { write: writeLong, isLoading } = useContractWrite(configWithdraw);

  const handleWithdraw = useCallback(() => {
    writeLong?.();
    !isLoading && onClose();
  }, [writeLong]);

  const listInfo = useMemo(
    () => [
      { name: 'Position Size', value: '$20' },
      { name: 'Collateral Asset', value: 'DAI' },
      { name: 'Collateral Value', value: '$20' },
      { name: 'Net Value', value: '28.000' },
      { name: 'Leverage', value: '10x' },
      { name: 'Liquidation Price', value: '$20,123' },
      { name: 'Execution Fee', value: '0/00058 ETH' },
      { name: 'Borrow Fee', value: '< $0.01' },
      { name: 'Slippage', value: '0.3%' },
      { name: 'Mark Price', value: '$27999' },
      { name: 'Trigger Condition', value: 'Mark Price > $26,812' },
    ],
    []
  );

  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px 10px 0 0 '
            alignItems='center'
            position='relative'
          >
            <Icon
              as={BiChevronLeft}
              position='absolute'
              top='15px'
              h='30px'
              cursor='pointer'
              w='30px'
              left='15px'
              color='text.400'
              bg='bg.200'
              borderRadius='100px'
            />
            <TemplateText
              fontSize='18px'
              color='text.500'
              txt='CLOSE COLLATERAL'
            />
          </ModalHeader>
          <ModalCloseButton
            top='15px'
            _hover={{
              color: darkModeColors.text555,
            }}
            bg='bg.200'
            borderRadius='50%'
            color={darkModeColors.text550}
          />

          <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='20px'>
            <Flex>
              <TemplateText fontSize='14px' color='text.300' txt='Withdraw' />
              <TemplateText
                fontSize='14px'
                color='text.300'
                txt={`Max Withdraw: ${maxValue} BTC`}
              />
            </Flex>
            <Box py={2}>
              {listInfo.map((item) => (
                <Flex justifyContent='space-between'>
                  <TemplateText
                    p='2px'
                    fontSize='14px'
                    color='text.300'
                    txt={item.name}
                  />
                  <TemplateText
                    fontSize='14px'
                    color='text.500'
                    txt={item.value}
                  />
                </Flex>
              ))}
            </Box>

            <ButtonBase
              mt='20px'
              w='100%'
              onClick={handleWithdraw}
              content='CONFIRM'
              bg='#2669f5'
              color='text.500'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
