import { getBarChart } from 'api/chart';
import { useQuery } from 'react-query';

export function useBarChart(base: string, quote: string, day: string) {
  return useQuery({
    queryKey: ['coinPair', base, quote, day],
    queryFn: () => {
      return getBarChart(base, quote, day);
    },
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: true,
  });
}
