import { Flex, FlexProps, Input } from '@chakra-ui/react';
import { ButtonsType } from 'constants/interface';
import { ChakraType } from 'constants/types';

import FlexBox from '../FlexBox';
import TemplateText from '../Text/TemplateText';

interface Props extends FlexProps {
  selected?: number;
  data?: ButtonsType[];
  setSelected?: (value: number) => void;
  p?: ChakraType;
  isPool?: boolean;
}
const ListButton = ({
  selected,
  isPool,
  data = [],
  setSelected = () => ({}),
  p = '9px 16px',

  ...props
}: Props) => {
  return (
    <Flex
      {...props}
      borderRadius='10px'
      alignItems='center'
      w={isPool ? '100%' : 'auto'}
      bg='bg.100'
      p={isPool ? '10px' : '0px'}
      gap='10px'
    >
      {data.map((item) => (
        <FlexBox
          h='38px'
          w='100%'
          key={item.value}
          p={p}
          cursor='pointer'
          textAlign='center'
          borderRadius='5px'
          onClick={() => setSelected(item.value || 0)}
          bg={item.value === selected ? 'bg.300' : 'unset'}
          fontSize={13}
          fontWeight={500}
        >
          <TemplateText
            txt={item.label || ''}
            color={item.value === selected ? 'text.500' : 'text.400'}
          />
        </FlexBox>
      ))}
      <Input
        textAlign='end'
        bg='bg.100'
        max={100}
        value={selected}
        type='number'
        color='text.500'
        border='none'
        _focusVisible={{ border: '1px solid #2669f5' }}
        fontSize={12}
        borderRadius='5px'
        placeholder='custom'
        onChange={(e) => setSelected(Number(e.target.value))}
      />
    </Flex>
  );
};

export default ListButton;
