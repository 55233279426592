import { ShowColumnValue } from 'constants/types';

const getTrader = (
  searchTrader: string | undefined,
  isHideFull: boolean | undefined,
  traders: ShowColumnValue[]
) => {
  if (searchTrader) {
    const newArrSearch: ShowColumnValue[] = traders.filter((item) =>
      item.traderNickname.match(new RegExp(searchTrader, 'i'))
    );

    return newArrSearch;
  }
  if (isHideFull) {
    const newArrHideFull: ShowColumnValue[] = traders.filter(
      (item) => item.followCount < item.maxfollowCount
    );

    return newArrHideFull;
  }

  return traders;
};

export default getTrader;
