import React from 'react';

export default function Arbitrum() {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 34 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.33 18.3594L24.1203 13.6247L31.6413 25.3388L31.6448 27.5869L31.6203 12.1172C31.6025 11.739 31.4017 11.393 31.0814 11.1889L17.5408 3.40039C17.2242 3.24468 16.8223 3.24656 16.5062 3.4053C16.4635 3.42681 16.4235 3.44995 16.3851 3.47544L16.3379 3.50513L3.19448 11.1215L3.14352 11.1449C3.07782 11.1748 3.01166 11.2134 2.94924 11.2578C2.70002 11.4365 2.53449 11.7009 2.48095 11.9971C2.47301 12.042 2.46693 12.0878 2.46436 12.1341L2.48493 24.7403L9.49045 13.8824C10.3723 12.4427 12.2941 11.9786 14.0781 12.0041L16.1717 12.0593L3.8353 31.8433L5.28947 32.6805L17.7736 12.0794L23.2918 12.0593L10.8399 33.1803L16.0288 36.1651L16.6489 36.5214C16.9112 36.628 17.2202 36.6334 17.4847 36.5378L31.2156 28.5805L28.5904 30.1018L21.33 18.3594ZM22.3945 33.6926L17.1536 25.4667L20.353 20.0376L27.236 30.8866L22.3945 33.6926Z'
        fill='#2D374B'
      />
      <path
        d='M17.1533 25.4669L22.3944 33.6926L27.2357 30.8869L20.3527 20.0378L17.1533 25.4669Z'
        fill='#28A0F0'
      />
      <path
        d='M31.6449 27.5872L31.6414 25.339L24.1204 13.625L21.3301 18.3597L28.5907 30.102L31.2159 28.5808C31.4733 28.3718 31.6292 28.0648 31.6456 27.7338L31.6449 27.5872Z'
        fill='#28A0F0'
      />
      <path
        d='M0.12793 29.7072L3.83514 31.8433L16.1715 12.0593L14.0779 12.0041C12.2941 11.9789 10.3724 12.4427 9.49028 13.8824L2.48477 24.7403L0.12793 28.3615V29.7072Z'
        fill='white'
      />
      <path
        d='M23.2918 12.0591L17.7737 12.0792L5.28955 32.6803L9.65324 35.1926L10.8397 33.1801L23.2918 12.0591Z'
        fill='white'
      />
      <path
        d='M33.97 12.0307C33.9239 10.8767 33.299 9.82043 32.3203 9.20533L18.6022 1.31632C17.6341 0.828869 16.4247 0.828167 15.4547 1.31609C15.3402 1.37383 2.11421 9.04448 2.11421 9.04448C1.93115 9.13215 1.75487 9.23666 1.58912 9.35542C0.715444 9.98174 0.18287 10.9548 0.12793 12.0237V28.3616L2.48477 24.7404L2.46419 12.134C2.467 12.088 2.47284 12.0424 2.48079 11.9977C2.5341 11.701 2.69985 11.4366 2.94907 11.2578C3.01149 11.2134 16.4631 3.42674 16.5058 3.40523C16.8222 3.24649 17.2238 3.24462 17.5404 3.40032L31.081 11.1888C31.4013 11.3929 31.6021 11.7389 31.6199 12.1172V27.7332C31.6035 28.0642 31.4726 28.3712 31.2152 28.5802L28.59 30.1015L27.2354 30.8865L22.3941 33.6923L17.4843 36.5375C17.2198 36.6331 16.9105 36.6277 16.6485 36.5211L10.8395 33.18L9.65277 35.1925L14.8733 38.1981C15.0458 38.2963 15.1997 38.3832 15.3259 38.4543C15.5214 38.564 15.6546 38.6371 15.7016 38.6601C16.0726 38.8403 16.6064 38.945 17.0875 38.945C17.5284 38.945 17.9586 38.8642 18.3657 38.7047L32.6266 30.4461C33.4451 29.8118 33.9267 28.8554 33.97 27.8194V12.0307Z'
        fill='#96BEDC'
      />
    </svg>
  );
}
