export const BANNER_INTRODUCTION = [
  {
    name: 'banner-1',
    img: './banner/banner1.jpg',
  },
  {
    name: 'banner-2',
    img: './banner/banner2.jpg',
  },
  {
    name: 'banner-3',
    img: './banner/banner3.jpg',
  },
  {
    name: 'banner-4',
    img: './banner/banner4.png',
  },
  {
    name: 'banner-5',
    img: './banner/banner5.png',
  },
  {
    name: 'banner-1',
    img: './banner/banner1.jpg',
  },
  {
    name: 'banner-2',
    img: './banner/banner2.jpg',
  },
  {
    name: 'banner-3',
    img: './banner/banner3.jpg',
  },
];

export const INFORMATION = [
  {
    name: 'Blockchains',
    value: '19',
  },
  {
    name: 'Total trades',
    value: '2.0 M+',
  },
  {
    name: 'Total active users',
    value: '770 K+',
  },
  {
    name: 'Aggregated sources',
    value: '250+',
  },
];

export const PRODUCTS = [
  {
    name: 'DEX Aggregator',
    description:
      'We aggregator the most liquidity across blockchains, empowering our users to swap at the best rate with high efficiency',
    img: './products/product-aggregator.png',
    button: 'Launch App',
    href: '/trade?feature=swap',
  },
  {
    name: 'Limit Order',
    description: `MicoDex limit orders let DeFi traders become advanced traders with greater flexibility and efficiency`,
    img: './products/product-limit.png',
    button: 'Learn More',
    href: '/trade?feature=limitOrder',
  },
  {
    name: 'Cross - Chain Swap',
    description: `With MicoDex's cross-chain aggregation function, users are able to tranfer and swap assets smoothly across chains by utilizing MicoDex's aggregation protocol`,
    img: './products/product-cross.png',
    button: 'Learn More',
    href: '/cross',
  },
  {
    name: 'Perpetual Futures',
    description: `With perpetual futures trading, users can amplify their trading positions and profits with leverages`,
    img: './products/product-perpetral.png',
    button: 'Learn More',
  },
];
