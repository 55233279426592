import { Box, Flex } from '@chakra-ui/react';
import DropDownItem from 'components/common/DropDownItem';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipExplain from 'components/common/TooltipExplain';
import { SETTING_SLIPPAGE } from 'data/future';
import React from 'react';

interface props {
  settingSlippage: number;
  setSettingSlippage: (value: number) => void;
}

export default function Slippage({
  setSettingSlippage,
  settingSlippage,
}: props) {
  return (
    <Box mt='10px' w='100%'>
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex alignItems='center'>
          <TemplateText txt='Slippage' />
          <TooltipExplain label='Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.' />
        </Flex>
        <DropDownItem isSlippage content={`${settingSlippage}%`}>
          <Box p='20px' bg='bg.100' borderRadius='10px'>
            <TemplateText txt='Setting slippage' />
            <Flex alignItems='center' gap='10px' mt='10px'>
              {SETTING_SLIPPAGE.map((item) => (
                <Box
                  onClick={() => setSettingSlippage(item.value)}
                  w='100%'
                  cursor='pointer'
                  _hover={{ background: 'bg.300' }}
                  border={
                    item.value === settingSlippage
                      ? '1px solid #2669f5'
                      : '1px solid #28344b'
                  }
                  borderRadius='5px'
                  key={item.name}
                  p='2px 0'
                >
                  <TemplateText
                    fontSize={13}
                    fontWeight={400}
                    textAlign='center'
                    txt={item.name}
                  />
                </Box>
              ))}
            </Flex>
          </Box>
        </DropDownItem>
      </Flex>
    </Box>
  );
}
