import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NoData from 'components/common/NoData';
import TemplateText from 'components/common/Text/TemplateText';
import { useTransaction } from 'hook/transaction';
import { useMemo } from 'react';
import { formatDateUTC } from 'utils/date';
import { hideAddress } from 'utils/string';
import { getTokenByAddress } from 'utils/token';
import { v4 as uuidv4 } from 'uuid';
import { useAccount } from 'wagmi';

export default function Transaction() {
  const { address } = useAccount();
  const headers = useMemo(() => ['Hash', 'From', 'To', 'Rate'], []);

  const { data, isLoading } = useTransaction(address?.toString() || '');

  const packages = useMemo(() => {
    const listTransaction: any[] = [];

    data &&
      data.map((item) => {
        const row = {
          Hash: {
            date: formatDateUTC(item.updated_at),
            hash: hideAddress(item.transaction_hash),
          },
          From: {
            ExchangeImage:
              getTokenByAddress(item.token_from)[0].img || './coin/BNBCoin.png',
            ExchangeName:
              getTokenByAddress(item.token_from)[0].symbol || 'token',
            total: item.amount_in,
          },
          To: {
            ExchangeImage:
              getTokenByAddress(item.token_to)[0].img || './coin/BNBCoin.png',
            ExchangeName: getTokenByAddress(item.token_to)[0].symbol || 'token',
            total: item.amount_out,
          },
          Rate: item.rate,
        };
        listTransaction.push(row);
      });

    return listTransaction;
  }, [data]);

  return (
    <>
      {!isLoading && (
        <TableContainer
          border='1px solid #28344b'
          borderRadius='12px'
          p='10px'
          mt='20px'
        >
          {packages.length == 0 ? (
            <NoData
              isHistoryTab
              content={`Please connect wallet and Let's start trading now.`}
              title='No Record'
            />
          ) : (
            <Table>
              <Thead>
                <Tr>
                  {headers.map((item) => (
                    <Th border='none' key={uuidv4()}>
                      <TemplateText txt={item} fontSize='12px' />
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody>
                {packages.map((item) => (
                  <Tr key={uuidv4()}>
                    <Td border='none'>
                      <Box alignItems='center'>
                        <TemplateText
                          txt={item.Hash.date}
                          fontSize='12px'
                          color='text.600'
                        />
                        <TemplateText
                          txt={item.Hash.hash}
                          fontSize='14px'
                          color='text.400'
                        />
                      </Box>
                    </Td>
                    <Td border='none'>
                      <Flex alignItems='center'>
                        <Image
                          src={item.From.ExchangeImage}
                          w='24px'
                          h='24px'
                        />
                        <Box ml='12px'>
                          <TemplateText
                            color='text.600'
                            txt={item.From.ExchangeName}
                          />
                          <TemplateText
                            color='text.400'
                            txt={item.From.total}
                          />
                        </Box>
                      </Flex>
                    </Td>
                    <Td border='none'>
                      <Flex alignItems='center'>
                        <Image src={item.To.ExchangeImage} w='24px' h='24px' />
                        <Box ml='12px'>
                          <TemplateText
                            color='text.600'
                            txt={item.To.ExchangeName}
                          />
                          <TemplateText color='text.400' txt={item.To.total} />
                        </Box>
                      </Flex>
                    </Td>
                    <Td border='none'>
                      <TemplateText
                        txt={item.Rate}
                        fontSize='14px'
                        color='text.400'
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      )}
    </>
  );
}
