import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import SearchInput from 'components/common/Input/SearchInput';
import TemplateText from 'components/common/Text/TemplateText';
import { TYPE_TAB_TOP_TRADERS } from 'constants/enum';
import { FILTER_ALL_TRADERS } from 'data/copytrading';
import React, { useCallback, useMemo, useState } from 'react';
import { BsFilterLeft } from 'react-icons/bs';
import { CiFilter } from 'react-icons/ci';
import { MdOutlineFilter } from 'react-icons/md';
import { TfiLayoutGrid2 } from 'react-icons/tfi';
import { Link, useLocation } from 'react-router-dom';

import AllTradersTab from './AllTradersTab';
import TopTradersTab from './TopTradersTab';

export default function TabTrader() {
  const router = useLocation();

  const [valueSearch, setValueSearch] = useState('');
  const [isHideFull, setIsHideFull] = useState(false);
  const [optionFilter, setOptionFilter] = useState('all');
  const handleChangeInput = useCallback((value: string) => {
    setValueSearch(value);
  }, []);

  const defaultTab = useMemo(() => router.search.slice(11), [router]);

  const defaultTabPanel = useMemo(
    () => Number(defaultTab === TYPE_TAB_TOP_TRADERS.ALL_TRADERS),
    [defaultTab]
  );

  const LIST_TAB = useMemo(
    () => [
      {
        name: 'Top traders',
        tab: '',
      },
      {
        name: 'All traders',
        tab: '?traderTab=all',
      },
    ],
    []
  );
  return (
    <Tabs variant='enclosed' index={defaultTabPanel}>
      <Box>
        <Flex alignItems='center' justifyContent='space-between'>
          <TabList w='30%' borderColor='bg.100'>
            {LIST_TAB.map((item) => (
              <Tab
                key={item.name}
                p='0px'
                _selected={{
                  color: 'text.500',
                  borderBottom: '5px solid transparent',
                  borderImageSlice: 1,
                  borderImageSource: 'linear-gradient(190deg,#1d6dff,#132038)',
                  borderTop: '0px solid transparent',
                  borderRight: '0px solid transparent',
                  borderLeft: '0px solid transparent',
                  marginTop: '5px',
                }}
                color='text.400'
                fontSize={{ base: '14px', md: '16px' }}
                fontWeight={600}
              >
                <Link
                  to={
                    item.name === TYPE_TAB_TOP_TRADERS.TOP_TRADERS
                      ? `${router.pathname}`
                      : `${router.pathname}${item.tab}`
                  }
                  className='link'
                >
                  {item.name}
                </Link>
              </Tab>
            ))}
          </TabList>
          {defaultTab === TYPE_TAB_TOP_TRADERS.ALL_TRADERS && (
            <SearchInput
              h='40px'
              paddingRight='90px'
              placeholder='Search trader'
              border='none'
              value={valueSearch}
              isFocus={true}
              w='20%'
              backgroundColor='bg.300'
              onChange={handleChangeInput}
            />
          )}
        </Flex>
        {defaultTab === TYPE_TAB_TOP_TRADERS.ALL_TRADERS && (
          <Flex mt='20px' alignItems='center' justifyContent='space-between'>
            <Flex gap='10px'>
              {FILTER_ALL_TRADERS.map((item) => (
                <TemplateText
                  key={item.name}
                  color={item.label === optionFilter ? '#2669f5' : 'text.500'}
                  onClick={() => setOptionFilter(item.label)}
                  borderBottom={
                    item.label === optionFilter
                      ? '2px dashed #2669f5'
                      : '2px dashed #727272'
                  }
                  txt={item.name}
                />
              ))}
            </Flex>

            <Flex alignItems='center' gap='10px'>
              <Checkbox
                color='text.500'
                onChange={(e) => setIsHideFull(e.target.checked)}
              >
                Hide Full
              </Checkbox>
              <Icon as={BsFilterLeft} w='24px' h='24px' color='text.500' />
              <Icon as={MdOutlineFilter} w='20px' h='20px' color='text.500' />
              <Icon as={TfiLayoutGrid2} w='20px' h='20px' color='text.500' />
              <Icon as={CiFilter} w='24px' h='24px' color='text.500' />
            </Flex>
          </Flex>
        )}
      </Box>
      <TabPanels>
        <TabPanel p='0'>
          <TopTradersTab />
          <ButtonBase
            w='100%'
            mt='20px'
            content='View more traders'
            bg='#2669f5'
            rightIcon={<ArrowRightIcon h='14px' w='14px' color='text.500' />}
          />
        </TabPanel>

        <TabPanel p='0'>
          <AllTradersTab
            isHideFull={isHideFull}
            valueSearchTrader={valueSearch}
            optionFilter={optionFilter}
          />
          <Flex mt='30px' justifyContent='center' gap='10px'>
            <ButtonBase
              w='150px'
              content='Previous'
              bg='bg.300'
              fsText='16px'
              leftIcon={<ArrowLeftIcon w='14px' h='14px' color='text.500' />}
            />
            <ButtonBase
              w='150px'
              content='Next'
              bg='bg.300'
              fsText='16px'
              rightIcon={<ArrowRightIcon w='14px' h='14px' color='text.500' />}
            />
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
