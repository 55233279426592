import { Flex, Image } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import React, { useMemo } from 'react';

interface props {
  imgCoin: string;
  symbolCoin: string;
  valueInputPay: number;
  leverage: number;
  entryPrice: number;
  slippage: number;
  isLong: boolean;
}

export default function Calculation({
  imgCoin,
  symbolCoin,
  valueInputPay,
  leverage,
  entryPrice,
  slippage,
  isLong,
}: props) {
  const isEmptyInput = useMemo(() => {
    return valueInputPay === 0;
  }, [valueInputPay]);

  const liquidationPrice = useMemo(
    () =>
      (valueInputPay * entryPrice * leverage * (1 - slippage / 100) -
        valueInputPay * entryPrice) /
      leverage,
    [valueInputPay, entryPrice, leverage, slippage]
  );

  return (
    <Flex mt='20px' direction='column' gap='10px'>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText
          txt='Collateral Asset'
          color='text.400'
          fontWeight={400}
        />
        <Flex alignItems='center' gap='5px'>
          <Image src={imgCoin} w='20px' h='20px' />
          <TemplateText txt={symbolCoin} fontWeight={400} />
        </Flex>
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText
          txt='Collateral Value'
          color='text.400'
          fontWeight={400}
        />

        <TemplateText
          txt={!isEmptyInput ? valueInputPay.toString() : '-'}
          fontWeight={400}
        />
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText txt='Leverage' color='text.400' fontWeight={400} />

        <TemplateText
          txt={!isEmptyInput ? `${leverage}x` : '-'}
          fontWeight={400}
        />
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText txt='Entry Price' color='text.400' fontWeight={400} />

        <TemplateText txt={`$ ${entryPrice + 1}`} fontWeight={400} />
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText
          txt='Liquidation Price'
          color='text.400'
          fontWeight={400}
        />

        <TemplateText
          txt={!isEmptyInput ? `$${Number(liquidationPrice).toFixed(3)}` : '-'}
          color='#fe6353'
          fontWeight={400}
        />
      </Flex>
    </Flex>
  );
}
