import { Flex } from '@chakra-ui/react';
import NoHistory from 'components/svg/NoHistory';
import NoOrders from 'components/svg/NoOrders';
import NoPositions from 'components/svg/NoPositions';
import React from 'react';

import TemplateText from '../Text/TemplateText';

interface props {
  title: string;
  content: string;
  isPositionsTab?: boolean;
  isOrdersTab?: boolean;
  isHistoryTab?: boolean;
}

export default function NoData({
  isHistoryTab,
  isOrdersTab,
  isPositionsTab,
  title,
  content,
}: props) {
  return (
    <Flex
      justifyContent='center'
      direction='column'
      alignItems='center'
      m='84px 0'
    >
      {isOrdersTab && <NoOrders />}
      {isHistoryTab && <NoHistory />}
      {isPositionsTab && <NoPositions />}
      <TemplateText
        txt={title}
        fontSize='16px'
        fontWeight={400}
        m='16px 0 9px'
      />
      <TemplateText fontWeight={400} color='text.400' txt={content} />
    </Flex>
  );
}
