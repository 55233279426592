import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import TradingViewChart from 'components/common/TrandingViewChart';
import Arrow from 'components/svg/Arrow';
import ArrowBottomMenu from 'components/svg/ArrowBottomMenu';
import { CoinPairTrade } from 'constants/interface';
import { useCoins } from 'hook/coin';
import { useMemo, useState } from 'react';

import FuturesTabs from './FuturesTabs';

interface Props {
  coinPair: CoinPairTrade;
  setCoinPair: (value: CoinPairTrade) => void;
}

export default function RightWrap({ coinPair, setCoinPair }: Props) {
  const [showChart, setShowChart] = useState(false);

  const { data: DAI } = useCoins('btc', true);

  const LIST_VALUE_COIN = useMemo(
    () => [
      {
        name: '24h Change',
        price: Number(DAI?.RAW.BTC?.USD.CHANGE24HOUR || 0).toFixed(2),
        increase: Number(DAI?.RAW.BTC?.USD.CHANGEPCT24HOUR || 0).toFixed(2),
      },

      {
        name: '24 High',
        price: Number(DAI?.RAW.BTC?.USD.HIGH24HOUR || 0).toFixed(2),
      },
      {
        name: '24 Low',
        price: DAI?.RAW.BTC?.USD.LOW24HOUR || 0,
      },
      {
        name: `24 Volume (${coinPair.coinBase.symbol})`,
        price: DAI?.RAW.BTC?.USD.VOLUME24HOUR || 0,
      },
      {
        name: `24 Volume (${coinPair.coinTarget.symbol})`,
        price: DAI?.RAW.BTC?.USD.VOLUME24HOURTO || 0,
      },
    ],
    [DAI]
  );

  return (
    <Box
      w={{ base: '100%', lg: '60%', xl: '70%' }}
      maxW={{ base: 'unset', lg: '950px', xl: '1000px' }}
      ml={{ lg: '2%', xl: '30px' }}
      pb={{ base: '30px', lg: 'unset' }}
    >
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 'unset', lg: '10px' }}
        justifyContent={{ base: 'unset', lg: 'space-between' }}
      >
        <Flex
          alignItems='center'
          gap='20px'
          justifyContent={{ base: 'space-between', lg: 'unset' }}
          pt={{ base: '10px', lg: 'unset' }}
          borderTop={{ base: '1px solid #28344b', lg: 'unset' }}
          overflowX={{ base: 'unset', lg: 'auto', xl: 'unset' }}
          ml='auto'
        >
          {LIST_VALUE_COIN.map((item) => (
            <Box key={item.name}>
              <TemplateText
                w='max-content'
                txt={item.name}
                fontWeight={400}
                color='text.400'
              />

              <Flex alignItems='center' gap='5px'>
                <TemplateText
                  fontWeight={400}
                  color={item.increase ? '#0dcb81' : 'text.500'}
                  txt={item.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                />

                {item.increase && (
                  <Flex alignItems='center' gap='5px'>
                    <Arrow isIncrease />
                    <TemplateText
                      fontWeight={400}
                      color='#0dcb81'
                      txt={`${item.increase.toString()}%`}
                    />
                  </Flex>
                )}
              </Flex>
            </Box>
          ))}
        </Flex>
      </Flex>

      <Box display={{ base: 'block', lg: 'none' }}>
        <Flex
          alignItems='center'
          gap='10px'
          mt='10px'
          onClick={() => setShowChart(!showChart)}
        >
          <TemplateText txt='View Chart' />
          <ArrowBottomMenu color='#eff3fb' w='10px' h='10px' />
        </Flex>

        {showChart && <TradingViewChart pair={coinPair.pair} />}
      </Box>

      <Box display={{ base: 'none', lg: 'block' }}>
        <TradingViewChart pair={coinPair.pair} />
      </Box>

      <Box display={{ base: 'none', lg: 'block' }}>
        <FuturesTabs />
      </Box>
    </Box>
  );
}
