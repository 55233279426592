import React from 'react';

export default function Tron() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='30px'
      height='30px'
      viewBox='0 0 200 200'
      enableBackground='new 0 0 200 200'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='100%'
        height='100%'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAC/VBMVEUAAAD/AAD/AAD/AAX/
ABH/ABP/ABH/ABH/ABL/ABL/ABL/ABH/ABH/ABL/ABL/ABP/ABL/ABP/ABL/ABP/ABL/ABH/ABL/
ABL/ABL/ABL/AA7/ABH/AAL/AA//ABH/ABH/ABL/ABL/ABL/ABL/ABL/AA7/ABb/AA//ABH/ABL/
ABP/ABP/ABL/ABL/ABH/AAT/ABH/ABP/ABP/ABP/ABH/ABH/ABD/ABH/ABP/ABL/ABL/ABP/ABL/
ABH/ABL/ABL/ABL/ABP/ABH/ABL/ABL/ABL/ABL/ABP/ABP/ABL/ABL/ABL/ABL/ABL/ABL/ABP/
ABP/ABL/ABL/ABL/ABL/ABL/ABL/ABT/ABH/ABL/ABL/ABP/ABT/ABX/ART/ABH/ABD/AhX/BRj/
BBf/Axb/AhT/ARP/Bhn/CRv/AAn/AAH/AAX/AAv/ABL/i5T/bXf/T1z/LTv/DB7/AAr/AA3/iZL/
////8vL/0tX/tLr/nKT/fYf/Ym//O0r/Fyj/DiD/7/D/+/v/+fn/4OP/xcr/rrT/g43/aHP/QlD/
HzD/q7H//f3//P3//Pz/+vr/+Pj/5uj/1Nj/oaj/dH7/SVf/Kjr/NEP//f7//v7/+vv/6+3/4+X/
jpb/VmP/JTX/AA//qbD//v//9PX/zdD/l5//W2f/NkX/OEf/9vf/7e//ys7/lJz/cn3/R1X/FCX/
2Nv/sLb/gIn/QE7/doD/6ev/usD/gov/ECH/8/P/v8P/IjP/GSr/h5D/trz/3eD/Tlr/AAf/XWn/
JDL/DBr/pKv/vcL/+/z/+fr/RFL/8PH/b3r/srj/yc3//v3/VGD/OUj/Gyz/BRb/BRP/WGT/e4X/
nqX/wMT/3+H/ZXD/Ahf/FSL/anX/2t3/5eb/mqH/KDj/ESP/Pkz/eIP/pq3/1tn/wcb/kZn/Ahb/
Hy3/YGz/0NP/CBj/TFn/ub7/6On/Lz7/CBL/UV7/AxT/EB3/AxL/yMz/ztL/AhH/Ag///fz/w8f/
Agz/KDX/BBX/BwuA+yyIAAAAW3RSTlMAAQIEBwscLlxukKSvy9nh6v331cC9no1OPBIOAyBCdZfD
5vKZEwkwZ9D++qFxMwU6/PvjZTcGKEyJxfmBIxpahcdVerPe7awslZv06KcXdXf4YlOotbf8ktKL
//beqQAAAAFiS0dEdjFjyUEAAAAHdElNRQfnAhwGJxBkZeRuAAAfeUlEQVR42t1dCXgT1fafpAuK
S40sAhYUkC0VawLiUqw8RXzKovjQp2+mk0ySNm0yYWmGW2QppuykKTudtkig7LKJgCBrlC4KPNlX
WRWpqOygfQ993//eO5M2bZM2kwkt3/98H7RN08z93bPec869lyDCRgqFUvgmIjIqutF99zd+4MGH
Ho55REUiejSmSdNmzR+7v0XLVo9HRgjvUyoU4Xt6uEAoY/HXyFat2zzxZNt2ZGCi2jd96ok20R06
CmCU9xAYhRJPcVTrTp2bdyEpiiJJtUDVMODXIIfgW+KaPN31mdZR6O9i7xEsyng4DmX0s12bxyAM
Gq1GVQNBNTwqjVaF0HRr3v25aDQTEcqGRqGI6AH/f/6FF18ikygyLk6jIoMjBCaOpJISmvZs8TL8
iPiIhmSLAjEj8ZVeXeDsahK0wYKoIJU2QQ3/9G+PvQr5EhvfUGxR9lAQypY9m0FeaOI0aqkoRM5o
EjRUkqrpay/AT+zREFxRxhNEx869X0+iVCGjqMAC+dLnjc6J0HLXN1cU0E79/c22CdD8aGWhELFo
NSSl7dITQomtV67AeXv5rdehjZKuF4H1haTIJo0bQSj1iST6qXYkpZYnUjVFDEKJafx8PcJIfLMP
fGbYmFEJBXoXslvjxPrAACW4Y8++6KFhhyFggf/6dY3ED7rLMFo8fHcg+FK/+yKxIt4tGPCjO/Wm
7j4OGI317wRt8V3iCoztBrzdl1TdJaGqQiqy7zvRhOKuMAU6wDbNoKWqD4ZAJKqkh1rih4ZbrGKJ
Du/0pRLqgx2Y1AMpba/EsIsXXDe1gdoRV18wEGk0Sc2ehauVcOKAcUP3dlRcvbGjgikx74ZVUWKJ
qP5QrOoXBqI4qm//qPDFLEriubZ3w5EHQTBqadsoXB4llvhHO6pejK4fUquobv8ID08URNf2VIOw
QyAV1efdsMDo0VxztwKrIJlCapv3kCteSqLHoHqJSWonalC8PCRKosODVIOyQ2QK1f9xOUhiiVbv
UfUUk9ROGuq9VqGrPIxKelNxDY1BoDiqd4dQkSiJAb0bwgv6p4SQkSggP5LuGRwikhBCSIUi6v17
CQdC8n6HEAoRishB945ceZE8GCkZiBLi0Db0yKuTlhoUKdEIK4i3GjIsCURq6i1paqIk3m3YsCQg
EvJ+KaYLxrvtG3rIgai9hFg4lniu3b0QmPgjNdXuuWCRKImObe9FBRFIRbXtGFzYpYiNHXSv8gOR
mhoUG0ztQREf2729qhpDaLqhh19JKlX77rHxdSOJIFr2U1fDodMzBqMpOaWhMYhI1P1aEhF1K/qA
ZjU8oTk1zWJldUazwcTcA2jiqPdbKepQeEVExxdrRFi21MGDhwwdlm61WOwCmoaVNLWWei2ijhSk
kni2z8AaFosbDkDGiI9Gjho9JtMKeaM3mY0NyRtVXL82tXt4pWJAFz9LQv3Yjx0AUda48RMmTpo8
xYbQGJHeNAySOKptq9pssEIR+46/0F1HTnVmuxw5Loxm2vQZ42fOmj2HRXpjMjaIoMVRT0TUGtI3
au83waufC3IRBt7pElgD8vILRkyY98l8zprG6ckkmq5fQVNr+jaqTbTiB/nPjLoNC4DADgAW8rnO
bF74vjC/YNHiJUvT0zi3Xmez2eoPiYZ8L7IWIPcFyJnQ9mUgB/gSz/NO8du85Ss+Xblq9ZoUUufG
gOj68J8aqkVgHJH9AvwVzWaurRi4AETkDl/xinPGZ+s+z0w303qW5fQIzV2FoyYf7hgQSNeAf2ZL
WQ/yqrAEOJ1OHxhe2rDxi0/mrNlk1qelsW4bwzB3EU7PACaLSOwX8G/otGWFroWV492cIXx1uLKd
FWiguGGTAKZ/uXjSmC1b03VWq91NGk13ybD1TfRrgZVE48B/Q7vTZ/pqydrh27Yv2lEoonG4KtDw
fLZo2DYsmrl+59z56XqLxcORxrtgptVv+vMlSsXz3WoJ3pPSvgKOyqkHH31t2TV39BdFxTM2CK/l
5JRUonG68krxd6UjZn7x1TdzM20Ijc0Io5twutA+0X4CFciQ2hZTjH3KogoLDOB3305KYz0Wi3nO
zt0r9+ydjhXImZXjyK5Ak+3Iyfo3frlg+8pV3+3bb8ZozAZjuHjzz6dqRsFK5d9jas03JHPrQGml
bJWCjFEHDqaaSQ6iSd8y+dDhI0ePCUYgq9RRyRvImmn4B/7Y0QXbjn+zJd1isXK02RyG8EZN9nu5
BksiIENqXRaesH6zQRyfS/xv6kn3CdpkNBttHAyMmf1Lh34/eOopAUFWTomrUtIgb4QfTi/aeGb9
obNGhIY0GEwmWbxRa9+qzhKlMrFJ7et0Rr/pI0HdCwUkTifYmOkxYW4xJqMR88Z2btj5JT/8OONb
DCDH1wo4S/JEA55x4acJFyfNPWexpEG9kWEF1GS3Rsqq+t6D6Kmto1vXaD1egh3HhtEAO0feBdYe
Sivz/p5GaGxuuARzj50yZ/WsIxszBOGC/mZhBRpXiYhmecGM4ovHV2/1wGDNxoQIRqX5gKgSqSgV
rbvUlTgxpc25gHlROvRQnuDZebD5uLXKCGgIhyE5u8djSz3389JL88ZvruEzYXDjEs3G8hUFv8xc
t3qr2wO9ZwhANNRLUVUaCuKJzto6U4tGz0oEhAeLLHMXARywOEHpzF9tNSaTTmaSbW7Obtcb09dM
+W3U5R3VYxv4Ndcpxjy5hSsKLm/7batbOhA1mdDdt/FGEat8o+6UtdHzzRU4dB6sOGud/5MwJPjv
s1Qd6a9eioItGwwjOTdpOnDuu8MzZxQ6Kt0m7/3qfcl1ehgnXby0VO9Yn3VJPPFCn7o7G5iUXcWC
no+00KnbXECc0UVXuZTAQ4C/sen0kHRs5vnjRXs3ZGR59aUiVhNUaPpVjpQKRUW97ptRiSdeo4Lo
GDVYhjggD5xg7TUjY1+fIYiJE2wYmqZjgniqTc+xHvrqpcUbT03fLM6Cb0h9ZbI+RSqSOKpnpWzF
EokvBVMMYfRnT2HZKjxuNRg9108BLCvZIH/3Ls4UxFOxKdDDgIA7+926Cb/syHf4wIBr0GmHuBtJ
0oBoqWYDKiKueOLVvkH1mhgsZ5CX48GPHgNptg77FGA3AbV25M20YJBgNIzJYNKzVotx/m+z5hX7
rmiyM5awEpGo1epXvLKlUMY/Fly10MRN/hbL1umlkANmdj5cACPZyC0FC67aTRJaJKDPMTN6zmJd
JwZwOP53OT+GSIKR0kqKU/eKEHfSxBIvP0QG1RZAM78exU/lt1nMpO0Ep5+IJAtFJODCeYvEeIM2
c/N/rAhCMZ4SfsVo7pYkJBryw+fFOkMs0WJgkJ36RuvuHMiSbHD05C046rIU9+4dwsqxFBy75GEk
DcFgnXtb5IcDxj0lwjcZu923pEiXWqNqIQBRKJS9kgYGOYnJmaehsYQe/brFgH+2jrkAsoQ5LZzH
6YJVFDQpnp07hNHDD/h98gJvLLp8FXdLCmsTqBd7YFeiJBKbBidZkMo8K3n8uCO3ktDTaCZtjhhL
QmUZuZU9EWSkQRu5H/JFfuSABVusJycIpjgbFEIkEoBoqZeisd2KJZ6JC7o/jtafd2Aga6FyCzxi
b84DOGKH9ueXZR5jcJ9D27c5hJHDAHTeLtbEnpsqaLwT8URK4KUd6C3GdZfQ4UD/gdXdkb3KIoqR
iTu4TYxXeLDjc2sw0sVwa4p4MXkJsteX6xmSRkj+I7xSKglJHNVVSJ5ENZfQGkBzQ7B5AV9u5ZiK
2R16xevlp/2g19Ul4VRy2vz/Al78iytD07A/T0ZInCK2VawtaGOupZ6OgjAUROtuUjpIdZvy8fML
V1fMPZ3smXysIoj84qAuudZB0LRn6QVhyAt5sHapXUx9YZ7kitEk5EmwGq8iu7WGMJTEM6SUXg2b
bjAabg44Y6h8FMNumQC8a5Tis57aQqYkHbtks3fqweU17gqbTXPmleDfQvTlXFVuC9aYa6lOWNu7
Smsu049B628nv3aOp/JJSaStKE8YXQlYNNcTeDoZtnyWENmgtxfpfANexp260iWsPvmSVXf0ZcFp
ShzVGZnfjg9I6/ZznyhG48gD39/xGUNyMjNrg5D5coENX3GBptPErtkmJl9d4OPByclVEDM20+Fp
eD5yc0t/uOYOzphrqOYoDdyhibQma5ttNHqUi/9pDes72mTrb+MEJDBoWcWxfq2X0TrlsuBAoXBe
OWSpnhJKYtJ2ZwlInGDwtfKgkKioD1tBINHtpXU50GzmOMgS3ln4Des7nfQB+/liUIpV3gEu7reb
/eGYO17MvOaA32d7DtRcJRvZ3YVY25wOjCSYIanUrSGQNhL7siidYTgSjhyw0qCv8huT5+QC4BAX
88Vf4yDGlxij/dApISrJzQPb//T85W+ejNxXhdg0O0v4wbfKg3FLWqoN9CNvqyX2naSwY0qdyCNP
38JWnVEjd3OlkL/j4YQPZQ2+v7aVQR+0uSInVnSS9R8E0EbPISE56XQ5hx8MZu2pot5WEBFPSW2g
SWbX3EZ6UAI+qZ74MLnLj6/wqvyKIVZT5Shg0D92XpYQ9Lv40omB15SMgVsi2mD+P3vS9XXHwiry
yQgisqnkFrPkO5eQbOXyxak1kNjSrhcIZhjGsdvM7or83Ql2/x7g/c23l8r1ZTU/OIVOhmthN5tm
GZrlzR1dTtXVOSA12TaSeLy95E4gxjNnOrL22bnLuBq/s9n2b/TGK2DPfq/xMnmm/CS6bR6cWs36
lRidm2M9ycuGDL58welNtYCNv9aJRE22iyIel96aReuMF5HtyQXbyBrmkYaRxmfe7BX4aYoH6wnt
OX8MePPAP03RVzpMOgUGCzhf5LbNH7rqx9+nb66SkgDgy/Q6bbBKHU20DKF50WT9Jg8bls1mfc3f
0rY7sxzecHjaJ6yOpnXsJ6XeCAZ8NlZPUziBR+IEnp5kDpy7+tW8o/mllQl8n8zkxWBkqxHRIgQg
DLf/S0Gld+r9/d5mP77ZO+6c4wc4zjCrYlilExkS1Uh1UBVYmzH93P6dw6de8GHAwirscIK9ycEA
eYXoGko7qdEyS/Brn3L+38COWYtCWzz0I+npR8REHA8yJnGkm/VYWTo98+vJuy8ezQc1iOcrihEu
MGNKEDlh1Hz6r1CAmKxjpmNL6trvX36TkIn22qipUyuKdiPOWyzs2P1nx3wyasEMcc59y9yoR8Th
k+kCGWfZIBYmavIDonlIDb5l7BnMEv5Mmn+HxehPjiysCL2yhVGWjl/68/nZu4tuTxemO6/EtxBU
0bUDNl8Q3pDr2vz9jWCyqGryAaJZSEAMltGFeL43XCvz/w7GnQaDJix/YjWEB8unLrgwTYi0svIq
MeSisqnwnqwdez9avG7J9REC8IWjuPJg1ldq8kGiaQgwoMm5dfIoj+Z72ug7gQL2G/pPZoC86s0R
fE6Ww1WRuoZsyBJLrPm/bJ+3fsm+KUaLxTIBv5IHpu4qD3J19RDRJCQgpNkyHKkAD27XiA29lGSy
Dvu9KhKXw7dCmpclLEw2r924+PClb+assaKqYkq6fiLWLhc4up8LKvqF9DoRExoQEzd3B87M518t
D1Rnpg32rz/z9QleDNmuvByBKaePFh0+9PmwrTTqMHQbUdHabF2PI2gHv/asPbjkEqQY4tHQgECW
fIYLcdlFFnPANxnZm8On+SJB1VDBcZeO+HH4pfPD9o/lLKgcajKYhAjTbN25HOC19OYlHkPQw3mE
CBEHXCNdysCZ+Rk3kwLro9k6+1tQUWYTWm7A9M8ujl725/50I2v1sG4SIqis6Zrsw9YiI8fzy1fd
+Sv41KkqZCDkgfLfsWw5VlkCT5zR8/1yH44Uji9av3pOZrrB7YEQdLSJSa7aQ2ji5u8VnedM6w0p
KeDQgRgsFwV/t91qqOVNE0WZGjdh3c75m1KNJMeybhg0Mv4a05JodqXoPIvT9ZJy+6EDYWxTpgtV
n8mBq1UGy2IB7UaLWad3o0ZBMnBrXVIK9wWOkXkwI90mrXQVOhA4yJ9QbOEEgwOru9lSLNTmR1oM
dQbj1F+W0UI+HGTsZKUVrkgi9I0iDPcbWseVgNv7A0kBzZjwqhjw66x1W6Ab9jGncBdBbt4Su9Ty
LvFIyEBgwDUOzjXPZ10KpO6MbesIbBGWH6+73sBwZ2dg1Nlgm11qD9SjoTpE/OQ7E7MxS47sCmBg
GO6q4DZXzGbryutQXOpHOAfpAhNuSKn0YIohXg8dCGlbU4oncPoyq//5PuC5vgIxDRTMqdMG3TiI
188QCzRYkvfQNyEekgGE5DZCH7fQ5VoVQDUN1iGlGMiOrXXl1pm0bTjqzwYFV0NoSmlKPChnw+Ef
y3BMDoorqj7VgFgOA2yiR/yvDhVhPDt55ECceVeuczekjkNNNiP6ywGiMxagCS8pnO2/4ma2nBGs
7+1aAjJEyezkK9hvOsAQq6SirgjkaeIDWVtAbw1BCa48cGaX3s/TaWPaHmGVONNaK0eYP77egSIZ
yJQzB6V5dBHIv4j75QChua0oMnKCgrMeP49nbOlHhYhjcK1AaNo2AZdGXaDoV10ZKZnUZFfiPlkc
0RuK0EhzwCq9H8fN6LfuxUCmjbbXYn3ppOQiIJTv114Nsr5dHUgLopEsILR9KbKZLlB8zo+pMdn3
HRP6oobW5kaMllF4+QUZ+7ldQu+EL5CWRLTUqkJVIGzmBaQlDvA5WxOI0Xr9YygycBk5XxfYVR+w
/7ZBWDbnHbeEwg9UV3iciGoniyU22yrkjl3giKFmIs1gmeTCbuTKmsBNfkbPvnFY/nL5ixKWhFVI
3f5xIrKtPNlKW5rhWggDrtNbasrWr5b1KP/Lg3FswCRCGbfpKPboTnAkqDY8fzjIppFExJOydkrT
7tSRqGfeBXbfqqHuaDVSit1IQKPFcDeFviC4BNl0I8QdZyryqQhC8ba8Ld9JaUtQgovn96ZWT2gn
0wcXCG5kgSfAXCf9UX5R3DswbhkXatu/Sv22QnoxtPqU2vffXoiQgG+qZ82TdWO3C0CG2/07B/pW
2uicUuwJXZM9EvsyKwkXQ4nWKnmb8Jk7o7D8OIuqc6SM27IXF3Fd3wcIKlP0Q69kC8m4HzzSHbqX
VO3RydqtPpQnWyesk6/kosGs2FqNJSbPmHE4JZI12388S7nnn8ZLqTywrlxypFiJg3y4A2rhaC7v
wKZk/dg9QrL6UrXhQjci1HuWL9X7BaLb9F8seg5wND2E0N1LGuoB1MKh6CzzxCaj9bgDZSH4X6pN
qsGyZCEGkjHWrzmyHSjGDCsBe8+GrOikt/NMSXSS1OZUk/6yz5kh7MeoNvFm6EawRcrwqyK0fb3Q
qwE2jLHK2aekpZ6BMKQ2nvkho+eiEOPOrGJlacYzTwAy3k/4QjLs8VIhlQqOWxkZ58OJjWcKIupp
macEGT2Tp+PY8HRKFSA2g7h3cY/fgHI2ViA+l1/PJsvZaqWlmkcJ2/Qldp7VnNuUXRsxS7KG+Abh
ye5NEwQ3srImkDJ95gwsWA5Q9JdN1ga4hKTuYiP2cwNlnttksHyfh4PDT30TXIz97C+CyO2uAeQE
t+Y2LkS7wN4/74QYYgmkVsU9g9tllUR0UJsuaiGm/M+1WLY27POJ+0yefaeFzPrq6ta37Nb/fsRJ
Xic4Niwt2KqUf9KQTRNxS6NC0eNFuUeCweiwBOeeZ/okGUzW1UJbH7+0ms9POnjt8HKhkLj8kMUs
bz//wKRe4o0ssUQLlcwT4E3c5x9DGC5+xMnKnKPB8gkvVKc2VR1qipEdXYiTvK5pk9gQlyBeUmsH
tqjYrfD8h0E3x/snmrlWjIu0y3+w/Op90WjZDXAx+ttqrTcmz9ICJ05igZFB5LZrJw350MsiEIUy
opc6Tt7HGa27sxBLwJ47J0SW0KRuuNjnQVcBksT9OQJbMx5czpRUlfJHCUmPxXuvlIkgXlGr5ckW
nbymAFohJ8if7bXAtM68GAGBw63CEebOyU9xTMw7125l5eJQqx59tWK3mNLfeUESqcxzUfAK87wW
mOEy8WqEBxd9j+egde5tQvcH79iXFnroLpKWaptYeXBYPNFT7lGftH5MDgay6GuxFYJJE8odPBji
G97rUtZlObCFK/2Olb8TXEO+5rM1NIJo+brcM87ocqzueblDRJaYrHPzhSxPlaYubrYLe/QSsF7S
LhH/pCL7vOADRKGI7S1XtmhukpCZLxI375qsY6bh6B7sc/u862yBiGPqWBlLEC9pqTeUvsefxRPd
E+SeZ6gbi/LpzoX554XMvMlyHVtfJxhb0Z1Gc5njsGdxgOJMqeVOP6QmtZ2rnPavVES9JPdgXxu0
trj5f5Ydj5Bmh+AueH5zuhcIrb+2R2woy78a1NafOkhFdWld9Vz5SOKDf8o9CFC/DDVQZ4MRW1Dd
nbZREwXre9vbHUz/dW2wsJTi83eyZfKPTVKrtT2JHtLOrgiC3GWXcRzoHIoSIrQ+dTFKpvJgu7cV
1eD5QWgHcDonWQxhOP5JRTVJrHZUAjRcb8m9/sj2x1fISuWAM6kw2qXZzC+R33OCxSbB/BrSJmeg
3A/vcI68eVC+giBv2LjGeSKKiJf7yWQJ1OQduWg/RsHZNJpMtm9Zi1YjTrDehoDYDJ59x8Sq1Pab
7jAoCBxuTPWzKzBLnvqnzA/WGbcJ+zFWHbShnZb5aNwl4BCSLNuJOz+PEJogwLGv2br7OYIh1Qd+
ztxRRET3kfm5Key+rFy0F2HvJpZmrHMdebgRbhnyF2XcrgUgD60Jc0+fTwsPDnW35/1dhaEk3pSp
JMnsmvFo0l2lszmS9uzE1tcBpsDIMPmPXRddDsFifRdSdc0ffeD/QD1lYl+ZH5x8Z4lQ9Tlj1Nv0
Q7D1XZifCYEw1u+85/Bc5P6S+Rgv9UsMdMZhT5mfzHjmnEaFuNzlP3M6+jDeZAJOrYGixU7+2NsY
V/cuimCpKxGIOj4sz3DRNmYeYoMT/GDj0nG9Fy610t2M589CYfUOLqRy4bqroV3go9uIFjLjFJN1
cimutS0ye/UFHEnVc5v2AGFpe3oYJ+sBlaSh7guMg4h8T97ineG2bhRycr959qMGJ/jDetJt/lHk
R+lcNkw4VOSgWq+GatRXXj7FaFklrNOLrFM+5nEMeamcPSxsi3FlHf6rzq3VwZE6rn2nWmAoFBFP
yFsqmjz7CrDbW75mGEaUBcbAcB7reSmYd9AdprPO4qh3aj0CVEm0aisPSRm7WFiRH169EPvD3Clj
ljuFJcjlm3fCEWGRSEG6DKj9WigF0aZfnJx43mC5JJyms2gSUhYnGHf+AvYf2eCXP1l5ydEKUg3s
82wdB3wrIiJeo+SEwfStm8U4v+gYjyNEMG4E3q/gyC2YmyY3GeelBOrFjnXdnRaraPW+LOEyW7aJ
Jkrc5SI290+b5AnHEgQRPi+ozrPj/R3uLYVM3NVTYiuDuNcF48iaeO1gmBRdpY55Jogz8P0ety6N
JRN8DnQUO+PBZ/+TdP5MbThU7d8N6npBdAC+nEjF6FnyLai++WVRZtB7c+oiNdk/IrjLd+VeSWC8
s6jK+V+Qdmx1h9Dg558hVNMgrySQfUmEwTK4GkNKv7KHyYGQairm78HfaCXv2g7G9vP0KtuRwBDZ
SfdKknJtB0IS0s7XCpZ86XuOGVgchiSvlyHqd6XdMKYg3godCeNeneWD47+pf4QLh4qUeLUNvmwo
9M6OMvsxr2w5wKI1IZwk6Z+0pOTLhtD1Tw+GXOtNvjMqW+QHn78vDEl3gRKo/tKvf4Ih/YD3Q0Zi
OyfIlrP029Hl0lvdA+F4PyqEC7nwFWkhI+G25wrVklFp4fLooV6Rhq8iCRmJbq6w2W3qrvIweUKI
Y0CoVyLKuEZQZzqGMygn9WHCoZVxjaCsix1tk6BcnV4mux9AJA31ngwcKOx6PLSNMjSXCfj863cO
hAeHmnyjg9xLQ+MHhfRovbEIrLJKORW0Vuofjmtcm9d9OLMfltj3bQ8TDjWpeatHaPaqGnUP5YJg
G2kIT5JXRbXvGhYYKBbu9v/hymYkXo3aUjIboUJlRzgv0fZeax5X/zgSwnutOWooIN6NoQbW90Xz
cVS77mG9aB4iiSWebUZp6vUW0TiSeq8NERtWHCgFSST2iqtHpqgTkvo+Ab15WOxVFYoniJZ/o2Ru
NgmaNGrq/ZZEUPkr6eKlIKLf6Suzjz5IdqjIvm93CLN6VBEvolP/ermunerdiQintarBFIKIvK/d
3cfxcIuORHi8eWCuEETHruiaqLuk9ehj+/a86zBESmzcjURZ8bDDgI6cfOTJxPrAINLzjWPgM2U2
oldnBrRUZLunousRBlLDRo0/hFC0YbNhKi1JUa+/9TIRvsgqGEJ3jyb26hIHuSK3Xw0zQwvdeELb
N/9OEHWV1MJOSmiLEzu/0Yei1AnyREytiVNRVEzvzpHQAdYrO7xcQW2RL7zWVJVEaULGAlFoKIps
1rOlEn5gQ8DAXImHEhb96mN/oxBfpOuLSpuAUHTp9coAVPOrb6GqwpUIFA293KJn04FJUPW1mqBt
skoTB/WCItu+2OJ5+BE96l03arIFDyH6me7NY9DAVHWCUatVGq0GvTemeddno5WIGQ0lU1VJocRR
UVTrZ7o+/WECFDPkLNU1t6So1eKr6C1dmnfu1DoK/Z33Qpd7ghRi22rHDtFt3n6yafvaIstuTZ98
u03rVsLVZrH3EgovGG/UHRH5eKuWLe7/V/NmTZvEPIoYQWoeiXn4oQcfaHz/fY2ioyLFsFYZSpEg
EP0fFBWqQc/gJTcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDItMjhUMDU6Mzk6MTYrMDE6MDCJ
YW7SAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAyLTI4VDA1OjM5OjE2KzAxOjAw+DzWbgAAAABJ
RU5ErkJggg=='
      />
    </svg>
  );
}
