import React from 'react';

export default function BobaChains() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='30px'
      height='30px'
      viewBox='0 0 130 130'
      enableBackground='new 0 0 130 130'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='130'
        height='130'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACCCAYAAACKAxD9AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAU
MklEQVR42u2de3hU1bXAf/s8Jgl5kQQh5AFBbQmliKXYB60gWi1WFGhtbcFqbUVufVC/W1sf9doH
XmvxWr1a37WtqPTh/eqj1dJqVbSlVhFEgoAghjxIAiRAEkhm5pyz7x/75EUyyQwk7Dlxft+3vklm
9jmz9tpr9uvsvbZg+DIKmAiU+3I8kANk+5LV7RWgFWjp9toCNAM7gC3dpFF3xoYCoVuBQSIfmAXM
BqahCr5giL6rEeUQ64CXgNVAk24DHC1BdYRsYCaq4E8HTtaYFwm8BbyIcoxXULVJiiHCAs4Gfgu0
oQogGaXN1/FsX+cUg8RU4HagHv2FnKjU+7pP1W3EoGIAC4DX0V+YgyWv+3kydBs3CFjAIqAC/QU3
VFIBLCTVbPSJDSwGtqO/oI6VbAcu9fOeAjXsG841QDw1xEzdhaCTQuBR9BdEssgKYIzuQjmWmMCV
wIFBNOJwkf2+bUzdhTTUjAPWaDJykGSNb6thyVzUVKxuIwdFGn2bDRts4LYkMGxQZTnDYGRRSqop
GKymolR3YR4pJwG7ksCIw0VqgSm6CzVRZqJ6wLqNN9xkP3Cq7sKNlwVAexIYbbhKOzBfdyEPxBLA
TQJjDXdxgct0F3YsLksCA33QJOmcYQGpmkBXzTBfd+F3MJNUn0CntJMED62mkBodJIPsR+PQspTU
PEEySS0aJp1sUjOGyShrOMLp6CN93HkrcMERXpti6CgF0oHnj8WXzUW/56ekf0n4qWWim0LGAetR
O4tSJC9NwMeAqngvSGRptQn8jpQTBIF8VFkNyUqnK9Ff5cm1sku++jX9+iS5XBFv4cbbNIwBtgK5
g+lZifKGVK+j3e9TK5YTMmB6UHdvHhsOoHaENwyUMN6m4TY0OwEorw05H2GEeQITuAOAohN0a5XU
5KJWOA1IPI4wC/i67hx1cIh3AEg3ygD49vW6NUp6LiKOKeiBHMEG7tGdkw5CgLBAEMJlP1EJ531L
t1aB4F4GmGgayBG+AUzWnYsO/v2c6gabjEQSQXoQ1a1UMJgMXNxfgv4cwQKu052D7vziZ6qfEJH1
AIxggm6VgsR19LPxtj9HuAAVdyhp2PCKem12XwUgh7N1qxQkTgC+EutDo5/3b9CteSyaeQGAXPOz
AMxZpFujwHADMco8liPMAz6iW+u+EEBY7PaVT0MC3/mBbq0Cw2TgvL4+iOUISTsoCwGGP3HaMXIo
mqRbq0DRZ03flyNMBU7RrW0s/rKyo8cjkDiI1MghUU6hj5hOfTnCRbo17Y+Hft6h+AgAMrxy3SoF
kV4ThIc7QkcMo6Rl25sdiquAqfnmubpVCiKLOGwoebgjnElAonaEZTUAWYZqxRZerVujQFEIfK77
G4c7QlI3Cx0I4ID7Uud/HrD4P3VrFTh6lHV3R8gmiTZL9EfIhRZ/WZ7HAaIe5AV207g25qPKHOjp
CDNRCx+TnluugbDRgsBE4mK5No5upYJHBt12Vnd3hNm6NYuXJ+6FkAGmv0RipDhHt0pBpbPMuzvC
6bq1ihcnovoJErVkKdc8A4APfVy3ZoGjs8w7HCEfFeo+ULS46wBIE6qDkOowJszHgDzocoRZBPDs
hmb5VwA8WnCBuQt1axQ4BKrsOx0hMP2D7rSwHgCPNhxXHZSQImFmQ5cjTNOtTaKEAEwQWIAkTeb7
PYYUCTINuhwhcBP21e+ALdSyNYBcMUe3SkGlHJQjjGLoDsIaMh66s2PkEAEg11ALdU8+TbdmgWMU
UGCgNkAEjqceUq/N7hsA2KIQCSz5rm7NAslEgwA2C93ZL58BwKMZD/jssIpefMwoD7wjHBLbAfBo
x3EgrFuhYFJukWQrlRMhBEQNteFFEiFTjMdhZ9zXz5gL00+DySeo8GTVNbB2Dax+EiLtunN3TDne
Qh2TG0j++luY/TUwycOhgTwWsoefYqdB9LCqYdZ8uP3Jrv89wJHqj46h08nAuVeAJbreE8ADN8GD
y3TndkjJEcBrwCd1a3IkTPgoPLERir27sIw0TPLYwVe487vwmL+k7dZH4IyLVIG2u5DvfYHR1iIM
YeHRjMQ77K4GJpkI0mh0nqHBfIQ0f861cTN8PinXdh81rwlgE0m6dD0e1krIcr5AgTUPi3y28WXa
quC6b8Fdz/vL3104QdyDaXStzjLJRpBB2KsnKtXZopYoIN0oBjyczqiBAkPmsM29EMtSD7ufvhuW
LdWd80FlkwB2EuBjY9ZK8JwQE6y7EZhs9S4l04CmesguhDLvUTBaAQObYnZF72Gf8RdsM/ZafgmE
Pch2pzLe/glR6gCwKaHCmUu6pRzs44F7OhOTnQbdVqkEEQOIGhHAUItUPIED3PFDVVi2kYtNCdXR
5WxnLofsv5Buwp5t8F8XwewCFWxjuoDPZMF3zoVNr0C6AVF7A9uYxw5nKSHGEaWGidZ95DvfQaKc
cJiQLYAIAT4qZrMHzQJOZCUeLex2HqXN+gfThSqoEdGzOGj/DQFU/gvOn5HY/dcfALejOx0tZoK9
HI9WQoxnM3OwgNNHQ/Me3ZY4KqKBP6f40XvUVnmDDAByjTN6fH7I/hvpqF98ok4A8LFcdW2mB9i1
bGURIUqIsJNJPEdYwt9367bC0WMArbqVOBruv0W9tnnbAMg0VEji8/3O3D3XwJRBaMsnmTDveFV1
vss5eB5EqGayeBaHrvhOAaXFAFp0a3E0NNX5r+5zAEhcPGDpTTAzG359e/z3yrrqKsZv3UjZ1o2Y
5b0nXGvfh1OEmoOoNpbgeYIoNUziz0Cg+wwtga4R8gphhr/R6aBYDahnDlEPMgvgUAI5G/f+VvKv
WEyDE6U6GmHsU3/APm1Wn2k/KVTHqtq4DJsSotSS76gYPrffrdsqR0RroGuEVXVw412qfXMMicBA
4mF7VsKLVLxwmLGmxYNF41lRXEaDE2Xsvf8bM/0Mv2bYJM/BIIscazrtLsy8UrdVjojgNg2XLFWd
xKIyCLeCbXTth8yOsfLOKCnq957n5ozEQM0jfDSUjjT670vPKYQ0AZXRawDBFHMVArDSdFsnYVoM
oFm3FkfCt/0fqwMsv1bVCkItYCPHUKu0j/N3P4mCfEo2b6DohVWUbHmbMb96uNf9DOmxvLEBT0p2
hNtp9FzM0f07TlMDFLkg7TpCjCdCJY4DFcF7YNVsADt0a3EkGEBO+BsAPHmveq/JWQXACENFzvjR
L9T7xf98mUzT5NYxxdw0qhB7xilYn+gZAqJq0smM2LqDG3fv4pf79tJ428+pzB81oB5Flmoi3vZU
PKdJ1p+D2OnaYQBbdGuRKGd+VQXHKAipdYqWPx3W7O+HdDmAA8zqFiTmhlGFAJSG0nCkJOvLX+p1
3/oFX6Kq/CSqJk3l4MO/6f3FVt9ByV56ADIMsBhNhBok8HyFbislxJZAOsIdv4U0ZwymSEcC3/ND
grZRCYCkHc9Vp16pNyTf3LWTEtvmJ3t2YQnB/ht/mNB3lmzeQEnFOkq2vE3Ztp4mu/Y/1GuNswwQ
RFwYmTTRKeNii4EKth0o2oHjxBIcmnCBhYv9qKxmVz8hQ47vTF9/yWLGWjYX1lRSHY1i1NdDOP61
TKWb1rPXc7m7sIRrC8bguVHGb6/skUYAB4z1gCBXfjpou4W2GsBeoFG3JomSbX5cTR5J1Ua/9oJa
UGJzHADp4sTOtM5rr1NTfhK7v72UmvKTqDrtrIS+S5omd44p4aCU5FkWmYaBF+3Zx969EdIMsMhj
pFBtkjEkpyUMOnuBxo7xUeCaB0kEkxwsAZUbYckc9avc5M6lLvogB82/91pyEnnp5T7vVbr5bUq2
KJmwbVOfaR470IQHpAvBu+F2zMN+8nct79gzaJFpfhiAzwYjqs8W6Hokv063NvGSX6xePQ5hkI4B
rHoapAsrb4WQCRH7TQzgE3HUz+M2radFeny/YDRfyR6J53mMr6vskcZMS2NnNMIltZUsqqlkrG1T
9bmeUV//+Tf1GvEa/N1XMPXTuq0VF+ugyxFeOoobHVPy/aG9WmKm5g/r/AHwz6/vWlswLc5G2jNN
vl8wmnzLZtqITDIA70DPan/nhIk03XYHpXaIIjuN2ukz8Gpqe6Rp9p9AOrIJiYcECkt0WysuXoIu
R1jdadUkx4t0/0+VtuvFTt+92s/78Y/6TPOPQ62dZ99sj0YQsrcpDj78ayonTuH9iZORrf3NFJiI
Dr1c3dYaEIkq+05HaALe0q1VPDT4q9U7ql8JlMfYwlu6eQOegFPtEKUSMi/4IqKg59lk1sgcKsLt
XFVXxTdqKym0bBquuTZhvcb6mwJsMQp8V6jarttaA7Ie2Ac9l+29qFureDi4X72aZOLSigecGyPm
uBSCWaE0zsk/jisKi4lIydiVj/RIU1lYRvMfnyHXtCixQjRceAnhZ1clrNdZ/vxUyDgOVx4C4M1X
dFtrQDrLvLsjBKafAB3rDg7ieJBVGDvde+GwOgtKCGwhcPc19Uqz/4Yb2TlxCjvLpxBd+2aPzzLO
/yJl2zZStq0C80OxD5C68gdqk4xLK63eBgDWv6zbSgPSWebdHeEVuk3GJTtNzouAJMMrJFYXwXId
6gQsrdrBxVXvIYCGr16cwLfAqJt/RFU4QnU4zNg/PUn6/D6Dm2PkguOq6C375BO6zRMPbcCrnfp3
+6AFeEq3dvHw5jNQL+4DJMdbDwCw7P7e6SonT6N6xixClVVkr3+LmvKTEvqesq1vc25WLiuKy3ik
uIx8w+C4W2/ulS6/WPVVxqGmrdut9/z5zaTmKbotQTj8gfsK3drFw5J5kGZCiBKi1BOWMGdJ32ll
0z7q5n2J3Rcv7vWZyMhg7J/+yKhfPdDntcIyqYlG8HfGUec40MeI4rUa1SxkmuUI0rGA7yb/Eeo9
yvpwR3ieOA6LTBZ2OXcCUOrdhADOnB//tTk/vYXi9f+m/YTjCc34NCVb3u6VpvKTp7Ih0s4te+r5
Xn0N6YZBW8W2XumagRHOR3FpJurtU4b8g27r9Es9+Mfg+BzuCA7wuG4t48ECDlgvYJBFhllKWMLN
T8Z/fc6CuRz0PH5TPJ4nSibQ5LqUvfdOjzRybxORHe/T6rmEhKD1qWfY++Xze6R5y3/WUWrdDEi2
czWjkn/N1+PQM1htXw/YVwBJH7HwjFL4azXsdVaSb51HOSupZCG1ESiOs4G+ftQYWjyPFi9CWShE
S7T3ESB1Z58X8/rb71DWzHcuImo14HmCDAPKkn9/ea8uQF+L8jYAb+jWdCAaa9Qeg2brWUKMwxPN
EB1LnQ2Xx7OAVEpu2L2L8XaIIsumyXGw7BFxf79lw6yr1Q7rXGsGgnS2iyUk/2QibwC92sFYM/IL
gD/q1jge1kpVGBPN+xFYbPYuJcOAy0+D11fHvs6c+GHGPv1/Pd5LZFTxhlS1QTlP4lBPVXQZ0t7F
vBOh9j3dVumXBSQwOjSACvQfez+g3H0vcq1EboycIvfI+2ST/IN81VXvXXz1wNenL5gv7Rmfivv7
RhWpe78mkfvlc3KPfEDWOMvkmxK5M6rfHgNIBTE2gff3jG4R8Nig+eEQ8q5UA+J85yqyrMmY5PGO
ewHpJtgHYWrW4HzPg0/DtPMgLGGyeJYoNRhyJO+LC3CATyX/sqRFwMq+Puhv4f7vCcgK5w/7oW6a
rLtpdTfiso+J5kMY0Yk4maoan38Uh4lPmKyaoGnnQcSBSeL3RKlByEx2iAuQBMIJ3gNiDmoHUn8x
8KDuHMTLOqnqPys6nSL7UgAMmcU78kJ/lTFctwhWrYzvfieeDL9T4Z6JAqXuMtJMtcS91dlKo3Un
oNY/BIDFwC9jfThQFmzUo8rArMmtkOqBSbsLU8xVRNgJCMJuI9Xm9Z2BIARABFavgu0VIASUToAZ
Z0J2AZ29/7CEQvdyRlqfwqMNiwK2Rr+OZbdhEP8CGM1sQoXkj3lEZjzZmAW8rDsnibD0v+GiG1Tt
kBn9PIX2JbgcQGCDtKmO/oxDZgWW2bFDStExlex4kOYWUWxch2WmAxKBTYuzid3W7ZhA07twVnBi
1s5CPVSMSbz+vII+Do1MduZ9E2582A+oJdVUdLZ5Mg7xhTcxyWWf8zJ14n7S/RXJLz8O11yoO2cJ
sQIY8JFrvI4wBrX/IVd3ro6E+ZfCjX7sZglEPMhwx5EjZpIuyrFEHiBxZQttcjsH+RcHxTudNYYE
/nwf/Phy3TlJmAOoWNsDPj9KpIW7Egjm7n+frDx4+Hl19pMXR/q2PXDqaN1aHxVXAvcM9k1NYA36
J0UGXTJzkNkF+vUYZFnjl1lcJNrnHYcaReQneF2KY0sTapRQFe8FiUZVqyKOjkcK7VxMAk4ACVQd
3XgXyAQ+ozu3KfrkNoagXxALm2HaXwi4rEFD8NRSoDYJMp8SJbV+mWhhCnSGMU+JPtnvl4VWZqKm
93Ub44Mq7XQ79V0381HPaXQb5YMmrm/7pOKyJDDMB016b9ZIEi4jVTMcq5ogaZ2gg/mk+gxDKW0k
YXMQi5mkRhNDIftIoo5hvEwBdiWB8YaL1JIEQ8QjpZTUDORgyBo0ThYNFjawPAmMGVT5GQE+c6sv
5qICe+o2bFCkEThHd6ENFeNINRXxyD8ZBk3BQJjAFaRGFX3Jft82wQjeO0iMQa2w1W38ZJFHfJt8
YJlJQDbcDpFs9G2QAtUrvhTYjv6COVayzc/zsBoRDBYWsJDhXUNUAF+j72g1KQ7DQM2nv47+ghss
ed3PU+CPYdbFVOB/gDr0F2aiUufrPlW3EYcTFjAHFeihDf2FHEsO+TrOIUDVfzA2dfcmG/UUbjZw
Omozh668SNSmnxdRsY1fJYCHqgbVEQ4nD7X1ezYwDSgHBj608cjYizr+Zh2q4Ffjh7oPMsPFEfqi
ALUTuNyX44EcVG2SDWR1+xvUr7gFdWh6x9/NqPBBW3zZSgAPQouH/wchdDt6s9iUAwAAACV0RVh0
ZGF0ZTpjcmVhdGUAMjAyMy0wMi0yOFQwNTowNjozMiswMTowMFficDAAAAAldEVYdGRhdGU6bW9k
aWZ5ADIwMjMtMDItMjhUMDU6MDY6MzIrMDE6MDAmv8iMAAAAAElFTkSuQmCC'
      />
    </svg>
  );
}
