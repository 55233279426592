import {
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { LIST_TAB_FUTURES } from 'data/future';
import { MdRefresh } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

export default function FuturesTabs() {
  return (
    <Tabs py='30px' variant='enclosed'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        borderBottom='1px solid #28344b'
      >
        <TabList color='#ffffff99' w='30%' borderBottom='1px solid #28344b'>
          {LIST_TAB_FUTURES.map((item) => (
            <Tab
              p={{ base: '5px', md: '16px' }}
              key={uuidv4()}
              _selected={{
                color: 'text.500',
                borderBottom: '5px solid transparent',
                borderImageSlice: 1,
                borderImageSource: 'linear-gradient(190deg,#1d6dff,#132038)',
                borderTop: '0px solid transparent',
                borderRight: '0px solid transparent',
                borderLeft: '0px solid transparent',
                marginTop: '5px',
              }}
              color='text.400'
              fontSize='14px'
              fontWeight={600}
              w='60%'
              gap='10px'
            >
              {item.name}
            </Tab>
          ))}
        </TabList>
        <TabList color='text.400' borderColor='bg.100'>
          <Flex alignItems='center' gap='10px'>
            <Icon as={MdRefresh} color='text.500' h='20px' w='20px' />
            <TemplateText txt='Refresh' />
          </Flex>
        </TabList>
      </Flex>
      <TabPanels>
        {LIST_TAB_FUTURES.map((item) => (
          <TabPanel p='0px' key={item.name}>
            {item.element}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
