import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, Input } from '@chakra-ui/react';
import { CoinType } from 'constants/interface';

import TemplateText from '../Text/TemplateText';

interface Props {
  setTotalSell: (value: number) => void;
  totalSell: number;
  coinBase: CoinType;
  onOpen: () => void;
}

export default function LimitOrderSell({
  setTotalSell,
  totalSell,
  coinBase,
  onOpen,
}: Props) {
  return (
    <Box
      w='full'
      p='12px 12px 10px'
      border='1px solid #28344b'
      bg='bg.100'
      borderRadius='12px'
      _focusWithin={{ border: '1px solid #2669f5' }}
    >
      <Flex justifyContent='space-between'>
        <TemplateText txt='Sell' color='text.400' fontSize='13px' />
        <Flex>
          <Box as='button' mr='5px'>
            <TemplateText
              borderRadius='4px'
              px='5px'
              _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
              txt='Max'
              color='#fa3961'
              fontSize='11px'
            />
          </Box>
          <Box as='button'>
            <TemplateText
              borderRadius='4px'
              px='5px'
              _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
              txt='Half'
              color='#fa3961'
              fontSize='11px'
            />
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent='space-between' py='10px'>
        <Flex as='button' gap='10px' alignContent='center' onClick={onOpen}>
          <Image src={coinBase.img} h='30px' />
          <Flex alignItems='center' gap='5px'>
            <TemplateText
              fontSize='20px'
              fontWeight='500'
              txt={coinBase.symbol}
              _hover={{ color: 'text.400' }}
            />
            <ChevronDownIcon color='text.500' boxSize={5} />
          </Flex>
        </Flex>
        <Input
          value={totalSell}
          type='number'
          p='0'
          focusBorderColor='bg.100'
          border='none'
          box-sizing='border-box'
          fontSize='20px'
          color='text.500'
          textAlign='right'
          onChange={(e) => setTotalSell(Number(e.target.value))}
        />
      </Flex>
      <Flex justifyContent='space-between'>
        <TemplateText fontSize='14px' color='text.400' txt='Balance : 0.01' />
        <TemplateText
          fontSize='14px'
          color='text.400'
          txt={`~ $ ${coinBase.price.toString()}`}
        />
      </Flex>
    </Box>
  );
}
