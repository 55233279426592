export default function HamburgerIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0.666687H12V2.00002H0V0.666687ZM0 5.33335H12V6.66669H0V5.33335ZM0 10H12V11.3334H0V10Z'
        fill='white'
      />
    </svg>
  );
}
