import Active from 'components/common/LimitOrderTable/Active';
import History from 'components/common/LimitOrderTable/History';
import Transaction from 'components/common/SwapTable/Transaction';
import FantomChains from 'components/svg/FantomChains';
import HecoChains from 'components/svg/HecoChains';
import { BsStar } from 'react-icons/bs';
import { MdRefresh, MdSettings } from 'react-icons/md';

export const ARR_COINS_CONVERTER = [
  {
    name: 'BTC',
    label: 'Bitcoin',
    value: '1',
    icon: <FantomChains />,
  },
  {
    name: 'USD',
    label: 'US Dollar',
    value: '19137.45',
    icon: <HecoChains />,
  },
];

export const TIME_SELECT = [
  { value: '1D', label: '1D' },
  { value: '7D', label: '7D' },
  { value: '1M', label: '1M' },
  { value: '3M', label: '3M' },
  { value: '1Y', label: '1Y' },
  { value: 'ALL', label: 'ALL' },
];

export const BUTTONS_SELECT = [
  { value: 'price', label: 'Original' },

  { value: 'tradingView', label: 'TradingView' },
];

export const INFO_GROUP = [
  {
    name: 'Minimum Receive',
    value: '-- --',
  },
  {
    name: 'Est. Gas Fee',
    value: '--',
  },
  {
    name: 'Price Impact',
    value: '0%',
  },
  {
    name: 'Your Gas Refund',
    value: '0% GAS REFUND',
  },
];

export const LIST_ICON = [
  { name: 'BsStar', icon: BsStar },
  { name: 'MdSettings', icon: MdSettings },
  { name: 'MdRefresh', icon: MdRefresh },
];
export const LIST_AVA = [
  { name: 'BNBChain', ava: './coin/BNBChain.png' },
  { name: 'BNBCoin', ava: './coin/BNBCoin.png' },
];

export const LIST_TAB_SWAP = [
  // { name: 'Market', element: <Market /> },
  { name: 'Transaction', element: <Transaction /> },
];

export const LIST_TAB_LIMIT_ORDER = [
  { name: 'Active', element: <Active /> },
  { name: 'History', element: <History /> },
];

export const LIST_GAS_PRICE = [
  { name: 'Standard', value: '24.23-24.30 Standard', price: '24.30' },
  { name: 'Normal', value: '28.03-28.16 Normal', price: '28.16' },
  { name: 'Fast', value: '29.30-29.61 Fast', price: '29.61' },
];

export const TOLERANCE = [
  { value: 0.5, label: '0.5%' },
  { value: 1, label: '1%' },
  { value: 3, label: '3%' },
];

export const BALANCE = [
  'PTD Balance',
  '8,000+',
  '40,000+',
  '400,000+',
  '2,000,000+',
];
export const GAS_REFUND_RATE = ['Gas Refund Rate', '25%', '50%', '75%', '100%'];
