import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NoData from 'components/common/NoData';
import TemplateText from 'components/common/Text/TemplateText';
import { useCoins } from 'hook/coin';
import { useFuture } from 'hook/future';
import useVisible from 'hook/useVisible';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DAI_ADDRESS,USDT_ADDRESS, VAULT_ADDRESS } from 'utils/ERC20';
import { VAULT_ABI } from 'utils/Vault';
import { v4 as uuidv4 } from 'uuid';
import { useAccount, useContractRead } from 'wagmi';

import ModalClose from './ModalClose';
import ModalWithdraw from './ModalWithdraw';

export default function PositionsTab() {
  const { isConnected, address } = useAccount();
  const [positions, setPositions] = useState<any>();
  const [positionSelect, setPositionSelect] = useState<any>();
  const [timeInterval, setTimeInterval] = useState(true);

  const withdrawModal = useVisible();
  const closeModal = useVisible();

  const headers = useMemo(
    () => [
      'Position',
      'Size',
      'Net Value',
      'Mark Price',
      'Entry Price',
      'Liquidation Price',
      'Collateral Asset',
      'Action',
    ],
    []
  );

  const { data, isSuccess: isSuccessFuture } = useFuture(address || '');

  const { data: DAI_Price } = useCoins('dai', isConnected);

  useEffect(() => {
    isSuccessFuture && setPositions(data);
  }, [data, isSuccessFuture]);

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    timeInterval &&
      setInterval(() => {
        toggle();
      }, 5000);
  }, [toggle, timeInterval]);

  const { data: Long, isSuccess: isSuccessLong }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    enabled: isConnected && timeInterval,
    functionName: 'getPositionDelta',
    args: [address, DAI_ADDRESS, DAI_ADDRESS, true],
  });

  const { data: Short, isSuccess: isSuccessShort }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    enabled: isConnected && timeInterval,
    functionName: 'getPositionDelta',
    args: [address, USDT_ADDRESS, DAI_ADDRESS, false],
  });

  const targeLong = useMemo(
    () => (isSuccessLong && (Number(Long[1]) / 10 ** 30).toFixed(2)) || 0,
    [Long, isSuccessLong]
  );

  const targeShort = useMemo(
    () => (isSuccessShort && (Number(Short[1]) / 10 ** 30).toFixed(2)) || 0,
    [Short, isSuccessShort]
  );

  const isProfitLong = useMemo(() => Long && Long[0], [Long]);

  const isProfitShort = useMemo(() => Short && Short[0], [Short]);

  const deltaLong = useMemo(
    () => (Long && !Long[0] ? Long[1] : 0 || 0),
    [Long]
  );

  const deltaShort = useMemo(
    () => (Short && !Short[0] ? Short[1] : 0 || 0),
    [Short]
  );

  return (
    <>
      {positions && (Long || Short) && isConnected ? (
        <TableContainer
          border='1px solid #28344b'
          borderRadius='12px'
          p='10px'
          mt='20px'
        >
          <Table>
            <Thead>
              <Tr>
                {headers.map((item) => (
                  <Th border='none' key={uuidv4()}>
                    <TemplateText txt={item} fontSize='12px' />
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {positions.map((position) => (
                <Tr key={uuidv4()}>
                  <Td border='none'>
                    <Box alignItems='center'>
                      <TemplateText
                        txt='BTC/USD'
                        fontSize='12px'
                        color='yellow.200'
                      />
                      {position?.is_long ? (
                        <TemplateText
                          txt='LONG'
                          fontSize='12px'
                          color='green.300'
                          w='fit-content'
                          m='auto'
                        />
                      ) : (
                        <TemplateText
                          txt='SHORT'
                          fontSize='12px'
                          color='red.300'
                          w='fit-content'
                          m='auto'
                        />
                      )}
                    </Box>
                  </Td>
                  <Td border='none'>
                    <TemplateText
                      color='text.400'
                      txt={(Number(position?.size) / 10 ** 30).toFixed(2)}
                    />
                  </Td>
                  <Td border='none'>
                    <Flex alignItems='center'>
                      <Box>
                        <TemplateText
                          color='text.400'
                          txt={`$${(
                            Number(position?.collateral) /
                            10 ** 30
                          ).toFixed(2)}`}
                        />
                        <TemplateText
                          color={
                            (position?.is_long ? isProfitLong : isProfitShort)
                              ? 'green.300'
                              : 'red.400'
                          }
                          txt={` ${
                            (position?.is_long ? isProfitLong : isProfitShort)
                              ? '+'
                              : '-'
                          } $${
                            position?.is_long ? targeLong : targeShort
                          } ( ${Number(
                            position?.is_long
                              ? (targeLong * 10 ** 32) / position?.collateral
                              : (targeShort * 10 ** 32) /
                                  position?.collateral || 0
                          ).toFixed(4)}%)`}
                        />
                      </Box>
                    </Flex>
                  </Td>
                  <Td border='none'>
                    <TemplateText
                      txt={DAI_Price?.RAW.BTC?.USD.PRICE || 0}
                      fontSize='14px'
                      color='text.400'
                    />
                  </Td>
                  <Td border='none'>
                    <TemplateText
                      txt={(Number(position?.average_price) / 10 ** 30).toFixed(
                        2
                      )}
                      fontSize='14px'
                      color='text.400'
                    />
                  </Td>
                  <Td border='none'>
                    <TemplateText
                      txt={position?.liquidate_price}
                      fontSize='14px'
                      color='yellow.400'
                    />
                  </Td>
                  <Td border='none'>
                    <Flex>
                      <Image
                        w='30px'
                        h='30px'
                        src={
                          position?.is_long
                            ? '../../coin/DAICoin.png'
                            : '../../coin/USDTCoin.png'
                        }
                      />
                      <TemplateText
                        txt={position?.is_long ? 'DAI' : 'USDT'}
                        fontSize='14px'
                        color='text.400'
                        my='auto'
                        mx={3}
                      />
                    </Flex>
                  </Td>
                  <Td border='none'>
                    <Flex>
                      {/* <Box as='button'>
                        <TemplateText
                          txt='Deposit'
                          fontSize='14px'
                          color='text.400'
                        />
                      </Box> */}
                      <Box
                        as='button'
                        mr={10}
                        onClick={() => {
                          setPositionSelect(position);
                          withdrawModal.show();
                        }}
                      >
                        <TemplateText
                          txt='Withdraw'
                          fontSize='14px'
                          color='text.400'
                        />
                      </Box>
                      <Box
                        as='button'
                        onClick={() => {
                          setPositionSelect(position);
                          closeModal.show();
                        }}
                      >
                        <TemplateText
                          txt='Close'
                          fontSize='14px'
                          color='text.400'
                        />
                      </Box>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <NoData
          title={`You haven't opened any positions yet.`}
          content={`Let's start trading now.`}
          isPositionsTab
        />
      )}
      <ModalWithdraw
        isLong={positionSelect && positionSelect?.is_long}
        isOpen={withdrawModal.visible}
        onClose={withdrawModal.hide}
        onOpen={withdrawModal.show}
        collateral={(positionSelect && positionSelect?.collateral) || ''}
        size={(positionSelect && positionSelect?.size) || ''}
        timeInterval={timeInterval}
      />
      <ModalClose
        isLong={positionSelect && positionSelect?.is_long}
        isOpen={closeModal.visible}
        onClose={closeModal.hide}
        onOpen={closeModal.show}
        collateral={(positionSelect && positionSelect?.collateral) || ''}
        size={(positionSelect && positionSelect?.size) || ''}
        timeInterval={timeInterval}
        delta={positionSelect?.is_long ? deltaLong : deltaShort}
      />
    </>
  );
}
