import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ERC20_ABI, ROUTER_ABI_TEST } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import { ConnectKitButton } from 'connectkit';
import { CoinPair } from 'constants/interface';
import useVisible from 'hook/useVisible';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTE_ADDRESS } from 'utils/ERC20';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

interface props {
  isEnterAmount?: boolean;
  valueInput: number;
  coinPair: CoinPair;
  isEnabled: boolean;
  minOut: number;
  setValueInput: (value: number) => void;
  isInfinity: boolean;
  isLoading: boolean;
}

export default function ButtonAction({
  isEnterAmount,
  valueInput,
  coinPair,
  isEnabled,
  minOut,
  setValueInput,
  isInfinity,
  isLoading,
}: props) {
  const { isConnected, address } = useAccount();
  const [timeInterval, setTimeInterval] = useState(true);
  const approveModal = useVisible();
  const [message, setMessage] = useState('');

  const { data: allowance }: any = useContractRead({
    address: `0x${coinPair.coinBase.address}`,
    abi: ERC20_ABI,
    functionName: 'allowance',
    enabled: isConnected && isEnabled && timeInterval,
    args: [address, ROUTE_ADDRESS],
  });

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    setInterval(() => {
      toggle();
    }, 5000);
  }, [toggle]);

  const LinkPrice = BigNumber(10).pow(18).toNumber();

  const allowanceNumber: BigNumber = useMemo(() => {
    if (allowance) {
      if (
        coinPair.coinBase.address === 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
      ) {
        return BigNumber(
          allowance.toString() / BigNumber(10).pow(6).toNumber()
        );
      } else {
        return BigNumber(allowance.toString() / LinkPrice);
      }
    }
    return BigNumber(0);
  }, [LinkPrice, allowance, coinPair.coinBase.address]);

  const { config: configApprove } = usePrepareContractWrite({
    address: `0x${coinPair.coinBase.address}`,
    abi: ERC20_ABI,
    functionName: 'approve',
    enabled: isConnected && isEnabled,
    args: [ROUTE_ADDRESS, BigNumber(1000 * 18)],
  });

  const { write: writeApprove, isLoading: isLoadingApprove } =
    useContractWrite(configApprove);

  const isApprove = useMemo(() => {
    return valueInput <= allowanceNumber.toNumber();
  }, [valueInput, allowanceNumber]);

  const handleApprove = useCallback(() => {
    setMessage('Approving');
    approveModal.show();
    writeApprove?.();
  }, [writeApprove, approveModal]);

  useEffect(() => {
    approveModal.visible &&
      !isLoadingApprove &&
      // !isLoadingSwap &&
      new Promise(() => {
        setTimeout(approveModal.hide, 5000);
      });
  }, [
    approveModal.visible,
    approveModal.hide,
    isLoadingApprove,
    // isLoadingSwap,
  ]);

  const ROUTER_ADDRESS_SWAP = '0xd15d99db76c8f6fd5f94dc9bd6e1a55f60f3fab5';

  const buyTokensFunction = useContractWrite({
    address: ROUTER_ADDRESS_SWAP,
    abi: ROUTER_ABI_TEST,
    functionName: 'swap',
  });

  const sellAmount = useMemo(() => {
    if (
      coinPair.coinBase.address === 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
    ) {
      return 10 ** 6 * valueInput;
    } else {
      return 10 ** 18 * valueInput;
    }
  }, [coinPair.coinBase.address, valueInput]);

  const handleSwap = useCallback(async () => {
    setMessage('Swapping');
    approveModal.show();
    const response = await fetch(
      `https://arbitrum.api.0x.org/swap/v1/quote?buyToken=0x${coinPair.coinTarget.address}&sellToken=0x${coinPair.coinBase.address}&sellAmount=${sellAmount}`,
      {
        headers: {
          '0x-api-key': '4949ce65-31d9-4370-bd4c-325f645b76cf',
        },
      }
    );
    const quote = await response.json();

    buyTokensFunction.write({
      args: [
        `0x${coinPair.coinBase.address}`,
        `0x${coinPair.coinTarget.address}`,
        sellAmount,
        quote.data,
      ],
      value: quote.value,
    });
  }, [
    approveModal,
    buyTokensFunction,
    coinPair.coinBase.address,
    coinPair.coinTarget.address,
    sellAmount,
  ]);

  return (
    <>
      {isConnected ? (
        <>
          {isLoading ? (
            <ButtonBase
              w='100%'
              mt='30px'
              h='54px'
              content=''
              borderRadius='12px'
              disabled={isLoading}
              _loading={{ color: 'text.500', background: 'bg.300' }}
              loadingText='Finding Best Prices'
              isLoading={isLoading}
            />
          ) : (
            <>
              {isEnterAmount ? (
                <>
                  {isInfinity ? (
                    <>
                      {isApprove ? (
                        <>
                          <ButtonBase
                            content='Swap Now'
                            w='100%'
                            bg='#2669f5'
                            colorText='text.500'
                            mt='30px'
                            h='54px'
                            fsText={{ base: '18px', lg: '16px', xl: '18px' }}
                            borderRadius='12px'
                            onClick={handleSwap}
                            // isDisabled={!isSuccess}
                          />
                          {/* {!isSuccess && (
                            <TemplateText
                              mt='8px'
                              color='red'
                              txt='Unable to fetch. Try adjusting your slippage.'
                            />
                          )} */}
                        </>
                      ) : (
                        <ButtonBase
                          content='Approve'
                          w='100%'
                          bg='bg.300'
                          colorText='text.400'
                          mt='30px'
                          h='54px'
                          fsText={{ base: '18px', lg: '16px', xl: '18px' }}
                          borderRadius='12px'
                          onClick={handleApprove}
                        />
                      )}
                    </>
                  ) : (
                    <ButtonBase
                      content='Not Enough'
                      w='100%'
                      bg='bg.300'
                      colorText='text.400'
                      mt='30px'
                      h='54px'
                      fsText={{ base: '18px', lg: '16px', xl: '18px' }}
                      borderRadius='12px'
                      disabled
                    />
                  )}
                </>
              ) : (
                <ButtonBase
                  content='Enter amount'
                  w='100%'
                  bg='bg.300'
                  colorText='text.400'
                  mt='30px'
                  h='54px'
                  fsText={{ base: '18px', lg: '16px', xl: '18px' }}
                  borderRadius='12px'
                />
              )}
            </>
          )}
        </>
      ) : (
        <ConnectKitButton.Custom>
          {({ show, isConnecting }) => {
            return (
              <ButtonBase
                onClick={show}
                content='Connect Wallet'
                w='100%'
                isLoading={isConnecting}
                _loading={{
                  color: 'text.500',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                bg='#2669f5'
                colorText='text.500'
                mt='50px'
                h='54px'
                fsText={{ base: '18px', lg: '16px', xl: '18px' }}
                borderRadius='12px'
              />
            );
          }}
        </ConnectKitButton.Custom>
      )}
      <Modal
        isCentered
        isOpen={approveModal.visible}
        onClose={approveModal.hide}
        size='sm'
      >
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius={12}>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px'
          >
            <Button
              isLoading
              border='none'
              color='white'
              variant='outline'
            ></Button>
            <TemplateText fontSize='18px' txt={message} my='auto' />
          </ModalHeader>
        </ModalContent>
      </Modal>
    </>
  );
}
