import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { FAQContentType } from 'constants/types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface props {
  item: FAQContentType;
}

export default function ContentFAQ({ item }: props) {
  return (
    <Box>
      {item.answer && (
        <Box>
          {item?.answer.map((answer) => (
            <Box key={uuidv4()}>
              {answer.title && (
                <TemplateText
                  color='text.500'
                  mt='10px'
                  fontSize='16px'
                  txt={answer.title}
                />
              )}

              {answer.content && (
                <Box>
                  {answer?.content.map((content) => (
                    <React.Fragment key={uuidv4()}>
                      <Flex alignItems='center' gap='5px'>
                        {content.firstparagraph && (
                          <TemplateText
                            mt='10px'
                            color='text.400'
                            fontSize='16px'
                            txt={content.firstparagraph}
                          />
                        )}
                        {content.isdetail && (
                          <TemplateText
                            mt='10px'
                            fontSize='16px'
                            color='#2669f5'
                            txt='Details'
                          />
                        )}
                      </Flex>
                      {content.secondparagraph && (
                        <TemplateText
                          mt='10px'
                          color='text.400'
                          fontSize='16px'
                          txt={content.secondparagraph}
                        />
                      )}
                      {content.thirdparagraph && (
                        <TemplateText
                          mt='10px'
                          color='text.400'
                          fontSize='16px'
                          txt={content.thirdparagraph}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
