export const substringContent = (content: string, number: number) => {
  return content.length > number
    ? `${content.substring(0, number)}...`
    : content;
};

export const patternInput = () => '/^-?d+.?d*$/';

export const hideAddress = (address : string) => {
  if (address.length <= 9) return address;

  const start = address.substring(0, 6);
  const end = address.substring(address.length - 3);

  return `${start}***${end}`;
}