import React from 'react';

export default function MyTrade() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 2.56714C3 2.25392 3.25392 2 3.56714 2H16.4329C16.7461 2 17 2.25392 17 2.56714V17.4329C17 17.7461 16.7461 18 16.4329 18H3.56714C3.25392 18 3 17.7461 3 17.4329V2.56714Z'
        fill='url(#paint0_linear_523_46317)'
      />
      <mask
        id='mask0_523_46317'
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='14'
        height='16'
      >
        <path
          d='M3 2.56714C3 2.25392 3.25392 2 3.56714 2H16.4329C16.7461 2 17 2.25392 17 2.56714V17.4329C17 17.7461 16.7461 18 16.4329 18H3.56714C3.25392 18 3 17.7461 3 17.4329V2.56714Z'
          fill='url(#paint1_linear_523_46317)'
        />
      </mask>
      <g mask='url(#mask0_523_46317)'>
        <rect
          opacity='0.8'
          x='2'
          y='19.2285'
          width='24.0753'
          height='14.3203'
          transform='rotate(-45 2 19.2285)'
          fill='url(#paint2_linear_523_46317)'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.8625 4.67188C5.67457 4.67188 5.52222 4.82423 5.52222 5.01216V5.74944C5.52222 5.93738 5.67457 6.08973 5.8625 6.08973H11.9876C12.1756 6.08973 12.3279 5.93738 12.3279 5.74944V5.01216C12.3279 4.82423 12.1756 4.67188 11.9876 4.67188H5.8625ZM5.8625 7.79027C5.67457 7.79027 5.52222 7.94262 5.52222 8.13056V8.86784C5.52222 9.05577 5.67457 9.20813 5.8625 9.20813H9.15193C9.33986 9.20813 9.49221 9.05577 9.49221 8.86784V8.13055C9.49221 7.94262 9.33986 7.79027 9.15193 7.79027H5.8625Z'
        fill='url(#paint3_linear_523_46317)'
      />
      <circle cx='14' cy='15' r='4.5' fill='url(#paint4_linear_523_46317)' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6881 12.7718L15.2635 12.772C15.1483 12.772 15.0906 12.9113 15.1721 12.9927L15.6103 13.4309L14.2203 14.765L13.0599 14.2828C12.9649 14.2434 12.8556 14.264 12.7814 14.3353L10.9453 16.1013C10.8274 16.2147 10.8118 16.3979 10.9087 16.5296L11.2986 17.0592C11.3571 17.1386 11.4721 17.1483 11.5431 17.0798L13.2021 15.4778L14.3 15.9444C14.3959 15.9851 14.5068 15.9644 14.5815 15.8917L16.3497 14.1703L16.7934 14.6141C16.8749 14.6955 17.0141 14.6378 17.0141 14.5227L17.0142 13.0978C17.0142 12.9177 16.8682 12.7718 16.6881 12.7718Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_523_46317'
          x1='3.49144'
          y1='9.74596'
          x2='15.7177'
          y2='9.91577'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_523_46317'
          x1='3.49144'
          y1='9.74596'
          x2='15.7177'
          y2='9.91577'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_523_46317'
          x1='12.8666'
          y1='21.9445'
          x2='7.14954'
          y2='16.8123'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' stopOpacity='0.4' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_523_46317'
          x1='8.35793'
          y1='4.38836'
          x2='11.7599'
          y2='9.49225'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_523_46317'
          x1='10.3654'
          y1='10.5'
          x2='16.8582'
          y2='14.2458'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#273550' />
          <stop offset='1' stopColor='#273550' />
        </linearGradient>
      </defs>
    </svg>
  );
}
