import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import CoinItem from 'components/common/CoinItem';
import TemplateText from 'components/common/Text/TemplateText';
import { CoinType } from 'constants/interface';
import { v4 as uuidv4 } from 'uuid';

import { LIST_POPULAR_TOKENS } from 'data/cross';
import { useDebounce } from 'hook/useDebounce';
import { useCallback, useEffect, useState } from 'react';
import './style.scss';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  setCrossToken: (value: CoinType) => void;
}

export default function ModalSelectToken({
  isOpen = true,
  setCrossToken,
  onClose = () => ({}),
}: PopoverItemProps) {
  const [searchText, setSearchText] = useState('');
  const [resultSearch, setResultSearch] = useState<CoinType[]>([]);

  const debouncedSearchText = useDebounce(searchText);

  const handleClose = useCallback(() => {
    setSearchText('');
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (debouncedSearchText) {
      const filteredSearch = LIST_POPULAR_TOKENS.filter((coin) =>
        coin.symbol.toLowerCase().includes(debouncedSearchText.toLowerCase())
      );
      setResultSearch(filteredSearch);
    } else {
      setResultSearch(LIST_POPULAR_TOKENS);
    }
  }, [debouncedSearchText]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size='lg'>
      <ModalOverlay />
      <ModalContent position='relative' bg='bg.200' borderRadius={12}>
        <ModalHeader
          display='flex'
          justifyContent='center'
          bg='bg.100'
          borderRadius='10px'
        >
          <TemplateText fontSize='18px' txt='Select a token' />
        </ModalHeader>
        <ModalCloseButton
          top='12px'
          _hover={{
            color: 'text.500',
          }}
          bg='bg.200'
          borderRadius='50%'
          color='text.400'
        />

        <ModalBody maxH='70vh'>
          <Input
            color='text.500'
            my='20px'
            w='100%'
            border='none'
            onChange={(e) => setSearchText(e.target.value)}
            focusBorderColor='#28344b'
            placeholder='Search name or paste address'
            bg='bg.100'
            fontSize='14px'
          />
          <TemplateText fontSize='14px' txt='Popular tokens' />
          <TemplateText py='20px' fontSize='14px' txt='All tokens' />
          <Box overflowY='auto' h='42vh' className='scrollBar'>
            <CoinItem
              key={uuidv4()}
              setCrossToken={setCrossToken}
              onClose={handleClose}
              listCoin={resultSearch}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
