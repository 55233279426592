import {
  AvatarGroup,
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import Chart from 'components/common/Chart';
import BarchartIcon from 'components/svg/BarchartIcon';
import ChartIcon from 'components/svg/ChartIcon';
import { CHART_TYPE } from 'constants/enum';
import { CoinPair } from 'constants/interface';
import { DetailChart } from 'constants/types';
import useModeTheme from 'hook/colorDarkMode';
import { useEffect, useMemo, useState } from 'react';
import { formatDateChartVer2 } from 'utils/date';
import { convertBigNumber } from 'utils/number';
import { v4 as uuidv4 } from 'uuid';

import BarChart from '../BarChart';
import TemplateText from '../Text/TemplateText';

interface props {
  pair?: string;
}

export default function TradingViewChart({ pair }: props) {
  const [chart, setChart] = useState(CHART_TYPE.LINE_CHART);
  const [barChartSelect, setBarChartSelect] = useState({
    name: '1D',
    value: '1',
  });
  const { darkModeColors } = useModeTheme();
  const [getDetailChart, setGetDetailChart] = useState<DetailChart>();

  const coinPairDefault: CoinPair = useMemo(() => {
    return {
      coinBase: {
        name: 'bitcoin',
        symbol: 'DAI',
        coingeckoSymbol: 'DAI',
        address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        price: 31237.23,
        img: '../../coin/DAICoin.png',
        exchange: 'MicoDexV1',
      },
      coinTarget: {
        name: 'usd',
        symbol: 'USDT',
        coingeckoSymbol: 'usd',
        address: 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        price: 337.23,
        img: './coin/USDTCoin.png',
        exchange: 'PancakeSwapV2',
      },
    };
  }, []);
  const [coinPair, setCoinPair] = useState(coinPairDefault);

  const chartTime = useMemo(() => {
    return [
      { name: '1D', value: '1' },
      { name: '1W', value: '7' },
      { name: '1M', value: '30' },
      { name: '1Y', value: '365' },
      { name: 'ALL', value: 'max' },
    ];
  }, []);

  useEffect(() => {
    setBarChartSelect(barChartSelect);
  }, [barChartSelect, chart]);
  return (
    <Box h='500px' mt='20px'>
      {/* <TradingViewWidget
        symbol={pair || 'BINANCE:BTCUSDT'}
        interval='15'
        timezone='Etc/UTC'
        theme={Themes.DARK}
        locale='en'
        autosize
      /> */}
      <Flex w='full' justifyContent='space-between'>
        <Flex w='fit-content'>
          {!coinPair ? (
            <Flex>
              <Skeleton
                h='36px'
                w='36px'
                borderRadius={50}
                startColor='white'
                endColor='gray'
              />
              <Skeleton
                h='36px'
                w='36px'
                borderRadius={50}
                startColor='white'
                endColor='gray'
              />
            </Flex>
          ) : (
            <AvatarGroup size='md' max={2}>
              <Image
                key={coinPair.coinBase.symbol}
                w='36px'
                h='36px'
                border='none'
                alt={coinPair.coinBase.name}
                src={coinPair.coinBase.img}
              />
              <Image
                key={coinPair.coinTarget.symbol}
                w='36px'
                h='36px'
                border='none'
                alt={coinPair.coinTarget.name}
                src={coinPair.coinTarget.img}
              />
            </AvatarGroup>
          )}
          <TemplateText
            pl='10px'
            h='fit-content'
            m='auto'
            fontSize='24px'
            color='text.500'
            txt={coinPair.coinBase.symbol}
          />
          <TemplateText
            h='fit-content'
            m='auto'
            px='5px'
            fontSize='16px'
            color={darkModeColors.text550}
            txt='/'
          />
          <TemplateText
            h='fit-content'
            m='auto'
            px='5px'
            fontSize='16px'
            color={darkModeColors.text550}
            txt={coinPair.coinTarget.symbol}
          />
        </Flex>

        <Flex gap='10px'>
          <Button
            bg={chart === CHART_TYPE.BAR_CHART ? 'bg.300' : 'bg.200'}
            colorScheme='outline'
            borderRadius='8'
            onClick={() => {
              setChart(CHART_TYPE.BAR_CHART);
            }}
          >
            <TemplateText txt='Line Chart' pr='5px' fontSize='14px' />
            <ChartIcon />
          </Button>
          <Button
            bg={chart === CHART_TYPE.LINE_CHART ? 'bg.300' : 'bg.200'}
            colorScheme='outline'
            borderRadius='8'
            onClick={() => {
              setChart(CHART_TYPE.LINE_CHART);
            }}
          >
            <TemplateText txt='Bar Chart' pr='5px' fontSize='14px' />
            <BarchartIcon />
          </Button>
        </Flex>
      </Flex>
      <Flex w='full' justifyContent='space-between'>
        <Box pt='10px'>
          <TemplateText
            color={
              chart === CHART_TYPE.LINE_CHART
                ? getDetailChart?.color
                : 'text.500'
            }
            txt={`$${convertBigNumber(getDetailChart?.y || 0)}`}
            fontSize='24px'
          />
          <TemplateText
            txt={`${formatDateChartVer2(getDetailChart?.x || 0)}`}
            fontSize='14px'
            color={darkModeColors.text550}
          />
        </Box>
        <Box mt='10px'>
          <Flex borderRadius='8px' bg='bg.200' h='fit-content' ml='10px'>
            {chartTime.map((item) => (
              <Button
                colorScheme='outline'
                borderRadius='8px'
                onClick={() => setBarChartSelect(item)}
                bg={barChartSelect.value === item.value ? '#28344b' : 'none'}
                key={uuidv4()}
              >
                <TemplateText
                  pr='5px'
                  color={
                    barChartSelect.value === item.value
                      ? darkModeColors.text555
                      : darkModeColors.text550
                  }
                  fontSize='14px'
                  txt={item.name}
                />
              </Button>
            ))}
          </Flex>
        </Box>
      </Flex>

      <Box w='100%'>
        {chart === CHART_TYPE.LINE_CHART ? (
          <BarChart
            setGetDetailChart={setGetDetailChart}
            coinPair={coinPair}
            barChartSelect={barChartSelect.value}
          />
        ) : (
          <Chart
            setGetDetailChart={setGetDetailChart}
            coinPair={coinPair}
            barChartSelect={barChartSelect.value}
          />
        )}
      </Box>
    </Box>
  );
}
