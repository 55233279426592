import { Box } from '@chakra-ui/react';
import { FONT_FAMILY } from 'constants/app';
import { CoinPair } from 'constants/interface';
import { DetailChart, Point } from 'constants/types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useLineChart } from 'hook/lineCHart';
import { useMemo } from 'react';
import { formatDateChartVer2 } from 'utils/date';
import { convertBigNumber } from 'utils/number';

interface props {
  setGetDetailChart: (value: DetailChart) => void;
  coinPair: CoinPair;
  barChartSelect: string;
}

export default function Chart({
  setGetDetailChart,
  coinPair,
  barChartSelect,
}: props) {
  const { data } = useLineChart(
    coinPair.coinBase.name,
    coinPair.coinTarget.coingeckoSymbol,
    barChartSelect
  );

  const options = useMemo(() => {
    return {
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        align: 'left',
        type: 'datetime',
        lineColor: '#787B80',
        lineWidth: 0,
        tickWidth: 0,
        minPadding: 0,
        maxPadding: 0,
        crosshair: {
          width: 0.5,
          color: '#848E9C',
          dashStyle: 'ShortDash',
        },
        labels: {
          style: {
            color: '#b7becb',
          },
        },
      },
      chart: {
        style: {
          fontFamily: FONT_FAMILY,
        },
      },
      yAxis: [
        {
          opposite: true,
          labels: {
            align: 'left',
            x: 30,
            y: 0,
            style: {
              textTransform: 'uppercase',
              fontWeight: 500,
              color: '#b7becb',
            },
          },
          title: {
            text: '',
            enabled: false,
          },
          offset: 0,
          gridLineWidth: 0,
          crosshair: {
            width: 0.5,
            color: '#848E9C',
            dashStyle: 'ShortDash',
          },
          tooltip: {
            xDateFormat: '',
            enabled: false, // tắt tooltip
          },
          crosshairs: true,
          lineWidth: 0,
          plotLines: [
            {
              color: 'red',
              width: 0.3,
              value: 85000,
              dashStyle: 'ShortDash',
            },
          ],
        },
        {
          title: false,
          visible: false,
        },
      ],
      series: [
        {
          data: data,
          color: '#2669f5',
          name: 'Price',
          lineWidth: 2,
          marker: {
            enabled: false,
          },
          showInLegend: false,
          line: {
            stroke: '#2669f5',
            width: 1,
          },
        },
      ],
      tooltip: {
        formatter() {
          let s = `<b style="font-size:12px"> ${formatDateChartVer2(
            this.x
          )} </b>`;
          this.points.forEach((point: Point) => {
            s += `<br/><b style="font-size:10px; color: ${
              point.series.color
            }; margin-right:5px;">● </b><b style="font-size:11px;margin-left:10px">${
              point.series.name
            }</b>: <span style="font-size:12px">$${convertBigNumber(
              point.y
            )}</span>`;
            setGetDetailChart({
              x: point.x,
              y: point.y,
            });
          });
          return s;
        },
        color: '',
        shared: true,
        crosshairs: false,
        animation: false,
        points: [],
        x: 0,
        borderColor: '#848e9c',
        borderRadius: 10,
        borderWidth: 0,
      },
    };
  }, [data, setGetDetailChart]);

  return (
    <Box>
      <style global='true' jsx='true'>
        {`
          .highcharts-background {
            fill: #09162e !important;
          }
        `}
      </style>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}
