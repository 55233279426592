import { Flex } from '@chakra-ui/react';
import { ROUTER_ABI_TEST } from 'abis/ERC20';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useContractWrite } from 'wagmi';

import DynamicText from './DynamicText';

export default function Banner() {
  const { darkModeColors } = useModeTheme();

  const buyTokensFunction = useContractWrite({
    address: '0xd15d99db76c8f6fd5f94dc9bd6e1a55f60f3fab5',
    abi: ROUTER_ABI_TEST,
    functionName: 'swap',
  });

  const handleSwap = useCallback(async () => {
    const response = await fetch(
      'https://arbitrum.api.0x.org/swap/v1/quote?buyToken=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&sellToken=0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9&sellAmount=100000',
      {
        headers: {
          '0x-api-key': '4949ce65-31d9-4370-bd4c-325f645b76cf',
        },
      }
    );
    const quote = await response.json();

    buyTokensFunction.write({
      args: [
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        '100000',
        quote.data,
      ],
      value: quote.value,
    });
  }, [buyTokensFunction]);

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      bg='bg.100'
      className='maxW'
      pt='90px'
      w='100%'
    >
      <Flex
        direction='column'
        justifyContent='center'
        w={{ base: '100%', lg: '50%' }}
        p={{ base: '0px 16px' }}
      >
        <TemplateText
          txt='Access'
          fontSize={{ base: '35px', md: '56px' }}
          fontWeight={300}
        />
        <Flex alignItems='center' gap='10px' mt={{ base: '30px', lg: '0' }}>
          <TemplateText fontSize={{ base: '35px', md: '56px' }} txt='Every' />

          <DynamicText />
        </Flex>
        <TemplateText
          txt='One-stop decentralized cryto trading solution.'
          mt='16px'
          mb={{ base: '30px', lg: '0' }}
          fontSize={{ base: '15px', md: '18px' }}
          fontWeight={400}
          color={darkModeColors.text550}
        />
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          alignItems='center'
          mt='90px'
          gap='24px'
        >
          <Link to='/trade?feature=swap'>
            <ButtonBase
              w='200px'
              h='60px'
              bg='#2669f5'
              content='Launch App'
              colorText='#eff3fb'
              fsText={18}
              fwText={600}
              borderRadius='12px'
            />
          </Link>
          <ButtonBase
            w='220px'
            h='60px'
            bg='#162239'
            content='Build with MicoDex'
            colorText={darkModeColors.text550}
            fsText={18}
            fwText={600}
            borderRadius='12px'
          />
        </Flex>
      </Flex>
      <Flex
        w='100%'
        justifyContent='center'
        display={{ base: 'flex', lg: 'none' }}
      >
        <ButtonBase
          w='220px'
          h='60px'
          bg='#162239'
          content='Swap TEST'
          colorText={darkModeColors.text550}
          fsText={18}
          fwText={600}
          borderRadius='12px'
          onClick={handleSwap}
        />
        <Link to='/trade?feature=swap'>
          <ButtonBase
            w={{ base: '100%', md: '100%' }}
            h='60px'
            bg='#2669f5'
            content='Launch App'
            colorText='#eff3fb'
            fsText={18}
            fwText={600}
            borderRadius='12px'
          />
        </Link>
      </Flex>
    </Flex>
  );
}
