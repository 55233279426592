import { getTransaction } from 'api/transaction';
import { useQuery } from 'react-query';

export function useTransaction(wallet: string) {
  return useQuery({
    queryKey: ['Transaction', wallet],
    queryFn: () => {
      return getTransaction(wallet);
    },
    refetchInterval: 3000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: !!wallet,
  });
}
