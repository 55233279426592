import { HEIGHT_CHART, WIDTH_CHART } from 'constants/app';
import { FAKE_DATA } from 'data/chart';

export default function ChartTrader() {
  const hUnit =
    HEIGHT_CHART / (FAKE_DATA[FAKE_DATA.length - 1].price - FAKE_DATA[0].price);
  const wUnit =
    WIDTH_CHART /
    (Date.parse(FAKE_DATA[FAKE_DATA.length - 1].datetime) -
      Date.parse(FAKE_DATA[0].datetime));

  let sum = 0;

  for (const item of FAKE_DATA) {
    sum += item.price;
  }

  const averagePrice = sum / FAKE_DATA.length;
  const prices = FAKE_DATA.map((item) => ({
    ...item,
    price: Math.abs(item.price - averagePrice),
    datetime: Date.parse(item.datetime) - Date.parse(FAKE_DATA[0].datetime),
  }));

  return (
    <svg
      xmlnsXlink='http://www.w3.org/1999/xlink'
      height='80px'
      version='1.1'
      viewBox='0 0 164 100'
      width='164px'
      x='0px'
      y='0px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <clipPath id='clip-1642496000'>
          <rect height='80' width='164' x='0' y='0' />
        </clipPath>
      </defs>
      <rect
        height='80'
        style={{ fill: 'rgb(255,255,255)', fillOpacity: '0', stroke: 'none' }}
        width='164'
        x='0'
        y='0'
      />
      <rect
        height='80'
        style={{ fill: 'rgb(255,255,255)', fillOpacity: '0', stroke: 'none' }}
        width='164'
        x='0'
        y='0'
      />
      <g clip-path='url(#clip-1642496000)'>
        {prices.map(
          (item, index) =>
            index != prices.length - 1 && (
              <line
                strokeMiterlimit={10}
                strokeWidth={2}
                stroke='#2669f5'
                strokeLinecap='round'
                x1={prices[index].datetime * wUnit}
                x2={prices[index + 1].datetime * wUnit}
                y1={prices[index]?.price * hUnit}
                y2={prices[index + 1].price * hUnit}
              />
            )
        )}
      </g>
    </svg>
  );
}
