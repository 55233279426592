import Arbitrum from 'components/svg/Arbitrum';
import Aurora from 'components/svg/Aurora';
import Avalanche from 'components/svg/Avalanche';
import BNB from 'components/svg/BNB';
import BobaChains from 'components/svg/BobaChains';
import FantomChains from 'components/svg/FantomChains';
import GnosisChains from 'components/svg/GnosisChains';
import Harmony from 'components/svg/Harmony';
import HecoChains from 'components/svg/HecoChains';
import Moonriver from 'components/svg/Moonriver';
import OKC from 'components/svg/OKC';
import Ont from 'components/svg/Ont';
import Op from 'components/svg/Op';
import Polygon from 'components/svg/Polygon';
import SepoliaIcon from 'components/svg/SepoliaIcon';
import SolanaChains from 'components/svg/SolanaChains';
import Terra from 'components/svg/Terra';
import Tron from 'components/svg/Tron';

export const LIST_CHAIN = [
  {
    name: 'Arbitrum',
    icon: <Arbitrum />,
  },
  {
    name: 'Sepolia',
    icon: <SepoliaIcon />,
  },
  {
    name: 'BNB Chain',
    icon: <BNB />,
  },
  {
    name: 'Solana',
    icon: <SolanaChains />,
  },
  {
    name: 'Polygon',
    icon: <Polygon />,
  },
  {
    name: 'Avalanche',
    icon: <Avalanche />,
  },
  {
    name: 'Fantom',
    icon: <FantomChains />,
  },
  {
    name: 'Optimism',
    icon: <Op />,
  },
  {
    name: 'Aurora',
    icon: <Aurora />,
  },

  {
    name: 'Harmony',
    icon: <Harmony />,
  },

  {
    name: 'Terra',
    icon: <Terra />,
  },
  {
    name: 'Gnosis',
    icon: <GnosisChains />,
  },

  {
    name: 'Boba',
    icon: <BobaChains />,
  },
  {
    name: 'Ontology',
    icon: <Ont />,
  },
  {
    name: 'Tron',
    icon: <Tron />,
  },
  {
    name: 'HECO',
    icon: <HecoChains />,
  },
  {
    name: 'OKC',
    icon: <OKC />,
  },
  {
    name: 'Moonriver',
    icon: <Moonriver />,
  },
];
