import { getFuture } from 'api/future';
import { useQuery } from 'react-query';

export function useFuture(wallet: string) {
  return useQuery({
    queryKey: ['future', wallet],
    queryFn: () => {
      return getFuture(wallet);
    },
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: 0,
    enabled: !!wallet,
  });
}
