import { Box, Flex } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import LimitOrderBuy from 'components/common/LimitOrderBuy';
import LimitOrderSell from 'components/common/LimitOrderSell';
import LimitPrice from 'components/common/LimitPrice';
import TemplateText from 'components/common/Text/TemplateText';
import Warning from 'components/common/Warning';
import ModalSelectToken from 'components/ModalSelectToken';
import ArrowChange from 'components/svg/ArrowChange';
import { CoinPair } from 'constants/interface';
import useVisible from 'hook/useVisible';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { convertBigNumber } from 'utils/number';

interface Props {
  coinPair: CoinPair;
  setCoinPair: (value: CoinPair) => void;
}

export default function LimitOrder({ coinPair, setCoinPair }: Props) {
  const [totalSell, setTotalSell] = useState(0);
  const [limitPrice, setLimitPrice] = useState(
    convertBigNumber(
      coinPair.coinTarget.price != 0
        ? coinPair.coinBase.price / coinPair.coinTarget.price
        : Infinity
    )
  );

  const camelCase = useMemo(() => {
    const price = convertBigNumber(
      coinPair.coinTarget.price != 0
        ? coinPair.coinBase.price / coinPair.coinTarget.price
        : Infinity
    );
    const slidingPrice = price - limitPrice;
    return {
      isShow: limitPrice < price,
      PCT: new BigNumber((slidingPrice * 100) / price).precision(2).toNumber(),
    };
  }, [limitPrice, coinPair]);

  const [TokenSell, setTokenSell] = useState(coinPair.coinBase);
  const [TokenBuy, setTokenBuy] = useState(coinPair.coinTarget);

  const outPut = useMemo(() => {
    return new BigNumber(totalSell * limitPrice).precision(6);
  }, [totalSell, limitPrice]);

  const onSwap = useCallback(() => {
    setCoinPair({
      coinBase: coinPair.coinTarget,
      coinTarget: coinPair.coinBase,
    });
  }, [coinPair.coinBase, coinPair.coinTarget, setCoinPair]);

  const detailModalSell = useVisible();
  const detailModalBuy = useVisible();

  useEffect(() => setLimitPrice(coinPair.coinBase.price), [coinPair]);
  useEffect(
    () =>
      setLimitPrice(
        convertBigNumber(
          coinPair.coinTarget.price != 0
            ? coinPair.coinBase.price / coinPair.coinTarget.price
            : Infinity
        )
      ),
    [coinPair]
  );

  return (
    <Box position='relative' mt={{ base: '24px', md: '48px', lg: '24px' }}>
      <LimitOrderSell
        setTotalSell={setTotalSell}
        totalSell={totalSell}
        coinBase={TokenSell}
        onOpen={detailModalSell.show}
      />
      <LimitPrice
        coinPair={coinPair}
        limitPrice={limitPrice}
        setLimitPrice={setLimitPrice}
      />
      <LimitOrderBuy
        coinTarget={TokenBuy}
        value={outPut.toNumber()}
        onOpen={detailModalBuy.show}
        camelCase={camelCase}
      />
      <Warning
        coinSymbol={coinPair.coinBase.symbol}
        isShow={camelCase.isShow}
        PCT={coinPair.coinTarget.price != 0 ? camelCase.PCT : Infinity}
      />
      <Box
        position='absolute'
        left='calc(50% - 16px)'
        top='255px'
        onClick={onSwap}
        cursor='pointer'
        bg='bg.200'
        w='40px'
        h='40px'
        borderRadius='50%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        border='1px solid #28344b'
        box-shadow='0 2px 6px 0 rgba(21,20,44,0.38)'
      >
        <ArrowChange color='#b7becb' />
      </Box>
      <ModalSelectToken
        setCrossToken={setTokenSell}
        isOpen={detailModalSell.visible}
        onClose={detailModalSell.hide}
      />
      <ModalSelectToken
        setCrossToken={setTokenBuy}
        isOpen={detailModalBuy.visible}
        onClose={detailModalBuy.hide}
      />
      {/* <ButtonAction isEnterAmount={totalSell > 0} /> */}
      <Flex pt='20px' justifyContent='space-between'>
        <TemplateText
          fontSize='14px'
          color='text.400'
          txt={`${coinPair.coinBase.symbol} sell price`}
          pr='5px'
        />
        <Flex>
          <TemplateText
            fontSize='14px'
            color='text.400'
            txt={`$ ${coinPair.coinBase.price.toString()}`}
            pr='5px'
          />
          <TemplateText
            fontSize='14px'
            txt={`${limitPrice} ${coinPair.coinTarget.symbol}`}
            pr='5px'
          />
        </Flex>
      </Flex>
      <Flex pt='10px' justifyContent='space-between'>
        <TemplateText
          fontSize='14px'
          color='text.400'
          txt={`${coinPair.coinTarget.symbol} sell price`}
          pr='5px'
        />
        <Flex>
          <TemplateText
            fontSize='14px'
            color='text.400'
            txt={`$ ${coinPair.coinTarget.price.toString()}`}
            pr='5px'
          />
          <TemplateText
            fontSize='14px'
            txt={`${convertBigNumber(1 / limitPrice)} ${
              coinPair.coinBase.symbol
            }`}
            pr='5px'
          />
        </Flex>
      </Flex>
    </Box>
  );
}
