import { Box, Image, Link, SimpleGrid } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import TitleSection from 'components/common/TitleSection';
import { PRODUCTS } from 'data/introduction';
import useModeTheme from 'hook/colorDarkMode';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Introduction() {
  const { darkModeColors } = useModeTheme();

  return (
    <Box p={{ base: '0px 16px', xl: 'unset' }} className='maxW'>
      <TitleSection
        title='MicoDex Products'
        description='We proudly provide cutting edge protocols for everyone to use with the hopes of providing the most dynamic trading platform in DeFi'
      />

      <SimpleGrid
        columns={2}
        gap={{ base: '10px', md: '32px' }}
        mt='48px'
        mb={{ base: '0px', xl: '100px' }}
      >
        {PRODUCTS.map((value) => (
          <Link
            cursor='pointer'
            borderRadius='16px'
            p='3px'
            _hover={{
              backgroundImage:
                '-webkit-linear-gradient(293deg,hsla(0,0%,95.7%,.11),rgba(215,214,224,.07),rgba(152,151,162,.19))',
            }}
            href={value.href}
            key={uuidv4()}
          >
            <Box
              bg='linear-gradient(45deg,#182234,#09162e)'
              w='100%'
              h='100%'
              p='20px 0'
              borderRadius='16px'
            >
              <Image
                p={{ base: '11px 0 0 15px', xl: '26px 0 0 20px' }}
                w={{ base: '70px', xl: '100px' }}
                h={{ base: '70px', xl: '100px' }}
                src={value.img}
              />

              <TemplateText
                m={{ base: '0 0 0 11px', xl: '8px 24px' }}
                fontSize={{ base: '16px', xl: '24px' }}
                fontWeight={600}
                txt={value.name}
              />

              <TemplateText
                w={{ base: '90%', xl: '550px' }}
                m={{ base: '6px 0 6px 11px', xl: ' 0 0 0 24px' }}
                fontSize={{ base: '13.333px', xl: '14px' }}
                color={darkModeColors.text550}
                txt={value.description}
                className='sub-text'
              />
            </Box>
          </Link>
        ))}
      </SimpleGrid>
    </Box>
  );
}
