import { Box, Flex, Icon, useMediaQuery } from '@chakra-ui/react';
import Gas from 'components/svg/Gas';
import { ConnectKitButton } from 'connectkit';
import { FIX_FOOTER } from 'data/footer';
import { BiUserCircle } from 'react-icons/bi';
import { MdLightMode } from 'react-icons/md';

import ButtonBase from '../Buttons/ButtonBase';
import TemplateText from '../Text/TemplateText';

export default function FooterTrade() {
  const [isDesktop] = useMediaQuery('(min-width: 1024px)');
  return (
    <Flex
      w='100%'
      p='8px 16px'
      bg={isDesktop ? 'bg.300' : 'unset'}
      position='fixed'
      bottom='0'
      justifyContent='space-between'
      alignItems='center'
    >
      {isDesktop ? (
        <>
          <Flex gap='10px' alignItems='center'>
            <Gas />
            <TemplateText txt='18.38~18.52' />
            <TemplateText txt='Gwei' color='text.400' />
          </Flex>
          <Flex alignItems='center' gap='30px'>
            {FIX_FOOTER.map((item) => (
              <Box key={item.name}>{item.icon}</Box>
            ))}
          </Flex>
        </>
      ) : (
        <>
          <Box
            bg='bg.300'
            h='40px'
            w='40px'
            textAlign='center'
            borderRadius='10px'
            cursor='pointer'
          >
            <Icon
              as={MdLightMode}
              _hover={{ color: 'text.500' }}
              color='text.400'
              ml='2px'
              h='25px'
              w='25px'
              mt='7px'
            />
          </Box>

          <ConnectKitButton.Custom>
            {({
              isConnected,
              isConnecting,
              show,
              truncatedAddress,

              ensName,
            }) => {
              return (
                <ButtonBase
                  onClick={show}
                  w={isConnected ? '168px' : '150px'}
                  h='44px'
                  p={isConnected ? '0 10px' : '0 28px'}
                  iconLeft={
                    isConnected && (
                      <Icon
                        h='20px'
                        w='20px'
                        color='text.500'
                        as={BiUserCircle}
                      />
                    )
                  }
                  bg={isConnected ? 'bg.200' : '#2669f5'}
                  content={
                    isConnected ? ensName ?? truncatedAddress : 'Connect Wallet'
                  }
                  colorText='text.500'
                  fsText={14}
                  isLoading={isConnecting}
                  loadingText='Connecting'
                  _loading={{ color: 'text.500', display: 'flex' }}
                  fwText={400}
                  borderRadius='10px'
                  display={{ base: 'none', xl: 'block' }}
                />
              );
            }}
          </ConnectKitButton.Custom>
        </>
      )}
    </Flex>
  );
}
