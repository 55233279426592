import { Box } from '@chakra-ui/react';
import { FONT_FAMILY } from 'constants/app';
import { CoinPair } from 'constants/interface';
import { DetailChart, Point } from 'constants/types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useBarChart } from 'hook/barChart';
import { formatDateChartVer2 } from 'utils/date';
import React, { useMemo } from 'react';
import { formatDateChart } from 'utils/date';

interface props {
  setGetDetailChart: (value: DetailChart) => void;
  coinPair: CoinPair;
  barChartSelect: string;
}

export default function BarChart({
  setGetDetailChart,
  coinPair,
  barChartSelect,
}: props) {
  const { data } = useBarChart(
    coinPair.coinBase.name,
    coinPair.coinTarget.coingeckoSymbol,
    barChartSelect
  );

  const options = useMemo(() => {
    return {
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      chart: {
        style: {
          fontFamily: FONT_FAMILY,
        },
      },
      xAxis: {
        align: 'left',
        type: 'datetime',
        lineColor: '#787B80',
        lineWidth: 0,
        tickWidth: 0,
        minPadding: 0,
        maxPadding: 0,
        crosshair: {
          width: 0.5,
          color: '#848E9C',
          dashStyle: 'ShortDash',
        },
        labels: {
          style: {
            color: '#b7becb',
          },
        },
      },

      yAxis: {
        lineWidth: 0,
        plotLines: [
          {
            color: '#848E9C',
            width: 0.3,
            value: 85000,
            dashStyle: 'ShortDash',
          },
        ],
        tooltip: {
          xDateFormat: '',
          enabled: false, // tắt tooltip
        },
        crosshair: {
          width: 0.5,
          color: '#848E9C',
          dashStyle: 'ShortDash',
        },
        gridLineWidth: 0,
        opposite: true,
        labels: {
          align: 'left',
          x: 30,
          y: 0,
          style: {
            textTransform: 'uppercase',
            fontWeight: 500,
            color: '#b7becb',
          },
        },
        title: {
          text: '',
          enabled: false,
        },
        offset: 0,
      },

      series: [
        {
          marker: {
            enabled: false,
          },
          showInLegend: false,
          line: {
            stroke: '#ff6b18',
            width: 1,
          },
          dataGrouping: {
            enabled: false,
            forced: true,
            units: [['minute', [5]]],
          },
          color: '#f84960',
          name: 'Price',
          lineColor: '#f84960',
          upColor: '#83c259',
          upLineColor: '#83c259',
          type: 'candlestick',
          pointPlacement: 'between',
          data: data,
        },
      ],
      tooltip: {
        formatter() {
          let s = `<b style="font-size:12px"> ${formatDateChartVer2(
            this.x
          )} </b>`;
          this.points.forEach((point: Point) => {
            s += `<br/><b style="font-size:10px; color: ${
              point.series.color
            }; margin-right:5px;">● </b><b style="font-size:11px;margin-left:10px">${
              point.series.name
            }</b>: <span style="font-size:12px">$${point.y.toLocaleString()}</span>`;
            setGetDetailChart({
              x: point.x,
              y: point.y,
              color: point.color,
            });
          });
          return s;
        },
        color: '',
        shared: true,
        crosshairs: false,
        animation: false,
        points: [],
        x: 0,
        borderColor: '#848e9c',
        borderRadius: 10,
        borderWidth: 0,
      },
    };
  }, [data, setGetDetailChart]);
  return (
    <Box>
      <style global='true' jsx='true'>
        {`
          .highcharts-background {
            fill: #09162e !important;
          }
        `}
      </style>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}
