import CustomControlIcon from 'components/svg/CustomControlIcon';
import SelectTrader from 'components/svg/SelectTrader';
import StartIcon from 'components/svg/StartIcon';
import { FAQContentType } from 'constants/types';

export const LIST_TABS = [
  {
    label: 'Futures',
    url: '/copytrading/futures',
    value: 'futures',
  },
  {
    label: 'Spot',
    url: '/copytrading/spot',
    value: 'spot',
  },
];

export const FILTER_ALL_TRADERS = [
  { name: 'Ranking', label: 'ranking' },
  { name: 'Follower', label: 'followers' },

  { name: 'ROI', label: 'roi' },
  { name: 'Total P&L', label: 'total p&l' },

  { name: 'Copiers PNL', label: 'Copiers PNL' },
  { name: 'AUM', label: 'aum' },
];

export const TOP_TRADERS = [
  {
    key: 'profit_rate',
    showColumnDesc: 'Highest ROI',
    showColumnIllustrate: 'Trader with the highest ROI',
    ShowColumnValue: [
      {
        traderNickname: 'Lamborghini',
        followCount: 1000,
        maxfollowCount: 1000,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 777,
        maxfollowCount: 1000,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 220,
        maxfollowCount: 220,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 123,
        maxfollowCount: 1000,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 500,
        maxfollowCount: 500,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 999,
        avt: '/avt.png',
        maxfollowCount: 1000,
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
    ],
  },
  {
    key: 'sort_total_income',
    showColumnDesc: 'Highest PnL',
    showColumnIllustrate: 'Trader with the highest PnL',
    ShowColumnValue: [
      {
        traderNickname: 'hagesenseii',
        followCount: 413,
        maxfollowCount: 1000,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '-149.03',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$293421.44',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$151422.90',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$549902.31',
          },
        ],
      },
      {
        traderNickname: 'Judit',
        followCount: 777,
        maxfollowCount: 1000,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '160.60',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$232624.06',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$7835.70',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$47375.82',
          },
        ],
      },
      {
        traderNickname: 'Lotus',
        followCount: 6,
        maxfollowCount: 500,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 123,
        maxfollowCount: 1000,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 500,
        maxfollowCount: 500,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 999,
        avt: '/avt-highest.png',
        maxfollowCount: 1000,
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
    ],
  },
  {
    key: 'sort_total_follow_profit',
    showColumnDesc: 'Followers highest PnL',
    showColumnIllustrate: 'Trader with the highest follower PnLs',
    ShowColumnValue: [
      {
        traderNickname: 'hagesenseii',
        followCount: 413,
        maxfollowCount: 1000,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '-149.03',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$293421.44',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$151422.90',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$549902.31',
          },
        ],
      },
      {
        traderNickname: 'Judit',
        followCount: 777,
        maxfollowCount: 1000,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '160.60',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$232624.06',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$7835.70',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$47375.82',
          },
        ],
      },
      {
        traderNickname: 'Lotus',
        followCount: 6,
        maxfollowCount: 500,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 123,
        maxfollowCount: 1000,
        avt: '/avt.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 500,
        maxfollowCount: 500,
        avt: '/avt-highest.png',
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
      {
        traderNickname: 'Lamborghini',
        followCount: 999,
        avt: '/avt-highest.png',
        maxfollowCount: 1000,
        itemVolist: [
          {
            colorColumn: true,
            percentColumn: true,
            showColumnDesc: 'ROI',
            showColumnValue: '4712.80',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Total P&L',
            showColumnValue: '$10559.95',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'Copiers PNL',
            showColumnValue: '$203270.05',
          },
          {
            colorColumn: false,
            percentColumn: false,
            showColumnDesc: 'AUM',
            showColumnValue: '$2066445.96',
          },
        ],
      },
    ],
  },
];

export const ALL_TRADERS = [
  {
    traderNickname: 'Lamborghini',
    followCount: 1000,
    maxfollowCount: 1000,
    avt: '/avt.png',
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
  {
    traderNickname: 'Ducati',
    followCount: 777,
    maxfollowCount: 1000,
    avt: '/avt.png',
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
  {
    traderNickname: 'BMW',
    followCount: 220,
    maxfollowCount: 220,
    avt: '/avt.png',
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
  {
    traderNickname: 'Toyota',
    followCount: 123,
    maxfollowCount: 1000,
    avt: '/avt.png',
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
  {
    traderNickname: 'Heniken',
    followCount: 500,
    maxfollowCount: 500,
    avt: '/avt.png',
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
  {
    traderNickname: 'Tiger',
    followCount: 999,
    avt: '/avt.png',
    maxfollowCount: 1000,
    itemVolist: [
      {
        colorColumn: true,
        percentColumn: true,
        showColumnDesc: 'ROI',
        showColumnValue: '4712.80',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Total P&L',
        showColumnValue: '$10559.95',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'Copiers PNL',
        showColumnValue: '$203270.05',
      },
      {
        colorColumn: false,
        percentColumn: false,
        showColumnDesc: 'AUM',
        showColumnValue: '$2066445.96',
      },
    ],
  },
];

export const GAINS_UP = [
  {
    icon: <SelectTrader />,
    title: 'Select traders',
    description:
      'Choose from over 80,000 traders with transparent trading records and filter by Pnl, ROI and more.',
  },
  {
    icon: <CustomControlIcon />,
    title: 'Set your controls',
    description:
      'Choose which trading pairs to follow, specity your order amounts, and set up your TP/SL prices',
  },
  {
    icon: <StartIcon />,
    title: 'Start copying!',
    description: `Once you're all set up, the trader's buy and sell orders will be automatically followed in real time. Close an order yourself or let your trader handle it.`,
  },
];

export const FAQContent: Array<FAQContentType> = [
  {
    question: 'What are the advantages of Bitget copy trading?',
    answer: [
      {
        title: 'Safety and reliability',
        content: [
          {
            firstparagraph:
              'All traders have been stricly by Bitget. Their trading records are visible and their staistics are transprent and clear. Hundreds of high-quality strategists are at your disposal.',
            secondparagraph: `All trader statistics come from realtime data of their trades on Bitget, which makes sure you're getting authentic, timely and transparent information for reference. All records of a trader's position and orders are traceble through our system, so their followers can feel comfortable when copying a trader or strategist`,
          },
        ],
      },
      {
        title: 'Automated returns',
        content: [
          {
            firstparagraph: `Follow multiple traders at the same time to maximize your profits. Copy trading lets you automatically enter and exit at the same time as traders you follow, so you don't need to keep a close eye on the market. Simply following elite strategists gives you the opportunity to enjoy higher returns with fewer obstacles.`,
            secondparagraph: `Your copy trading dashboard showing realized and unrealized PnL for every trade is easy to follow and updated in realtime.`,
            thirdparagraph: `Customizing how you copy trade is simple and intuitive. Modify the amount and contracts you want to copy at any time, or stop copying your trade to take profit on a position whenever you want.`,
          },
        ],
      },
    ],
  },
  {
    question: 'How do I copy trade?',
    answer: [
      {
        content: [
          {
            firstparagraph:
              'Simply select a trader, set up your controls and track the copy trades at your leisure. For more detail:',
            isdetail: true,
          },
        ],
      },
    ],
  },
  {
    question: 'How do I view my copy trades?',
    answer: [
      {
        content: [
          {
            firstparagraph:
              'Select My trades on the main screen of copy trading to view detailed data on all your copy trades, including your total capital (USDT), net profit, profit on specific trades, and information on trades you follow.',
          },
        ],
      },
    ],
  },
  {
    question: 'Why did my order fail?',
    answer: [
      {
        content: [
          {
            firstparagraph: `There are a number of reasons why a follower might failto copy a trader's order including, but not limited to: the trader has canceled the order, the trader has removed the follower from their list, the follower's futures account has insufficient funds, or the follower has reached their copy  order limits on the trading pair. Followers will be notified when an order has not been executed or if they are no longer following a trader.`,
          },
        ],
      },
    ],
  },
  {
    question: 'How do I check my copy order limits on trading pairs?',
    answer: [
      {
        content: [
          {
            isdetail: true,
          },
        ],
      },
    ],
  },
  {
    question: 'How do I check which trading pairs I can copy trade with?',
    answer: [
      {
        content: [
          {
            isdetail: true,
          },
        ],
      },
    ],
  },
];
