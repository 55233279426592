import { Box, Flex, Image } from '@chakra-ui/react';
import ArrowBottomMenu from 'components/svg/ArrowBottomMenu';
import { CoinType } from 'constants/interface';
import InputChange from 'pages/Swap/Left/MoneyConvertBox/InputChange';

import BigNumber from 'bignumber.js';
import ModalSelectToken from 'components/ModalSelectToken';
import useVisible from 'hook/useVisible';
import TemplateText from '../Text/TemplateText';

interface Props {
  setConvertCoin: (value: CoinType) => void;
  isOpen?: boolean;
  onClose?: () => void;
  setValue: (value: number) => void;
  value: number;
  convertCoin: CoinType;
  balance: number;
}

export default function WrapAddLiquidity({
  setConvertCoin,
  setValue,
  value,
  convertCoin,
  balance,
}: Props) {
  const detailModal = useVisible();

  return (
    <>
      <Box
        w='100%'
        bg='bg.300'
        borderRadius='10px'
        p='16px 20px 16px 16px'
        _focusWithin={{ border: '1px solid #2669f5' }}
      >
        <Flex alignItems='center' justifyContent='end'>
          <Flex gap='20px'>
            <TemplateText color='text.400' txt={`Balance: ${balance}`} />
          </Flex>
        </Flex>
        <Flex alignContent='center' justifyContent='end' mt='5px'>
          <Box as='button' onClick={() => setValue(balance)}>
            <TemplateText
              borderRadius='4px'
              px='7px'
              _hover={{ bg: 'bg.200', color: '#ea5a0a' }}
              txt='Max'
              color='#fa3961'
              fontSize='11px'
            />
          </Box>
          <Box
            as='button'
            onClick={() =>
              setValue(
                BigNumber(Number(balance || 0))
                  .dividedBy(2)
                  .dp(6, BigNumber.ROUND_DOWN)
                  .toNumber()
              )
            }
          >
            <TemplateText
              borderRadius='4px'
              px='7px'
              _hover={{ bg: 'bg.200', color: '#ea5a0a' }}
              txt='Half'
              color='#fa3961'
              fontSize='11px'
            />
          </Box>
        </Flex>
        <Flex mt='10px' alignContent='center' justifyContent='space-between'>
          <Flex
            w='30%'
            onClick={detailModal.show}
            key={convertCoin.name}
            alignItems='center'
            borderRadius={10}
            cursor='pointer'
          >
            <Flex alignItems='center' justifyContent='center' gap='10px'>
              <Flex alignItems='center' gap='10px'>
                <Image w='30px' h='30px' src={convertCoin.img} />
                <TemplateText fontWeight={400} txt={convertCoin.symbol} />
              </Flex>

              <ArrowBottomMenu w='15px' h='15px' />
            </Flex>
          </Flex>

          <InputChange setValueInput={setValue} defaultValue={value} />
        </Flex>
      </Box>
      <ModalSelectToken
        setCrossToken={setConvertCoin}
        isOpen={detailModal.visible}
        onClose={detailModal.hide}
      />
    </>
  );
}
