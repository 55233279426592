import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/react';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import ArrowDown from 'components/svg/ArrowDown';
import { REFER_OTHERS } from 'data/referral';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function ReferralPage() {
  return (
    <Box>
      <Flex
        w='100%'
        className='maxW'
        alignItems='center'
        p={{ base: '0px 16px', md: '0px 50px 50px 50px', xl: 'unset' }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent='center'
      >
        <Box w={{ base: '100%', md: '50%', xl: '40%' }} mt='60px'>
          <TemplateText
            display={{ base: 'none', md: 'block' }}
            txt='Referral Program'
            fontSize='46px'
          />
          <TemplateText
            display={{ base: 'block', md: 'none' }}
            txt='Share your love'
            fontWeight={400}
            fontSize='32px'
          />

          <TemplateText
            fontSize={{ base: '14px', md: '16px' }}
            color='text.400'
            fontWeight={400}
            w={{ base: '100%', md: '90%', xl: '63%' }}
            txt={`Share invitarion links to your friend to earn up to 25% fee rebates! Through MicoDex's  program. You will receive free PNL worth 15% of your referee's gas fees! Your referral will also receive a 10% rebate on all gas spent on MicoDex. Our referral program is live NOW on Ethereum. BNB Chain. Avalanche. Fantom and Polygon!`}
          />
          <Flex
            display={{ base: 'none', md: 'flex' }}
            alignItems='center'
            mt='90px'
            gap='24px'
          >
            <ButtonBase
              w='172px'
              h='44px'
              bg='#2669f5'
              content='Invite now'
              colorText='text.500'
              fsText={18}
              fwText={600}
              borderRadius='12px'
            />
            <ButtonBase
              w='172px'
              h='50px'
              bg='bg.200'
              content='Read more'
              colorText='text.400'
              fsText={18}
              fwText={600}
              borderRadius='12px'
            />
          </Flex>
        </Box>
        <Box
          mt={{ base: '20px', md: 'unset' }}
          w={{ base: '100%', md: '50%', xl: '60%' }}
        >
          <Image w='100%' src='./banner/banner_referral.png' />
        </Box>
        <Flex
          display={{ base: 'flex', md: 'none' }}
          alignItems='center'
          justifyContent='space-around'
          m='90px 0'
          w='100%'
          gap='24px'
        >
          <ButtonBase
            w={{ base: '150px', md: '172px' }}
            h={{ base: '50px', md: '50px' }}
            bg='#2669f5'
            content='Invite now'
            colorText='text.500'
            fsText={16}
            fwText={400}
            borderRadius='12px'
          />
          <ButtonBase
            w={{ base: '150px', md: '172px' }}
            h={{ base: '50px', md: '50px' }}
            bg='bg.200'
            content='Read more'
            colorText='text.400'
            fsText={16}
            fwText={400}
            borderRadius='12px'
          />
        </Flex>
      </Flex>

      <Box bg='bg.200' p={{ base: '20px 0', md: '120px 0' }}>
        <Box className='maxW'>
          <Box position='relative' p={{ base: '0 16px', xl: 'unset' }}>
            <TemplateText
              textAlign={{ base: 'center', md: 'unset' }}
              fontWeight={400}
              fontSize={{ base: '24px', md: '44px' }}
              txt='How to refer others:'
            />
            <Box position='absolute' top='-70px' left='-85px'>
              <Image w='50%' src='./imgtite.png' />
            </Box>
          </Box>

          <SimpleGrid
            p={{ base: '0 16px', xl: 'unset' }}
            columns={{ base: 1, md: 2, xl: 3 }}
            gap={{ base: 'unset', md: '20px' }}
            mt={{ base: '20px', md: '60px' }}
          >
            {REFER_OTHERS.map((item, index) => (
              <React.Fragment key={uuidv4()}>
                <Box
                  width='100%'
                  h={{ base: 'unset', md: '213px' }}
                  bg='bg.300'
                  p={{ base: '16px', md: '23px 30px 40px 29px' }}
                  borderRadius='20px'
                  position='relative'
                >
                  <TemplateText
                    fontWeight={400}
                    fontSize={{ base: '32px', xl: '40px' }}
                    color='text.400'
                    txt={item.way}
                    display={{ base: 'none', md: 'block' }}
                  />
                  <Box display={{ base: 'block', md: 'none' }}>{item.img}</Box>

                  <TemplateText
                    fontWeight={400}
                    mt={{ base: '-30px', md: 'unset' }}
                    display={{
                      base:
                        item.label === 'Get your referral link'
                          ? 'none'
                          : 'block',
                      md: 'block',
                    }}
                    fontSize={{ base: '16px', md: '18px', xl: '24px' }}
                    txt={item.label}
                  />
                  {item.label === 'Get your referral link' && (
                    <TemplateText
                      fontWeight={400}
                      mt={{ base: '-30px', md: 'unset' }}
                      fontSize={{ base: '16px', md: '18px', xl: '24px' }}
                      txt='Aggregator'
                    />
                  )}
                  <TemplateText
                    maxW='260px'
                    fontWeight={400}
                    color='text.400'
                    fontSize={{ base: '12px', md: '14px' }}
                    txt={item.description}
                  />
                  <Box
                    position='absolute'
                    bottom={{ base: '10px', md: '20px' }}
                    right={{ base: '10px', md: '20px' }}
                  >
                    {item.icon}
                  </Box>
                </Box>
                {REFER_OTHERS.length - 1 != index && (
                  <Flex
                    display={{ base: 'flex', md: 'none' }}
                    justifyContent='center'
                  >
                    <ArrowDown />
                  </Flex>
                )}
              </React.Fragment>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}
