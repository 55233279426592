import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import { ERC20_ABI, GLP_MANAGER_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import WrapAddLiquidity from 'components/common/WrapAddLiquidity';
import { ConnectKitButton } from 'connectkit';
import { CoinType } from 'constants/interface';
import { USDT } from 'data/cross';
import { useCoins } from 'hook/coin';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GLP_ADDRESS, GLP_MANAGER_ADDRESS } from 'utils/ERC20';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

import CalculationAddLiquidity from './CalculationAddLiquidity';
import Setting from '../Setting';

export default function AddLiquidity() {
  const [convertCoin, setConvertCoin] = useState<CoinType>(USDT);
  const [valueMoney, setValueMoney] = useState(1);
  const [balance, setBalance] = useState(0);
  const [balanceGLP, setBalanceGLP] = useState(0);
  const [timeInterval, setTimeInterval] = useState(true);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [valueTolerance, setValueTolerance] = useState(3);

  const { isConnected, address } = useAccount();

  const { data: balanceToken } = useBalance({
    address: address,
    token: `0x${convertCoin.address}`,
    enabled: isConnected && timeInterval,
  });

  const { data: balanceGLP_Token } = useBalance({
    address: address,
    token: GLP_ADDRESS,
    enabled: isConnected && timeInterval,
  });

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  const { data: dataTo, isLoading: isLoadingTo } = useCoins(
    convertCoin.symbol,
    isConnected
  );

  const coinPrice = useMemo(
    () =>
      !isLoadingTo && isConnected
        ? dataTo?.RAW[convertCoin.symbol]?.USD.PRICE
        : 0,
    [isLoadingTo, dataTo?.RAW, convertCoin.symbol, isConnected]
  );

  const isInfinity = useMemo(
    () => valueMoney <= balance,
    [valueMoney, balance]
  );

  const { data: allowance }: any = useContractRead({
    address: `0x${convertCoin.address}`,
    abi: ERC20_ABI,
    functionName: 'allowance',
    enabled: isConnected && timeInterval,
    args: [address, GLP_MANAGER_ADDRESS],
  });

  const LinkPrice = BigNumber(10).pow(18).toNumber();

  const allowanceNumber: BigNumber = useMemo(() => {
    if (allowance) {
      return BigNumber(allowance.toString() / LinkPrice);
    }
    return BigNumber(0);
  }, [LinkPrice, allowance]);

  const { config: configApprove } = usePrepareContractWrite({
    address: `0x${convertCoin.address}`,
    abi: ERC20_ABI,
    functionName: 'approve',
    enabled: isConnected && timeInterval,
    args: [GLP_MANAGER_ADDRESS, BigNumber(1000 * 18)],
  });

  const { write: writeApprove } = useContractWrite(configApprove);

  const isApprove = useMemo(() => {
    return valueMoney <= allowanceNumber.toNumber();
  }, [valueMoney, allowanceNumber]);

  const minUSDG = useMemo(
    () => (1 - valueTolerance / 100) * valueMoney * coinPrice * LinkPrice,
    [valueMoney, coinPrice, LinkPrice, valueTolerance]
  );

  const { data: aumInUSDG }: any = useContractRead({
    address: GLP_MANAGER_ADDRESS,
    abi: GLP_MANAGER_ABI,
    functionName: 'getAumInUsdg',
    enabled: isConnected && timeInterval,
    args: [true],
  });

  const { data: glpSupply }: any = useContractRead({
    address: GLP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'totalSupply',
    enabled: isConnected && timeInterval,
  });

  const minGLP = useMemo(
    () =>
      BigNumber(minUSDG)
        .multipliedBy(glpSupply)
        .dividedBy(aumInUSDG)
        .toNumber(),
    [minUSDG, glpSupply, aumInUSDG]
  );

  const { config: configAddLq, isSuccess } = usePrepareContractWrite({
    address: GLP_MANAGER_ADDRESS,
    abi: GLP_MANAGER_ABI,
    functionName: 'addLiquidity',
    enabled: isConnected && timeInterval && valueMoney > 0 && isApprove,
    args: [`0x${convertCoin.address}`, valueMoney * 10 ** 18, minUSDG, minGLP],
  });

  const { write: writeAddLq } = useContractWrite(configAddLq);

  const handleApprove = useCallback(() => {
    writeApprove?.();
  }, [writeApprove]);

  const handleAddLq = useCallback(() => {
    writeAddLq?.();
    isSuccess && setValueMoney(1);
  }, [writeAddLq, isSuccess]);

  useEffect(() => {
    setInterval(() => {
      toggle();
    }, 10000);
    setBalance(Number(balanceToken?.formatted) || 0);
    setBalanceGLP(Number(balanceGLP_Token?.formatted) || 0);
  }, [balanceToken, balanceGLP_Token, setBalance, toggle, timeInterval]);

  return (
    <Flex
      alignItems='center'
      direction='column'
      justifyContent='center'
      minH={{ base: '93.8vh', xl: '91.3Vh' }}
      gap='20px'
      p={{ base: '0 16px', lg: 'unset' }}
    >
      <Box
        w={{ base: '100%', lg: '460px' }}
        p='36px 30px 30px'
        borderRadius='30px'
        bg='bg.200'
        position='relative'
        mt={{ base: '50px', lg: 'unset' }}
      >
        <Flex mb='30px' alignItems='center' gap='10px'>
          <Link to='/liquidity'>
            <Box
              bg='bg.300'
              h='40px'
              w='40px'
              textAlign='center'
              borderRadius='100px'
              cursor='pointer'
            >
              <Icon
                as={ChevronLeftIcon}
                _hover={{ color: 'text.400' }}
                color='text.500'
                ml='-2px'
                h='30px'
                w='30px'
                mt='5px'
              />
            </Box>
          </Link>
          <Flex w='full' justifyContent='space-between' alignItems='center'>
            <TemplateText fontSize={18} txt='Add Liquidity' />
            <Box
              bg='bg.300'
              h='40px'
              w='40px'
              textAlign='center'
              borderRadius='100px'
              onClick={onToggle}
              cursor='pointer'
            >
              <Icon
                as={MdSettings}
                _hover={{ color: 'text.400' }}
                color='text.500'
                h='30px'
                w='30px'
                mt='5px'
              />
            </Box>
          </Flex>
        </Flex>
        <Box pb='50px' textAlign='center'>
          <Flex
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            w='100%'
          >
            <WrapAddLiquidity
              balance={balance}
              convertCoin={convertCoin}
              setConvertCoin={setConvertCoin}
              value={valueMoney}
              setValue={setValueMoney}
            />
          </Flex>
        </Box>

        {!isSuccess && valueMoney > 0 && isApprove && isConnected && (
          <TemplateText
            mb='20px'
            color='red'
            txt='Unable to fetch. Try adjusting your slippage.'
          />
        )}

        {isOpen && (
          <Setting
            isOpen={isOpen}
            onClose={onClose}
            valueTolerance={valueTolerance}
            setValueTolerance={setValueTolerance}
          />
        )}

        {isConnected ? (
          <Box>
            <TemplateText txt='PRICE AND POOL SHARE' mb='20px' />
            <Flex
              border='1px solid #28344b'
              p='24px'
              justifyContent='space-between'
              borderRadius='10px'
            >
              <Box textAlign='center'>
                <TemplateText txt={coinPrice} />
                <TemplateText color='text.400' txt='Token Price' />
              </Box>
              <Box textAlign='center'>
                <TemplateText
                  txt={BigNumber(minGLP)
                    .dividedBy(LinkPrice)
                    .decimalPlaces(5)
                    .toString()}
                />
                <TemplateText color='text.400' txt='est. PLP' />
              </Box>
              <Box textAlign='center'>
                <TemplateText
                  txt={`${BigNumber(minGLP)
                    .dividedBy(glpSupply)
                    .multipliedBy(100)
                    .decimalPlaces(9)
                    .toString()} %`}
                />
                <TemplateText color='text.400' txt='Share of pool' />
              </Box>
            </Flex>
            <Flex mt='40px' gap='20px'>
              {}
              <ButtonBase
                w='full'
                bg='#2669f5'
                onClick={handleApprove}
                content={
                  isInfinity ? `Approve ${convertCoin.symbol}` : 'Not Enough'
                }
                isDisabled={!isInfinity}
              />
            </Flex>
            <ButtonBase
              content='Supply'
              w='100%'
              bg='bg.100'
              onClick={handleAddLq}
              mt='20px'
              isDisabled={!isApprove}
            />
          </Box>
        ) : (
          <ConnectKitButton.Custom>
            {({ isConnecting, show }) => {
              return (
                <ButtonBase
                  onClick={show}
                  w='100%'
                  h='44px'
                  bg='#2669f5'
                  content='Connect Wallet'
                  fsText={14}
                  isLoading={isConnecting}
                  _loading={{
                    color: 'text.500',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  fwText={400}
                  borderRadius='10px'
                />
              );
            }}
          </ConnectKitButton.Custom>
        )}
      </Box>
      {true && (
        <CalculationAddLiquidity
          glp={balanceGLP}
          glp_percent={BigNumber(balanceGLP)
            .multipliedBy(LinkPrice)
            .multipliedBy(100)
            .dividedBy(glpSupply)
            .toNumber()}
        />
      )}
    </Flex>
  );
}
