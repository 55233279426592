import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ROUTER_ABI } from 'abis/ERC20';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback, useEffect, useMemo } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { DAI_ADDRESS,ROUTE_ADDRESS, VAULT_ADDRESS } from 'utils/ERC20';
import { VAULT_ABI } from 'utils/Vault';
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

import CoinItem from './CoinItem';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  valueInputPay: number;
  leverage: number;
  setModalSuccess: () => void;
  settingSlippage: number;
  isEnable: boolean;
  processing: () => void;
}

export default function ModalFutureLong({
  isOpen,
  onClose,
  valueInputPay,
  leverage,
  setModalSuccess,
  settingSlippage,
  isEnable,
  processing,
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();

  const { data: btc_price }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    enabled: isEnable && isOpen,
    functionName: 'getMaxPrice',
    args: [DAI_ADDRESS],
  });

  const _price = useMemo(
    () => (Number(btc_price) / 10 ** 30).toFixed(2),
    [btc_price]
  );

  const size = useMemo(
    () =>
      Number(btc_price) *
      valueInputPay *
      leverage *
      (1 - Number(Number(settingSlippage / 100).toFixed(2))),
    [valueInputPay, leverage, btc_price, settingSlippage]
  );

  const { config: configLong } = usePrepareContractWrite({
    address: ROUTE_ADDRESS,
    abi: ROUTER_ABI,
    enabled: isEnable && valueInputPay > 0 && isOpen,
    functionName: 'increasePosition',
    onError(error) {
      console.log('Error Long', error);
    },
    args: [
      [DAI_ADDRESS],
      DAI_ADDRESS,
      valueInputPay * 10 ** 18,
      0,
      size,
      true,
      btc_price,
    ],
  });

  const {
    write: writeLong,
    isLoading,
    isSuccess,
  } = useContractWrite(configLong);

  const handleLong = useCallback(() => {
    processing();
    writeLong?.();
    !isLoading && onClose();
  }, [writeLong, isLoading, onClose, processing]);

  useEffect(() => {
    isSuccess && setModalSuccess();
  }, [isSuccess, setModalSuccess]);

  const listInfo = useMemo(
    () => [
      { name: 'Collateral Asset', value: 'DAI' },
      { name: 'Collateral Value', value: `$${valueInputPay * Number(_price)}` },
      { name: 'leverage', value: `${leverage.toString()}x` },
      { name: 'Liquidation Price', value: '...' },
      { name: 'Position Fee', value: '$ 0.02' },
      { name: 'Swap Fee', value: '0.31%' },
      { name: 'Execution Fee', value: '0/00058 ETH' },
      { name: 'Trigger Condition', value: `Mark Price < $${_price}` },
      { name: 'Entry Price', value: `$${_price + 1}` },
      { name: 'Borrow Fee', value: '0.00031% per hour' },
      { name: 'Slippage', value: '0.3%' },
    ],
    [valueInputPay, _price, leverage]
  );

  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px 10px 0 0 '
            alignItems='center'
            position='relative'
          >
            <Icon
              as={BiChevronLeft}
              position='absolute'
              top='15px'
              h='30px'
              cursor='pointer'
              w='30px'
              left='15px'
              color='text.400'
              bg='bg.200'
              borderRadius='100px'
            />
            <TemplateText fontSize='18px' color='text.500' txt='Long BTC' />
          </ModalHeader>
          <ModalCloseButton
            top='15px'
            _hover={{
              color: darkModeColors.text555,
            }}
            bg='bg.200'
            borderRadius='50%'
            color={darkModeColors.text550}
          />

          <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='20px'>
            <Box>
              <CoinItem
                name='DAI'
                img='../../coin/DAICoin.png'
                val={valueInputPay}
              />
              <CoinItem
                name='DAI'
                img='../../coin/DAICoin.png'
                val={Number(_price)}
              />
            </Box>
            <Box py={2}>
              {listInfo.map((item) => (
                <Flex justifyContent='space-between'>
                  <TemplateText
                    p='2px'
                    fontSize='14px'
                    color='text.300'
                    txt={item.name}
                  />
                  <TemplateText
                    fontSize='14px'
                    color='text.500'
                    txt={item.value}
                  />
                </Flex>
              ))}
            </Box>

            <ButtonBase
              mt='20px'
              w='100%'
              onClick={handleLong}
              content='CONFIRM NOW'
              bg='#2669f5'
              color='text.500'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
