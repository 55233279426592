import {
  Box,
  Flex,
  // Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { NavItem } from 'constants/interface';
import useModeTheme from 'hook/colorDarkMode';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface props {
  navItem: NavItem;
}

const Menu = ({ navItem }: props) => {
  const menu = useDisclosure();
  const { darkModeColors } = useModeTheme();
  const popoverContentBgColor = useColorModeValue('#162239', 'gray.800');
  const [ishover, setIsHover] = useState<boolean>();

  return (
    <Popover trigger='hover'>
      <Flex
        onClick={() => {
          menu.onOpen();
        }}
        alignItems='center'
      >
        <PopoverTrigger>
          <Flex
            zIndex={999}
            alignItems='center'
            cursor={navItem.disable ? 'default' : 'pointer'}
            position='relative'
          >
            <Box p='0px 10px' mt='6px' w='100%'>
              {navItem.disable ? (
                <Flex position='relative'>
                  <Box
                    bg='bg.300'
                    color='text.500'
                    p='0 5px'
                    fontSize='14px'
                    borderRadius='10px'
                    position='absolute'
                    top='-17px'
                    right='-17px'
                  >
                    soon
                  </Box>
                  <TemplateText
                    color='gray'
                    txt={navItem.label}
                    fontWeight={400}
                    fontSize={16}
                  />
                </Flex>
              ) : (
                <Link to={navItem.href || '#'}>
                  <TemplateText
                    _hover={{ color: '#2669f5' }}
                    fontWeight={400}
                    color={ishover ? '#2669f5' : darkModeColors.text555}
                    fontSize={16}
                    txt={navItem.label}
                  />
                </Link>
              )}
            </Box>
          </Flex>
        </PopoverTrigger>
      </Flex>

      {navItem.children && (
        <Portal>
          <PopoverContent
            w='auto'
            h='100%'
            p='10px 0px'
            borderRadius='16px'
            bg={popoverContentBgColor}
            border='none'
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <SimpleGrid columns={navItem.children.length > 5 ? 2 : 1}>
              {navItem.children.map((child) => (
                <React.Fragment key={uuidv4()}>
                  <Link to={child.href || '#'}>
                    <Flex
                      w='250px'
                      cursor='pointer'
                      p='10px'
                      _hover={{ background: 'bg.300' }}
                      alignItems='center'
                      justifyContent='space-between'
                      onClick={menu.onClose}
                    >
                      <Flex w='92%'>
                        <Flex alignItems='center' mr='8px'>
                          {child.icon}
                        </Flex>
                        <Box>
                          <TemplateText
                            mr='10px'
                            txt={child.label || ''}
                            fontSize={14}
                            color={darkModeColors.text555}
                          />
                          <TemplateText
                            mr='10px'
                            color={darkModeColors.text550}
                            txt={child.description || ''}
                            fontSize={10}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </Link>
                </React.Fragment>
              ))}
            </SimpleGrid>
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

export default Menu;
