import React from 'react';

export default function Aurora() {
  return (
    <svg
      width='30px'
      height='30px'
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>aurora</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='aurora' fill='#70D44B' fillRule='nonzero'>
          <circle
            id='椭圆形备份-3'
            fillOpacity='0.2'
            cx='15'
            cy='15'
            r='15'
          ></circle>
          <path
            d='M14.496539,8.15 C15.1298723,8.15 15.7048723,8.50833333 15.9882057,9.075 L20.671539,18.45 C21.0798723,19.275 20.746539,20.275 19.921539,20.6833333 C19.6882057,20.8 19.4382057,20.8583333 19.1798723,20.8583333 L9.81320569,20.8583333 C8.89653902,20.8583333 8.14653902,20.1083333 8.14653902,19.1916667 C8.14653902,18.9333333 8.20487235,18.675 8.32153902,18.45 L13.0048723,9.075 C13.2882057,8.5 13.8632057,8.14166667 14.496539,8.15 M14.496539,7 C13.4298723,7 12.4548724,7.6 11.9798724,8.55833333 L7.29653902,17.9333333 C6.60487235,19.325 7.16320569,21.0083333 8.55487235,21.7083333 C8.94653902,21.9 9.37987235,22.0083333 9.81320569,22.0083333 L19.1882057,22.0083333 C20.7382057,22.0083333 22.0048723,20.75 22.0048723,19.2 C22.0048723,18.7666667 21.9048723,18.3333333 21.7048723,17.9416667 L17.0132057,8.55833333 C16.5382057,7.6 15.5632057,7 14.496539,7 Z'
            id='形状'
          ></path>
        </g>
      </g>
    </svg>
  );
}
