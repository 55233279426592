import axios from 'axios';

export const getLineChart = async (
  base: string,
  quote: string,
  day: string
) => {
  const res = await axios.get(
    `https://api.coingecko.com/api/v3/coins/${base}/market_chart?vs_currency=${quote}&days=${day}`
  );
  return res?.data?.prices;
};

export const getBarChart = async (base: string, quote: string, day: string) => {
  const res = await axios.get(
    `https://api.coingecko.com/api/v3/coins/${base}/ohlc?vs_currency=${quote}&days=${day}`
  );
  return res?.data;
};
