import React from 'react';

export default function Polygon() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='30px'
      height='30px'
      viewBox='0 0 130 130'
      enableBackground='new 0 0 130 130'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='130'
        height='130'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACCCAYAAACKAxD9AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAp
pUlEQVR42u2deZhdVZX2f2vvc84dashESFIJg4AQB0SGRmhbEKQDOASxkUEbp24HHLp9HJlCJEwG
WrudG/VzAJFJbaRBTQAZu6EFGVQ0iCCQpDIPNd57zzl7r++Pc26lklSSSnJvVRHyPs99Uqm6Z4/r
7L32Wu9aW9hFMfetS/ewNQ4Sy0yFmaK6H0g7SJuibQKtQBtIa/aE9gI9Cr2C9ID2gHaryLMCi9Sx
KPC66Py7ZqwZ7b41AzLaDWgE5p64eGIo9lhFjwMOQ5kJTGpSdWsQFgGPCnJ3ou7eixfstXa0x2Bn
8aIUhPmzV7WltdoxHnMc6PHAa0exLwo8DvJrg787KBTu+/ytk3tGe4y2Fy8aQZj7Rg2iaPnfe/x7
EN4OFEe7TVtAFeUWg7kmjqfecfE9ko52g4aDMS8I805ccgiY9wj6bmDKaLdnO7FCkevAX3PRghlP
jHZjtoYxKQhz56oJ/2/ZKar+PJC/Ge32NAb6sIi5InndtJ9ffLH40W7NphhTgjD3jRqE0bIzVPQ8
4FWj3Z4m4UlFLne1aTeNpW1jTAjC1Yc/Eq7co+N9wOdB9x/t9owM5BmFL05Z3fnDD//2iGTUWzPa
DZh38rJjxftvsOuuANvCk6Ly0QsXdtw3mo0YNUH4wptXTrVpfBUi/ziaAzB2oNeaNPzsBXdNWTEa
tY+4INz0TrWLujvPEbgMaB+NTo9hdClcOLO941un3yxuJCseUUGYe8LSvQPLDcDRI1nvixAPpo4z
L75z+gsjVaEZqYoumbXkrYHlcXYLwXBwdGB57JJZS946UhU2fUW4+vBHwhWTp10uymdGqlO7FJSr
9lyz7IJmnyyaKgiXzlq6lwo3snsV2Fk8KMoZFy6cvrhZFTRNEC47afFrvNpfgU5rVh0vMXSq9Sdd
9Iu9ft+MwpuiI1w6q/MYr+a+3ULQUHSIM/fPO7HzDc0ovOGCcOlJnaeq6EJgXNOH5qWHcYLeccms
pW9vdMENFYRLTlryYVX9CVAYqZF5CaKA8NN5szo/1MhCG6YjzJvV+SERvXrkx+WlC1X58EULO77d
iLIaIgiXntR5ar4SjJhdYjcA8Cj/MGfh9Ft2tqCdFoRLZ3Uek+sEu7eD0UFNVGbtrNNqpwRh3psX
HyzO3M9uxXC00aXWv2FnjpY7LAiZsUj+b/cRccygU5SjdtTotEN7+tWHPxJmFsPdQjCG0KHCjVcf
/ki4Iw/vkCCsnDTtMnabjccijs7nZrux3VvDJbOWvBWR/25aVzTY5BcGL6DisRojKEpIKiEitawT
OujbOvyqdlmovm3Owhm3bc8j2yUIOZ/gMWBi83oxxCKlBrBYdYDHmRQvYBXQKP87IB4dO3zQ0cTa
1HHo9vAZhr013PROtTmppIlCMBh+w0dirHTjbR/O1vDGoSZBTRW13WD6MIBx5ZFp2tjHxMByw03v
VDvcB4YtCIu6O89hRPSCwQLgUGLEgBTL9LGcZ3oWMv6VTzPz+FW4aY+xMv0zfb4fKSekUdO8tC9G
HP1Ud+dHhvvlYW0Nl71pxRQfpE8xYvYCn2/8HhEloZd1uohzrziNqQdAaquExSIrVqxnyoTxfGXO
Apb/WYjSyYTpiy0YqqnoMmlw0HAIscNaEXyQXEUThMCLx5ts3wcDGhAFfbhaNwGtVF2F5fpb/vbM
Il/+6WlMPRi0nBIUQzwwaWobLurmX684jst/OIu1rY+gxT4Ss5IoSgi0gE8CJNchnPE4U19xXhIY
5wN35XC+uM0VIY87uKcpzRQHasGXEByYKmLbqCTr0GgJMw5O+MgFx6GRh1AQEbz3GGPwKB6PEGMJ
SWsBtV7oXlLlq/PuYZJ9LT1ruykUSkgKXiA1FvEml/6XjDAgKsduywS9VUHIIpCmPUaTgk/EtQEe
tT14WwWJWa6/458/+WZe9TftFMan9MeOKCpgvEcka272r8ejCJZaNaYQAeJwvkggHu2ynH/O9ZT8
fpi+CYgrgy8BipEYD+ioh/eMGJ7cc/WyQ7fGe9zqUFxyYucHQRvi5hwKNuinvz+lKoZg/Er+5rhx
vOMjB1NzCVEhxOEw+Ztr2NRgVn+js/dbiYGYlBKCx6LQG9G/AuZ88qeU6CCIJxNSQEyKashLyVmq
yAcvWtDx3S39fYuCMPeNGoTFpU+pyn5Na51ZTRp207JXL5/54kn4Fo86jzEBYkBRjPosFYUZ6iS0
YXlXrVuSLCpVPCFWLS5JCAh59N7l3PTVJ2jR/TBphGJ5KQkCyDNpbdrMLQXebnEkwmjZGTsnBAYv
2RFQNCCtGVQdJqiRUoGwnyXuEU771Kv47FdOhNZ+jDisCTAGhBQhQSETV03wCaT9gIf+2nritEbq
FfUm0zXUIuIxFLEISEoQhWiUcNgJk/ji9Sey0tyLGV8hCC0FGxAguaEKPFE+ZrkCq0FmsNoloPvb
wrLTt/TXIVeEuXPV2Ic6fy/wyh2r1AApSAIYxIcEBUNvTwVbNgTjVnDkm1qYddarshYUM4ugYFAV
RLK336OgghclpYsAoVZJKBUmAgYFxDjADXRG2NxEjQcVh3pP91Khujrg8nNvZErbwdA3GbygJsZL
3URdX2mCzGq561grn0yP6njNUPkZhhSEnHH0s52pUciEALWoeEwpoSf9KxP3rfHZ+ScT04MYT1ho
AzWogIgHTUEMSoCieNKBciQFC/gKGAEiwDqwDhVBCTAbdSnvrzdZEcQIQlqNCWjh5u88wcN3dlF2
HQQuRNSiA4vk4LHahbYQ5dShGE1DCsIlJy75zY5nKvEgCaKGKBpPIlX6dQVx8U/M/8YZSFsNLRqc
hlipZg3Q4hAtSVE8/f29lEsFpC/igduW8aubn8b6CSRJlVrQyX6vGs/Hzj0BLaak0g06Dmst1Wo/
5XIZ8AP6g4jgSVEcaIyrBFBp4er5C+hcVKLkZ6BJShILxUILA4at4VtqXwTQh+csmHHkpr/dbPjn
nbjkEEEe3/GKfO4VNLROnMha/yfe8+lD6Xh5QKndZo4hb1CjiHoQjyBkb53BeUBSDAmiJaqrYdmz
nm9d/nOKbh8iPxlRj9caoZ1EGq5lvS7izWfN5PjZL6ciKYVCpmxmp83smAlg1IJkW44hxfkka0+1
hO2zfPwfr2GflsOJK0XwLbvatjAARV+7aU6nIdY8856drynbp9dX/8LH5x7Fvkf0YlstsavhNAVx
GE2ybYMAxVHX+bO31SFpEb8azv3gD/jelQ9Rcq9G0naSJAFjCWwLAX1ob4HW9GDuur6bz599B0uf
6aba40lqNbJVxeDJFMlsUlMMgk9CapUAI2Uqfg2MS/nqj97DutpzBGH9/TBDuMVf/BDM2Zv/bhDm
vlGDoNC5hJ3JXiYue9ONxU97iM999TT6kqWMb5+CMRvLXbZnW1JCLJ7+vnUUggKBb+WLn/0lvUvb
CWrtlMNW4trwuLFx2zJ64kVc+vV3E01IkUJMEEaA4lQwWAQP2IHeq2q2bTj4ze2LWXB1N4FrzY+Y
AbugFXJ5WuvYa/BRcqOZiaLlf8/OprCrm+tMD+/959kUyjBx/JQBq+CG8z4kxCQI4hJEU1qYxI+u
epjz33MbPc/uTyl5DdaNx23H6txSm0Kx9zC++tlFfPXcuwm6yviKwWktV0YFcEMv+QJHHbcXie8D
qfs/diFFcQOmBoVlJwz+xUa99Pid3xYwiFic6WPvAyNUYlTtRuZhVc0+aTuqStqt/OEOx6dOv53n
H9yXYt8hlLSAuvUIZVwyfAqE1hztQRuuq4WeZ6cy5wP3cOPXF+HXttLftwrVBO9CGHTM3NA2BwbC
YmbChjGQZKpp0I3mekAQ5s9e1ZZnNG1MNZI5ekCQLRj1oyDBVkM+cdq13PLNlUwyB2N9gHWFTFnz
BZAa2O7h1+smgkYIKZaI0HXw7EPwidOvoRhPRzTGBEO3R2XDgqY5RW6XhfL2+bNXtdX/OyAIaa12
DI1Ka6sWxaJKpgxuYUC7V/Zz0TnXMa18OJI4tBbiNcypaCnepHjjMgvlMJFEq0mCGk5LqJ+ESyYR
9I/jZS2z+NS7b0KSFqDCVvd9zc3ParbyvU3tDWbQh03+NgYhlOK4OhBZPdDqLMF1oyrJfP7GZoe3
bH01G31ELONaxxH2v4yCjiPVIj5Is78qGHz2r7cYP3yGtvGF7Bk8hpjQ1Ei9JU36mBjtTddS8GnE
UAcmg0WUAYXS5PqB6Oaf+qgN/EeDzJI6QKg1iA5dz1iBDprzQa3U40e6Ib3rIK4G1Crkdv1mrcX5
MVBDvve96wZM2JsjF1rJzdZbWYmEGlbBukK2nRFn/hEF6wOMBhj12QlqjEKUgTk3kN13QJbqvjEV
iORK4da/V6tCwY6jGJWyN7FJVPTseBigPsSare1+BnWgWj9VbGUS0wJiWlEJUJvgwx4qaQVvlTSM
sUWPD9Kxznk49Iq3PD8h6zkQij2WUVCQs1XVZG+epCC6UYxCY7Hlgr3PzNDeQRpDXJPckLTlZV0C
S79bhWtfzOLk14x/zXJO+ZdpHHma54Xq7fSa5yAK8WN4awAkTYJjIT9D5TefjBLq3sNmyuHWeYo+
Zz+ptzz4P38itK3g7FaVxX63AldcznGzD+C4085mfW05hdYCUTCdN8w+i84/BfzHvNvZMzgiO/2M
USgcB9xSP0wf1vwKPZK/HSKCukwKDeCcAynjTS3zKjYYIhb1itEi6iIUqKWKiCIuxVYjuhfDF8+/
nqK+HGUyjnaseAJNceoJjKVYFvp1NT3JKqYc5vjUeSeQBjV82ENbaQKWTPcoT2pn39fB1xe8k1u/
+wR3/expJsgrCeIyUSEg9ZBqESTO9RFA7ahEaYlkcx/kszRz5JswkvB5FJQHUwNTwTol6Q8wlYgr
5/4Et2ofitXXYa1FCRFNsOoxkiBGgCL9up71wRPM/+670RIQpaiPMRTRTah0isN7y+z3v4LZ7ziE
b1/5MM/9fjWYDlxiCMSgvgQagu0F00dW6IhjJkAw961L9yBp2kVYYwJmwAPpMVhMGuH7hBu/dT+L
/i+gmBxGwRewQUiS5kwq8flzhsSuo1sfYM4V72Lc3gdgiqCmmziGKCqjalHR3Iua12kydp1zQi1c
x4fmHYbvtlzw0Z/S1nIYaZfH0E1GnClBukcmDCOPPS5/05JJga1x0NjWZ3YemcLmEI1o9fvwwM3L
uPmaR+mY8BoK/Y7IhIh6Uu+z06M4iq3Qn6wlCVfx+jdP5+/f8S5Mez/OKp4ihnbCkMxoJgqkMGhV
sDYzU4s1FNvHo6SYycql3/kHVi+Dq867icBNoz3cl6S/Rmhq+FE6YngrBwVimckuHkGsGBAQX+CF
PzgWP7GOqXIk2mUpSxWISaWE1wKWCmor9KTLWZH8lm9e8y9IGziB1IQYQPCZ5bTObSQzIG1udzAY
+lFCHCFCSo9dzZRXTOTKa0/nwTs6+a8fPMb4lr2xSQs+Hp37yjzMNEqz9IM6zTxnBw1adlRzo4GA
8ULgc0tis4z74kEt1hWI4umE6TQSPDHVbEL9ONQZTFDDtDrWmMd4/4VH8K2f/wtJOePHGQMWm1sd
QyBG1IILMRoi4jOCrjfEVHGa4lNAy0iuRhoCxrXtARhMW8rrT9mTf/vxW2g/YBlr6cQHMU57MOIR
NTgcnghnwJl8Y1Ofu9EbODww02Q3pDYJw1hptG5yJqVpRgTNTdsS48XhTRU0wniLF48ppvSxknXu
j0w7ZBn/ft1p7POaAhqkGGvrgzVImD2OEC99YNeCVEBDbLAWlS4sZYQIbC3zvtbJNgw+JAdgPGv6
lvPxC2bxr5f+HWt5AFMyeBxIDevHY0izKDBJcp0lGMSrbNDwiO4XZNfkjg4ySfcYsSiSD2jj+YGC
B/U4mwmaYgh8H2pSElujs/Y0F/77KUyb6Um0DycVjDGIsWgSgx1sB6gHzNVAI6xrwWWMN6qrJxKV
QcpAsYZaj2DJzNX1baQeT+FBAtonjcOZPia/2vNvN7+LIDGc98GfUajug/V9mHg81hWyvA/imrKL
G6Q9AGkb1qu7HajzDbaF1vFQbHVoJd9pm0UCkYSMrBhSdf0UWj291W664j/ywc8ew6HHn0J3/zoS
CtigDZH8zfVKGNS5CSbjTqQpYRjiKt2UwsmsfAauOP9mWs0BWBWUmJqs4F8veBvTDoTEesKCIUkr
BEEeoqG5H0MDrC2BZGxtKWa2p0u++2aStUXO/dgPmFA8GN8/iYAiNqhlR2DdoTRJW5kv2oL8wuwR
h6oStgt/7fwD+xSOxvsQJMg9fo3dA40KniiTs6iLrvQZjjnllbztzIxV7W2VqFRGNHvzFZetIkLu
VayH6BuCICRJHFHPZM779A2EvTNpr7yOlrBA4ipAgEn34MYvP8Vflj/AnP/8JybvDVZKoA5FUDVk
7FqfR2dkHk+tk+jaFKJuvnLD+3joV3/lxqv/SJu8AkumlDZ8VRDa5JITlz4P7N3Icm0Aie3lgh8c
iJvQRci4IQ3ISbqWPz9Y4ofznqY12IPQGZwEOSEkRc0G7531m68WG05bZkAPEBUMDi8ZS7lQKJBU
a5ioQFxcSzj1eS648mS05MFkbCkxg6IhPLnFLwaNcBoRp4qRLiKNkFqZr166kHVP7pvFWmiE8bkF
YVAKH1BUUkpT++jVp5n7tVOhBbpr64lKrWhisIHDCPkEhyAbW2BVHSKeNX8J+c683+CXT0dNupnx
qgF4PgDadrqYzVDPeAKC26IXwcUtHHR4gXTCo7jKYbSUplLt78sCXrWQuyBqG8ywW8KgLcVIf7Z0
akQQRKQkVEwPqfkj8797MrS8Eh+QTX7esA3tq8cxhBlxVTwi3aRxN206g7tuWcIvr7+Pop9GkdaN
mzC4nAEeg6WywmPDAzn3Hxcw86hxvO8zRxHXKkQF6E88UVjOoruot8UMkGlFLE4rtEwO6UqfpdVM
GZZXdwfQZo8/4DOXAA3V0Kwo3vZxzNumYMspsoXsvLGrICZl1lsO55Ajp3H7woWUZDyCRyTOffsl
jC+iMkRE98Do+fzjUKMoBZz1pHY1cfAcHYd1c/5XTkTL3WhYzU3GdsBdPng6VZKMiQ0IFklLrH2y
wKWfvI0XHm2j5GbQGrTidMvNGYyQFoyfSNo/ga7lnl/ceA/jy5PY64BJBAQYm+Zttxu6M6hNRgw2
8szYY1/++OAa8GGjpwuQwB5/wKfnNLpkrzUEx7QDy0ya0YKYjd6XAdhAMEGAjZRiu+PkM19N6io8
ueh3eF/Cmnacq6HahwwVDS0GyJxHikNEiTUklV7KU7vpsb/l8m+dwpFv3g+NPGpKIAUUhxEz0KYN
im2Wva3avxZqio3LfPKsa/nT3SFRujeRthFgwTu8bN6fIVc+teA9QaBYDSnqNP7yuz5+cuNCXnvw
wYRRBUcVggJWhirBgPQxqa2d+25bTqBlvFoaq+CLl0tOXLoWmNBIQQgkCyitTHyY8772TqL2PgLb
svkYecDEgODTEBNUEQJcb8B35j/KX3/XS9mXMT7ApHtu/rxkiqXXBGOyVcG197B8/WNc/B/vp2Vy
lZaJ+TKf79kQoF6wWzig1PqhoPDIHWu44dv3Mzk6mKSW2yG0ABpkMZKmsvlwDjE3KgYvKYYNXkYI
cKaXoL0LM+EFPjnvVKLJfVhahmxTwlrM+olc9t5FhK59u+j9w8RaA/Q0utTUA2lISWZQWQeBKaDq
UM3CzZS8JyZBcj5ioutJXJF+NXSbtXxk3muZf+0x9AbPQMHk6fTqukJdMcx0EQkUCjE1VnPM28fz
1evez54HxpQmKgn1t8cj9aglU9uovc4pPlVIYP1ix7+c9UN+dd1K2uPDodKKF4Mny9vkbBUXDJ9V
7SXNhEELWRkmAdNLq22Hnn3pX7o/C36yCIi3WIZS3zaaFnXVY4874NMfFJjcuDJ9HqpsiHsMd/7q
fiIbsPfLJmMKnqrrARMMhLCrgFGHtREYS4hQjKJs3wwss97+ajoObOeB/70Dh8HQBi4kNB4bFKn4
buJoKW37d3Lh149n+sFlwlYDYjESYqVOncuCITO1zOJSh5ApjMan1FZZzv/AT3n6nvWElX0wtYmI
lPL0PJK3VzEqmWl5KMjmH0Fya7pmv8q5k2keX2FcwvIV6zjm7dMQCTfRWTIYLFQs9/5sKSbnUzSY
yLM0EKSn0Qal+omhGEzEymu5+8erueX673PF195Peeo4HAlYBiIejUSgDiNJvu8PijCKYP9Disy/
5izu/eXz/Ormx2gLpuOlQL/rpypL+MKX3oG09EH7WqI8H+hQBi3JYyFRQ60SU4w81kd896qH+Otv
SrT5o3BBlVBKeB+xxYioRoxPvioWojaqSRXrWvNUsqMApScAbfDWEOR8vxr4mFBLhG4qQbHAFZ94
nLj4V+Z/91TS0ENUxeNITYAlxPgAb3JjkkomDxIjNkWCkDeeNo03zd4HV4FqPxQngImOxJkYbyNS
WgYWz43eLIX+ajfWxhSCCFxEQUPu+a/l/OJHz9Iuk2l1EeKU1I3Po6A3nESaYfau+9d8QuaZ9EAe
BbbpqqAovolkaJFMELobzhfUYND5XzOad21vQunF+jY+c8bdHHJMyNn/+nc4BSnXBgZccpLHQNg8
AAZjXWYfKIEt99AysQIyMfPz103AKLB5qhsViMIiQRJRWRuy4vmYf7voJ+wz+XVEfdMICmUg10GU
rC11FnNTOAJ1J5hm1sVRprx7tDtQkWcb6/TLch+qGFyuIQsWDdZj0xYCJ7T5Dp57oMSF//cwXe5J
/v3a9xGMcxvePvFAkp8KQsRLHmGdopKgvgXv2zDGYyhk+zDKBsLjxtlO1KfgIu74yTP8+r+eI+zf
iynB0VRXFJkQtuB9H04MSgSmH8i3kQbb9AfDKDn/yeW+kGFgOyK+tgei8qwRWNToglXYsJxKxu3P
MpkleBWstCEuoJ0pjE8P5bz3LeSX1z5H3GMQ4+npXY8S4Ymyl8ZUcztshFDMuAFBmpmIJcvQkple
AZ/ZMVLfTbXSR9+alLgz4MIPfI//+Uk3peqBBNKKpK0Ym+LNSrxmiT1MlFKV5+jr68oSdfgivkmM
UiGjwqFmG06kFLAMdA5odPSUwiKjrvGCMOzBUCFIxjE1PIb7bljHuWf/iMWPJrQzKYsiIsmCabPM
SYOeNLk9Ps+vJCFKQIrLuIMiBFqmWGnj25cs5OKP3Eu07gjom4BxBYwPEKmCJDgxxEGNfvsCfcXH
Ofcrb6Bbl+IMgG0eWWYMwcCiwBV4KmjqPeRbRtrfTiQpcc8K2pmMr03l+5cuZlX1N3zl++8ijfqI
xhXBCLWao1AYfI7OU+mK4jTJdAVxiJbx64vcfv2zPHjH84TJDFqTPTAaIQKJ80hk8N6TeodGnm73
DOec93oOOryINzG+kJFIjRrcCCXKUB29EHzj9Clz8W3TVwNrRqMB3nbjbT9eS+BLGB/QvzJlWngU
l57zCF/67P9Adwi1hCis+ysGTUrusrZiCFwZXd/GH369jo+f8QMW3R9RrL4ak+6JisdLjDMeCWuk
so6g3ZK0LWO/16/kP246mZcdbqEc42xlkBNr18ufNARWn3/XjDXZKyYsQnn9SLfA2TgbdB/iBJCU
9vYivt9TiqbTs7iVCz90Fx0zEz76hZOoVmMKBZspV5q5jpWUShdUVgVcfv6PaUsOZrJ5HdpnwVdR
C6mAeIMoJK4bE60naV3O5V/5B3rTPqpRH4WohNOUzFJdyMzF4up543dlLIINNstHYeQFwbh6QEfd
dmDwGoGFmlNC2pDuV7DmtzFzTr+Tk884gL+dvS9VKoRRGa1UsdUyl37sBqRvL9rS1xL48QDUYkXE
Yn12HUB2REtJWpfw0flHMH2/v8VLSqvZEFRixRCnitp+1Jfxtg98+0CoeyOh5NcU4PGiA26/ze0I
ARCD2EGJvRonnqo8Wq8FQe5W9BMN722DIK5IW/wa7r1hObdeeyvFdsP4iRN47i/LCXUcYfJyQh1H
a2kcVT+UwlM/tzuQmKnTJmGG9IiaDUZW0dywtA0uxA4jt56KzfkTozS2cDfkgpCouzdgC77iUcTg
BFxJDUw6nXI8CaqO3jXCRNkbsESFMs45qjW/FYd6tiJ4nxAVyV2FQwhCfofUwP8lbeIk5TkhmmQf
GAY0CNN76z3n4gV7rQUeH63WbLO1RkltSqwJGloIFKSGSoqahJr2k5oaLtzGEi4eGwiqW1YC6zaI
TFHckFSrWfAaUyqbLFf0EOZlyB1XzVFUHjvv9n3WwUYbjfy6qT3eGYjLqO6mlsU/aAi+FetK2LRE
4AICB9EWV/GcOqf5crzF3AeeYslw0CtmbNgSmpewAUgRk7K+eylic4umjNyirMLAnJsNP/i7R6wF
2wnjLda1YF0Bo2ZA8RtwDuVv7RaTUkiSZWkj22bcRmkmN/54Y/n0vLdQiZbjXUBR/cCdUF48zjhS
mwx6Braef6HOnRj8AeOznLDduopJL6+QajqkECgJqmGemMzSSDKZDJrzgZELCoX7gGqT5rIBGDzY
deUvcwyp+I1S4w2NIF9Ztr7UG2OQNmXWWfvgWl4gRcBUEKlgcIgvYAbyPg5j25Ak+56GuSk582gG
LQVc+TnS1sf5/BVvwW+B15kJu+bchsGUup2EUomi4v0D/a7/8PlbJ/eg3LLzNYxRSC1L9u2DPHpg
qEnMfp9qyt+dOp4v/PhI1pQeJA3Xk5osfD30nkjWsqnJe0vwvpzFL9oKabSOJOqmVlyJTv89c7//
t3zpR/9In1lNFG7p8lKLkGBd5gjLqN0NEAThls/fOnmAgrBRiQZzzQhNywgjwOeBpatW9OEqZsC/
vzGBJXM7GxOiYYgrruZLN57OJ758KOvD3+Bb1hO0hlSSevjatoWhWO4i9esyIlqrZ4U+wKe+fDif
//IsqmGCFkoUi+NBNyg49TapKkmSgoc/PZFiXAteG2XtlI3meqPWx/HUO4BtXhb5YoPWj4m+wLTJ
BxJFDOz7GyNAAbVxtl+bFnwQE87o5qrr38lJHyiymvtIi6uHeHYLb6kXwlI3FfMQp/zTNK760T9R
nFFBA4dGCak4DAHiht77s2uNIr73jdvAt2be1p3H8rQ27c4ttv7ieyRV5LrmTsvIw3qTO5BKGB9x
8cd+Rm29y15CETxJllnNA2Kxkt0SZykABVqLrQQlxyEn7MH8G0/llaf0ELevoRKsJqEry44iBYxm
pmnnqxAk2JLSFS1m36M9X7rhNA55U0Q0LsZEJUQshSDE5B5TAkBjPA4nKbWkSpoIQVDjzw96gure
+T1XBXb+SCvXbXrJ1xBi7He57WHw2++dxXVN4srP/SLjZvk+0iTJAlNNDQeoFnI+hR8IdgGLsZlP
5B1nH8cXvn0IxelLkLKQ4HDhGlywDFM0VJIC/WY5XdEvmfefx3Dah/4G2jyYzOxtqC//G9zpHoeX
EI/FYikERdLumEcXVvl/V97LuGJHrnhuI//jMGDEbTbHTbjKZwwitxt4E6MmG8ygmLC674+84wOH
8/qT98GVKtnVTyZPaaEykEm+nqnVOYcxhlotoViMsjnphcs+dxM9K6dSCgrYSFnZ8xyX/MeZlCZU
iSYKilKrJRQKxcxZJoLPudGZ4yxbCVRLWEkyn9rakM+d82Pa5BBabAfUhEq8Dm88xhXYcYVxmFf5
QGMu9xpT0CA/NqZ4yQxKoUlxWiO23ayIH2ful97L1P3BlFO8OozkeQzyi8E2wKM4EhKsBliNNuT4
rmYpdmwrEGZENGw9iWh9qF2e5yDjZ4qAS2NSVyEKS+j6iCvP/2/WLynTYvbBxPWLSn1O0gl3Lg3f
9lzuNXeumuChzt/RpKuARx6bZlAnfzMrgKGagJ2wkpX9D3HlNz+GbYfC+DQL1dMQySOkNoxlZuTx
eRItwWNVQBOcgmqItSEqflAMhGa8iIGW+CzWQS0kFhzcfu0T3PnTTsbJTFwNWlqLaBJnQTKDIq0G
G9G2E9t33R/ApbM6362iPxrtKWwIxOXWpoxtlF1I7lC1mDTASQxGSEiJiy8wYe8ePj//bVnsm8l8
DkqdugFokiuZmhmcMKSxUgzzNDvic8pR3SrpMqMXFk/uaZQES4L4Eska4WNnf4/J/khaC61Z1JWG
pGlEYNOBCC+vhZyxvWM6giLvvmhBx4+HHKItPTQiVwKPOWT3Trqgj670af5u9gze8aEjIMzuhXOA
aIoVRTUTqg3HuU3vnMyhGe8yVYsRg08cYWhxHmwVrjz3V3S9EFBKp0Ha0BDUTbCDVwJffI+kquaL
IzcJYwPGQuDGYfv347Ffwrnv+jluuUESsJopA6mGmRMsv+54azGJKvkd16RYPOpSSOG/v/kY55/9
a9b+cQbj9BXYxie/2Lgd8MUtCQFsg39w9eGPhCv3mPYYu4yusG1YJWNJIRlN3vaireuw4zs5/8pT
cKWYVGIKUSYA/f0p5XIRj8tiFNnYMogY+nq7aGstEa8PeerxPr7z5VvZU18FBIgrZhZDCkNGWDcI
T+65etmhH/7tEVukKW/T5znv5GXHivf3jOx0jB4kv544+znLxdzvahTLJdbX/sKRJ7Yx+10zMe2t
KJ4gcgzcA6JBdjT0fuBqQ68xSY/SvSTgSxf9nHHmdWi1FYnX5/dR1q86ah7vQVSOvXBhx31b/c5w
CrrkxCXXgJw9nO++uJGlznEDl5JlFkkjAaoVTFAjThNShLXtd/ON758DJYeTPkRKQJBFZKmCBvgY
fnH9n1jwkz8wkUMoMh6jvaRU8DqF7CL1LAAoq6sZfZJr5izoeO82vzWcoi5704opPkifAsaN4KyM
acTBKmK7nOmvMHziC3/Pqq61TNxjAj5NCH1E5x/h8jk3M9m+CpNGeb7FukpmRuq+6S6TBgddcNeU
bfqPhk2HmXfi0o8LfG0kWv9igJp1hNF4uqo91PQF2qauZ+Zr9qPam/DwA0/TbvZnfPnlpJUYMeDd
pjS0ESHJf3zOgunfGM4Xhy0IN71T7VPdnfcDR49ED8Y6xLWAqWQKXp480+fkGOvz2EbNLIG62ali
RMiqDx7U3vGG028eHg17uwhyc09YundgeQwY/tWsuyzqqXg2oavlQmE0W/5dPd3PgL1hRIRgbeo4
9OI7p78w3Ae2a326+M7pL6C6TcXjpQAvWR5pZ1KceFIDSgHVCDUJzsQkdtPTmoedsAwOG6rv3R4h
gB3YqOYsnHEbylXN7cnYh8m1/PplpVb9AB2ufpOsoX573WDX8aa3xTYYylVzFs64bbv7syN17blm
2QXAg83rzW7sIB7M52a7sUOC8OHfHpGIcgbQOdo9340BdIpyxtash1vDDq9RFy6cvlitPwnoGu0R
2A261PqTLlw4ffGOFrBTm9VFv9jr96IyG2h8uPBuDBc1Rd520S/2+v3OFLLTWsuFCzvuQzmTEToX
7cZG8ChnXrSg4/6dLagh6uuchdNvUZVzRntUXnqQjwxFO9sRNOwcc9HCjm+ryofZvTKMBDzIh+Ys
6PhOowpseOjtJbOWvh3hBmDs3oz94kYV5axGrQR1NCUG+9JZnceo6K3s9lY2GusVmd0InWBTNMXE
deHCjvvU+jeALGv+2Lxk0KnWH9MMIYAm2jqzo6W+jt0WyEbgQVGO2tkj4tbQVKf4hQunL95z9bJj
d/smdhwKV+65etmxO2MsGg5GLE/LJbOWvBWRH7LbhT1crDXoey5YMOP2kahsRLOo5XyGG9hNbtkW
/leUM5u9CgzGiKfTy5lOHwEuY/epYlN0ARcc1N7xn8NlFjUKo5ZXMSPEJle9NNjRw4FcY1L7ueEQ
TZtS+2h3P7c5fJOXUBDNxtA/iJqPbSvuoNkYdUGALKJqxR4d7xU4F3T/0W7PSEDgLx6ZP2V15w93
lEPQ4PaMHcx9owa2sOx0Qc9n110hnhThsqTacfPWYhFHGmNKEOqYO1dN8GDnbETP33Uyt+jDqFye
Ht1x61D5CUYbY1IQBmPeiUsOEczZoO8Gpo52e7YTy0GuU/y1Fy2Y8cRoN2ZrGPOCUMfcN2oQFJad
APoe4FSgONptGhJKBeEWkGvS2rQ7x9LyvzW8aARhMObPXtUWx9U3KOY4UY4HDh3FvijwmAq/Fvzd
UVS8f3BG0xcLXpSCsCmueMvzE9IkOFbhOBEOA2YCezSputXAIlUeFbg7CNN766nuX8zYJQRhKFz+
piWTvJWDPMwUmKmi+xmkXZU2hDagFWgDacue0B6gB+hF6RGhx6PdovKswiIDi4zTp86/a8aoXITW
bPx/DqV+SwqL670AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDItMjhUMDQ6Mzk6MTYrMDA6MDDs
S9AyAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAyLTI4VDA0OjM5OjE2KzAwOjAwnRZojgAAAABJ
RU5ErkJggg=='
      />
    </svg>
  );
}
