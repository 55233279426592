import NoData from 'components/common/NoData';
import React from 'react';

export default function OrdersTab() {
  return (
    <NoData
      title={`You haven't placed any orders yet.`}
      content={`Let's start trading now.`}
      isOrdersTab
    />
  );
}
