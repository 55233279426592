import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipExplain from 'components/common/TooltipExplain';
import MantleDex from 'components/svg/MantleDex';
import useModeTheme from 'hook/colorDarkMode';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenCurrentModal: () => void;
}

export default function GasRefundProgramModal({
  isOpen = true,
  onClose = () => ({}),
  onOpenCurrentModal = () => ({}),
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
        <ModalHeader
          display='flex'
          justifyContent='center'
          bg='bg.100'
          borderRadius='10px 10px 0 0 '
          alignItems='center'
          position='relative'
        >
          <Icon
            as={BiChevronLeft}
            position='absolute'
            top='15px'
            h='30px'
            cursor='pointer'
            w='30px'
            left='15px'
            color='text.400'
            bg='bg.200'
            borderRadius='100px'
            onClick={() => {
              onOpenCurrentModal();
              onClose();
            }}
          />
          <TemplateText
            fontSize='18px'
            color='text.500'
            txt='Gas Refund Program'
          />
        </ModalHeader>
        <ModalCloseButton
          top='15px'
          _hover={{
            color: darkModeColors.text555,
          }}
          bg='bg.200'
          borderRadius='50%'
          color={darkModeColors.text550}
        />

        <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='60px'>
          <Flex
            justifyContent='space-between'
            bg='bg.300'
            borderRadius='10px'
            p='15px'
          >
            <Flex alignItems='center' gap='10px'>
              <MantleDex />
              <Box>
                <TemplateText txt='Avaiable Claim' color='text.400' />
                <TemplateText txt='0 PTD' />
              </Box>
            </Flex>
            <TooltipExplain label='Gas refunds are only supported on the BSC Chain at the moment' />
          </Flex>
          <Box
            borderRadius='10px'
            p='15px'
            mt='30px'
            border='1px solid #28344b'
          >
            <Flex justifyContent='space-between' alignItems='center'>
              <TemplateText txt='Pending Claim' color='text.400' />
              <TemplateText txt='0' />
            </Flex>
            <TemplateText color='text.400' txt='Next token release at' />
          </Box>
          <ButtonBase
            w='100%'
            mt='30px'
            bg='#2669f5'
            colorText='text.500'
            fsText='14px'
            fwText={400}
            content='Claim PTD'
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
