import React from 'react';

interface Props {
  isHorizontal?: boolean;
  color?: string;
}

export default function ArrowChange({ color }: Props) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='20px'
      height='20px'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M1165 4896 c-60 -28 -108 -77 -168 -171 -24 -38 -159 -248 -300 -466
-300 -465 -492 -782 -608 -1004 -82 -156 -84 -162 -84 -230 0 -54 6 -81 25
-120 32 -66 99 -131 168 -163 55 -26 64 -27 265 -30 l207 -3 0 -747 c0 -420 4
-784 10 -832 52 -454 383 -819 825 -911 80 -17 140 -19 536 -19 419 0 447 1
462 18 22 24 22 75 0 94 -10 9 -45 30 -78 48 -90 49 -140 86 -216 158 -129
121 -220 270 -276 449 l-28 88 -3 827 -3 827 208 3 c201 3 210 4 264 29 69 33
129 91 166 161 22 43 27 66 28 123 0 68 -2 74 -77 216 -133 254 -298 528 -578
959 -73 113 -189 293 -258 400 -68 107 -141 212 -161 234 -54 58 -121 86 -206
86 -53 0 -82 -6 -120 -24z'
        />
        <path
          d='M2617 4902 c-10 -10 -17 -33 -17 -50 0 -35 4 -39 133 -115 213 -125
376 -334 454 -584 l28 -88 3 -827 3 -827 -208 -3 c-201 -3 -210 -4 -264 -29
-69 -33 -129 -91 -166 -161 -22 -43 -27 -66 -28 -123 0 -68 2 -74 77 -216 133
-254 304 -537 578 -960 74 -112 189 -292 258 -399 68 -107 141 -212 161 -234
109 -116 302 -116 413 0 20 22 76 102 125 179 49 77 184 286 300 465 298 461
471 751 599 1000 44 86 48 101 49 165 0 54 -6 81 -23 118 -31 62 -97 129 -163
164 -54 28 -56 28 -266 31 l-213 3 0 747 c0 420 -4 784 -10 832 -29 254 -133
464 -318 642 -144 138 -315 230 -506 269 -81 17 -139 19 -537 19 -419 0 -447
-1 -462 -18z'
        />
      </g>
    </svg>
  );
}
