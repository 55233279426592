import React from 'react';

export default function MantleDex() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='24px'
      height='24px'
      viewBox='0 0 32 32'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g>
        <path
          style={{ opacity: '0.976' }}
          fill='#f66c63'
          d='M 9.5,-0.5 C 11.1667,-0.5 12.8333,-0.5 14.5,-0.5C 14.9528,0.458367 15.6195,1.2917 16.5,2C 14.6183,2.19808 12.9516,2.69808 11.5,3.5C 11.1667,3.5 10.8333,3.5 10.5,3.5C 9.89022,2.60895 9.55688,1.60895 9.5,0.5C 9.5,0.166667 9.5,-0.166667 9.5,-0.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '0.988' }}
          fill='#d05876'
          d='M 14.5,-0.5 C 16.8333,-0.5 19.1667,-0.5 21.5,-0.5C 21.5,0.166667 21.8333,0.5 22.5,0.5C 22.1658,3.18802 22.8325,5.52135 24.5,7.5C 23.391,7.44312 22.391,7.10978 21.5,6.5C 18.9436,3.57621 15.9436,2.90954 12.5,4.5C 11.8333,4.5 11.5,4.16667 11.5,3.5C 12.9516,2.69808 14.6183,2.19808 16.5,2C 15.6195,1.2917 14.9528,0.458367 14.5,-0.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#b82e67'
          d='M 12.5,4.5 C 15.9436,2.90954 18.9436,3.57621 21.5,6.5C 18.3704,6.30027 15.3704,5.63361 12.5,4.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#b24e8a'
          d='M 22.5,0.5 C 26.5278,2.52776 29.5278,5.52776 31.5,9.5C 31.5,11.1667 31.5,12.8333 31.5,14.5C 30.194,12.5273 28.5273,10.8606 26.5,9.5C 26.1667,8.5 25.5,7.83333 24.5,7.5C 22.8325,5.52135 22.1658,3.18802 22.5,0.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#e15771'
          d='M 9.5,0.5 C 9.55688,1.60895 9.89022,2.60895 10.5,3.5C 10.0618,3.43456 9.72845,3.60123 9.5,4C 11.7119,7.71614 10.3785,9.88281 5.5,10.5C 4.90326,10.2648 4.56993,9.7648 4.5,9C 6.19967,6.76911 7.19967,4.26911 7.5,1.5C 7.84171,0.661754 8.50838,0.32842 9.5,0.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#fceef2'
          d='M 10.5,3.5 C 10.8333,3.5 11.1667,3.5 11.5,3.5C 11.5,4.16667 11.8333,4.5 12.5,4.5C 15.3704,5.63361 18.3704,6.30027 21.5,6.5C 22.391,7.10978 23.391,7.44312 24.5,7.5C 25.5,7.83333 26.1667,8.5 26.5,9.5C 25.4937,9.93767 25.4937,10.6043 26.5,11.5C 24.3364,11.7215 22.6697,12.7215 21.5,14.5C 20.8501,14.1961 20.1835,13.8627 19.5,13.5C 18.7055,15.4951 18.0388,17.4951 17.5,19.5C 15.3318,20.1786 13.3318,21.1786 11.5,22.5C 8.48977,21.8058 7.8231,20.1392 9.5,17.5C 10.0431,17.44 10.3764,17.1067 10.5,16.5C 9.5,15.8333 8.5,15.1667 7.5,14.5C 7.70931,13.914 8.04265,13.414 8.5,13C 6.88066,12.7645 5.88066,11.9311 5.5,10.5C 10.3785,9.88281 11.7119,7.71614 9.5,4C 9.72845,3.60123 10.0618,3.43456 10.5,3.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#b171d6'
          d='M 2.5,5.5 C 3.42367,8.44672 3.42367,11.4467 2.5,14.5C 0.786787,13.3646 0.120121,11.698 0.5,9.5C 0.659599,7.84702 1.32627,6.51369 2.5,5.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '0.99' }}
          fill='#faf0f5'
          d='M 26.5,9.5 C 28.5273,10.8606 30.194,12.5273 31.5,14.5C 31.5,16.8333 31.5,19.1667 31.5,21.5C 29.1667,25.8333 25.8333,29.1667 21.5,31.5C 20.5,31.5 19.5,31.5 18.5,31.5C 18.1667,30.1667 17.8333,28.8333 17.5,27.5C 22.1667,26.1667 25.1667,23.1667 26.5,18.5C 27.7563,16.2743 27.7563,13.941 26.5,11.5C 25.4937,10.6043 25.4937,9.93767 26.5,9.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#d56697'
          d='M 7.5,1.5 C 7.19967,4.26911 6.19967,6.76911 4.5,9C 4.56993,9.7648 4.90326,10.2648 5.5,10.5C 5.88066,11.9311 6.88066,12.7645 8.5,13C 8.04265,13.414 7.70931,13.914 7.5,14.5C 8.5,15.1667 9.5,15.8333 10.5,16.5C 10.3764,17.1067 10.0431,17.44 9.5,17.5C 8.79105,16.596 7.79105,16.2627 6.5,16.5C 6.5,18.1667 6.5,19.8333 6.5,21.5C 5.83333,21.1667 5.16667,20.8333 4.5,20.5C 3.63732,18.5786 2.97065,16.5786 2.5,14.5C 3.42367,11.4467 3.42367,8.44672 2.5,5.5C 3.58457,3.41311 5.25124,2.07978 7.5,1.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#ffcbd1'
          d='M 26.5,11.5 C 27.7563,13.941 27.7563,16.2743 26.5,18.5C 26.3444,14.8649 25.1777,14.5315 23,17.5C 21.9614,16.7562 21.4614,15.7562 21.5,14.5C 22.6697,12.7215 24.3364,11.7215 26.5,11.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#d5a4d3'
          d='M 13.5,12.5 C 15.0481,12.8212 15.7148,13.8212 15.5,15.5C 12.7524,15.5983 12.0857,14.5983 13.5,12.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '0.99' }}
          fill='#946cf7'
          d='M -0.5,9.5 C -0.166667,9.5 0.166667,9.5 0.5,9.5C 0.120121,11.698 0.786787,13.3646 2.5,14.5C 2.97065,16.5786 3.63732,18.5786 4.5,20.5C 4.93611,22.7463 5.60278,24.9129 6.5,27C 5.94404,27.3826 5.61071,27.8826 5.5,28.5C 2.78913,26.7248 0.789131,24.3915 -0.5,21.5C -0.5,17.5 -0.5,13.5 -0.5,9.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#fd96a6'
          d='M 21.5,14.5 C 21.4614,15.7562 21.9614,16.7562 23,17.5C 25.1777,14.5315 26.3444,14.8649 26.5,18.5C 25.1667,23.1667 22.1667,26.1667 17.5,27.5C 16.2921,28.2341 14.9587,28.5674 13.5,28.5C 14.6935,27.7281 15.6935,26.7281 16.5,25.5C 17.9468,24.8658 18.6134,23.6991 18.5,22C 18.472,20.9302 18.1386,20.0968 17.5,19.5C 18.0388,17.4951 18.7055,15.4951 19.5,13.5C 20.1835,13.8627 20.8501,14.1961 21.5,14.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#c73e71'
          d='M 9.5,17.5 C 7.8231,20.1392 8.48977,21.8058 11.5,22.5C 13.3318,21.1786 15.3318,20.1786 17.5,19.5C 18.1386,20.0968 18.472,20.9302 18.5,22C 18.6134,23.6991 17.9468,24.8658 16.5,25.5C 15.1667,25.5 13.8333,25.5 12.5,25.5C 9.80214,25.1551 7.80214,23.8218 6.5,21.5C 6.5,19.8333 6.5,18.1667 6.5,16.5C 7.79105,16.2627 8.79105,16.596 9.5,17.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#ad70de'
          d='M 4.5,20.5 C 5.16667,20.8333 5.83333,21.1667 6.5,21.5C 7.80214,23.8218 9.80214,25.1551 12.5,25.5C 12.5,26.5 12.5,27.5 12.5,28.5C 10.2646,28.2053 8.26465,28.5386 6.5,29.5C 5.83333,29.5 5.5,29.1667 5.5,28.5C 5.61071,27.8826 5.94404,27.3826 6.5,27C 5.60278,24.9129 4.93611,22.7463 4.5,20.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '1' }}
          fill='#cc73b3'
          d='M 12.5,25.5 C 13.8333,25.5 15.1667,25.5 16.5,25.5C 15.6935,26.7281 14.6935,27.7281 13.5,28.5C 13.1667,28.5 12.8333,28.5 12.5,28.5C 12.5,27.5 12.5,26.5 12.5,25.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '0.988' }}
          fill='#e678a4'
          d='M 6.5,29.5 C 8.26465,28.5386 10.2646,28.2053 12.5,28.5C 12.5569,29.609 12.8902,30.609 13.5,31.5C 12.1667,31.5 10.8333,31.5 9.5,31.5C 8.70951,30.5987 7.70951,29.9321 6.5,29.5 Z'
        />
      </g>
      <g>
        <path
          style={{ opacity: '0.925' }}
          fill='#f4afc5'
          d='M 17.5,27.5 C 17.8333,28.8333 18.1667,30.1667 18.5,31.5C 16.8333,31.5 15.1667,31.5 13.5,31.5C 12.8902,30.609 12.5569,29.609 12.5,28.5C 12.8333,28.5 13.1667,28.5 13.5,28.5C 14.9587,28.5674 16.2921,28.2341 17.5,27.5 Z'
        />
      </g>
    </svg>
  );
}
