import {
  Box,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { STATUS } from 'constants/enum';
import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function History() {
  const headers = useMemo(
    () => ['You Sell', 'You Buy', 'Rate', 'CreateAt', 'Expiration', 'Status'],
    []
  );

  const packages = useMemo(
    () => [
      {
        sell: {
          name: 'Binance',
          symbol: 'BNB',
          total: 337.23,
          img: './coin/BNBCoin.png',
        },
        buy: {
          name: 'BUSD',
          symbol: 'BUSD',
          total: 337.23,
          img: './coin/BNBChain.png',
        },
        rate: 432.01,
        createAt: ' 2023/03/17 15:09:58 ',
        expiration: ' 2023/03/17 15:19:59 ',
        status: 'Processing',
      },
      {
        sell: {
          name: 'Binance',
          symbol: 'BNB',
          total: 337.23,
          img: './coin/BNBCoin.png',
        },
        buy: {
          name: 'BUSD',
          symbol: 'BUSD',
          total: 337.23,
          img: './coin/BNBChain.png',
        },
        rate: 432.01,
        createAt: ' 2023/03/17 15:09:58 ',
        expiration: ' 2023/03/17 15:19:59 ',
        status: 'Expired',
      },
      {
        sell: {
          name: 'Binance',
          symbol: 'BNB',
          total: 337.23,
          img: './coin/BNBCoin.png',
        },
        buy: {
          name: 'BUSD',
          symbol: 'BUSD',
          total: 337.23,
          img: './coin/BNBChain.png',
        },
        rate: 432.01,
        createAt: ' 2023/03/17 15:09:58 ',
        expiration: ' 2023/03/17 15:19:59 ',
        status: 'Processing',
      },
    ],
    []
  );

  const getColor = useCallback(
    (status: string) => (status === STATUS.PROCESSING ? '#90adec' : '#ff6b18'),
    []
  );

  return (
    <TableContainer
      border='1px solid #28344b'
      borderRadius='12px'
      p='10px 5px'
      mt='20px'
    >
      <Table>
        <Thead>
          <Tr>
            {headers.map((item) => (
              <Th border='none' key={uuidv4()}>
                <TemplateText txt={item} fontSize='12px' />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {packages.map((item) => (
            <Tr key={uuidv4()}>
              <Td border='none'>
                <Flex alignItems='center'>
                  <Image src={item.sell.img} w='24px' h='24px' />
                  <Box pl='8px'>
                    <TemplateText color='text.400' txt={item.sell.symbol} />
                    <TemplateText txt={item.sell.total.toString()} />
                  </Box>
                </Flex>
              </Td>
              <Td border='none'>
                <Flex alignItems='center'>
                  <Image src={item.buy.img} w='24px' h='24px' />
                  <Box pl='8px'>
                    <TemplateText color='text.400' txt={item.buy.symbol} />
                    <TemplateText txt={item.buy.total.toString()} />
                  </Box>
                </Flex>
              </Td>
              <Td border='none'>
                <Flex>
                  <TemplateText txt='1' />
                  <TemplateText
                    color='text.400'
                    txt={item.sell.symbol + ' ='}
                    px='3px'
                  />
                  <TemplateText txt={item.rate.toString()} />
                  <TemplateText
                    color='text.400'
                    txt={item.buy.symbol}
                    px='3px'
                  />
                </Flex>
              </Td>
              <Td border='none'>
                <TemplateText
                  txt={item.createAt}
                  fontSize='14px'
                  color='text.400'
                />
              </Td>
              <Td border='none'>
                <TemplateText
                  txt={item.expiration}
                  fontSize='14px'
                  color='text.400'
                />
              </Td>
              <Td border='none'>
                <TemplateText
                  txt={item.status}
                  fontSize='14px'
                  color={getColor(item.status)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
