import {
  Box,
  Divider,
  Flex,
  Icon,
  Image,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { ERC20_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import DropDownItem from 'components/common/DropDownItem';
import TemplateText from 'components/common/Text/TemplateText';
import TooltipExplain from 'components/common/TooltipExplain';
import { ORDER_TYPE } from 'constants/enum';
import { CoinPairTrade } from 'constants/interface';
import {
  LIST_COIN_LONG,
  LIST_COIN_SHORT,
  LIST_COIN_TRADE,
  SETTING_SLIPPAGE,
} from 'data/future';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoDiffAdded } from 'react-icons/go';
import {
  DAI_ADDRESS,
  ROUTE_ADDRESS,
  USDT_ADDRESS,
  VAULT_ADDRESS,
} from 'utils/ERC20';
import { parse } from 'utils/number';
import { toastError } from 'utils/toast';
import { VAULT_ABI } from 'utils/Vault';
import { useAccount, useBalance, useContractRead } from 'wagmi';

import ButtonAction from './ButtonAction';
import Calculation from './Calculation';
import Leverage from './Leverage';
import MarketInfo from './MarketInfo';
import Slippage from './Slippage';

interface Props {
  coinPair: CoinPairTrade;
  setCoinPair: (value: CoinPairTrade) => void;
  isLongTab: boolean;
}

export default function MainThread({
  coinPair,
  setCoinPair,
  isLongTab,
}: Props) {
  const orderTypes = useMemo(() => {
    return ['Market order', 'Limit order'];
  }, []);

  const [ordertype, setOrderType] = useState(orderTypes[0]);
  const [coinPay, setCoinPay] = useState(
    isLongTab ? LIST_COIN_LONG[0] : LIST_COIN_SHORT[0]
  );
  const [valueInput, setValueInput] = useState(0);
  const [valueInputPay, setValueInputPay] = useState(0);
  const [leverage, setLeverage] = useState(10);
  const [settingSlippage, setSettingSlippage] = useState(
    SETTING_SLIPPAGE[1].value
  );
  const { isConnected, address } = useAccount();
  const [balance, setBalance] = useState(0);
  const [timeInterval, setTimeInterval] = useState(true);

  const isMaketOrder = useMemo(
    () => ordertype === ORDER_TYPE.MARKET_ORDER,
    [ordertype]
  );

  const isEmptyInput = useMemo(() => {
    return valueInputPay === 0;
  }, [valueInputPay]);

  const { data: balanceToken } = useBalance({
    address: address,
    token: isLongTab ? DAI_ADDRESS : USDT_ADDRESS,
    enabled: isConnected,
    onError(error) {
      toastError(error);
    },
  });

  const { data: allowance }: any = useContractRead({
    address: DAI_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'allowance',
    enabled: isConnected && timeInterval,
    args: [address, ROUTE_ADDRESS],
  });

  const allowanceNumber: BigNumber = useMemo(() => {
    if (allowance) {
      return BigNumber(allowance.toString() / 10 ** 18);
    }
    return BigNumber(0);
  }, [allowance]);

  const isApprove = useMemo(() => {
    return valueInputPay <= allowanceNumber.toNumber();
  }, [valueInputPay, allowanceNumber]);

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    setInterval(() => {
      toggle();
    }, 5000);
    setBalance(Number(balanceToken?.formatted) || 0);
  }, [balanceToken, setBalance, toggle]);

  const isInfinity = useMemo(() => valueInput < balance, [valueInput, balance]);

  const { data: btc_price }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'getMaxPrice',
    args: [DAI_ADDRESS],
  });

  const _price = useMemo(
    () => (Number(btc_price) / 10 ** 30).toFixed(2),
    [btc_price]
  );

  return (
    <Box>
      <Flex alignItems='center' gap='10px'>
        <Box w='40%'>
          <Flex alignItems='center' mb='10px'>
            <TemplateText
              fontWeight={400}
              txt='Order Type'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
            />
            <TooltipExplain
              label='Market order: execute the order immediately at current price.
              • Limit order: execute the order when the market trades at a specific price.'
            />
          </Flex>
          <DropDownItem content={ordertype} isOrderType>
            <Box bg='bg.100' borderRadius='10px' w='160px' mt='-10px' p='5px 0'>
              {orderTypes.map((item) => (
                <TemplateText
                  cursor='pointer'
                  borderRadius='10px'
                  key={item}
                  onClick={() => setOrderType(item)}
                  p='10px 20px'
                  color={item === ordertype ? 'text.500' : 'text.400'}
                  _hover={{ color: 'text.500', background: 'bg.300' }}
                  txt={item}
                />
              ))}
            </Box>
          </DropDownItem>
        </Box>
        <Box w={{ base: '60%', md: '100%' }}>
          <Flex alignItems='center' mb='10px'>
            <TemplateText fontWeight={400} txt='Price' />
            <TooltipExplain label='Asset pricing is powered by Chainlink Oracles. It is an aggregate of prices from leading volume exchanges' />
          </Flex>
          <NumberInput
            value={_price + 1}
            onChange={(e) => setValueInput(parse(e))}
          >
            <NumberInputField
              _focus={{ boxShadow: 'none' }}
              color={isMaketOrder ? 'text.400' : 'text.500'}
              bg={isMaketOrder ? '' : 'bg.100'}
              disabled={isMaketOrder}
              _focusWithin={{
                border: '1px solid #2669f5',
              }}
              border='1px solid #28344b'
              _hover={{ borderColor: 'transparent' }}
              borderRadius='8px'
            />
          </NumberInput>
        </Box>
      </Flex>

      <Box mt='10px'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center'>
            <TemplateText fontWeight={400} txt='Pay' />
            <TooltipExplain label='choose how much collateral you wish to allocate for this trade.' />
          </Flex>
          <TemplateText txt={`Balance - ${Number(balance || 0).toFixed(3)}`} />
        </Flex>
        <Flex
          p={{ base: '10px', md: '20px' }}
          bg='bg.100'
          borderRadius='10px'
          mt='10px'
          h='70px'
          _focusWithin={{ border: '1px solid #2669f5' }}
          alignItems='center'
        >
          <Box w='100%'>
            <NumberInput onChange={(e) => setValueInputPay(parse(e))}>
              <NumberInputField
                p='0px'
                color='#F6342B'
                fontSize={16}
                fontWeight={700}
                placeholder='0.0'
                _focusVisible={{
                  borderColor: 'transparent',
                }}
                border='none'
              />
            </NumberInput>
            {!isEmptyInput && (
              <TemplateText
                className='text-Token'
                fontSize='12px'
                color='text.400'
                wordBreak='break-word'
                fontWeight={400}
                txt={`~$ ${Number(
                  isLongTab ? Number(_price) * valueInputPay : valueInputPay
                ).toFixed(3)}`}
              />
            )}
          </Box>

          <DropDownItem content={coinPay.symbol} img={coinPay.img}>
            <Flex direction='column' gap='15px' p='5px 0'>
              {(isLongTab ? LIST_COIN_LONG : LIST_COIN_SHORT).map((item) => (
                <Flex
                  cursor='pointer'
                  onClick={() => {
                    setCoinPay(item);
                    setValueInputPay(0);
                  }}
                  key={item.name}
                  alignItems='center'
                  justifyContent='space-between'
                  p='10px'
                  borderRadius='10px'
                  _hover={{ background: 'bg.300' }}
                >
                  <Flex alignItems='center' gap='10px'>
                    <Image src={item.img} h='30px' w='30px' />
                    <Box>
                      <TemplateText
                        fontWeight={400}
                        txt={item.symbol}
                        color={
                          item.name === coinPay.name ? 'text.500' : 'text.400'
                        }
                      />
                      <TemplateText
                        txt={balance.toString()}
                        fontWeight={400}
                        color={
                          item.name === coinPay.name ? 'text.500' : 'text.400'
                        }
                      />
                    </Box>
                  </Flex>
                  <Icon as={GoDiffAdded} h='15px' w='15px' color='text.400' />
                </Flex>
              ))}
            </Flex>
          </DropDownItem>
        </Flex>
      </Box>

      <Box mt='10px'>
        <Flex alignItems='center'>
          <TemplateText fontWeight={400} txt='Position Size' />
          <TooltipExplain label='Size equals leverage times your amount of collateral' />
        </Flex>
        <Flex
          p={{ base: '10px', md: '20px' }}
          bg='bg.300'
          borderRadius='10px'
          mt='10px'
          h='70px'
          _focusWithin={{ border: '1px solid #2669f5' }}
          alignItems='center'
        >
          <Box w='100%'>
            <TemplateText
              fontSize='16px'
              className={!isEmptyInput ? 'text-Token' : ''}
              color={!isEmptyInput ? '#0ECB81' : 'text.400'}
              wordBreak='break-word'
              fontWeight={700}
              txt={
                !isEmptyInput
                  ? Number(
                      (valueInputPay * leverage * (1 - settingSlippage / 100)) /
                        (isLongTab ? 1 : Number(_price))
                    )
                      .toFixed(3)
                      .toString()
                  : '0.0'
              }
            />
            {!isEmptyInput && (
              <TemplateText
                className='text-Token'
                fontSize='12px'
                color='text.400'
                wordBreak='break-word'
                fontWeight={400}
                txt={`~${Number(
                  (isLongTab ? Number(_price) : 1) *
                    valueInputPay *
                    leverage *
                    (1 - settingSlippage / 100)
                ).toFixed(3)}`}
              />
            )}
          </Box>
          <DropDownItem
            content={coinPair.coinBase.symbol}
            img={coinPair.coinBase.img}
          >
            <Flex p='10px 0' direction='column' gap='10px'>
              {LIST_COIN_TRADE.map((item) => (
                <Flex
                  p='10px'
                  _hover={{ background: 'bg.300' }}
                  cursor='pointer'
                  borderRadius='10px'
                  onClick={() => setCoinPair(item)}
                  key={item.coinBase.name}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Flex alignItems='center' gap='10px'>
                    <Image src={item.coinBase.img} h='30px' w='30px' />
                    <Box>
                      <TemplateText
                        fontWeight={400}
                        txt={item.coinBase.symbol}
                        color={
                          item.coinBase.name === coinPair.coinBase.name
                            ? 'text.500'
                            : 'text.400'
                        }
                      />
                    </Box>
                  </Flex>
                  <Icon as={GoDiffAdded} h='15px' w='15px' color='text.500' />
                </Flex>
              ))}
            </Flex>
          </DropDownItem>
        </Flex>
      </Box>
      {/* Leverage */}
      <Leverage leverage={leverage} setLeverage={setLeverage} />

      {isMaketOrder && (
        <Slippage
          setSettingSlippage={setSettingSlippage}
          settingSlippage={settingSlippage}
        />
      )}

      <ButtonAction
        settingSlippage={settingSlippage}
        valueInputPay={valueInputPay}
        leverage={leverage}
        isLongTab={isLongTab}
        isApprove={isApprove}
        symbolCoin={coinPay.symbol}
        isInfinity={isInfinity}
        timeInterval={timeInterval}
      />

      <Calculation
        symbolCoin={isLongTab ? coinPair.coinBase.symbol : 'USDT'}
        imgCoin={isLongTab ? coinPair.coinBase.img : './coin/USDTCoin.png'}
        leverage={leverage}
        valueInputPay={valueInputPay}
        entryPrice={Number(_price)}
        slippage={settingSlippage}
        isLong={isLongTab}
      />

      <Divider borderColor='#28344b' h='1px' mt='10px' />
      <MarketInfo />
    </Box>
  );
}
