import { Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { ChakraType } from 'constants/types';
import useModeTheme from 'hook/colorDarkMode';
import { ReactNode } from 'react';

interface Props extends FlexProps {
  fsText?: ChakraType;

  fwText?: number;
  content?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  colorText?: string;
  gap?: ChakraType;
  p?: ChakraType;
  bd?: ChakraType;
  isdetail?: boolean;
  img?: string;
  showText?: boolean;
  isOrderType?: boolean;
}
const TagBox = ({
  content = '',
  fsText,
  fwText = 500,
  iconLeft,
  iconRight,
  colorText,
  p = '4px 8px',
  gap,
  bd,
  isOrderType,
  img,
  showText,
  ...props
}: Props) => {
  const { darkModeColors } = useModeTheme();
  return (
    <Flex
      {...props}
      p={p}
      gap={gap ? gap : '8px'}
      alignItems='center'
      border={bd || darkModeColors.border200}
      cursor='pointer'
      justifyContent='center'
    >
      {iconLeft}
      {img && <Image h='30px' w='30px' src={img} />}
      <TemplateText
        display={showText ? 'block' : { base: 'none', xl: 'block' }}
        txt={content}
        fontSize={fsText ? fsText : '13px'}
        fontWeight={fwText}
        whiteSpace={isOrderType ? 'nowrap' : 'unset'}
        overflow={isOrderType ? 'hidden' : ''}
        textOverflow={isOrderType ? 'ellipsis' : ''}
        color={colorText || darkModeColors.text100}
      />

      <Box>{iconRight}</Box>
    </Flex>
  );
};

export default TagBox;
