import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import WrapSelectCoin from 'components/common/WrapSelectCoin';
import { ChildrenPoolType } from 'constants/interface';
import { POOL_IMPORT } from 'data/pool';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import CalculationPool from './CalculationPool';

export default function ImportPool() {
  const [filter, setFilter] = useState('');
  const [convertCoin, setConvertCoin] = useState<ChildrenPoolType>();
  const [convertMoney, setConvertMoney] = useState<ChildrenPoolType>();

  const newPools = useMemo(
    () => POOL_IMPORT.filter((item) => item.name === filter),
    [filter]
  );

  return (
    <Flex
      alignItems='center'
      direction='column'
      justifyContent='center'
      minH={{ base: '93.8vh', xl: '91.3Vh' }}
      gap='20px'
      p={{ base: '0 16px', lg: 'unset' }}
    >
      <Box
        w={{ base: '100%', lg: '460px' }}
        p='36px 30px 30px'
        borderRadius='30px'
        bg='bg.200'
        position='relative'
        mt={{ base: '50px', lg: 'unset' }}
      >
        <Flex mb='30px' alignItems='center' gap='10px'>
          <Link to='/liquidity'>
            <Box
              bg='bg.300'
              h='40px'
              w='40px'
              textAlign='center'
              borderRadius='100px'
              cursor='pointer'
            >
              <Icon
                as={ChevronLeftIcon}
                _hover={{ color: 'text.400' }}
                color='text.500'
                ml='-2px'
                h='30px'
                w='30px'
                mt='5px'
              />
            </Box>
          </Link>

          <TemplateText fontSize={18} txt='Import Pool' />
        </Flex>
        <Box p='50px 0' textAlign='center'>
          <Flex direction='column' gap='100px' w='100%'>
            {POOL_IMPORT.map((item, index) => (
              <Box
                key={item.name}
                onClick={() => {
                  setFilter(item.name);
                }}
              >
                <WrapSelectCoin
                  key={item.name}
                  newArrayPool={newPools}
                  item={item}
                  index={index}
                  name={item.name}
                  setConvertCoin={setConvertCoin}
                  setConvertMoney={setConvertMoney}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>

      <CalculationPool convertCoin={convertCoin} convertMoney={convertMoney} />
    </Flex>
  );
}
