import { TriangleDownIcon } from '@chakra-ui/icons';
import React from 'react';

interface props {
  isRotate?: boolean;
  unMargin?: boolean;
}

export default function TriangleDownIconRotate({ isRotate, unMargin }: props) {
  return (
    <TriangleDownIcon
      ml={unMargin ? 'unset' : '20px'}
      mt={unMargin ? 'unset' : '3px'}
      color='text.400'
      transition='all .25s ease-in-out'
      transform={isRotate ? 'rotate(180deg)' : 'rotate(0deg)'}
    />
  );
}
