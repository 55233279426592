import { Box } from '@chakra-ui/react';
import AllSymbol from 'components/svg/AllSymbol';
import routes from 'config/routes';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';

const Layout = () => {
  const [location, setLocation] = useState('');

  return (
    <Box bg='bg.100'>
      <Router>
        <Box>
          <Box>
            <Box
              position={location == '/' ? 'fixed' : 'unset'}
              w='100%'
              zIndex={999}
              bg='bg.100'
            >
              <Header setLocation={setLocation} />
            </Box>
            <Box>
              <Routes>
                {routes.map((route) => {
                  return (
                    <React.Fragment key={route.path}>
                      <Route
                        path={route.path}
                        element={
                          <Box>{React.createElement(route.component)}</Box>
                        }
                      />
                    </React.Fragment>
                  );
                })}
              </Routes>
            </Box>
            {location == '/' && (
              <Box>
                <Footer />
              </Box>
            )}
          </Box>
        </Box>
      </Router>
      <AllSymbol />
    </Box>
  );
};
export default Layout;
