import { Box, Flex, Image, Skeleton } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import ArrowBottomMenu from 'components/svg/ArrowBottomMenu';
import { CoinType } from 'constants/interface';
import InputChange from 'pages/Swap/Left/MoneyConvertBox/InputChange';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { convert_USD } from 'utils/number';
import { toastError } from 'utils/toast';
import { useAccount, useBalance } from 'wagmi';

import TemplateText from '../Text/TemplateText';
interface Props {
  isFrom: boolean;
  coin: CoinType;
  setValueInput: (value: number) => void;
  setBalance: (value: number) => void;
  setToken: (value: CoinType) => void;
  value: number;
  onOpen: () => void;
  isLoading: boolean;
  valueInput?: number;
  coinPrice: number;
}

export default function SwapCoinBox({
  isFrom,
  coin,
  setValueInput,
  value,
  onOpen,
  isLoading,
  valueInput,
  setBalance,
  coinPrice,
}: Props) {
  const { address, isConnected } = useAccount();
  const [timeInterval, setTimeInterval] = useState(true);

  const { data: balanceToken } = useBalance({
    address: address,
    token: `0x${coin.address}`,
    enabled: isConnected && timeInterval,
    onError(error) {
      toastError(error);
    },
    staleTime: 5000,
  });

  const toggle = useCallback(() => setTimeInterval((prev) => !prev), []);

  useEffect(() => {
    setInterval(() => {
      toggle();
    }, 5000);
    isFrom && setBalance(Number(balanceToken?.formatted) || 0);
  }, [balanceToken, isFrom, setBalance, toggle]);

  const isNaN = useMemo(() => value.toString() === 'NaN', [value]);

  const priceConvert = useMemo(() => {
    return convert_USD((isFrom ? valueInput : value) || 0, coinPrice);
  }, [coinPrice, isFrom, value, valueInput]);

  return (
    <Box
      alignItems='center'
      bg={isFrom ? 'bg.100' : 'bg.300'}
      borderRadius='12px'
      border='1px solid #28344b'
      p='16px 20px'
      mb={isFrom ? '20px' : 'unset'}
      _focusWithin={{ border: '1px solid #2669f5' }}
    >
      <Flex justifyContent='space-between'>
        <TemplateText
          txt={isFrom ? 'From' : 'To'}
          color='text.400'
          fontSize={13}
        />
        <>
          {isFrom && (
            <Flex>
              <Box
                as='button'
                onClick={() =>
                  setValueInput(
                    BigNumber(Number(balanceToken?.formatted || 0))
                      .dp(6, BigNumber.ROUND_DOWN)
                      .toNumber()
                  )
                }
              >
                <TemplateText
                  borderRadius='4px'
                  px='5px'
                  _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
                  txt='Max'
                  color='#fa3961'
                  fontSize='11px'
                />
              </Box>
              <Box
                as='button'
                onClick={() =>
                  setValueInput(
                    BigNumber(Number(balanceToken?.formatted || 0))
                      .dividedBy(2)
                      .dp(6, BigNumber.ROUND_DOWN)
                      .toNumber()
                  )
                }
              >
                <TemplateText
                  borderRadius='4px'
                  px='5px'
                  _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
                  txt='Half'
                  color='#fa3961'
                  fontSize='11px'
                />
              </Box>
            </Flex>
          )}
        </>
      </Flex>

      <Flex justifyContent='space-between' m='10px 0px'>
        <Flex gap='10px' alignItems='center' cursor='pointer' onClick={onOpen}>
          <Image src={coin.img} h='36px' w='36px' />
          <TemplateText
            w='auto'
            fontSize={{ base: '20px', lg: '16px', xl: '20px' }}
            txt={coin.symbol}
            _hover={{ color: 'text.400' }}
          />
          <ArrowBottomMenu h='12px' w='12px' color='#b7becb' />
        </Flex>
        <Box w='50%'>
          {isFrom ? (
            <InputChange
              setValueInput={setValueInput}
              defaultValue={valueInput}
            />
          ) : (
            <>
              {isLoading || isNaN ? (
                <Skeleton height='30px' borderRadius={5} />
              ) : (
                <TemplateText
                  textAlign='end'
                  fontSize={{ base: '22px', lg: '16px', xl: '22px' }}
                  fontWeight={700}
                  txt={BigNumber(value).dp(6, BigNumber.ROUND_DOWN).toString()}
                />
              )}
            </>
          )}
        </Box>
      </Flex>
      <Flex alignItems='center' justifyContent='space-between'>
        <TemplateText
          txt={`Balance: ${BigNumber(Number(balanceToken?.formatted) || 0)
            .dp(6, BigNumber.ROUND_DOWN)
            .toNumber()}`}
          color='text.400'
          fontSize={13}
        />
        {isLoading ? (
          <Skeleton height='19px' w='30%' borderRadius={5} />
        ) : (
          <TemplateText
            txt={`~$${priceConvert}`}
            color='text.400'
            fontSize={13}
          />
        )}
      </Flex>
    </Box>
  );
}
