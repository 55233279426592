import {
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import Loading from 'components/svg/Loading';
import { TAB_TYPE } from 'constants/enum';
import { CoinPair } from 'constants/interface';
import useVisible from 'hook/useVisible';
import { useEffect, useMemo, useState } from 'react';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import LimitOrder from './LimitOrder';
import MoneyConvertBox from './MoneyConvertBox';
import ModalSetting from './SettingModal';

interface Props {
  coinPair: CoinPair;
  setCoinPair: (value: CoinPair) => void;
  defaultTab?: string;
  setTab: (value: string) => void;
}

export default function LeftWrap({
  coinPair,
  setCoinPair,
  defaultTab,
  setTab,
}: Props) {
  const [slipPage, setSlipPage] = useState(3);

  const LIST_TAB = useMemo(() => ['Swap', 'Limit Order'], []);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set isLoading to true every 3 seconds
    const timer = setInterval(
      () => {
        setIsLoading(!isLoading);
      },
      isLoading ? 1000 : 10000
    );

    // Clear the timer if the component unmounts
    return () => clearInterval(timer);
  }, [isLoading]);

  const defaultTabPanel = useMemo(
    () => Number(defaultTab === TAB_TYPE.LIMIT_ORDER),
    [defaultTab]
  );

  const isFavorite = useVisible();

  const settingModal = useVisible();

  return (
    <Box pb={{ base: '70px', lg: 'unset' }} w='475px'>
      <Box
        bg='bg.200'
        p={{
          base: '14px 5px 28px',
          md: '16px 16px 28px',
          lg: '16px 10px 28px 10px',
          xl: '6px 20px 28px',
        }}
        borderRadius='20px'
      >
        <Tabs
          variant='enclosed'
          index={defaultTabPanel}
          onChange={(index) =>
            setTab(index === 0 ? TAB_TYPE.SWAP : TAB_TYPE.LIMIT_ORDER)
          }
        >
          <Flex alignItems='center' justifyContent='space-between'>
            <TabList w='70%' borderColor='bg.200'>
              {LIST_TAB.map((item) => (
                <Tab
                  position='relative'
                  p={{ base: '5px', md: '16px', lg: '16px 0px', xl: '16px' }}
                  key={uuidv4()}
                  _selected={{
                    color: 'text.500',
                    borderBottom: '5px solid transparent',
                    borderImageSlice: 1,
                    borderImageSource:
                      'linear-gradient(190deg,#1d6dff,#132038)',
                    borderTop: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    marginTop: '5px',
                  }}
                  color={item === 'Limit Order' ? 'text.200' : 'text.500'}
                  fontSize={{ base: '14px', md: '16px' }}
                  fontWeight={600}
                  isDisabled={item === 'Limit Order'}
                  w='50%'
                >
                  {item}
                  {item === 'Limit Order' && (
                    <TemplateText
                      bg='bg.300'
                      p='2px 4px'
                      borderRadius='10px'
                      top='0px'
                      right='10px'
                      position='absolute'
                      txt='Soon'
                    />
                  )}
                </Tab>
              ))}
            </TabList>
            <Flex gap='10px' alignItems='center'>
              <Icon
                as={isFavorite.visible ? BsStarFill : BsStar}
                onClick={isFavorite.toggle}
                color='text.500'
                _hover={{ color: 'text.400' }}
                h='20px'
                w='20px'
                cursor='pointer'
              />
              <Icon
                onClick={settingModal.show}
                as={MdSettings}
                _hover={{ color: 'text.400' }}
                cursor='pointer'
                color='text.500'
                h='20px'
                w='20px'
              />
              <Loading />
            </Flex>
          </Flex>
          <TabPanels>
            <TabPanel p='0'>
              <MoneyConvertBox
                coinPair={coinPair}
                setCoinPair={setCoinPair}
                slipPage={slipPage}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel p='0'>
              <LimitOrder coinPair={coinPair} setCoinPair={setCoinPair} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <ModalSetting
        isOpen={settingModal.visible}
        onClose={settingModal.hide}
        setSlipPage={setSlipPage}
        slipPage={slipPage}
      />
    </Box>
  );
}
