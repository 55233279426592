import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import MantleDex from 'components/svg/MantleDex';
import { BALANCE, GAS_REFUND_RATE } from 'data/Swap';
import useModeTheme from 'hook/colorDarkMode';
import useVisible from 'hook/useVisible';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';

import GasRebateHistoryModal from './GasRebateHistoryModal';
import GasRefundProgramModal from '../GasRefundProgramModal';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export default function ModalGasRefund({
  isOpen = true,
  onClose = () => ({}),
  onOpen,
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();
  const gasRebateHistoryModal = useVisible();
  const gasRefundProgramModal = useVisible();
  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px 10px 0 0 '
            alignItems='center'
            position='relative'
          >
            <Icon
              as={BiChevronLeft}
              position='absolute'
              top='15px'
              h='30px'
              cursor='pointer'
              w='30px'
              left='15px'
              color='text.400'
              bg='bg.200'
              borderRadius='100px'
            />
            <TemplateText
              fontSize='18px'
              color='text.500'
              txt='Gas Refund Program'
            />
          </ModalHeader>
          <ModalCloseButton
            top='15px'
            _hover={{
              color: darkModeColors.text555,
            }}
            bg='bg.200'
            borderRadius='50%'
            color={darkModeColors.text550}
          />

          <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='20px'>
            <Flex
              bg='bg.300'
              p='10px 20px'
              borderRadius='10px'
              alignContent='center'
              justifyContent='space-between'
            >
              <Flex alignItems='center' gap='10px'>
                <MantleDex />
                <Box>
                  <TemplateText txt='Total Gas Refund' color='text.400' />
                  <TemplateText txt='0 PTD' />
                </Box>
              </Flex>
              <ButtonBase
                h='30px'
                mt='6px'
                bg='bg.100'
                content='History'
                color='text.500'
                onClick={() => {
                  gasRebateHistoryModal.show();
                  onClose();
                }}
              />
            </Flex>
            <Flex
              bg='bg.300'
              p='10px 20px'
              borderRadius='10px'
              alignContent='center'
              justifyContent='space-between'
              mt='20px'
            >
              <Flex alignItems='center' gap='10px'>
                <MantleDex />
                <Box>
                  <TemplateText txt='Claimable Gas Refund' color='text.400' />
                  <TemplateText txt='0 PTD' />
                </Box>
              </Flex>
              <ButtonBase
                h='30px'
                mt='6px'
                bg='#2669f5'
                content='Claim'
                color='text.500'
                onClick={() => {
                  gasRefundProgramModal.show();
                  onClose();
                }}
              />
            </Flex>
            <Box
              p='10px 20px'
              border='1px solid #28344b'
              mt='20px'
              borderRadius='10px'
            >
              <TemplateText txt='Gas refund will be claimed as PTD and distributed after 30 lock-up days.' />
              <TemplateText txt='*Note that you need to switch to the corresponding chain to claim your refunds.' />
            </Box>

            <Flex
              p='10px 20px'
              border='1px solid #28344b'
              mt='20px'
              borderRadius='10px'
              justifyContent='space-between'
            >
              <Box textAlign='center' w='50%' borderRight='1px solid #28344b'>
                {BALANCE.map((item) => (
                  <TemplateText key={item} txt={item} />
                ))}
              </Box>
              <Box w='50%' textAlign='center'>
                {GAS_REFUND_RATE.map((item) => (
                  <TemplateText key={item} txt={item} />
                ))}
              </Box>
            </Flex>

            <ButtonBase
              mt='20px'
              w='100%'
              content='Lock now'
              bg='#2669f5'
              color='text.500'
            />
            <TemplateText txt='Learn more' textAlign='center' mt='20px' />
          </ModalBody>
        </ModalContent>
      </Modal>
      <GasRebateHistoryModal
        isOpen={gasRebateHistoryModal.visible}
        onClose={gasRebateHistoryModal.hide}
        onOpenCurrentModal={onOpen}
      />
      <GasRefundProgramModal
        isOpen={gasRefundProgramModal.visible}
        onClose={gasRefundProgramModal.hide}
        onOpenCurrentModal={onOpen}
      />
    </Box>
  );
}
