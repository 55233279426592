import React from 'react';

export default function FantomChains() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='30'
      height='30'
      viewBox='0 0 129 129'
      enableBackground='new 0 0 129 129'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='129'
        height='129'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAACBCAYAAADnoNlQAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAX
KUlEQVR42u2dd3xUVfbAv3dmEkIJ0oQMgqCgQcBFFhaQ3osi8lvBsosFWSFBQRTXRREDyIruKqiU
BFwbC2vBgjSRbigiTUQQQlEQSAKEXgIkM/f3x50h05JMeTPvTcj388knM+/Ou3PefWfuu/fcc84V
lEBqDZJVpIVEm5lEAYlCUk8KKglJvIR41F8FAfESBHDO+SfgnITzSE5LwX4TZCDIEIKMI9PECb2v
LRwIvQUIlWqPy3hLDO1MZjpLSUugAVAtTF93AsgANgrBytx80k/NFGf0boNQiTolqD9Mlsm109Zu
o5M00VlIWgBmncSxA5sRrBSwKvYi6Qc+FJf0bqNAiRIlkKJGMi3N8IiEB4AqektUCGeAT4VkVmYa
60FIvQXyB0MrQY2n5E0inwFC8DBwi97yBMivUjLLbmf2sZliv97CFIUhlcA6RDbHxGigr96yaIGA
hTb459FUsUFvWQqRzzhYh8q2SF4CeugtS5hYLmFCdirpRnpUGEIJEobKTkKSAnTQW5YIsU7YGZc5
QyzTWxDQWQluGCZryXwmSeivd0PoguAr8hmRNVP8rq8YejBYxiRYeFpIxgLl9WwAA3ARySuVTzDp
l7niih4CRFwJrMmyHZAKNNLjgg2LYLfdzpNH08TKyH91pEiRFutxXkEyKtIXGWVMqpzDC5HsFSKi
BDcMk7Xs+XwCtInUhUU5G6Xkgew0cSASX2YK9xckJMte9ny2UaoAgdBCCH60Jsu+kfiy8PUEKdJk
PcYE4IVIXEgJ5q2s6vydcSI/XF8QFiVo2F/GnqrKhwgeCl/bXFPMM8fxl8OTRW44KtdcCao9LuNj
4vgCSbfwt801xZoyZehz4C1xWuuKNVWCGkmyusnEIiTNI9Y01xBC8rPZQs9DU0WmpvVqVZH1SVkH
O8uIvtW+aOOg2U63wzPEXq0q1EQJaiTJ6kKwTkB9/drGf26sCk1vgpqVwGaHrNOwcT8cjR4fod/N
ktaH08QRLSoLWQkcY4CVRn8ECAHdb4cnu0Pzm73L7RLSd8E7S2DDPr2l9YsdZcrQTosxQkhK0LC/
jD11PQuNPghscyuMuhea3eTf51f9AhO/hh2H9Ja8WNaY4+gR6qwheCVIkSbrUWYbeRrYpA680Afa
3xbc+fO3wOsL4Ldjel9JEUi+zqpBv1DsCEErgTVZvopBDUH1E+Af98DdTb3LpIQvN8GctbDzMEig
QU34axvo3wpMHi1is8Mn38OkRWrsYEgE72RNF08Hf3oQJCTLXgIW633tntxQBZ672/fNBFi6HV6f
D7sKmWDVT4Dn74HePpTnSj68vxqmLoWT54OTr041pUhXwmH7k/TLShNfBHNqwErgWAzaBlQNw6UE
RbV4GN4THm0HMRbv8u/3wqtfw5Zf/avvjjrwwr3QroF32flLkLYcZq5Ur/0lxgzLR6sGH/UJrN+j
eTOctUHTY6nCz6ssIDAlSJEW6zFWY5DFoIplYUgXGNwFypfxLt9xSN3873apx0CgtE2EF/sqpfDk
5Hl4ewnMWgOX84qva0gXSLmv4P1/VsH4LyDfrmmTbClvoc2+KeJyICcFpATWoXKiEfwB4mJgYAcY
1gMq+fBL+u2YGtAt3KqmfqEgBPRsAqP6wC0J3uWZp+CNRfD5hsJvqNkEq8dAvRrux1f/AknvwVkN
VwQkTMlOFcMDukZ/P+jwCErXTtzAiTHDA3fCs3dBQiXv8qNn4I2F8NkGyLNp+91mE/RrAc/1VmMP
T/YfVeONRdt89zoVy6rxxsAOSrGcrM2AB98JXVndEPTKmi6W+P9xfxgsY6xmfkQnlzCTgHuawfO9
4abq3uWnL8CUb+GD7+CSH11zKMRa4JF2MKIXVKngXb79d2VjSN/tWxnuvAXefcL93Jc+U4NOrRCw
PzaXxv6GxPmlBAlJcqQQvKFpa/ojnIDOjVRX3KiWd/nFKzBzhRqoadml+kOFOBjcGZK6qteerN+j
lGHLb95lN1aFb0ZBZcej7PwlaPy8trMGCSnZqWK8P58tVgkcs4FdQAU/6tOMFvXUoKxFPe+yPBv8
d40amB0/G0mpvKlaAYb1hMfaq17Ck8Kmpfe1gCmPFbx/cIoyW2vIZZuNRv6EwBXrXibzmUQEFcBs
ghfvhXkjvRVASpj7A7Qdq7pQvRUA4MR5GPs5tBkLn6z3frZ3/wMsfVEpiSuLt7nPKro21ly0MmYL
74As9odeZEh3wlDZCfhXuBrQFyN6wTN3eR//djsM/g/MXhv5rt8fzuUqGRdsheoV4VZrQZlJQJfG
cOA47HKs++XboE0i3OjIpHDiPMzbrLlYt5RvzuYLm8cVaZUosidwhIZFlIdau79fvwfu+TcMTIPd
mrpShIe92fDEu3DX67Bmt3vZoE7u7117siph6mtNJlKK6w0KVQLHlDDisYHWSgWvr+TD6l3wiyar
5pFld6ayA7jOVm6o7P6Z0xcKXlcN1wNX0tyaRPeiPmIpomx0eJupeGItanzwt04weTH8bz3khc3n
VhssJmXLGHm3b1uGK++ugiXb1euwXpfgJeDbwot94MgPsCnM7eWTQ1PV4NAXB3Pg3wth3iaNjSsa
YBLQ+49q9dKXLQNU999EL3uriQ5Z00S67yLfR3XvBcDb2FKnGkx9DJaNhm63u1ve9EII6NQQloyC
tEHeChDMmkVYUHkffOKlBAlJsi4GyRCSuhxGfezt+3dbTfgoGb4eCa109GpsdjN8PgLmPAWNa7uX
/X4Chn8EH6/XTz43JN0ShsiGvoq8lEAIBugtr5M8m1qla50CE76CMxfdy5vfDF8+6/smhJMGNeHD
JFjwnDIDu3LsLLz4CbQbB5//oJxSjIIw87Cv4x5KIAXwiN7CepJ7BaYvg5Zj1ADxoke8bqeGsPQF
392xltSppqx8K0YrI5ArZy7CP+fBnS/Dh+kGHcBK/kqK9Prhu80OaiTTEgPHDZzNVQPDD75Ty8iP
tXd3IunTTLmUfbweJi2G7NPafG/1ivB0LxjQVq1kuqLn+kUQ1E44TodsWOV60E0JHHkCDU/OOUj5
XHn3PHuXmpI53cnMJnWz+reE97+Dqd/CqQvBfU/FsspF/W+doGyse1lePny0Rq1eGsF87TeSh/FQ
gqtdQ/1hsowjUWTUcOQkjJwNHcYrz2BXysRAclfY8IoyRfvyPCqMsrHq5v/wiupxXBXALtUaQZux
8PLcKFMAQEA/62BZzvXYVSXItdMW42YKLZL9R5WHTo+JsHKne1l8nHLm+H68MtvGFmEeizErX4H1
42B0X7iunHv5gq3Q8RV4djYcPqn3VQdNvBR0dD1wtUnsNjoZI6Fd8Px8CAZMg5b1laXxTy6rkNXi
4ZX+ytfvzYXw1eaC9fsYi/Iw/ntvqHu9d70rd6rl4J+NH4ziFyYTnXDxFr+qBI5k0SWCH/ZB30m+
HVJqVYHJj8D4+2Fftoo7qF9DPf892bRfOar+EB1haX4jJZ1d31tAxRM6soWXGKSEFTtg1U7f5tz4
OGha1/e5Ow/Da/NVD2AYi5+WCJrWGiSrHH5PnATHmMASQzv0SxcfVuxSeR2/scg/1/CzuarrL7EK
oBC2mIIVYhNcfUaUOJw+iktGwbSBasZQHBXLwqyh8NWzvl3bSgpSFNxzC4CEVnoLpTVF+SiezVXm
XKej55mL6lfvGsPQop5ycVu5Uz0aoiBCOSCE4E7na+cUsUGQdRmOxrVh9pO+fRQv5SnjUcsx6rnv
ZE8WtHrZt0m6c6MCk7Rn8EhUI0l0ehxZag2SVWzh2zMoYtxcXdkD+jTzLsu3w//WqZtcWDYSV5P0
0z3hkfbuJmKnSfqzDcokfSR67QRO4qsNJiFnJlkWaSFRb2lCoWZl5cVzfyvfzijzNsO/FignT3/I
OQdj5iqT9Mi7lflZuJikH2qtIpE+WqOymuSc07sFgsdiIhHIstjMJEajfaBqBRWJ7LmI5GTFDvUs
d+32A+HQCRgxSy0M/aMP9HBZNYyxqPWEv7SJqsUjLwQkAqstjhdRQ3GRyFobeHZnKk/nZjepgaar
/0C5WLUuMbBDQRhcri7J6oMmEcAiJPWioSOIi4HHO8JT3X1HIu/KhInzYEWY5vdbfoN+b0GH25RJ
2tWJ5bpy8NL/wROd4a1vosMhFkAKlW3OIgWVMLAWxJjVc/iZu6DGdd7lkXQ+lVK5kafv8m2FrHEd
THxQxSe+sTAswSSaIqASqJ4g3qg60LI+pKcojx5Pjp2FtxbDHB1+dXaplq6/2aYGpM/1dldQpwfS
Uz3gYkDpIiJOPIDFsXewIfHlRHo2F6YthfdWec/pI02eDeasgy82qnHB8J7uy8+J1uDrjhAVQVkM
DasErlzOUylepi1zj9wxApfylGf0nHWQ3E2FrHt6IhkU1RMQ4ZDzQPHH0GMUnItPH6xWBqcB7bx9
Eg2GUgLH9vGG5Eq+chJZm6G3JIFx7CyM/gzmb4WPh6mZjUGJY7CMMUmDbJDpi1gLzHkSxvdXnkHR
QuXy8PKf4dPhhlYAABqeQliAc0DlUCsLF07r3EOtYcYKmLEczhl0c/ryZZQRK6mrclqJAi7/Mldc
MbwSOClfRrmXD+ygbPYfpvvnJBIJYi3KzX1Er+jqsYCzoJaSDbsEsmCryvnjSuXyKinkurGqd7CE
fT+3wjGb1ALT2rEw4X53BbBL+HQDLPtZP/n84ByomA3DKsGvx+CxNOjzhkpN60rNyvDmAFg1Ri3x
RjJCWQi1oLR8NLz9qHJedWXxNug8AZ6ZpV0UVJg4B8pYFGS65six+dfC7fb1aqi8gD8dVAtHnili
tKb1rSrvsa+9E9ZmqLR1Px7Qu8X85iyABclp484PCijObt+kjhqNh+tG3F5b3fyOPoK7I6WAWiPg
DKgFpP1RoANX8bTbe6aFaZsIi55X5a8vUK5joVCUx9K+bOWz8M1P0emZLGEfqHFVRhTK72W390x2
3esO6NFE5T18c2HgYWMJldRs5KHW3h5LmafUyuUXP2iepTzSZABYEGQYeSm5OFzt9kO6wJCuytkD
1Kj3gVbw5+b+u4NVKq98FgZ19HZRDzS9vdGRwqEEQpARjV2ZJ05H0Q/TYXgPd0dRV4NT2nLlEuZJ
uVjlFJLczTsk7cLlgvOMaqgKhhiTQwmOTBMnrMnyBAbaySQUjp91dxTt17Igd0H5MurY4x3d08jU
q6Gilq+v6F5XXj58kA5TlqiMoyWM84emkgUFAakZQOvg6zMeTkfR1GXKUbRnk4Kyyh7uaZ7ZRO0S
Pvse3iwZruWFkQHKxdipBBspYUpw9Uqz4PEZ8Me68EJftUdiUSz6Ubmo783WW/LwItQ9BxxKIAQr
pWSE3oKFk60H4P63ocmN8OcWKiL5hspqdJ91Slkkv9hY8m++E7tgpfO1BSA3n/Q4M3b8SH0fzUgJ
2w6qv2sdkc9q52sTwKmZ4gxgcN/YUjTkp6yZIsf5puCX79I9lFLCke73+qoSCI+0ZqWUXISpkBR2
sRdJx7GgUEqJ5kJeYXkMHdunfaq3hKWEnS+PTxdupi+32YCQzNJbwlLCi7DzX89jbkqQmcZ6IOAN
l0uJDiRkZp70ngB42AWEFMJbU0opGZhgDnOFzcdxd/LzS5WgpGIz+763XkpwbKbYL2Ch3gKXoi0C
Vh2dKnz6Pvs0E9vgn3oLXYq22CQTCivzqQRHU8UGKVlBmGhcW3nrNrsJapcILwZjI2DD0bTCjYFF
7Ys4AegSDqHeflRtZgVql5KRs31/zuARvcUiBMSXDb2eULHDBKfvgC8KXTXMTuM7YF04hDrlYqrw
3FPAdZeSpK7w1iPR2Vv8qR58+Qz0bV5wLEcf76Rt2akFae19UcTSsZDCzrhwSOV6o6/3iN1z3ULO
JJRbuTPMy9P9y4i4btfX0iPTypy1OggkGV9ULwDF+A9kzhDLEHyltVy/nyh4fUcdqOASwfv6fLXz
mSsxZuUXuGG82r+gogG6WE+ceYqWOzbudEVKtWfTB99FXKylWWnMK+5DxT5145uO/R4TQwDNIu3z
8tXmVaB8+vcfLUg6KYH03Spl3K1W94RQMWb163q4nXq/45Dagl5Prq8Io+9VG2k0ru0dE7k2A4a+
r9zWIswVs53eZ7eIE8V90K/gI2uSHIVgolbSWUyw/fWCYJHTF6Dna+49BKjHwd1NVQSQr+TSemYw
q1hWuac/0bkgzsGVbQfh1Xm6ZlmZkJUqxvjzQb+UoGF/GXvqen5CapcN/bH28OqDBe9PnIcnZsIG
H5lILSbo70gVZ63kXX4wpyBvYLh3JI2L8R3x5GSvIzRtib6haQew0Shrprjoz4f9DkOskSQ7m4R2
tgOTgE+Gq9hBJ1LC+6uVt6+vII8yMfBoO5UqroqPdFu7M9UNWPaz9jfAYoL774TnPGIfnRw56QhN
26j/1rh2yT1H04TfVt+AYlGtyfJN4FmthL2uHMz8G7Tz6F8O5kD78YV38fFxKi3MkC7ug0onW36D
176GdXtCl9H5SPpHHxWc6knOOZXKdvbagt3VdEUwI2u6SArslABo2F/GnqrGGtBu06wYM4y9DwZ2
LDg2ebH6VRVHlQqqWx7Ywfd+h+m7YOJ8FToeKEJA+wYqHP0PN3qXn7sE05eq3IoXDJK1VMJ2Sxyt
Dk8WAeVcDzgqPSFJ1hWCH3HkxdWKdg3gtQfBYlYbUAaSLbxm5YLtcn3tebB4m5p6+htTUFSgyuU8
eG+1yqoa7Pa7YeKCgGaZqSLgoWhQqQmsybIvaG8/KBOjrIP7ggwAKSqXgF2qMPXJi7xnIU4Srer8
Xnd4lxk+qaZkQFaamBPMqUHnp7Amy8lgzKil22vDqHuhk4+sIjY7fLUJvtwEW39Tj5GmddUGFq4b
WziRUs06/r3Q/91TdOA/WaniiWBPDj5JSYq0WI8xF+irdwsURqv6aqOK5jcHd/6yn9VsY9cRva+k
SL6tnEOfX+aKoNN9h5SpptYzsqztEt8C7fRuiUIvUEDXxsrcfNsN/p2zYZ/Ke7Rpv97SF8umfEFn
T+/hgNsoVCnqjpCVrlwiXQpuD7WucGIScG9zlYWkMGXYtF9N91bvioocRHukhbbZU0TIDylNclbV
fkrWzLexHqijd8v4Q4OacEddZX3Mt8GxM+rXfzAn1Jojg4RMJG2y08QBLerTLHFZrSHyFpuJ5cCN
IVdWSqEIyLKb6JY9TezUqk7NQtEPzxB7zZLWwA5dWufaYI9d0lpLBQCN8xEcThNHYqA9oIf7REln
k7TQVqtHgCuaJ6X4PVWcMsfRXcL8iDTNtcHSfEFnLQaBvghLZpLDk0VudnXukzAlvG1zDSB5t3IO
94Q6DSyKsGe0TRgq+wnJezh23irFby4gGRKsKTgQIpLWuPpgWc9s5lOgWciVXQNI2G6C+4NZDAqG
iCSqOjZT7C9voU3p48EPBDMscbSKlAKor4ww1qGyp5BMlVAv0t9tcA7YJcMC8QjSioinrMuaLpbE
5tJYQgpgEHcMXbkCTMBGIz0UAHTeDq/6YFnPbOEdJHfpKYeOLDXbeerwDLE39KqCxwD7XUhRI4m7
TSZSkDQPvb6oYBswLiuVr4uLDooEBlACJ1JYk+iOYAzQRm9pwoGADXaYoGID9b/5LnIZDSkSkmkv
4CWgq97SaIGAVTbJBBUebpyb7yKfcUkYIhsKMw8j+StQO+QKI4iETBPMsZn5b2EZQoyCoZXgKinS
lHCcDkgeFtAPx27fBuQC8KWAWZk5rPKVJMqIRIcSuGAdLMtJQUeTiU5S0hlBU52v4yckK4WJVXmw
Kpw2/nARdUrgSa1Bsoothg7SRGcBrZAkEr6e4jyQIWCjXbBS5LPaNVt4tBL1SuCNFNUGk2AxkSgg
EUiUgvpCBcvEoxay4h1/ziC2y6jdQs85/ws449g3MEMKMmJMZKg9g4w3sCslFAbLmIb9ZWzoFUU3
/w9YIbHG8VMSrAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMi0yOFQwNTowNjozMyswMTowMPGV
e4QAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDItMjhUMDU6MDY6MzMrMDE6MDCAyMM4AAAAAElF
TkSuQmCC'
      />
    </svg>
  );
}
