import React from 'react';

export default function Op() {
  return (
    <svg
      width='30.000000000000004'
      height='30.000000000000004'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <title>background</title>
        <rect
          x='-1'
          y='-1'
          width='8.12243'
          height='8.12243'
          id='canvas_background'
          fill='none'
        />
      </g>

      <g>
        <title>Layer 1</title>
        <g id='页面-1' fill='none' fillRule='evenodd'>
          <g stroke='null' id='画板' fill='#FF0420'>
            <circle
              stroke='null'
              id='椭圆形备份-3'
              fillOpacity='0.2'
              cx='15'
              cy='15'
              r='15'
            />
            <circle
              stroke='null'
              id='svg_3'
              fill='#FF0420'
              r='10.49892'
              cy='15'
              cx='15'
            />
            <g stroke='null' id='编组-19' fillRule='nonzero'>
              <path
                stroke='null'
                id='svg_1'
                fill='white'
                d='m10.92703,18.54204c-0.78057,0 -1.42008,-0.18368 -1.9186,-0.551c-0.49196,-0.37387 -0.73794,-0.90516 -0.73794,-1.59391c0,-0.14431 0.01642,-0.32143 0.04919,-0.53133c0.08531,-0.47225 0.20666,-1.03965 0.36406,-1.70213c0.44602,-1.80381 1.59721,-2.70572 3.45351,-2.70572c0.50507,0 0.95764,0.08526 1.35779,0.25578c0.40014,0.16397 0.71497,0.41325 0.94453,0.7478c0.22957,0.32794 0.3444,0.72152 0.3444,1.18066c0,0.13775 -0.01642,0.31158 -0.04924,0.52148c-0.09836,0.58377 -0.21646,1.15118 -0.35421,1.70213c-0.22957,0.89865 -0.6264,1.571 -1.19052,2.01702c-0.56411,0.43948 -1.31841,0.65923 -2.26299,0.65923l0.00001,-0.00001zm0.13775,-1.41683c0.36737,0 0.67889,-0.10822 0.93473,-0.32469c0.26239,-0.21646 0.44933,-0.5477 0.56081,-0.99372c0.15085,-0.6166 0.26569,-1.15448 0.3444,-1.61363c0.02622,-0.13775 0.03933,-0.27875 0.03933,-0.4231c0,-0.59688 -0.31158,-0.89535 -0.93468,-0.89535c-0.36737,0 -0.6822,0.10822 -0.94459,0.32469c-0.25578,0.21651 -0.43948,0.54775 -0.55095,0.99377c-0.11808,0.43948 -0.23617,0.97736 -0.35421,1.61363c-0.02627,0.13114 -0.03938,0.26889 -0.03938,0.4132c0,0.60349 0.31488,0.90521 0.94453,0.90521l0,-0.00001z'
              />
              <path
                stroke='null'
                id='svg_2'
                fill='white'
                d='m15.23573,18.44367c-0.07215,0 -0.12794,-0.02297 -0.16727,-0.0689c-0.03283,-0.05249 -0.04263,-0.11148 -0.02952,-0.17708l1.35779,-6.3954c0.01311,-0.07215 0.04919,-0.13119 0.10822,-0.17713c0.05905,-0.04588 0.12134,-0.06885 0.18694,-0.06885l2.6172,0c0.72808,0 1.31185,0.15085 1.75132,0.45258c0.44607,0.30177 0.66909,0.73794 0.66909,1.30861c0,0.16397 -0.01966,0.33454 -0.05905,0.51162c-0.16397,0.75435 -0.49521,1.3119 -0.99372,1.67266c-0.49196,0.36076 -1.1676,0.54114 -2.02687,0.54114l-1.32827,0l-0.45258,2.15477c-0.01316,0.07215 -0.04924,0.13114 -0.10822,0.17708c-0.05905,0.04593 -0.12139,0.0689 -0.18694,0.0689l-1.33813,0l0,-0.00002zm3.48304,-3.75853c0.27545,0 0.51488,-0.07545 0.71822,-0.22631c0.2099,-0.15085 0.34765,-0.36732 0.41325,-0.64937c0.01966,-0.11154 0.02952,-0.2099 0.02952,-0.29516c0,-0.19024 -0.05574,-0.33454 -0.16727,-0.43292c-0.11154,-0.10498 -0.30172,-0.15741 -0.57067,-0.15741l-1.18071,0l-0.37387,1.76118l1.13152,0l0.00001,0z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
