import React from 'react';

export default function IconSwap() {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.825 2.51428L8.43 0.919996C8.27 0.737139 8 0.862853 8 1.12V2.14285H1C0.725 2.14285 0.5 2.4 0.5 2.71428C0.5 3.02857 0.725 3.28571 1 3.28571H8V4.30857C8 4.56571 8.27 4.69142 8.425 4.50857L9.82 2.91428C9.92 2.80571 9.92 2.62285 9.825 2.51428Z'
        fill='#6C757D'
      />
      <path
        d='M0.175007 7.48572L1.57001 9.08001C1.73001 9.26287 2.00001 9.13715 2.00001 8.88001V7.85715H9.00001C9.27501 7.85715 9.50001 7.60001 9.50001 7.28572C9.50001 6.97144 9.27501 6.71429 9.00001 6.71429H2.00001V5.69144C2.00001 5.43429 1.73001 5.30858 1.57501 5.49144L0.180007 7.08572C0.0800071 7.19429 0.0800071 7.37715 0.175007 7.48572Z'
        fill='#6C757D'
      />
    </svg>
  );
}
