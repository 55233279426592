import { Box, NumberInput, NumberInputField } from '@chakra-ui/react';
import { parse } from 'utils/number';

interface Props {
  setValueInput: (value: number) => void;
  defaultValue?: number;
}
export default function InputChange({ setValueInput, defaultValue }: Props) {
  return (
    <Box>
      <NumberInput
        value={defaultValue}
        onChange={(e) => setValueInput(parse(e))}
      >
        <NumberInputField
          _focus={{ boxShadow: 'none' }}
          p='0px'
          color='text.500'
          fontSize={22}
          border='none'
          textAlign='end'
          autoFocus
        />
      </NumberInput>
    </Box>
  );
}
