export const CROSS_CHAIN_SWAP = [
  {
    name: 'Minimum Received',
    price: '144.75 USDT',
    description:
      'The estimated amount you will receive may vary due to token volatility and changing bridge fees.',
  },
  {
    name: 'Est. Cost',
    price: '~$ 7.6705',
    description: 'Estimated bridge time based on last 10 tx’s.',
  },
  {
    name: 'Est. Arrival Time',
    price: '~ 4 Min',
    description: 'Estimated bridge time based on last 10 tx’s.',
  },
];

export const LIST_POPULAR_TOKENS = [
  {
    name: 'usd',
    symbol: 'USDT',
    coingeckoSymbol: 'usd',
    address: 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    price: 1.03,
    img: '../../coin/USDTCoin.png',
    exchange: 'MicoDexV1',
  },
  // {
  //   symbol: 'USDG',
  //   coingeckoSymbol: 'usd',
  //   price: 1.01,
  //   address: '368906D05C2864a794A7d0cF3Db8FE563d8deF94',
  //   exchange: 'MicoDexV1',
  //   name: 'usd',
  //   img: '../../coin/USDGCoin.png',
  // },
  {
    name: 'dai',
    symbol: 'DAI',
    coingeckoSymbol: 'dai',
    address: 'DA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    price: 31237.23,
    img: '../../coin/DAICoin.png',
    exchange: 'MicoDexV1',
  },
  {
    name: 'link',
    symbol: 'LINK',
    coingeckoSymbol: 'link',
    address: 'f97f4df75117a78c1A5a0DBb814Af92458539FB4',
    price: 337.23,
    img: '../../coin/LINKCoin.png',
    exchange: 'MicoDexV1',
  },
  {
    name: 'ethereum',
    symbol: 'WETH',
    coingeckoSymbol: 'eth',
    address: '82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    price: 337.23,
    img: '../../coin/WETHCoin.png',
    exchange: 'MicoDexV1',
  },
];

export const USDT = {
  name: 'usd',
  symbol: 'USDT',
  coingeckoSymbol: 'usd',
  address: 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  price: 1.03,
  img: '../../coin/USDTCoin.png',
  exchange: 'PancakeSwapV2',
};
