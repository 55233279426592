import {
  Box,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import useVisible from 'hook/useVisible';
import { ReactNode } from 'react';

import TemplateText from '../Text/TemplateText';

interface PopoverItemProps extends PopoverContentProps {
  isDetail?: boolean;
  chidlren?: ReactNode;
  label?: string;
}
function TooltipItem({ label = '', children, ...props }: PopoverItemProps) {
  const menu = useVisible(false);

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={menu.visible}
      onClose={menu.hide}
      onOpen={menu.toggle}
      closeOnBlur={true}
      trigger='hover'
      placement='top'
    >
      <PopoverTrigger>
        <Box ml='5px' as='button'>
          {children}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          {...props}
          borderRadius='5px'
          fontWeight='300'
          lineHeight='16px'
          fontSize='13px'
          w='280px'
          bg='bg.200'
          p='12px'
          boxShadow={'2px 2px 13px #09162e'}
          border='1px solid #28344b'
        >
          <PopoverArrow boxShadow='none' bg='bg.200' />
          <TemplateText fontSize={12} fontWeight={400} txt={label} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default TooltipItem;
