import { Box, Flex, Image } from '@chakra-ui/react';
import { CoinType } from 'constants/interface';

import { useCoins } from 'hook/coin';
import { useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';
import TemplateText from '../Text/TemplateText';

interface props {
  setCrossToken: (value: CoinType) => void;
  onClose: () => void;
  listCoin: CoinType[];
}

export default function CoinItem({ setCrossToken, onClose, listCoin }: props) {
  const { isConnected, address } = useAccount();

  const { data: USDT } = useBalance({
    address: address,
    token: `0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9`,
    enabled: isConnected,
  });

  const { data: USD_Price } = useCoins('usd', isConnected);

  const { data: USDG } = useBalance({
    address: address,
    token: `0x368906D05C2864a794A7d0cF3Db8FE563d8deF94`,
    enabled: isConnected,
  });

  const { data: DAI } = useBalance({
    address: address,
    token: `0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1`,
    enabled: isConnected,
  });

  const { data: DAI_Price } = useCoins('dai', isConnected);

  const { data: LINK } = useBalance({
    address: address,
    token: `0x20Cfa0e0f269b9854a681230eE9273778C96E4F0`,
    enabled: isConnected,
  });

  const { data: LINK_Price } = useCoins('link', isConnected);

  const { data: WETH } = useBalance({
    address: address,
    token: `0x82aF49447D8a07e3bd95BD0d56f35241523fBab1`,
    enabled: isConnected,
  });

  const { data: WETH_Price } = useCoins('eth', isConnected);

  const listBalance = useMemo(() => {
    return {
      USDT,
      USDG,
      DAI,
      LINK,
      WETH,
    };
  }, [USDT, USDG, DAI, LINK, WETH]);

  const listPrice = useMemo(() => {
    return {
      USDT: USD_Price?.RAW.USD?.USD.PRICE || 0,
      USDG: USD_Price?.RAW.USD?.USD.PRICE || 0,
      DAI: DAI_Price?.RAW.BTC?.USD.PRICE || 0,
      LINK: LINK_Price?.RAW.LINK?.USD.PRICE || 0,
      WETH: WETH_Price?.RAW.ETH?.USD.PRICE || 0,
    };
  }, [USD_Price, DAI_Price, LINK_Price, WETH_Price]);

  return (
    <>
      {listCoin.map((item) => (
        <Flex
          py='10px'
          cursor='pointer'
          alignItems='center'
          gap='10px'
          h='56px'
          mt='20px'
          p='0px 10px'
          borderRadius='10px'
          _hover={{ background: '#ffffff08' }}
          onClick={() => {
            setCrossToken(item);
            onClose();
          }}
          key={item.name}
        >
          <Flex>
            <Image w='30px' h='30px' src={item.img} />
            <Box ml='10px'>
              <TemplateText fontSize='14px' txt={item.symbol} />
              <TemplateText fontSize='12px' color='text.400' txt={item.name} />
            </Box>
          </Flex>
          <Box ml='auto' pl='10px'>
            <TemplateText
              ml='auto'
              w='fit-content'
              fontSize='14px'
              txt={`${Number(listBalance[item.symbol]?.formatted || 0).toFixed(
                5
              )}`}
            />
            <TemplateText
              ml='auto'
              w='fit-content'
              fontSize='12px'
              color='text.400'
              txt={`$${Number(
                listPrice[item.symbol] * listBalance[item.symbol]?.formatted ||
                  0
              ).toFixed(5)}`}
            />
          </Box>
        </Flex>
      ))}
    </>
  );
}
