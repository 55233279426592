import React from 'react';

export default function Email() {
  return (
    <svg
      data-v-e55b351e=''
      viewBox='0 0 1024 1024'
      version='1.1'
      width='20px'
      height='20px'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M747.136 276.864c43.264 0 78.4 35.072 78.4 78.4v470.272c0 43.264-35.136 78.336-78.4 78.336H120.128A78.4 78.4 0 0 1 41.728 825.6V355.2c0-43.328 35.072-78.4 78.4-78.4h627.008z m78.4-156.8a156.8 156.8 0 0 1 156.736 156.8v391.872c0 43.328-35.072 78.4-78.4 78.4V355.264a156.8 156.8 0 0 0-156.736-156.8H198.464c0-43.264 35.136-78.336 78.4-78.336h548.672zM689.28 360l-4.16 2.56-251.584 179.712-251.52-179.648-4.16-2.56a39.168 39.168 0 0 0-45.248 63.168l3.84 3.2L410.88 622.208l4.16 2.624c11.584 6.272 25.6 6.272 37.12 0l4.224-2.56 274.304-196.032 3.84-3.136a39.168 39.168 0 0 0-40.832-65.28l-4.352 2.048z'
        fill='#b7becb'
      ></path>
    </svg>
  );
}
