import TemplateText from 'components/common/Text/TemplateText';
import React, { useEffect, useMemo, useState } from 'react';

export default function DynamicText() {
  const [index, setIndex] = useState(0);
  const work = useMemo(() => ['Token', 'AMM', 'Blockchain'], []);
  const color = useMemo(() => ['#b7becb', '#4b86ff', '#a1d75f'], []);

  useEffect(() => {
    const tick = () => setIndex((i) => i + 1);

    const id = setInterval(tick, 2000);
    return () => clearInterval(id);
  }, []);
  return (
    <TemplateText
      className={`text-${work[index % work.length]}`}
      fontSize={{ base: '35px', md: '56px' }}
      color={`${color[index % color.length]}`}
      txt={work[index % work.length]}
      ml={{ base: 'unset', xl: '10px' }}
    />
  );
}
