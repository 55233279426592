import { ChevronDownIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Flex, Image } from '@chakra-ui/react';
import { CamelCase, CoinType } from 'constants/interface';
import { useMemo, useRef } from 'react';
import { convertBigNumber } from 'utils/number';

import TemplateText from '../Text/TemplateText';

interface Props {
  coinTarget: CoinType;
  value: number;
  onOpen: () => void;
  camelCase: CamelCase;
}

export default function LimitOrderBuy({
  coinTarget,
  value,
  onOpen,
  camelCase,
}: Props) {
  const defaultValue = useRef(5);

  const isZeroPrice = useMemo(() => {
    return coinTarget.price === 0;
  }, [coinTarget.price]);

  return (
    <Box
      w='full'
      p='12px 12px 10px'
      border='1px solid #28344b'
      bg='bg.300'
      borderRadius='12px'
      mt='25px'
    >
      <Flex justifyContent='space-between'>
        <TemplateText txt='Buy' color='text.400' fontSize='13px' />

        {camelCase.PCT > defaultValue.current && (
          <Flex>
            <WarningTwoIcon color='#f75029' boxSize={4} mr='10px' />
            <TemplateText
              txt='Hight Slippage!'
              color='#f75029'
              fontSize='14px'
            />
          </Flex>
        )}
      </Flex>
      <Flex justifyContent='space-between' py='10px'>
        <Flex>
          <Flex as='button' onClick={onOpen} alignItems='center' gap='10px'>
            <Image src={coinTarget.img} h='30px' />
            <Flex alignItems='center' gap='5px'>
              <TemplateText
                fontSize='20px'
                fontWeight='500'
                txt={coinTarget.symbol}
              />
              <ChevronDownIcon color='text.400' boxSize={5} />
            </Flex>
          </Flex>
        </Flex>
        <TemplateText
          fontSize='20px'
          txt={`${!isZeroPrice ? value.toString() : Infinity}`}
        />
      </Flex>
      <Flex justifyContent='space-between'>
        <TemplateText fontSize='14px' color='text.400' txt='Balance : 0.01' />
        <Flex>
          <TemplateText
            fontSize='14px'
            color='text.400'
            txt={`~ $ ${convertBigNumber(
              !isZeroPrice ? value * coinTarget.price : Infinity
            ).toString()}`}
          />
          {camelCase.PCT !== 0 && (
            <TemplateText
              fontSize='14px'
              ml='5px'
              color={camelCase.PCT > 0 ? '#f75029' : 'green'}
              txt={` ( ${!isZeroPrice ? -camelCase.PCT : Infinity}% )`}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
