import React from 'react';

export default function Loading() {
  return (
    <svg
      data-v-24cd6078=''
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19 19'
      id='arrow_loading'
      width='16px'
      height='16px'
    >
      <path
        data-v-24cd6078=''
        stroke='none'
        fill='none'
        d='M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z'
      ></path>
      <defs data-v-24cd6078=''>
        <path
          data-v-24cd6078=''
          id='arrow'
          stroke='none'
          fill='none'
          d='M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z'
        ></path>
        <clipPath data-v-24cd6078='' id='arrow-clip'>
          <use data-v-24cd6078='' xlinkHref='#arrow'></use>
        </clipPath>
      </defs>
      <g data-v-24cd6078='' clip-path='url(#arrow-clip)'>
        <circle
          data-v-24cd6078=''
          cx='7.02'
          cy='7.02'
          r='4.69'
          transform='rotate(365,12,12)'
          fill='none'
          stroke='white'
          stroke-width='16'
          stroke-dasharray='30'
          stroke-dashoffset='0'
        >
          <animate
            data-v-24cd6078=''
            attributeName='stroke-dashoffset'
            values='0;-30'
            begin='0.7s'
            repeatCount='indefinite'
            dur='10s'
          ></animate>
        </circle>
      </g>
      <use data-v-24cd6078='' xlinkHref='#arrow'></use>
      <animateTransform
        data-v-24cd6078=''
        id='transform_0'
        attributeName='transform'
        attributeType='XML'
        type='rotate'
        from='0 0 0'
        to='-10 0 0'
        dur='0.07s'
        begin='arrow_loading.click;'
        repeatCount='1'
      ></animateTransform>
      <animateTransform
        data-v-24cd6078=''
        id='transform_1'
        attributeName='transform'
        attributeType='XML'
        type='rotate'
        from='-45 0 0'
        to='390 0 0'
        dur='0.6s'
        begin='transform_0.end'
        repeatCount='1'
      ></animateTransform>
      <animateTransform
        data-v-24cd6078=''
        id='transform_2'
        attributeName='transform'
        attributeType='XML'
        type='rotate'
        from='390 0 0'
        to='360 0 0'
        dur='0.15s'
        begin='transform_1.end'
        repeatCount='1'
      ></animateTransform>
    </svg>
  );
}
