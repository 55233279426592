import React from 'react';

export default function LimitOrder() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='20'
      height='20'
      x='0'
      y='0'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g>
        <g fill='#57a4ff'>
          <circle cx='136' cy='376' r='96' fill='transparent'></circle>
          <path
            d='M112 343h32c8.837 0 16 7.163 16 16s-7.163 16-16 16h-32zM112 375h40c8.837 0 16 7.163 16 16s-7.163 16-16 16h-40z'
            fill='transparent'
          ></path>
          <circle cx='216' cy='152' r='16' fill='transprent'></circle>
          <circle cx='88' cy='216' r='16' fill='transparent'></circle>
          <circle cx='344' cy='184' r='16' fill='transparent'></circle>
          <circle cx='472' cy='104' r='16' fill='transparent'></circle>
        </g>
        <g fill='#004fac'>
          <path
            d='M136 272c-57.438 0-104 46.562-104 104s46.562 104 104 104 104-46.562 104-104c-.062-57.412-46.588-103.938-104-104zm0 192c-48.601 0-88-39.399-88-88s39.399-88 88-88 88 39.399 88 88c-.055 48.578-39.422 87.945-88 88z'
            fill='#2669f5'
          ></path>
          <path
            d='M496 464H228.86c48.637-51.252 46.517-132.227-4.735-180.864s-132.227-46.517-180.864 4.735A126.452 126.452 0 0 0 40 291.44V16a8 8 0 0 0-16 0v8h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v16h-8a8 8 0 0 0 0 16h8v48c0 .586.067 1.17.2 1.74-34.42 61.707-12.301 139.633 49.406 174.054 43.296 24.151 96.648 21.114 136.924-7.794H496a8 8 0 0 0 0-16zm-360 24c-61.856 0-112-50.144-112-112s50.144-112 112-112 112 50.144 112 112c-.068 61.828-50.172 111.932-112 112z'
            fill='#2669f5'
          ></path>
          <path
            d='M164.9 370.78c6.512-11.528 2.445-26.153-9.083-32.665A23.943 23.943 0 0 0 152 336.38V336a8 8 0 0 0-15.93-1H128v-8a8 8 0 0 0-16 0v8h-8a8 8 0 0 0 0 16v48a8 8 0 0 0 0 16h8v8a8 8 0 0 0 16 0v-8h8a8 8 0 0 0 16 0c13.248.007 23.994-10.727 24-23.976a23.985 23.985 0 0 0-11.1-20.244zM120 351h24a8 8 0 0 1 0 16h-24zm32 48h-32v-16h32a8 8 0 0 1 0 16zM240 488h-22a8 8 0 0 0 0 16h22a8 8 0 0 0 0-16zM302 488h-22a8 8 0 0 0 0 16h22a8 8 0 0 0 0-16zM366 488h-22a8 8 0 0 0 0 16h22a8 8 0 0 0 0-16zM430 488h-22a8 8 0 0 0 0 16h22a8 8 0 0 0 0-16zM494 488h-22a8 8 0 0 0 0 16h22a8 8 0 0 0 0-16zM88 240c11.484-.016 21.353-8.153 23.557-19.423l84.009-56.007c6.935 11.288 21.707 14.818 32.996 7.883a23.99 23.99 0 0 0 9.104-10.132l83.1 27.7c3.329 12.83 16.429 20.532 29.259 17.202a24 24 0 0 0 17.583-18.929l88.473-66.355c9.909 8.77 25.052 7.846 33.822-2.064s7.846-25.052-2.064-33.822-25.052-7.846-33.822 2.064a23.96 23.96 0 0 0-5.717 19.659l-84.193 63.144c-7.215-11.109-22.069-14.265-33.178-7.05a23.979 23.979 0 0 0-8.593 9.809l-83.1-27.7c-3.327-12.831-16.424-20.535-29.255-17.209a24 24 0 0 0-17.536 18.653l-84.011 56.007c-6.942-11.291-21.724-14.817-33.015-7.875s-14.817 21.724-7.875 33.015A24 24 0 0 0 88 240zM472 96a8 8 0 1 1-8 8 8.01 8.01 0 0 1 8-8zm-128 80a8 8 0 1 1-8 8 8.01 8.01 0 0 1 8-8zm-128-32a8 8 0 1 1-8 8 8.01 8.01 0 0 1 8-8zM88 208a8 8 0 1 1-8 8 8.01 8.01 0 0 1 8-8zM488 320H376a8 8 0 0 0 0 16h112a8 8 0 0 0 0-16zM488 296H376a8 8 0 0 0 0 16h112a8 8 0 0 0 0-16zM488 344H376a8 8 0 0 0 0 16h112a8 8 0 0 0 0-16zM488 368h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16zM408 368a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z'
            fill='#2669f5'
          ></path>
        </g>
      </g>
    </svg>
  );
}
