import React from 'react';

export default function Tutorials() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 2.56714C3 2.25392 3.25392 2 3.56714 2H16.4329C16.7461 2 17 2.25392 17 2.56714V17.4329C17 17.7461 16.7461 18 16.4329 18H3.56714C3.25392 18 3 17.7461 3 17.4329V2.56714Z'
        fill='url(#paint0_linear_523_47237)'
      />
      <mask
        id='mask0_523_47237'
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='14'
        height='16'
      >
        <path
          d='M3 2.56714C3 2.25392 3.25392 2 3.56714 2H16.4329C16.7461 2 17 2.25392 17 2.56714V17.4329C17 17.7461 16.7461 18 16.4329 18H3.56714C3.25392 18 3 17.7461 3 17.4329V2.56714Z'
          fill='url(#paint1_linear_523_47237)'
        />
      </mask>
      <g mask='url(#mask0_523_47237)'>
        <rect
          opacity='0.8'
          x='5'
          y='19.2285'
          width='24.0753'
          height='14.3203'
          transform='rotate(-45 5 19.2285)'
          fill='url(#paint2_linear_523_47237)'
        />
      </g>
      <path
        d='M3 2.57C3 2.2552 3.2552 2 3.57 2H5V18H3.57C3.2552 18 3 17.7448 3 17.43V2.57Z'
        fill='url(#paint3_linear_523_47237)'
      />
      <path
        d='M9.83691 12.6032C9.83691 12.4427 9.96709 12.3125 10.1277 12.3125L10.9999 12.3125C11.1605 12.3125 11.2906 12.4427 11.2906 12.6032V13.4755C11.2906 13.6361 11.1605 13.7662 10.9999 13.7662H10.1277C9.96709 13.7662 9.83691 13.6361 9.83691 13.4755V12.6032Z'
        fill='white'
      />
      <path
        d='M10.091 11.4658H11.0484C11.1822 11.4658 11.2907 11.3575 11.2907 11.2237V11.1574C11.2907 10.7815 12.9337 10.1218 12.9337 8.57723C12.9337 7.34307 12.0554 6.23242 10.5335 6.23242C9.08737 6.23242 8.13336 7.32036 8.13336 8.5564V8.84223C8.13336 8.97605 8.24184 9.08452 8.37565 9.08452H9.3448C9.47861 9.08452 9.58709 8.97605 9.58709 8.84223V8.5564C9.58709 8.10022 10.0414 7.67858 10.5714 7.67858C11.1014 7.67858 11.4875 8.07751 11.4875 8.57723C11.4875 9.81457 10.1892 9.70498 9.87782 10.8121C9.83584 10.9613 9.83253 11.101 9.83729 11.2238C9.84248 11.3575 9.95722 11.4658 10.091 11.4658Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_523_47237'
          x1='3.49144'
          y1='9.74596'
          x2='15.7177'
          y2='9.91577'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_523_47237'
          x1='3.49144'
          y1='9.74596'
          x2='15.7177'
          y2='9.91577'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_523_47237'
          x1='15.8666'
          y1='21.9445'
          x2='10.1495'
          y2='16.8123'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2669f5' stopOpacity='0.4' />
          <stop offset='1' stopColor='#2669f5' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_523_47237'
          x1='3.19231'
          y1='2'
          x2='5.10543'
          y2='2.13797'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#273550' />
          <stop offset='1' stopColor='#273550' />
        </linearGradient>
      </defs>
    </svg>
  );
}
