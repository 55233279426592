import { Box, Flex } from '@chakra-ui/react';
import FooterTrade from 'components/common/FooterTrade';
import { CoinPair } from 'constants/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LeftWrap from './Left';
import RightWrap from './Right';

export default function SWapPage() {
  const location = useLocation();
  const defaultTab = useMemo(() => location.search.slice(9), [location]);

  const [tab, setTab] = useState('');

  useEffect(() => setTab(defaultTab), [defaultTab]);

  const isDevelopPage = useMemo(
    () => defaultTab === 'limitOrder',
    [defaultTab]
  );

  const coinPairDefault: CoinPair = useMemo(() => {
    return {
      coinBase: {
        name: 'ethereum',
        symbol: 'WETH',
        coingeckoSymbol: 'eth',
        address: '82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        price: 1.001,
        img: './coin/WETHCoin.png',
        exchange: 'Ethereum',
      },
      coinTarget: {
        name: 'usd',
        symbol: 'USDT',
        coingeckoSymbol: 'usd',
        address: 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        price: 337.23,
        img: './coin/USDTCoin.png',
        exchange: 'PancakeSwapV2',
      },
    };
  }, []);
  const [coinPair, setCoinPair] = useState(coinPairDefault);

  return (
    <Box>
      <Flex
        display={isDevelopPage ? 'block' : 'none'}
        position='fixed'
        h='100vh'
        zIndex={99}
        top='0px'
        right='0px'
        bottom='0px'
        left='0px'
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          boxSize='full'
          color='white'
          textShadow='0 0 20px black'
          fontWeight='bold'
          fontSize='30px'
          backdropFilter='auto'
          backdropBlur='6px'
        >
          Under Development
        </Box>
      </Flex>
      <Flex
        justifyContent={{
          base: 'center',
          lg: 'space-between',
          '2xl': 'center',
        }}
        p='16px'
        minH='100vh'
      >
        <LeftWrap
          coinPair={coinPair}
          setCoinPair={setCoinPair}
          defaultTab={tab}
          setTab={setTab}
        />
        <RightWrap
          coinPair={coinPair}
          setCoinPair={setCoinPair}
          defaultTab={tab}
        />
      </Flex>
      <FooterTrade />
    </Box>
  );
}
