import React from 'react';

export default function NoOrders() {
  return (
    <svg
      width='67'
      height='62'
      viewBox='0 0 67 62'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='96.88%' id='10oov9hsyb'>
          <stop stopColor='#b7becb' offset='0%' />
          <stop stopColor='#b7becb' offset='100%' />
        </linearGradient>
        <path id='3bbrsbqoea' d='M0 0h23.642v23.643H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(3.315 3.41)'>
          <mask id='jmziccrwic' fill='#eff3fb'>
            <use xlinkHref='#3bbrsbqoea' />
          </mask>
          <path
            d='M21.42 11.821c0 5.302-4.297 9.6-9.6 9.6-5.3 0-9.599-4.298-9.599-9.6 0-5.301 4.298-9.6 9.6-9.6s9.6 4.299 9.6 9.6'
            fill='url(#10oov9hsyb)'
            mask='url(#jmziccrwic)'
          />
          <path
            d='M11.997 12.115a.95.95 0 0 1-.955-.943V6.867a.95.95 0 0 1 .955-.943.95.95 0 0 1 .956.943v4.305a.95.95 0 0 1-.956.943m1.092 2.515c0 .596-.489 1.078-1.091 1.078a1.085 1.085 0 0 1-1.093-1.078c0-.595.49-1.077 1.093-1.077.602 0 1.091.482 1.091 1.077'
            fill='#28344b'
            mask='url(#jmziccrwic)'
          />
        </g>
        <path
          d='M60.249 45.974c0 5.302-4.298 9.6-9.6 9.6s-9.6-4.298-9.6-9.6c0-5.301 4.298-9.6 9.6-9.6s9.6 4.299 9.6 9.6'
          fill='url(#10oov9hsyb)'
          transform='translate(.898 1.328)'
        />
        <path
          d='M65.572 47.303c0 7.745-6.279 14.025-14.026 14.025-7.745 0-14.025-6.28-14.025-14.025 0-7.746 6.28-14.026 14.025-14.026 7.747 0 14.026 6.28 14.026 14.026M28.949 15.354c0 7.746-6.28 14.026-14.026 14.026C7.177 29.38.898 23.1.898 15.354c0-7.745 6.28-14.026 14.025-14.026 7.747 0 14.026 6.28 14.026 14.026'
          stroke='#b7becb'
        />
        <path
          d='M55.79 28.384v-11.84a6.28 6.28 0 0 0-6.36-6.281c-8.569.11-17.482-.092-17.482-.092'
          stroke='#b7becb'
        />
        <path fill='#b7becb' d='M52.428 25.831h6.42l-3.21 3.577z' />
        <path
          d='M11.914 34.083v11.84a6.28 6.28 0 0 0 6.36 6.281c8.57-.11 17.483.092 17.483.092'
          stroke='#b7becb'
        />
        <path fill='#b7becb' d='M15.277 36.636h-6.42l3.21-3.577z' />
      </g>
    </svg>
  );
}
