import React from 'react';

export default function Way02() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='50px'
      height='50px'
      viewBox='0 0 94 74'
      enableBackground='new 0 0 94 74'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='94'
        height='74'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF4AAABKCAYAAAAylePjAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAP
  hklEQVR42u1cTYwcx3X+3qvq7unZn9nl8m9Xf5b/JIpWBIWyEgRwwhxzUG5DSwcDjg5LCNAKoEDL
  RhCArVMiKSAFbiKYCyggrOggLhABvuQqX+yYhgJLlDeOZVsgLS0pksvl/szOTHdXvRxmerfnv2d3
  FF/mAx5mu7r6VdXXr+u9+ltgiCGGGOILBw1K0VNPzeYdBwXHQT4M2XchKiJRImSYdWxMVHHCsDRy
  EHcvXrxY+WM1ODge6P8ev3bQcfxJtvG4CPIi8IiJhYUAGBEOASkrY9fh8p3JSXtzYWEhGmQ99kR8
  sThb4JjvI6UOWZF81ufE2hK03ADUtcXFNza/II4b8MxTs/vFdb9kxBwisgwoACaVQ4GIpPa3gYhQ
  km6tFUXm81Dc37/77r+uDKI+uyK+WJwtsFFHDMv+dLpCUu1sUABg+fNyXPn1j3/8bxuDobgRz/7t
  s2Mbnv8NwO5LyjSpCiqVqm/je9j+aWyP3AL8K4uL58p7qVe/xFOx+NzXWfBlEd57N1VvnLVWlNK/
  eeed+d8DkD3rraNYfP5BxPYhYuI2DLbUhSxtly0iRGRT1/X2KkAEURybD95994c3d1u3zOQVi0UF
  TB1joalOebj+qdr6Z8pEDSQ2p9vtzzlpPH9+5MiBXwZBYPdK+re//cI32Mb37lVPJxjL4or+8N//
  4/Xru3leZckUBAHfuUPfBNQkiKVZNLEwsRgiCBGSdKlfS1O6IgIRgevPJWJBI6s3K4UrSz+/sRdS
  isW5R4hwjxCLIhZDLNJBGATLrekWBHRJJyaAzME/fejJtQ9+/Yu+u51MxE9PP/aoCPYrpYSZWwSJ
  AKA6qUSE5vzJ/eYXQkoJtnWRf+TIk97S0uVbuyH96aefn1FKfSWpgxCByIBI6qIarsFa0nWmen5m
  SLv8nMoPtrDaTh058uD1paWlrK4tG/FPP/38DFt8iQEhEXQTBiQtve635FO1ximFsSce/rONXy5d
  3urP0ouuUmOPsYAIEAIELKjFiTWBFTDtXDfmQ/2aBZbq+erXTHU9SAkLLCvWE+5HH/1XX4bSlfhi
  sehqFP6EGExEUNze4rMKqGbxzXq206GEwcJgCZkmjxy5/7OlpaXMzvaJR//6K0x6Im29DJZGi92x
  ZEMGFrKuDN+WKFphuOtWcYUsaaVYNTyHdl8GgZmFEY8+9vhf3vzww59mjvV1t5ued+/91holgAUA
  gww+LwagpcQGVdFuRHHoWAUPMY1A1yKWFj3UmK4BOIBDzsF7AVzN0pDjx49r67iHAGlTSb1TtzoM
  yZ04dj7uFBZ+5zunD0pkvwrNbvq59oxpQKJ7AfxvVuI7RjWzs7PO1vrInxOBk8EENUUpjYqsiSn+
  w9Qq35j/z/lq8/25uTlvZcU9rCW+T8AKQGqQ0qQriXpY4q99beJnWaKcZ575+0Ma5YczNdrS9R+9
  c/Y3vfLN/c2ct1qgY0TK6ZXXGonfvvT6T5ExHO7Y1Tz88F/MCGGfkEAIIgTRpEBEiJucoxJsFA6Y
  D958819WLv/2clsnc/nyZXPlys/WHn/iyE3E+XFmdnk7GrKSlJEux8Dy7duV8kcf/bzUqyGPP37s
  fiHywZB2olmLZStsaeNH75xdykLO5d9eNg8debKiHJ5q1tOsnxTRo49+c/XKlcvVLLq5o1WQOqi1
  tmkxbMWwFa1hEwFQunZj/Mr8/HymAi9evFi5dmP8CoCSaFjRsM3lpNM95oNZ9BpDY938i2Vb+ztn
  P0Efg7TFxflbIlxp0VMXpbRN/jbGH82qty3xc3NznhL4ZIykRYlYJWIb0in8+L33gjhrgQDw3ntB
  HFP4MZGRmnBNmsojYyQmGjt+POjqi4Ig0B6TVlasUmKVbS8Uo3zx4ut3+6krADjCdzvpZDaS/O04
  1tsT8RsbuUmltG0WaRJ21erbb8+v99sQAHj77fl1ZrWqRFsRqUldb7pMl1mmpzcmuun63e82PWIW
  YhYy9V9mMU0Ch/smHQDYUinR0aLX7KQrQzqrzk4ZxyyzoCk4anlLSu1qkJMgjum2sjy+rbfuwrY9
  ab18re0YgNud9CgVO2K03VFSe1ABgHaAuHYd2aivcUEC8VWkoCziCIL2jlEAwM2usy3xHHOeWGy3
  KD8C8OVPvNXdNCSB76+vVioTdjtkaI5d6uU7pLpOOStFHNnayNiBQYOJGIOIatf5WO1qTj2OI3KJ
  xSi97RustVQrb9s+oEPOPHptIf748UBbXXEBEqBzPR3llYM++/ZmLCwsRN/97vfLBnGuSXv9t1Z+
  aJSPWujb1im++ea5OyLyi17ldQuHu2GEPCeUyLLZsQx2IIhqFdL1KosKM7/Ylt7jvvs2PS2wNXE6
  CoVhzxAvC7Qxm636G8v3mGVubq7rh0xE0kt2W8fIMX7Sp29HNKaxr2fDEsecKbID2lj8qNFuRXO9
  ks0vcGccIUR7WghIECsqE5kmUhotHgBKJd8FkLlhg0KxWFTG8KjW1gKApDqXNHkCACEyG2ML8aJi
  R4ubclTt+dChGggJfqiqscsdRqY75VeN9OG6Bgff/+qEgwgw2J5htTZZBIqQpIl17Vtv/VNm591C
  fOiwBkz7zzJxHQxUlAkH0TCKchHnylJrRmeIMZmmsAeNHOOA1Y7dab4BOPE1Drheb9bhGvoYmLUQ
  z5xnTnsRAGhDsVWqr/nnTvCwZkp21DZUptm2Q0D7///EP/fcDyZNWTta1DYf1pjt+SVWRhLnytq/
  04/uVuLDMhtHCQAw136tMi2TWaV1teflOQD4FAUzrsJGS6m/0nT5ErsD24qSBcViUVWr+qByYE16
  sZZ3rNrU411tovIbbwR97ZZoIV6PaiFj66SqBgLSvm1ycnkgi9KTk8tCNNXhJe6U7/ScHxwsxsaO
  HspxzJD6NHMHD2OMoc1K/Hm/+tsOoBzxOhCxMxWxf2ZmIMTPzMzI7eUME/27GnPuDs8++9KY1tXx
  GBAXHkJUt+fy3RQHIarwrLdx4a1/7Du0biW+DJhcnIHUzNMSPWFU7/JUPLjyumFu7rxntu4cIqkZ
  g7iAE+6QnY6tKFZyYw272uLR2sePOCaK2vff2tb6+piVZN+21BvWej0tnqwZiE/phiAI9Kef3pxR
  OjVKjtE4zIw9JF1uPtYrFxaDXUV3reFkGIrWblsLFFWrjILB8vLyQJzd8vI0aX29p8XbXJZ1x92j
  WCyq1VXM+D4Y1VRZ3g7RO/BgDIXnLvxDX5FMGi3Ej7NrjPRu5K3VmYEQv7p6ne7d37u8kv3iiC8W
  i+rw4aPTYQgNaGmYHIxNE00GnsDeWEHfDjWNFuLLyjWtQ/g6Unt8R0ZMx9WrfjAyAo4i1VherjVf
  3jN7mpDrhGKxqKamHjuslNIqSl5u2sKbLD4HaD1ya/GH39vTALKFeMcJTRTp9taVWl9xGQMh3nXB
  4lBjeW1e+/r61uCcSh3FYlEdOnT0kAh0FBmBSk8/+fXfRotnE6++/vr39jxB2KaPR+w4vaOMkbI/
  kJHkROyrql/uWV6hcHigFl8sXlL33PPxQQ5FYXslwG/82tK7+HPA5ubG+sLCK7tacWtGC/FK7Ys8
  W25r8Wl72PDsQOI79qz2rN+z/75bHhkY8bOzF5w8Pt5vTKSMSr4vv9bC9ISR2kmvbuY2FhZeWRtU
  HVq6i29963AcRZE0iK6JjlKiZSDEl7TolvLalH/hwuxAiA+CwC3otanYjUgE1hffivhWBG3FrwUa
  6wsLPxgY6W2JP3HihNHsGsmR3RapSypNVQdCPKmq6Iay2ogqx9FeFjISnDp11i/dxD6MAFo74vt5
  Kz5Z3yerdSxaOy1SpvLm/HwwkO6lK/EAEHtboRM60iJOXUJHHO3I3Nz5zNsZ2mFu7rzrFNqU0yRx
  Dnuegj59+rURa8vjoeNIGEaSF9gwjCSRfBtrd139hZAOdBj3FwoINzfbbFVIbC5Zeq6i3eiiD9zx
  REYsepyemljfG/EvvfTKmCpHOQdkoygkoDbT7QDA6Aiq1UiSc0CeFxI2AYyOlF59de/RSye0JX55
  ebrqeSt+r4ddl9wgCHg3JziCIOCNjVG3Wg17diFXVx/Z7culIDg/trERusatjxXcplG5AK67MwEj
  4or1uXTun18cyNJmJ7QlfmHhZHTq1FmTy1V6jE5DAAfyAHZxcu9A3nXXxHW7r9rcvUt2cfFE3zF8
  EARcLufGqtVNPWZzPQ2jmqtQpZKTQuHezSA4MZDVtW7o6CCVMtVKJecBQKGelrh1z6sKAFSrVapU
  brmnTp31z53LbiGnTp31KxVygAlb09P5Bedy6PtMbBAEulzOjRAJi5CteB0+mFScUhHfbm39T+nc
  uRcHep61EzoSf/XqA5Wj93zWsPyQmGalkowyckmS99rp17g0Wip363aCIGDcPJC/K6GuVKoykWhB
  l4hlYq2vbmZ29oKzslL183kP0mvOaby2H2trKy83PjtcWlw8NfDRcSd07UpOn35tRCnT8eX4fr6B
  sHJ5i4xRcbXqxdWqZ2dmrsvy8jR5XpU9r6q76WqvvxIFQZB5CSQIAhcr+2pWkT6bmD4SPAVgJTWp
  OHXAAre2BnHSsB90Jb5YvKQefPCTzCe2B418vlzKSkgQXHKBW7vYAqLt+Hgp8xhhfX2dxsfHpfP9
  SQmCv+vZPXa1wMXFE+b8+fPhtWsTGgBGRzcIADY3x7pWdGurli+f755vtJ4vWcJJ569W78avvvr9
  jKQHvLl51cHuDifT5mY/B61HsLlpOuYfHb2aqQ49P/0XXnghPHXqLBcKIwTUNm2Ojnb3o6OjyebO
  Hv62nm/n5EEt/9paSebnX+yrb5+ZSa2a/SF14742183odL/Xc22wvv7AYIgHIIXCehVYd4EHumZc
  W1ttsIRCYVKy5G/MdxVnz56pnjv3YraWbjc4paOQvgGgkDq/tv4AGs+zPdCaP7lfqN/fTm9Gkx4A
  KysbmYjP/IkFQcArK/u+8E0WU1N3on4dnYjQyy8vpuqWPuL0SJsnOt3v9VzvfNPTq3Ly5MmeIWnm
  KCMIAisi4cmTC/rYsX5o6Y3r16/T8vK0LCzMxsDuJsOmp1dTz02n7uxs4X///Z37x44l17X7tTY1
  Pfd+6rKhze3119ry9cFafBqXLl1SS0sHUs/+pCnHX/VIb8ZP7B7DObp06dJAVsQGgRMneo+097Rg
  HQRBQ2N/9atHCACOHs12GvvMmTN72rfeXJczZ84IALz88ss929Utb7t73dKS9OQ6S5sGuR+xH10D
  +580QwwxxBBDDDHEEEMMMcQQQwwxxBBDDDHEEEMMMcQQQwwxxBB/RPwfW/qEXMw2pNEAAAAldEVY
  dGRhdGU6Y3JlYXRlADIwMjMtMDMtMDRUMDQ6MzE6MDArMDA6MDD0GmY1AAAAJXRFWHRkYXRlOm1v
  ZGlmeQAyMDIzLTAzLTA0VDA0OjMxOjAwKzAwOjAwhUfeiQAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAA
  MjAyMy0wMy0wNFQwNDozMTowMCswMDowMNJS/1YAAAAASUVORK5CYII='
      />
    </svg>
  );
}
