import { Flex } from '@chakra-ui/react';
import FooterTrade from 'components/common/FooterTrade';
import { LIST_COIN_TRADE } from 'data/future';
import React, { useState } from 'react';

import LeftWrap from './Left';
import RightWrap from './Right';

export default function FuturesPage() {
  const [coinPair, setCoinPair] = useState(LIST_COIN_TRADE[0]);

  return (
    <React.Fragment>
      <Flex
        justifyContent={{ base: 'space-between', '2xl': 'center' }}
        direction={{ base: 'column', lg: 'row' }}
        p='16px'
      >
        <LeftWrap coinPair={coinPair} setCoinPair={setCoinPair} />
        <RightWrap coinPair={coinPair} setCoinPair={setCoinPair} />
        {/* <Box display={{ base: 'block', lg: 'none' }}>
          <FuturesTabs />
        </Box> */}
      </Flex>

      <FooterTrade />
    </React.Fragment>
  );
}
