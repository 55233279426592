import HistoryTab from 'pages/Futures/Right/FuturesTabs/HistoryTab';
import OrdersTab from 'pages/Futures/Right/FuturesTabs/OrdersTab';
import PositionsTab from 'pages/Futures/Right/FuturesTabs/PositionsTab';

export const LIST_COIN_TRADE = [
  {
    coinBase: {
      name: 'dai',
      symbol: 'DAI',
      coingeckoSymbol: 'dai',
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      price: 1.03,
      img: './coin/DAICoin.png',
      exchange: 'ETH',
    },
    coinTarget: {
      name: 'usdc',
      symbol: 'USDT',
      coingeckoSymbol: 'usd',
      address: 'Fd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      price: 1001,
      img: './coin/USDTCoin.png',
      exchange: 'USD',
    },
    lastprice: '$27,476,86',
    change24h: '-2.95',
    pair: 'BINANCE:BTCUSD',
  },
];

export const LIST_HEADER_LIST_COIN = [
  { name: 'Pair' },
  { name: 'Last Price' },

  { name: '24h Change' },
];

export const LIST_TAB_FUTURES = [
  { name: 'Positions', element: <PositionsTab /> },
  { name: 'Orders', element: <OrdersTab /> },
  { name: 'History', element: <HistoryTab /> },
];

export const LIST_COIN_LONG = [
  {
    symbol: 'DAI',
    name: 'Dai',
    value: '0',
    img: './coin/DAICoin.png',
  },
  // {
  //   symbol: 'ETH',
  //   name: 'Ethereum',
  //   value: '0',
  //   img: './coin/WETHCoin.png',
  // },
];

export const LIST_COIN_SHORT = [
  {
    symbol: 'USDT',
    name: 'USDT',
    value: '0',
    img: './coin/USDTCoin.png',
  },
];

export const LEVERAGE_CUSTOM = [2, 5, 10, 20, 30];

export const SETTING_SLIPPAGE = [
  { name: '0.1%', value: 0.1 },
  { name: '0.3%', value: 0.3 },
  { name: '0.5%', value: 0.5 },
];

export const LIST_VALUE_COIN = [
  {
    name: '24h Change',
    price: 639.39,
    increase: 2.23,
  },

  {
    name: '24 High',
    price: 28783.05,
  },
  {
    name: '24 Low',
    price: 27874.79,
  },
  {
    name: '24 Volume',
    price: 5986.568,
  },
  {
    name: '24 vlume',
    price: 168795056,
  },
];
