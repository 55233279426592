import {
  Box,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ROUTER_ABI } from 'abis/ERC20';
import BigNumber from 'bignumber.js';
import ButtonBase from 'components/common/Buttons/ButtonBase';
import TemplateText from 'components/common/Text/TemplateText';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback, useMemo, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import {
  DAI_ADDRESS,
  ROUTE_ADDRESS,
  USDT_ADDRESS,
  VAULT_ADDRESS,
} from 'utils/ERC20';
import { convertPrice } from 'utils/number';
import { VAULT_ABI } from 'utils/Vault';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  collateral: string;
  size: string;
  isLong: boolean;
  timeInterval: boolean;
}

export default function ModalWithdraw({
  isOpen,
  onClose,
  collateral,
  isLong,
  timeInterval,
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();
  const { address } = useAccount();
  const [withdrawValue, setWithdrawValue] = useState(0);

  const { data: btc_price_short }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'getMaxPrice',
    enabled: timeInterval && !isLong && isOpen,
    args: [DAI_ADDRESS],
  });

  const { data: btc_price_long }: any = useContractRead({
    address: VAULT_ADDRESS,
    abi: VAULT_ABI,
    functionName: 'getMinPrice',
    enabled: timeInterval && isLong && isOpen,
    args: [DAI_ADDRESS],
  });

  const _price = useMemo(
    () =>
      BigNumber(isLong ? btc_price_long : btc_price_short || 0)
        .dividedToIntegerBy(10 ** 30)
        .toNumber(),
    [btc_price_long, btc_price_short]
  );

  const half = useMemo(() => {
    const price = isLong ? _price : 1;
    return Number(convertPrice(collateral).toNumber() / (2 * price));
  }, [collateral, _price]);

  const collateralInput = useMemo(
    () => (isLong ? withdrawValue * _price : withdrawValue),
    [withdrawValue, _price, isLong]
  );

  const sizeInput = useMemo(() => collateralInput * 10, [collateralInput]);

  const { config: configWithdraw } = usePrepareContractWrite({
    address: ROUTE_ADDRESS,
    abi: ROUTER_ABI,
    enabled: !!collateralInput && !!sizeInput && isOpen,
    functionName: 'decreasePosition',
    onError(error) {
      console.log('Error withdraw', error);
    },
    args: [
      isLong ? DAI_ADDRESS : USDT_ADDRESS,
      DAI_ADDRESS,
      collateralInput * 10 ** 30,
      sizeInput * 10 ** 30,
      isLong,
      address,
      isLong ? btc_price_long : btc_price_short,
    ],
  });

  const { write: writeLong, isLoading } = useContractWrite(configWithdraw);

  const handleWithdraw = useCallback(() => {
    writeLong?.();
    !isLoading && onClose();
  }, [writeLong, isLoading, onClose]);

  const listInfo = useMemo(
    () => [
      { name: 'Position Size', value: `$${sizeInput}` },
      { name: 'Collateral Asset', value: `${isLong ? 'DAI' : 'USDT'}` },
      { name: 'Collateral Value', value: `$${withdrawValue}` },
      { name: 'Net Value', value: '28.000' },
      { name: 'Leverage', value: `${10}x` },
      { name: 'Liquidation Price', value: '$ ...' },
      { name: 'Execution Fee', value: '0/00058 ETH' },
      { name: 'Borrow Fee', value: '< $0.01' },
      { name: 'Slippage', value: '0.3%' },
      { name: 'Mark Price', value: `$${_price}` },
      { name: 'Trigger Condition', value: `Mark Price > $${_price - 1}` },
    ],
    [withdrawValue, sizeInput, _price]
  );

  const isMax = useMemo(() => withdrawValue <= half * 2, [half, withdrawValue]);

  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
          <ModalHeader
            display='flex'
            justifyContent='center'
            bg='bg.100'
            borderRadius='10px 10px 0 0 '
            alignItems='center'
            position='relative'
          >
            <Icon
              as={BiChevronLeft}
              position='absolute'
              top='15px'
              h='30px'
              cursor='pointer'
              w='30px'
              left='15px'
              color='text.400'
              bg='bg.200'
              borderRadius='100px'
            />
            <TemplateText
              fontSize='18px'
              color='text.500'
              txt='WITHDRAW COLLATERAL'
            />
          </ModalHeader>
          <ModalCloseButton
            top='15px'
            _hover={{
              color: darkModeColors.text555,
            }}
            bg='bg.200'
            borderRadius='50%'
            color={darkModeColors.text550}
          />

          <ModalBody maxH='70vh' zIndex={1} mt='10px' pb='20px'>
            <Flex>
              <TemplateText fontSize='14px' color='text.300' txt='Withdraw' />
              <TemplateText
                fontSize='14px'
                color='text.300'
                txt={`Max Withdraw: ${half.toFixed(5)} ${
                  isLong ? 'DAI' : 'USDT'
                }`}
              />
            </Flex>
            <Flex
              p={{ base: '10px', md: '20px' }}
              bg='bg.100'
              borderRadius='10px'
              mt='10px'
              h='50px'
              _focusWithin={{ border: '1px solid #2669f5' }}
              alignItems='center'
            >
              <Input
                onChange={(e) => setWithdrawValue(Number(e.target.value))}
                value={withdrawValue}
                p='0px'
                color='#F6342B'
                fontSize={16}
                fontWeight={700}
                placeholder='0.0'
                _focusVisible={{
                  borderColor: 'transparent',
                }}
                type='number'
                border='none'
              />
              <Box as='button' onClick={() => setWithdrawValue(Number(half))}>
                <TemplateText
                  borderRadius='4px'
                  px='5px'
                  _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
                  txt='Max'
                  color='#fa3961'
                  fontSize='11px'
                />
              </Box>
              <TemplateText
                borderRadius='4px'
                px='5px'
                _hover={{ bg: 'bg.300', color: '#ea5a0a' }}
                txt={isLong ? 'DAI' : 'USDT'}
                color='text.400'
                fontSize='14px'
              />
            </Flex>

            {!isMax && (
              <TemplateText
                p='2px'
                fontSize='14px'
                color='red'
                txt='Value exceeded.'
              />
            )}
            <Box py={2}>
              {listInfo.map((item) => (
                <Flex justifyContent='space-between'>
                  <TemplateText
                    p='2px'
                    fontSize='14px'
                    color='text.300'
                    txt={item.name}
                  />
                  <TemplateText
                    fontSize='14px'
                    color='text.500'
                    txt={item.value}
                  />
                </Flex>
              ))}
            </Box>

            <ButtonBase
              mt='20px'
              w='100%'
              onClick={handleWithdraw}
              content='CONFIRM'
              bg='#2669f5'
              color='text.500'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
