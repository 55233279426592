import { Box, Flex, Icon, Image } from '@chakra-ui/react';
import { ShowColumnValue } from 'constants/types';
import React, { useEffect, useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import getTrader from 'utils/getTrader';
import { v4 as uuidv4 } from 'uuid';

import ButtonBase from '../Buttons/ButtonBase';
import ChartTrader from '../Chart/ChartTrader';
import TemplateText from '../Text/TemplateText';

interface props {
  traders: ShowColumnValue[];
  searchTrader?: string;
  setNotFoundTrader?: (value: boolean) => void;
  notFoundTrader?: boolean;
  isHideFull?: boolean;
  optionFilter?: string;
}

export default function CardTrader({
  traders,
  searchTrader,
  setNotFoundTrader,
  notFoundTrader,
  isHideFull,
}: props) {
  const [listTraderOriginal, setListTraderOrigin] =
    useState<ShowColumnValue[]>(traders);

  useEffect(() => {
    const listTraderFilter = getTrader(searchTrader, isHideFull, traders);
    setListTraderOrigin(listTraderFilter);
  }, [isHideFull, searchTrader, traders]);

  useEffect(() => {
    return setNotFoundTrader?.(listTraderOriginal.length == 0);
  }, [listTraderOriginal, setNotFoundTrader]);

  return (
    <>
      {notFoundTrader ? (
        <Flex alignItems='center' justifyContent='center' h='400px'>
          <TemplateText txt={`Can't Find Trader You Want`} />
        </Flex>
      ) : (
        <>
          {listTraderOriginal.map((column) => (
            <Box
              key={uuidv4()}
              borderRadius='10px'
              bg='bg.200'
              p={{ base: '16px 10px', md: '16px 20px' }}
              _hover={{
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, #273550 0px 8px 16px -8px',
              }}
            >
              <Flex alignItems='center' justifyContent='space-between'>
                <Flex alignItems='center' gap='10px'>
                  <Image
                    h='48px'
                    w='48px'
                    borderRadius='50%'
                    src={column.avt}
                  />
                  <Box>
                    <TemplateText txt={column.traderNickname} fontSize='16px' />
                    <Flex alignItems='center' gap='5px'>
                      <Icon
                        as={AiOutlineUsergroupAdd}
                        w='16px'
                        h='16px'
                        color='text.400'
                      />
                      <TemplateText
                        fontWeight={400}
                        color='text.400'
                        txt={`${column.followCount}/${column.maxfollowCount}`}
                      />
                    </Flex>
                  </Box>
                </Flex>
                <ButtonBase
                  content={
                    column.followCount < column.maxfollowCount
                      ? 'Follow'
                      : 'Full'
                  }
                  bg={
                    column.followCount < column.maxfollowCount
                      ? '#2669f5'
                      : 'bg.300'
                  }
                />
              </Flex>

              <Flex
                mt='10px'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box>
                  <TemplateText
                    fontSize='24px'
                    color={
                      Number(column.itemVolist[0].showColumnValue) > 0
                        ? '#0ECB81'
                        : '#E43E53'
                    }
                    txt={`${
                      Number(column.itemVolist[0].showColumnValue) > 0
                        ? `+${column.itemVolist[0].showColumnValue}`
                        : `${column.itemVolist[0].showColumnValue}`
                    }%`}
                  />
                  <TemplateText
                    color='text.400'
                    txt={column.itemVolist[0].showColumnDesc}
                  />
                </Box>
                <ChartTrader />
              </Flex>
              <Flex
                mt='20px'
                borderTop='1px solid #28344b'
                alignItems='center'
                justifyContent='space-between'
                p='10px 0 0 0'
              >
                <Box>
                  <TemplateText txt={column.itemVolist[1].showColumnValue} />
                  <TemplateText
                    txt={column.itemVolist[1].showColumnDesc}
                    color='text.400'
                  />
                </Box>
                <Box>
                  <TemplateText txt={column.itemVolist[2].showColumnValue} />
                  <TemplateText
                    txt={column.itemVolist[2].showColumnDesc}
                    color='text.400'
                  />
                </Box>
                <Box>
                  <TemplateText txt={column.itemVolist[3].showColumnValue} />
                  <TemplateText
                    txt={column.itemVolist[3].showColumnDesc}
                    color='text.400'
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </>
      )}
    </>
  );
}
