import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useContractRead } from 'wagmi';

import { ERC20_ABI } from '../abis/ERC20';

const Decimals = () => {
  const { data: TokenData }: any = useContractRead({
    address: USDT_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'decimals',
  });

  const balance: BigNumber = useMemo(() => {
    if (TokenData) {
      return BigNumber(TokenData.toString());
    }
    return BigNumber(0);
  }, [TokenData]);

  return balance.toNumber();
};

export default Decimals;

export const USDT_ADDRESS = '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9';
export const DAI_ADDRESS = '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1';
export const ROUTE_ADDRESS = '0xD15D99Db76c8F6Fd5F94dc9BD6E1A55f60F3FAB5';
export const VAULT_ADDRESS = '0xdd11ADC92EA53a20052a79631e8398E7A5b66AA1';
export const WETH_ADDRESS = '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1';
export const GLP_ADDRESS = '0xd11EB3284889f052bE3AAd1b29fA2A71D8428817';
export const GLP_MANAGER_ADDRESS = '0x77c1026619dC30baB9feFB4a610B7Af6F6836Cd4';
export const GOERLI_RPC =
  'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';

export const mantle = {
  id: 5001,
  name: 'Mantle Testnet',
  network: 'mantle',
  iconUrl: 'https://i.imgur.com/Q3oIdip.png',
  iconBackground: '#000000',
  nativeCurrency: {
    decimals: 18,
    name: 'BIT',
    symbol: 'BIT',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.mantle.xyz'],
    },
    public: {
      http: ['https://rpc.testnet.mantle.xyz'],
      // public rpc url
    },
  },
  blockExplorers: {
    default: {
      name: 'Mantle Testnet Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
    etherscan: {
      name: 'Mantle Testnet Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
  },
  testnet: true,
};
