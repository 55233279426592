import React from 'react';

export default function CustomControlIcon() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_i_29_197)'>
        <rect
          width='66'
          height='66'
          transform='matrix(0.83205 -0.5547 0.8 0.6 6 68.282)'
          fill='#eff3fb'
        />
      </g>
      <rect
        x='3.2641'
        y='0.0905995'
        width='62'
        height='62'
        transform='matrix(0.83205 -0.5547 0.8 0.6 6.47573 70.1288)'
        stroke='#162239'
        strokeWidth='4'
      />
      <rect
        width='66'
        height='66'
        transform='matrix(0.83205 -0.5547 0.8 0.6 6 54.282)'
        fill='#162239'
      />
      <circle
        r='20'
        transform='matrix(0.898384 0.43921 -0.0444006 0.999014 25.8691 69.2027)'
        fill='#162239'
        stroke='#eff3fb'
        strokeWidth='2'
      />
      <circle
        r='20'
        transform='matrix(0.898384 0.43921 -0.0444006 0.999014 19.9337 72.2027)'
        fill='#273550'
        stroke='#eff3fb'
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9659 80.841C21.833 81.8511 23.2831 82.2404 24.3164 81.9882C25.3497 81.7306 25.8664 80.8602 25.8664 79.3811C25.8664 77.4986 25.0041 75.7931 23.2796 74.2905C24.4483 74.179 25.0326 73.3721 25.0326 71.8722C25.0326 70.4316 24.5088 69.0716 23.4613 67.8062C22.4137 66.5593 21.0562 65.5855 19.3887 64.8832L13.7234 62.6204V77.6242L19.9659 80.841ZM16.7591 70.0789V66.4514L19.0894 67.4469C19.9445 67.8204 20.6144 68.2706 21.099 68.7971C21.5835 69.3271 21.8258 69.8837 21.8258 70.4641C21.8258 71.059 21.58 71.4184 21.0883 71.5416C20.5966 71.6636 19.9303 71.5276 19.0894 71.1374L16.7591 70.0789ZM16.7591 72.616V76.516L19.7094 78.0038C20.65 78.4896 21.3733 78.6935 21.8793 78.6104C22.3852 78.5264 22.6382 78.1629 22.6382 77.5207C22.6382 76.8785 22.3852 76.2463 21.8793 75.6274C21.3733 75.0128 20.6429 74.4725 19.688 74.0074L16.7591 72.616Z'
        fill='#eff3fb'
      />
      <mask id='path-7-inside-1_29_197' fill='#eff3fb'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51 44.4259V41C51 39.8954 51.8954 39 53 39C54.1046 39 55 39.8954 55 41V44.4011C56.7973 45.1678 58.0571 46.9511 58.0571 49.0286C58.0571 51.106 56.7973 52.8893 55 53.656V69C55 70.1046 54.1046 71 53 71C51.8954 71 51 70.1046 51 69V53.6312C49.2332 52.8513 48 51.084 48 49.0286C48 46.9732 49.2332 45.2058 51 44.4259Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51 44.4259V41C51 39.8954 51.8954 39 53 39C54.1046 39 55 39.8954 55 41V44.4011C56.7973 45.1678 58.0571 46.9511 58.0571 49.0286C58.0571 51.106 56.7973 52.8893 55 53.656V69C55 70.1046 54.1046 71 53 71C51.8954 71 51 70.1046 51 69V53.6312C49.2332 52.8513 48 51.084 48 49.0286C48 46.9732 49.2332 45.2058 51 44.4259Z'
        fill='#eff3fb'
      />
      <path
        d='M51 44.4259L51.4038 45.3408L52 45.0776V44.4259H51ZM55 44.4011H54V45.0617L54.6076 45.3209L55 44.4011ZM55 53.656L54.6076 52.7362L54 52.9954V53.656H55ZM51 53.6312H52V52.9795L51.4038 52.7163L51 53.6312ZM52 44.4259V41H50V44.4259H52ZM52 41C52 40.4477 52.4477 40 53 40V38C51.3431 38 50 39.3431 50 41H52ZM53 40C53.5523 40 54 40.4477 54 41H56C56 39.3431 54.6569 38 53 38V40ZM54 41V44.4011H56V41H54ZM54.6076 45.3209C56.0496 45.936 57.0571 47.3658 57.0571 49.0286H59.0571C59.0571 46.5364 57.5451 44.3996 55.3924 43.4813L54.6076 45.3209ZM57.0571 49.0286C57.0571 50.6913 56.0496 52.1211 54.6076 52.7362L55.3924 54.5758C57.5451 53.6575 59.0571 51.5207 59.0571 49.0286H57.0571ZM54 53.656V69H56V53.656H54ZM54 69C54 69.5523 53.5523 70 53 70V72C54.6569 72 56 70.6569 56 69H54ZM53 70C52.4477 70 52 69.5523 52 69H50C50 70.6569 51.3431 72 53 72V70ZM52 69V53.6312H50V69H52ZM51.4038 52.7163C49.9862 52.0906 49 50.6737 49 49.0286H47C47 51.4942 48.4801 53.612 50.5962 54.546L51.4038 52.7163ZM49 49.0286C49 47.3835 49.9862 45.9665 51.4038 45.3408L50.5962 43.5111C48.4801 44.4451 47 46.5629 47 49.0286H49Z'
        fill='#eff3fb'
        mask='url(#path-7-inside-1_29_197)'
      />
      <mask id='path-9-inside-2_29_197' fill='#eff3fb'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M68 65.4884V69C68 70.1046 67.1046 71 66 71C64.8954 71 64 70.1046 64 69V65.5132C62.2027 64.7465 60.9429 62.9632 60.9429 60.8857C60.9429 58.8083 62.2027 57.025 64 56.2583V41C64 39.8954 64.8954 39 66 39C67.1046 39 68 39.8954 68 41V56.2831C69.7668 57.063 71 58.8303 71 60.8857C71 62.9411 69.7668 64.7085 68 65.4884Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68 65.4884V69C68 70.1046 67.1046 71 66 71C64.8954 71 64 70.1046 64 69V65.5132C62.2027 64.7465 60.9429 62.9632 60.9429 60.8857C60.9429 58.8083 62.2027 57.025 64 56.2583V41C64 39.8954 64.8954 39 66 39C67.1046 39 68 39.8954 68 41V56.2831C69.7668 57.063 71 58.8303 71 60.8857C71 62.9411 69.7668 64.7085 68 65.4884Z'
        fill='#eff3fb'
      />
      <path
        d='M68 65.4884L67.5962 64.5735L67 64.8367V65.4884H68ZM64 65.5132H65V64.8525L64.3924 64.5933L64 65.5132ZM64 56.2583L64.3924 57.1781L65 56.9189V56.2583H64ZM68 56.2831H67V56.9348L67.5962 57.198L68 56.2831ZM67 65.4884V69H69V65.4884H67ZM67 69C67 69.5523 66.5523 70 66 70V72C67.6569 72 69 70.6569 69 69H67ZM66 70C65.4477 70 65 69.5523 65 69H63C63 70.6569 64.3431 72 66 72V70ZM65 69V65.5132H63V69H65ZM64.3924 64.5933C62.9504 63.9783 61.9429 62.5485 61.9429 60.8857H59.9429C59.9429 63.3779 61.4549 65.5147 63.6076 66.433L64.3924 64.5933ZM61.9429 60.8857C61.9429 59.223 62.9504 57.7932 64.3924 57.1781L63.6076 55.3385C61.4549 56.2568 59.9429 58.3936 59.9429 60.8857H61.9429ZM65 56.2583V41H63V56.2583H65ZM65 41C65 40.4477 65.4477 40 66 40V38C64.3431 38 63 39.3431 63 41H65ZM66 40C66.5523 40 67 40.4477 67 41H69C69 39.3431 67.6569 38 66 38V40ZM67 41V56.2831H69V41H67ZM67.5962 57.198C69.0138 57.8237 70 59.2406 70 60.8857H72C72 58.42 70.5199 56.3023 68.4038 55.3683L67.5962 57.198ZM70 60.8857C70 62.5308 69.0138 63.9478 67.5962 64.5735L68.4038 66.4032C70.5199 65.4692 72 63.3514 72 60.8857H70Z'
        fill='#eff3fb'
        mask='url(#path-9-inside-2_29_197)'
      />
      <defs>
        <filter
          id='filter0_i_29_197'
          x='6'
          y='31.6718'
          width='107.715'
          height='76.2102'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-12' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_29_197'
          />
        </filter>
      </defs>
    </svg>
  );
}
