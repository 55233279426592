import { Box, Flex } from '@chakra-ui/react';
import LoadingSpinner from 'components/common/LoadingSpinner';
import React, { useState } from 'react';

import BannerCopytrading from './BannerCopyTrading';
import ListTabs from './ListTabs';

export default function CopyTrading() {
  const [tabSelected, setTabSelected] = useState<string>();

  const isDevelopPage = true;

  return (
    <Box
      p={{ base: '0 16px', xl: 'unset' }}
      h={!tabSelected ? '100vh' : 'unset'}
    >
      <Flex
        display={isDevelopPage ? 'block' : 'none'}
        position='fixed'
        h='100vh'
        zIndex={99}
        top='0px'
        right='0px'
        bottom='0px'
        left='0px'
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          boxSize='full'
          color='white'
          textShadow='0 0 20px black'
          fontWeight='bold'
          fontSize='30px'
          backdropFilter='auto'
          backdropBlur='6px'
        >
          Under Development
        </Box>
      </Flex>
      <Box className='maxW'>
        <ListTabs setTabSelected={setTabSelected} />
        {!tabSelected ? (
          <Flex alignItems='center' justifyContent='center'>
            <LoadingSpinner />
          </Flex>
        ) : (
          <BannerCopytrading tabSelected={tabSelected} />
        )}
      </Box>
    </Box>
  );
}
