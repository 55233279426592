import React from 'react';

export default function SolanaChains() {
  return (
    <svg
      width='30.000000000000004'
      height='30.000000000000004'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
    >
      <g>
        <title>Layer 1</title>
        <g id='页面-1' fill='none' fillRule='evenodd'>
          <g id='6备份-2'>
            <circle
              id='椭圆形备份-11'
              fillOpacity='0.1'
              fill='#8A84DA'
              cx='15'
              cy='15'
              r='15'
            />
            <g stroke='null' id='编组-17' fillRule='nonzero'>
              <path
                stroke='null'
                d='m9.6622,18.26451c0.09676,-0.09672 0.22798,-0.15104 0.36479,-0.15104l12.62259,0c0.22994,0 0.34508,0.27807 0.18238,0.4406l-2.4942,2.4922c-0.09676,0.09672 -0.22796,0.15101 -0.36476,0.15101l-12.6226,0c-0.22994,0 -0.34506,-0.27802 -0.1824,-0.44056l2.4942,-2.4922l0,-0.00001z'
                id='路径'
                fill='#BA4DEF'
              />
              <path
                stroke='null'
                d='m9.6622,8.95374c0.09676,-0.0967 0.22798,-0.15102 0.36479,-0.15102l12.62259,0c0.22994,0 0.34508,0.27805 0.18238,0.44059l-2.4942,2.49221c-0.09676,0.0967 -0.22796,0.15102 -0.36476,0.15102l-12.6226,0c-0.22994,0 -0.34506,-0.27805 -0.1824,-0.44059l2.4942,-2.49221z'
                id='路径'
                fill='#36D3B8'
              />
              <path
                stroke='null'
                d='m20.33778,13.57947c-0.09676,-0.09672 -0.22796,-0.15104 -0.36476,-0.15104l-12.6226,0c-0.22994,0 -0.34506,0.27807 -0.1824,0.4406l2.4942,2.4922c0.09676,0.09672 0.22798,0.15104 0.36479,0.15104l12.62259,0c0.22994,0 0.34508,-0.27807 0.18238,-0.4406l-2.4942,-2.4922z'
                id='路径'
                fill='#8A84DA'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
