import { CoinPairTrade } from 'constants/interface';
import React from 'react';

import MainThread from '../MainThread';

interface Props {
  coinPair: CoinPairTrade;
  setCoinPair: (value: CoinPairTrade) => void;
}

export default function ShortTab({ coinPair, setCoinPair }: Props) {
  return (
    <MainThread
      coinPair={coinPair}
      setCoinPair={setCoinPair}
      isLongTab={false}
    />
  );
}
