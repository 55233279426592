import { Box, Flex } from '@chakra-ui/react';
import TemplateText from 'components/common/Text/TemplateText';
import { ChildrenPoolType } from 'constants/interface';
import React from 'react';

interface props {
  convertMoney?: ChildrenPoolType;
  convertCoin?: ChildrenPoolType;
}

export default function CalculationPool({ convertMoney, convertCoin }: props) {
  return (
    <Box
      w={{ base: '100%', lg: '460px' }}
      p='36px 30px 30px'
      borderRadius='30px'
      bg='bg.200'
    >
      <TemplateText mb='18px' txt='LP TOKENS IN YOUR WALLET' />
      <Box>
        <Flex alignItems='center' justifyContent='space-between' mb='10px'>
          <Flex alignItems='center' gap='25px'>
            <Flex position='relative' alignItems='center'>
              {convertMoney?.icon}
              <Box position='absolute' top='0px' left='18px'>
                {convertCoin?.icon}
              </Box>
            </Flex>
            <TemplateText
              fontWeight={400}
              color='text.400'
              txt={`${convertMoney?.name}/${convertCoin?.name}`}
            />
          </Flex>
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={`${(
              Number(convertMoney?.value) / Number(convertCoin?.value)
            ).toFixed(2)}`}
          />
        </Flex>
        <Flex alignItems='center' justifyContent='space-between' mb='10px'>
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={convertMoney?.name || ''}
          />
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={String(convertMoney?.value)}
          />
        </Flex>
        <Flex alignItems='center' justifyContent='space-between'>
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={convertCoin?.name || ''}
          />
          <TemplateText
            color='text.400'
            fontWeight={400}
            txt={String(convertCoin?.value)}
          />
        </Flex>
      </Box>
    </Box>
  );
}
