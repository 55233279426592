import { ChakraProvider } from '@chakra-ui/react';
import Layout from 'components/layout/Layout';
import React from 'react';
import customTheme from 'styles/theme';

import '../src/styles/main.scss';

function App() {
  return (
    <ChakraProvider theme={customTheme} portalZIndex={5000}>
      <Layout />
    </ChakraProvider>
  );
}

export default App;
