export default function Gas() {
  return (
    <svg
      width='20'
      height='17'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.00001 10.6667V0.666667C1.00001 0.489856 1.07025 0.320286 1.19527 0.195262C1.3203 0.0702379 1.48987 0 1.66668 0H7.66668C7.84349 0 8.01306 0.0702379 8.13808 0.195262C8.26311 0.320286 8.33334 0.489856 8.33334 0.666667V6H9.66668C10.0203 6 10.3594 6.14048 10.6095 6.39052C10.8595 6.64057 11 6.97971 11 7.33333V10C11 10.1768 11.0702 10.3464 11.1953 10.4714C11.3203 10.5964 11.4899 10.6667 11.6667 10.6667C11.8435 10.6667 12.0131 10.5964 12.1381 10.4714C12.2631 10.3464 12.3333 10.1768 12.3333 10V5.33333H11C10.8232 5.33333 10.6536 5.2631 10.5286 5.13807C10.4036 5.01305 10.3333 4.84348 10.3333 4.66667V2.276L9.22868 1.17133L10.1713 0.228667L13.4713 3.52867C13.5333 3.59049 13.5825 3.66396 13.616 3.74484C13.6496 3.82573 13.6668 3.91244 13.6667 4V10C13.6667 10.5304 13.456 11.0391 13.0809 11.4142C12.7058 11.7893 12.1971 12 11.6667 12C11.1362 12 10.6275 11.7893 10.2525 11.4142C9.87739 11.0391 9.66668 10.5304 9.66668 10V7.33333H8.33334V10.6667H9.00001V12H0.333344V10.6667H1.00001ZM2.33334 1.33333V5.33333H7.00001V1.33333H2.33334Z'
        fill='#b7becb'
      />
    </svg>
  );
}
