import React from 'react';

export default function OKC() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='30px'
      height='30px'
      viewBox='0 0 250 250'
      enableBackground='new 0 0 250 250'
      xmlSpace='preserve'
    >
      <image
        id='image0'
        width='100%'
        height='100%'
        x='0'
        y='0'
        href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6CAAAAACthwXhAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAADsMAAA7DAcdvqGQAAAAHdElNRQfnAhwGJxBkZeRuAAADtklEQVR42u3d0XHTQBDG8YMGiEQD
BFFBTAcOkwbcAqGEtEAL4AYYTzqIMzSAQwPxhAY4JxWIF16Y0cPp2xPWZv8/wpvlu8/r9T2tlBIA
AAAAAAAAAAAAAACAlFJ6Ufi65q3w5o974+66E2HRfDCu+q/L+5z78X/rzrLoYistultVTd6Lto2+
aLcTF83LeskbdRN9b6jAlbzotuj9X5a8qD2V9/9ej76Qrzwr+q4VRT/Rv7av9ei6XPSqouiP+m/m
72NEb9tq0Y9T9aL9D8pFZZ9x1cu+toPiVr3sU3uWVXff6zr3vW7gver0usB9r3OuK7xX3cB71XX0
eo3o9LqCc32y/Q9y3+s6971u4L3q9LrAfa9zriu8V93Ae9V19HqN6PS6gnN9sv0Pct/rOve9buC9
6vS6wH2vc64rvFfdwHvVdfR6jej0uoJzfbL9D3Lf6zr3vW7gver0usB9r3OuK7xX3cB71XU1e/0Y
+59Jr+ttZ5g5+yFf+VT0qqLo++9y8ms9+o185abikF+3ESfOTNN2l1lbdVP2q1w42Nkslamtw/U+
pWbZpuYw9m9/d0hpca4cLTe3ls+7InVGcWuaCZ0DfTCybERxxpZq8qqTqYOKfuEN9K9tY5gEnkX0
GSN6RESPiOgRET0iokdE9IiIHhHRIyJ6RESPiOgRET0iokdE9IiIHhHRIyJ6RESPiOgRET0iokdE
9IiIHhHRIyJ6RFNH1x9hePj5fz+J+sS5yL5fH3vnZt0m97nvx/7P66kHnkoHO7vVos2j/z18u00p
dW+aQ0oj///ap5RWF6fjF827rzUf2blQZxSvDIs2a3UusuZMqNywlhHFlbpo/7no/Yt+4btzuXKG
x1Ze6B9axcdW6vt/p1/6Sr6y7FYPZee6/mtruE2H4V4V9aIb7lBiuDmL97sRHeXmLPO4G9Fxbsnj
vere7zxGr0sB5Cvd97r3O48d5waL3quuo9drRKfXFZzrk+1/kPte17nvdQPvVafXBe57nXNd4b3q
Bt6rrqPXa0Sn1xWc65Ptf5D7Xte573UD71Wn1wXue51zXeG96gbeq66j12tEp9cVnOuT7X+Q+17X
ue91A+9Vp9cF7nudc13hveoG3quuo9drRKfXFZzrk+1/0Dye2HmvX6p7qFf13Z28C32wM+nPsttX
HHRbiQ+QNE1nNltx0ftF0fsXTjcuP50Jm3/amAYNu48flKmnuy9lz60sjK4MPbXZ8oDav4u2efRf
zbFOAAAAAAAAAAAAAAAAIII/M7jK8UfgLRMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDItMjhU
MDU6Mzk6MTYrMDE6MDCJYW7SAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAyLTI4VDA1OjM5OjE2
KzAxOjAw+DzWbgAAAABJRU5ErkJggg=='
      />
    </svg>
  );
}
