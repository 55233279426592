import React from 'react';

export default function SepoliaIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
width='30.000000000000004'
      height='30.000000000000004'
      viewBox='0 0 32 32'
    >
      <g fill='none' fill-rule='evenodd'>
        <circle cx='16' cy='16' r='16' fill='#627EEA' />
        <g fill='#FFF' fill-rule='nonzero'>
          <path fill-opacity='.602' d='M16.498 4v8.87l7.497 3.35z' />
          <path d='M16.498 4L9 16.22l7.498-3.35z' />
          <path fill-opacity='.602' d='M16.498 21.968v6.027L24 17.616z' />
          <path d='M16.498 27.995v-6.028L9 17.616z' />
          <path fill-opacity='.2' d='M16.498 20.573l7.497-4.353-7.497-3.348z' />
          <path fill-opacity='.602' d='M9 16.22l7.498 4.353v-7.701z' />
        </g>
      </g>
    </svg>
  );
}
