import { arbitrum } from '@wagmi/core/chains';
import { ConnectKitProvider } from 'connectkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { mantle } from 'utils/ERC20';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum],
  [publicProvider()]
);

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  publicClient,
  webSocketPublicClient,
});

const queryClient = new QueryClient();
root.render(
  <WagmiConfig config={config}>
    <ConnectKitProvider
      customTheme={{
        '--ck-body-background': '#162239',
        '--ck-overlay-background': '#0b0a1aa8',
        '--ck-primary-button-color': '#eff3fb',
        '--ck-primary-button-hover-color': '#eff3fb',
        '--ck-primary-button-background': '#273550',
        '--ck-primary-button-hover-background': '#273550',
        '--ck-body-color': '#eff3fb',
        '--ck-body-color-muted': '#b7becb',
        '--ck-secondary-button-background': '#273550',
        '--ck-secondary-button-hover-background': '#273550',
        '--ck-secondary-button-color': '#eff3fb',
        '--ck-body-background-secondary': '#162239',
        '--ck-body-action-color': '#eff3fb',
      }}
    >
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>
    </ConnectKitProvider>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
