export enum CURRENCY_TYPE {
  COIN = 'Coin',
  MONEY = 'Money',
}

export enum SWAP {
  MARKET = 'Market',
  TRANSACTIONS = 'Transaction',
}

export enum LIMIT_ORDER {
  ACTIVE = 'Active',
  HISTORY = 'History',
}

export enum TIME_FRAME {
  ONE_DAY = '1D',
  MINUTES_30 = '30Min',
}

export enum CHART_TYPE {
  BAR_CHART = 'Bar chart',
  LINE_CHART = 'Line chart',
}

export enum SWAP_TYPE {
  ORIGINAL = 'Original',
  TRADING_VIEW = 'TradingView',
}

export enum TAB_NAME {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export enum ORDER_TYPE {
  LIMIT_ORDER = 'Limit order',
  MARKET_ORDER = 'Market order',
}

export enum STATUS {
  ACTIVE = 'Active',
  PROCESSING = 'Processing',
}

export enum TAB_TYPE {
  SWAP = 'swap',
  LIMIT_ORDER = 'limitOrder',
}

export enum TYPE_MARKET {
  BEST_DIFF = 'BEST',
  MATCH_DIFF = 'MATCH',
  NEGATIVE_DIFF = 'NEGATIVE',
}
export enum TYPE_HISTORY_TRANSACTION {
  WAITING_FOR_SWAP = 'Waiting for Swap',
}

export enum TYPE_TAB_SECLECTED_COPY_TRADING {
  FUTURES = 'futures',
  SPOT = 'spot',
}

export enum TYPE_TAB_TOP_TRADERS {
  TOP_TRADERS = 'top',
  ALL_TRADERS = 'all',
}

export enum TYPE_OPTION_FILTER {
  RANKING = 'ranking',
  FOLLOWERS = 'followers',
  TOTAL_PL = 'total p&l',
  COPIERS_PNL = 'Copiers PNL',
  AUM = 'aum',
}

export enum TYPE_GET_COLOR {
  YOUR_GAS = 'Your Gas Refund',
  SLIPPAGE = 'Slippage',
}
