import { Box, Flex, Image } from '@chakra-ui/react';
import useModeTheme from 'hook/colorDarkMode';
import React from 'react';

import TemplateText from '../Text/TemplateText';

interface props {
  title: string;
  description: string;
  isEcosystem?: boolean;
}

export default function TitleSection({
  title,
  description,
  isEcosystem,
}: props) {
  const { darkModeColors } = useModeTheme();
  return (
    <Flex
      direction='column'
      alignItems='center'
      position='relative'
      p={{ base: '0 16px', xl: 'unset' }}
    >
      <TemplateText
        textAlign='center'
        fontSize={{ base: '32px', xl: '44px' }}
        fontWeight={700}
        txt={title}
      />
      <Box
        position='absolute'
        top='-80px'
        left={isEcosystem ? '100px' : '350px'}
      >
        <Image w='50%' src='./imgtite.png' />
      </Box>
      <TemplateText
        mt='24px'
        textAlign='center'
        maxW='745px'
        fontSize={{ base: '13.333px', xl: '16px' }}
        fontWeight={400}
        color={darkModeColors.text550}
        txt={description}
      />
    </Flex>
  );
}
