import {
  Box,
  Collapse,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import ListButton from 'components/common/ListButton';
import TemplateText from 'components/common/Text/TemplateText';
import TriangleDownIconRotate from 'components/common/TriangleDownIconRotate';
import { LIST_GAS_PRICE, TOLERANCE } from 'data/Swap';
import useModeTheme from 'hook/colorDarkMode';
import useVisible from 'hook/useVisible';
import React, { useCallback, useState } from 'react';

interface PopoverItemProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  slipPage: number;
  setSlipPage: (value: number) => void;
}

export default function ModalSetting({
  isOpen = true,
  onClose = () => ({}),
  slipPage,
  setSlipPage,
}: PopoverItemProps) {
  const { darkModeColors } = useModeTheme();
  const [gasPrice, setGasPrice] = useState(LIST_GAS_PRICE[0]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setGasPrice({
        price: e.target.value,
        name: gasPrice.name,
        value: gasPrice.value,
      }),
    [gasPrice.name, gasPrice.value]
  );

  const collapse = useVisible();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent position='relative' bg='bg.200' borderRadius='10px'>
        <ModalHeader
          display='flex'
          justifyContent='center'
          bg='bg.100'
          borderRadius='10px 10px 0 0 '
          alignItems='center'
          position='relative'
        >
          <Box
            position='absolute'
            bg='bg.200'
            top='15px'
            left='10px'
            p='5px 10px'
            borderRadius='6px'
            onClick={() => {
              setGasPrice(LIST_GAS_PRICE[0]);
              setSlipPage(3);
            }}
          >
            <TemplateText color='text.400' txt='reset' />
          </Box>
          <TemplateText
            fontSize='18px'
            color='text.500'
            txt='Advanced Settings'
          />
        </ModalHeader>
        <ModalCloseButton
          top='15px'
          _hover={{
            color: darkModeColors.text555,
          }}
          bg='bg.200'
          borderRadius='50%'
          color={darkModeColors.text550}
        />

        <ModalBody maxH='70vh' mt='10px' pb='20px'>
          <Box>
            <Flex alignContent='center' justifyContent='space-between'>
              <TemplateText txt='Gas Price' />
              <Flex onClick={collapse.toggle} alignItems='center' gap='10px'>
                <TemplateText
                  color='text.400'
                  txt={`Normal(${gasPrice.price} Gwei)`}
                />

                <TriangleDownIconRotate unMargin isRotate={collapse.visible} />
              </Flex>
            </Flex>
            <Collapse
              in={collapse.visible}
              animateOpacity
              style={{ marginTop: '0!important' }}
            >
              <Stack align='start'>
                <Box w='100%' mt='20px'>
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                    bg='bg.300'
                    p='15px'
                    borderRadius='10px'
                  >
                    {LIST_GAS_PRICE.map((item) => (
                      <TemplateText
                        onClick={() => setGasPrice(item)}
                        txt={item.value}
                        color={
                          gasPrice.value === item.value ? '#2669f5' : 'text.500'
                        }
                        fontSize={12}
                        key={item.name}
                      />
                    ))}
                  </Flex>
                  <Input
                    mt='20px'
                    border='none'
                    value={gasPrice.price}
                    bg='bg.300'
                    placeholder='custom'
                    _focusVisible={{ borderColor: 'transparent' }}
                    type='number'
                    color='text.500'
                    fontSize={12}
                    textAlign='end'
                    onChange={handleChange}
                  />
                </Box>
              </Stack>
            </Collapse>

            <Flex mt='20px' justifyContent='space-between' mb='20px'>
              <TemplateText txt='Slippage Tolerance' />
              <TemplateText txt={`${slipPage}%`} />
            </Flex>
            <ListButton
              selected={slipPage}
              setSelected={setSlipPage}
              data={TOLERANCE}
              p={{ base: '10px 7px', md: '10px 16px' }}
              w='100%'
              mb='8px'
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
