import {
  AvatarGroup,
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import BarChart from 'components/common/BarChart';
import Chart from 'components/common/Chart';
import LimitOrderTable from 'components/common/LimitOrderTable';
import Swap from 'components/common/SwapTable';
import TemplateText from 'components/common/Text/TemplateText';
import BarchartIcon from 'components/svg/BarchartIcon';
import ChartIcon from 'components/svg/ChartIcon';
import Retweet from 'components/svg/Reweet';
import { CHART_TYPE, TAB_TYPE } from 'constants/enum';
import { CoinPair } from 'constants/interface';
import { DetailChart } from 'constants/types';
import useModeTheme from 'hook/colorDarkMode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDateChartVer2 } from 'utils/date';
import { convertBigNumber } from 'utils/number';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  coinPair: CoinPair;
  setCoinPair: (value: CoinPair) => void;
  defaultTab?: string;
}

export default function RightWrap({
  coinPair,
  defaultTab,
  setCoinPair,
}: Props) {
  const [chart, setChart] = useState(CHART_TYPE.BAR_CHART);
  const [barChartSelect, setBarChartSelect] = useState({
    name: '1D',
    value: '1',
  });
  const { darkModeColors } = useModeTheme();
  const [getDetailChart, setGetDetailChart] = useState<DetailChart>();

  const chartTime = useMemo(() => {
    return [
      { name: '1D', value: '1' },
      { name: '1W', value: '7' },
      { name: '1M', value: '30' },
      { name: '1Y', value: '365' },
      { name: 'ALL', value: 'max' },
    ];
  }, []);

  const isSwap = useMemo(
    () => defaultTab !== TAB_TYPE.LIMIT_ORDER,
    [defaultTab]
  );

  const onSwap = useCallback(() => {
    setCoinPair({
      coinBase: coinPair.coinTarget,
      coinTarget: coinPair.coinBase,
    });
  }, [coinPair.coinBase, coinPair.coinTarget, setCoinPair]);

  useEffect(() => {
    setBarChartSelect(barChartSelect);
  }, [barChartSelect, chart]);

  return (
    <Box
      w={{ base: '60%', xl: '70%' }}
      maxW='1000px'
      display={{ base: 'none', lg: 'block' }}
      ml={{ lg: '5%', xl: '30px' }}
    >
      <Flex w='full' justifyContent='space-between'>
        <Flex w='fit-content'>
          {!coinPair ? (
            <Flex>
              <Skeleton
                h='36px'
                w='36px'
                borderRadius={50}
                startColor='white'
                endColor='gray'
              />
              <Skeleton
                h='36px'
                w='36px'
                borderRadius={50}
                startColor='white'
                endColor='gray'
              />
            </Flex>
          ) : (
            <AvatarGroup size='md' max={2}>
              <Image
                key={coinPair.coinBase.symbol}
                w='36px'
                h='36px'
                border='none'
                alt={coinPair.coinBase.name}
                src={coinPair.coinBase.img}
              />
              <Image
                key={coinPair.coinTarget.symbol}
                w='36px'
                h='36px'
                border='none'
                alt={coinPair.coinTarget.name}
                src={coinPair.coinTarget.img}
              />
            </AvatarGroup>
          )}
          <TemplateText
            pl='10px'
            h='fit-content'
            m='auto'
            fontSize='24px'
            color='text.500'
            txt={coinPair.coinBase.symbol}
          />
          <TemplateText
            h='fit-content'
            m='auto'
            px='5px'
            fontSize='16px'
            color={darkModeColors.text550}
            txt='/'
          />
          <TemplateText
            h='fit-content'
            m='auto'
            px='5px'
            fontSize='16px'
            color={darkModeColors.text550}
            txt={coinPair.coinTarget.symbol}
          />
          <Box
            color={darkModeColors.text550}
            m='auto'
            px='10px'
            cursor='pointer'
            onClick={onSwap}
          >
            <Retweet />
          </Box>
        </Flex>

        <Flex gap='10px'>
          <Button
            bg={chart === CHART_TYPE.BAR_CHART ? 'bg.300' : 'bg.200'}
            colorScheme='outline'
            borderRadius='8'
            onClick={() => {
              setChart(CHART_TYPE.BAR_CHART);
            }}
          >
            <TemplateText txt='Line Chart' pr='5px' fontSize='14px' />
            <ChartIcon />
          </Button>
          <Button
            bg={chart === CHART_TYPE.LINE_CHART ? 'bg.300' : 'bg.200'}
            colorScheme='outline'
            borderRadius='8'
            onClick={() => {
              setChart(CHART_TYPE.LINE_CHART);
            }}
          >
            <TemplateText txt='Bar Chart' pr='5px' fontSize='14px' />
            <BarchartIcon />
          </Button>
        </Flex>
      </Flex>

      <Flex w='full' justifyContent='space-between'>
        <Box pt='10px'>
          <TemplateText
            color={
              chart === CHART_TYPE.LINE_CHART
                ? getDetailChart?.color
                : 'text.500'
            }
            txt={`$${convertBigNumber(getDetailChart?.y || 0)}`}
            fontSize='24px'
          />
          <TemplateText
            txt={`${formatDateChartVer2(getDetailChart?.x || 0)}`}
            fontSize='14px'
            color={darkModeColors.text550}
          />
        </Box>
        <Box mt='10px'>
          <Flex borderRadius='8px' bg='bg.200' h='fit-content' ml='10px'>
            {chartTime.map((item) => (
              <Button
                colorScheme='outline'
                borderRadius='8px'
                onClick={() => setBarChartSelect(item)}
                bg={barChartSelect.value === item.value ? '#28344b' : 'none'}
                key={uuidv4()}
              >
                <TemplateText
                  pr='5px'
                  color={
                    barChartSelect.value === item.value
                      ? darkModeColors.text555
                      : darkModeColors.text550
                  }
                  fontSize='14px'
                  txt={item.name}
                />
              </Button>
            ))}
          </Flex>
        </Box>
      </Flex>

      <Box w='100%'>
        {chart === CHART_TYPE.LINE_CHART ? (
          <BarChart
            setGetDetailChart={setGetDetailChart}
            coinPair={coinPair}
            barChartSelect={barChartSelect.value}
          />
        ) : (
          <Chart
            setGetDetailChart={setGetDetailChart}
            coinPair={coinPair}
            barChartSelect={barChartSelect.value}
          />
        )}
      </Box>
      {/* <Routing coinPair={coinPair} /> */}
      {isSwap ? <Swap /> : <LimitOrderTable />}
    </Box>
  );
}
