import { Icon } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import TooltipItem from '../TooltipItem';

interface props {
  label: string;
}

export default function TooltipExplain({ label }: props) {
  return (
    <TooltipItem
      children={
        <Icon
          display='block'
          h='15px'
          w='15px'
          color='text.400'
          as={AiOutlineInfoCircle}
        />
      }
      label={label}
    />
  );
}
