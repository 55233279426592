import React from 'react';

interface Props {
  color?: string;
}

export default function LongIcon({ color }: Props) {
  return (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_0_24415'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='12'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 3.30469L15.803 0.801736L17.1576 9.35401L1.35455 11.857L0 3.30469Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_0_24415)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.206 0.896301L10.9102 1.57668C10.5805 1.62891 10.3554 1.93857 10.4077 2.26833C10.4599 2.59809 10.7696 2.82307 11.0993 2.77084L13.8591 2.33374L9.72826 7.99995L5.9615 5.84236C5.70204 5.69379 5.37303 5.7571 5.18727 5.99138L1.38333 10.7898C1.17592 11.0514 1.2199 11.4316 1.48152 11.639C1.61953 11.7484 1.79053 11.7879 1.95178 11.7624C2.0962 11.7395 2.23276 11.6645 2.33077 11.5409L5.81174 7.14992L9.59726 9.31826C9.86466 9.47144 10.2046 9.39887 10.3862 9.14981L14.9168 2.9351L15.3266 5.5222C15.3788 5.85193 15.6885 6.07694 16.0182 6.02472C16.348 5.97249 16.5729 5.6628 16.5207 5.33307L15.8976 1.39881C15.8454 1.06905 15.5357 0.844072 15.206 0.896301Z'
          fill={color}
        />
      </g>
    </svg>
  );
}
