import React from 'react';

interface props {
  isIncrease?: boolean;
}

export default function Arrow({ isIncrease }: props) {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M.697 4.783h6.607c.614 0 .926-.74.497-1.18L4.498.21a.696.696 0 0 0-.996 0L.198 3.603a.695.695 0 0 0 .499 1.18m0 6.334h6.607c.614 0 .926-.74.497-1.18L4.498 6.543a.696.696 0 0 0-.996 0L.198 9.936a.695.695 0 0 0 .499 1.18'
        fill={isIncrease ? '#0dcb81' : '#f6475D'}
        fillRule='evenodd'
      />
    </svg>
  );
}
